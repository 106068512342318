import React, { useEffect, useCallback } from 'react';

import {
  Card,
  Avatar,
  Button,
  IconButton,
  Icon,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';

import { Aux } from '@/components';

export default props => {
  const {
    remoteWorkspaces,
    user,
    workspace,
    pending,
    listRemoteWorkspaces,
    unlinkRemoteWorkspace
  } = props;

  useEffect(() => {
    listRemoteWorkspaces();
  }, []);

  const remove = useCallback(remote => {
    unlinkRemoteWorkspace(remote);
  });

  if (pending) {
    return <CircularProgress />;
  }

  if (remoteWorkspaces.size) {
    return (
      <Aux>
        <List className="SlackWorkspace">
          {remoteWorkspaces.map(remoteWorkspace => (
            <ListItem key={remoteWorkspace}>
              <ListItemAvatar>
                <Avatar>{remoteWorkspace.charAt(0).toUpperCase()}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={remoteWorkspace} />
              <ListItemSecondaryAction>
                <IconButton
                  color="secondary"
                  onClick={() => remove(remoteWorkspace)}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Aux>
    );
  } else {
    return (
      <div className="SlackWorkspace">
        <p>No remote workspaces linked</p>
      </div>
    );
  }
};
