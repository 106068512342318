import { connect } from 'react-redux';
import { NS as GLOBAL_NS, starService, unstarService } from '@/reducers/global';

import ServiceCell from './ServiceCell';
import './ServiceCell.scss';

const mapStateToProps = state => ({
  starred: state.getIn([GLOBAL_NS, 'starred'])
});

const mapDispatchToProps = dispatch => ({
  star: handle => dispatch(starService(handle)),
  unstar: handle => dispatch(unstarService(handle))
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, ownProps, stateProps, dispatchProps, {
    isStarred:
      stateProps.starred.filter(
        item => item.getIn(['profile', 'handle']) === ownProps.handle
      ).size !== 0
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ServiceCell);
