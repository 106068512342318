import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Portal,
  CircularProgress,
  // Alert,
  // AlertTitle,
} from '@material-ui/core';
// import { Alert, AlertTitle } from '@material-ui/lab';
// import SwaggerUI from 'swagger-ui-react';
// import YAML from 'yaml';

import { Link } from 'react-router-dom';
// import 'swagger-ui-react/swagger-ui.css';

import { SwaggerPreview } from '../swagger-preview/swagger-preview';

export const PublishedSwagger = (props) => {
  const {
    language = 'md',
    service,
    publishedPage,
    pending,
    section,
    canEdit,
    getPublishedPage,
  } = props;

  const params = useParams();

  const [spec, setSpec] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    getPublishedPage(params.pageId);
  }, []);

  useEffect(() => {
    let content = publishedPage.get('content');

    setSpec(content);

    // }
  }, [publishedPage]);

  if (pending) {
    return <CircularProgress />;
  }

  return (
    <div>
      {canEdit ? (
        <Portal container={document.getElementById('foo')}>
          <Button
            className="ml-auto center"
            color="primary"
            component={Link}
            to={`/services/${service.get('handle')}/draft-${section}/${
              params.pageId
            }`}
          >
            Edit
          </Button>
        </Portal>
      ) : null}
      <Card>
        <CardContent>
          <div className="ApiDoc">
            <SwaggerPreview spec={spec} />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
