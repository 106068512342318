import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Button,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormControl
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Redirect } from 'react-router-dom';

import { Main, Container } from '@/components';
import { required, isHandle } from '@/validators';
import slugify from '@/utils/slugify';

import './CreateTeam.scss';

const useStyles = makeStyles({
  formControl: {
    marginBottom: '20px'
  }
});

const CreateTeam = props => {
  const classes = useStyles();

  const { team, success, handleValid, createTeam, validateHandle } = props;
  const [handleHelperText, setHandleHelperText] = useState('');

  const {
    formState,
    control,
    errors,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    triggerValidation,
    register
  } = useForm({
    mode: 'onChange',
    defaultValues: { name: '', handle: '' }
  });

  useEffect(() => {
    return () => {
      props.reset();
    };
  }, []);

  useEffect(() => {
    clearErrors('handle');
    if (handleValid === false) {
      setError('handle', 'exist', 'Service handle is already taken');
      setHandleHelperText('');
    } else if (handleValid === true) {
      clearErrors('handle');
      setHandleHelperText('Service handle is available');
    } else if (handleValid === null) {
      setHandleHelperText('');
    }
  }, [handleValid]);

  const _handleSubmit = form => {
    const { name, handle } = form;
    const body = { name, handle };

    createTeam(body);
  };

  const onNameBlur = e => {
    const handle = getValues('handle');
    const name = getValues('name');
    if (!handle && name) {
      const slug = slugify(name);
      setValue('handle', slug, true);
      validateHandle(handle);
    }
  };

  const onHandleBlur = () => {
    const handle = getValues('handle');
    if (handle) {
      validateHandle(handle);
    } else {
      triggerValidation('handle');
      setHandleHelperText('');
    }
  };

  const onHandleKeyPress = e => {
    const regex = new RegExp('^[a-z0-9-]+$');
    const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);

    if (!regex.test(str)) {
      e.preventDefault();
      return false;
    }
  };

  if (team.size) {
    return <Redirect to={`/teams/${team.get('handle')}`} />;
  }

  const isValid = formState.isValid;

  return (
    <Container>
      <Main>
        <Card className="CreateTeam">
          <form onSubmit={handleSubmit(_handleSubmit)}>
            <CardHeader title="Create Team" />
            <CardContent>
              <FormControl fullWidth={true} className={classes.formControl}>
                <Controller
                  label="Name"
                  name="name"
                  as={TextField}
                  control={control}
                  rules={{ required: true }}
                  onBlur={onNameBlur}
                  autoComplete="off"
                />
              </FormControl>

              <FormControl fullWidth={true} className={classes.formControl}>
                <Controller
                  name="handle"
                  as={TextField}
                  control={control}
                  label="Handle"
                  autoComplete="off"
                  rules={{ required: true }}
                  onKeyPress={onHandleKeyPress}
                  onBlur={onHandleBlur}
                  error={!!errors.handle}
                  helperText={
                    errors.handle ? errors.handle.message : handleHelperText
                  }
                />
              </FormControl>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isValid}
              >
                Create Team
              </Button>
            </CardActions>
          </form>
        </Card>
      </Main>
    </Container>
  );
};

export default CreateTeam;
