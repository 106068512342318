import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import { NS } from './actionTypes';

import moment from 'moment';

const getService = state => state.getIn([NS, 'service']);
const getServiceHealth = state => state.getIn([NS, 'dailyServiceHealth']);

const getHealths = state => state.getIn([NS, 'health']);

const getHealthsReports = state =>
  state.getIn([NS, 'dailyHealthReport'], Map());

const GOOD = 'GOOD';
const MAINTENANCE = 'MAINTENANCE';
const WARNING = 'WARNING';
const ERROR = 'ERROR';
const CRITICAL = 'CRITICAL';

const getServiceHealthStatus = status => {
  const maintenance = status.get('maintenance');
  const warning = status.get('warning');
  const error = status.get('error');
  const critical = status.get('critical');

  if (critical) {
    return CRITICAL;
  }
  if (error) {
    return ERROR;
  }
  if (warning) {
    return WARNING;
  }
  if (maintenance) {
    return MAINTENANCE;
  }
  return GOOD;
};

const getServiceHealthMessages = status => {
  // const incidents = status.get('incidents');
  const maintenance = status.get('maintenance');
  const warning = status.get('warning');
  const error = status.get('error');
  const critical = status.get('critical');
  const messages = [];
  if (critical) {
    const time = moment().to(moment().add(critical, 'seconds'), true);
    messages.push(`${time} of critical`);
  }
  if (error) {
    const time = moment().to(moment().add(error, 'seconds'), true);
    messages.push(`${time} of error`);
  }
  if (warning) {
    const time = moment().to(moment().add(warning, 'seconds'), true);
    messages.push(`${time} of warning`);
  }
  if (maintenance) {
    const time = moment().to(moment().add(maintenance, 'seconds'), true);
    messages.push(`${time} of maintenance`);
  }
  return messages;
};

const getHealthReportMessages = status => {
  const failing = status.get('failing');
  const paused = status.get('paused');
  const passing = status.get('passing');
  const messages = [];
  if (failing) {
    messages.push(`${failing} failing events`);
  } else if (paused) {
    messages.push(`${paused} paused events`);
  }
  return messages;
};

export const getFormattedServiceHealth = createSelector(
  [getService, getServiceHealth],
  (service, serviceHealth) => {
    const title = service.get('name');

    const data = serviceHealth.map(result => {
      const time = result.get('date');
      const status = getServiceHealthStatus(result);
      const messages = getServiceHealthMessages(result);
      return Map({ time, status, messages });
    });

    const total = serviceHealth.size * 864000;
    const downtime = serviceHealth
      .map(
        item =>
          item.get('critical', 0) +
          item.get('error', 0) +
          item.get('maintenance', 0) +
          item.get('warning', 0)
      )
      .reduce((sum, incidents) => sum + incidents, 0);

    const uptime = parseFloat(100 - (downtime / total) * 100).toFixed(2);

    return Map({
      title,
      uptime,
      data
    });
  }
);

export const getHealthCheckReport = (state, id) => {
  return state.get(id, List());
};

export const getFormattedHealthCheckResults = createSelector(
  [getHealthCheckReport],
  report =>
    report.map(item => ({
      time: item.get('time'),
      start: item.get('start'),
      success: item.get('success')
    }))
);
