import { connect } from 'react-redux';
import { NS } from './reducer';
import { createTeam, getTeams, validateHandle, reset } from './actions';
import CreateTeam from './CreateTeam';

const mapStateToProps = state => ({
  teams: state.getIn([NS, 'teams']),
  team: state.getIn([NS, 'team']),
  success: state.getIn([NS, 'success']),
  handleValid: state.getIn([NS, 'handleValid'])
});

const mapDispatchToProps = dispatch => ({
  createTeam: payload => dispatch(createTeam(payload)),
  getTeams: () => dispatch(getTeams()),
  validateHandle: handle => dispatch(validateHandle(handle)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTeam);
