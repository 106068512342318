import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  NS,
  updateUser,
  updateUserIcon,
  clearUserIcon
} from '@/reducers/user';

import Profile from './Profile';

const mapStateToProps = state => ({
  user: state.getIn([NS, 'user'])
});

const mapDispatchToProps = dispatch => ({
  updateUser: payload => dispatch(updateUser(payload)),
  upload: payload => dispatch(updateUserIcon(payload)),
  clear: ({ id }) => dispatch(clearUserIcon(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
