import React, { PureComponent, useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  CircularProgress,
  Avatar,
  Button,
  Toolbar,
  Icon,
  Box,
  Card,
  CardContent,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  Select,
  FormControl,
  InputLabel,
  Typography,
  MenuItem,
  Link
} from '@material-ui/core';
// import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { makeStyles } from '@material-ui/core/styles';

import { Main, Container, SearchInput, TablePagination } from '@/components';

const useStyles = makeStyles({
  formControl: {},
  actions: {
    display: 'flex',
    alignItems: 'flexEnd',
    justifyContent: 'space-between'
  }
});

const MutedItem = props => {
  const classes = useStyles();
  const { muted, updateMuted, deleteMuted } = props;
  const [mutedValue, setMutedValue] = useState('all');

  useEffect(() => {
    if (muted.activity && muted.notifications) {
      setMutedValue('all');
    } else if (muted.activity) {
      setMutedValue('activity');
    } else {
      setMutedValue('notifications');
    }
  }, []);

  const handleChange = useCallback(event => {
    const value = event.target.value;
    setMutedValue(value);

    let payload = { activity: true, notifications: true };
    if (value === 'activity') {
      payload = { activity: true, notifications: false };
    } else if (value === 'notifications') {
      payload = { activity: false, notifications: true };
    }

    updateMuted(muted.profile.id, payload);
  });

  const handleDelete = useCallback(() => {
    deleteMuted(muted.profile.id);
  });

  return (
    <div>
      <p>{muted.profile.name}</p>
      <p>{muted.profile.description}</p>
      <Box className={classes.actions}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-helper-label">Muted</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={mutedValue}
            onChange={handleChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="activity">Activities</MenuItem>
            <MenuItem value="notifications">Notifications</MenuItem>
          </Select>
        </FormControl>
        <Button onClick={handleDelete}>Unmute</Button>
      </Box>
    </div>
  );
};

export default props => {
  const {
    user,
    muted,
    pending,
    getMuted,
    updateMuted,
    deleteMuted,
    updateCrumbs
  } = props;

  useEffect(() => {
    const handle = user.get('handle');
    getMuted(handle);

    updateCrumbs([{ label: 'Muted' }]);
  }, []);

  if (pending) {
    return (
      <Box mx="auto">
        <CircularProgress size={32} />
      </Box>
    );
  }

  if (!muted.length) {
    return (
      <Card>
        <CardContent>
          <Typography>You didn't muted any notifications</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        {muted.map(m => (
          <MutedItem
            key={m.profile.id}
            muted={m}
            updateMuted={updateMuted}
            deleteMuted={deleteMuted}
          />
        ))}
      </CardContent>
    </Card>
  );
};
