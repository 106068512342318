import './InputFieldGroup.scss';

import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  TextField,
  FormControl
} from '@material-ui/core';
import { set } from 'lodash';

const getFieldValue = (entity, field) => {
  return entity.getIn(field.split('.')) || '';
};

const getDefaultValues = (entity, field) => {
  const defaultValues = {};
  const value = getFieldValue(entity, field);
  set(defaultValues, field, value);
  return defaultValues;
};

const InputFieldGroup = props => {
  const { label, sublabel, field, entity, entityId, save, ...extra } = props;

  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(entity, field)
  });

  useEffect(() => {
    const value = getFieldValue(entity, field);
    reset({ [field]: value }, { isDirty: false });
  }, [entity]);

  const onSubmit = form => {
    reset(form, { isDirty: false });
    save({ id: entityId, body: form });
  };

  const { isDirty } = formState;

  return (
    <Card className="InputFieldGroup">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title={label} />
        <CardContent>
          <FormControl fullWidth>
            <Controller
              name={field}
              as={TextField}
              control={control}
              autoComplete="off"
              helperText={sublabel}
              {...extra}
            />
          </FormControl>
        </CardContent>
        {isDirty ? (
          <CardActions>
            <Button color="primary" variant="contained" type="submit">
              Save
            </Button>
          </CardActions>
        ) : null}
      </form>
    </Card>
  );
};

export default InputFieldGroup;
