import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  CardHeader,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import { Redirect, Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    alignSelf: 'center',
    justifySelf: 'center',
    margin: 'auto',
    width: '80%',
    maxWidth: '400px'
  },
  formControl: {
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: '0px'
    }
  },
  actions: {
    justifyContent: 'space-between'
  },
  alert: { margin: '0 16px' }
});

const Login = props => {
  const { token, error, reset, login, getWorkspace } = props;

  const styles = useStyles();

  const [submitting, setSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [redirect] = useState(localStorage.getItem('auth_redirect'));
  const { control, handleSubmit, formState, reset: resetForm } = useForm({
    mode: 'onChange',
    defaultValues: { email: '', password: '' }
  });

  const { isValid, isDirty } = formState;

  useEffect(() => {
    if (showError) {
      setShowError(false);
    }
  }, [isDirty]);

  useEffect(() => {
    if (error) {
      setShowError(true);
      setSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    getWorkspace();
    return () => {
      reset();
    };
  }, []);

  const onSubmit = form => {
    const { email, password } = form;
    setSubmitting(true);
    resetForm({ email, password }, { isDirty: false });
    login(email, password);
  };

  if (token) {
    localStorage.removeItem('auth_redirect');
    return <Redirect to={redirect} />;
  }

  return (
    <Card className={styles.root} variant="outlined">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="Login" />
        {showError ? (
          <Alert severity="error" className={styles.alert}>
            Login/password did not match
          </Alert>
        ) : null}
        <CardContent>
          <FormControl fullWidth className={styles.formControl}>
            <Controller
              label="Email"
              name="email"
              as={TextField}
              control={control}
              rules={{
                required: true,
                pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
              }}
              fullWidth={true}
            />
          </FormControl>
          <FormControl fullWidth>
            <Controller
              label="Password"
              name="password"
              type="password"
              as={TextField}
              control={control}
              rules={{
                required: true
              }}
              fullWidth={true}
            />
          </FormControl>
        </CardContent>
        <CardActions className={styles.actions}>
          <Button
            className="mr-auto"
            to="/forgot-password"
            color="primary"
            component={Link}
          >
            Forgot Password
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || submitting}
          >
            Submit
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default Login;
