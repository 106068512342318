import Overview from './Overview';

import { connect } from 'react-redux';
import { NS } from '../../ducks/actionTypes';
import {
  getTeamById,
  updateTeamById,
  updateTeamAvatarById,
  toggleAddMemberModal,
  toggleInviteModal,
  reset,
  getPublicFeed,
  updateCrumbs
} from '../../ducks/actions';

const mapStateToProps = state => ({
  pending: state.getIn([NS, 'pending', 'team']),
  team: state.getIn([NS, 'team']),
  publicFeed: state.getIn([NS, 'feed']),
  publicFeedPending: state.getIn([NS, 'pending', 'feed']),
  canEdit: state.getIn([NS, 'team', 'is_admin'], false)
});

const mapDispatchToProps = dispatch => ({
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs)),
  getPublicFeed: handle => dispatch(getPublicFeed(handle))
  //   getTeam: payload => dispatch(getTeamById(payload)),
  //   update: payload => dispatch(updateTeamById(payload)),
  //   upload: payload => dispatch(updateTeamAvatarById(payload)),
  //   showAddMember: () => dispatch(toggleAddMemberModal(true)),
  //   showInviteUser: () => dispatch(toggleInviteModal(true)),
  //   reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview);
