import React, { PureComponent } from 'react';

import { IconButton, Icon } from '@material-ui/core';

class StarService extends PureComponent {
  state = { count: 0, starred: false };
  componentDidMount() {
    const { count, starred } = this.props;
    this.setState({ count, starred });
  }

  handleStar = event => {
    event.preventDefault();
    const { star, handle } = this.props;
    const { count } = this.state;
    star(handle);
    this.setState({ count: count + 1, starred: true });
  };

  handleUnstar = event => {
    event.preventDefault();
    const { unstar, handle } = this.props;
    const { count } = this.state;
    unstar(handle);
    this.setState({ count: count - 1, starred: false });
  };

  render() {
    const { count, starred } = this.state;

    return (
      <div className="ServiceCell__actions">
        {starred ? (
          <IconButton
            color="primary"
            onClick={this.handleUnstar}
          >
            <Icon>star</Icon>
          </IconButton>
        ) : (
          <IconButton onClick={this.handleStar}>
            <Icon>star</Icon>
          </IconButton>
        )}
      </div>
    );
  }
}

export default StarService;
