import React, { PureComponent } from 'react';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemAvatar
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { Container, Main, ServiceHealth, Sidenav } from '@/components';
import Activity from './components/Activity';
import { TourButton } from './components/Tour/Tour';
import './Home.scss';

const firstChar = str => str.charAt(0).toUpperCase();

export const Placeholder = ({ number = 3 }) => {
  return (
    <React.Fragment>
      {Array.from(new Array(number)).map((_, i) => (
        <ListItem key={i}>
          <ListItemAvatar>
            <Skeleton variant="circle" width={40} height={40} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Skeleton height={18} width="60%" style={{ marginBottom: 4 }} />
            }
            secondary={
              <Skeleton height={14} width="80%" style={{ marginBottom: 0 }} />
            }
          />
        </ListItem>
      ))}
    </React.Fragment>
  );
};

export const HomeItem = ({ link, primary, secondary, thumbnail }) => (
  <ListItem button to={link} component={Link}>
    <ListItemAvatar>
      <Avatar alt={primary} src={thumbnail}></Avatar>
    </ListItemAvatar>
    <ListItemText primary={primary} secondary={secondary} />
  </ListItem>
);

export class Home extends PureComponent {
  componentDidMount() {
    this.props.getServices();
    this.props.getTeams();
    this.props.getUserEvents();
  }

  render() {
    const {
      services,
      servicesPending,
      teams,
      teamsPending,
      starred,
      events,
      eventsLoading
    } = this.props;

    return (
      <Container>
        <Sidenav
          id="home-sidenav-left"
          className="Home__sidenav Home__sidenav--right"
        >
          <List>
            <ListSubheader style={{ position: 'relative' }}>
              My Services
            </ListSubheader>
            {servicesPending ? (
              <Placeholder />
            ) : services.size ? (
              services.map(service => (
                <HomeItem
                  key={service.get('id')}
                  link={`/services/${service.get('handle')}`}
                  primary={service.get('name')}
                  secondary={
                    <ServiceHealth
                      prefix="Status: "
                      health={service.get('health')}
                    />
                  }
                  thumbnail={service.getIn(['thumbnail_url'])}
                />
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No Services" disabled={true} />
              </ListItem>
            )}
            <Divider />
            <ListSubheader style={{ position: 'relative' }}>
              My Teams
            </ListSubheader>
            {teamsPending ? (
              <Placeholder />
            ) : teams.size ? (
              teams.map(team => (
                <HomeItem
                  key={team.get('id')}
                  link={`/teams/${team.get('handle')}`}
                  primary={team.get('name')}
                  secondary={`Members: ${team.get('member_count')}`}
                  thumbnail={team.getIn(['thumbnail_url'])}
                />
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No Teams" />
              </ListItem>
            )}
          </List>
        </Sidenav>
        <Main>
          {/* <TourButton /> */}
          <Activity events={events} loading={eventsLoading} />
        </Main>
        <Sidenav
          id="home-sidenav-right"
          className="Home__sidenav Home__sidenav--right"
        >
          <List>
            <ListSubheader style={{ position: 'relative' }}>
              Starred Services
            </ListSubheader>
            {starred.size ? (
              starred.map(service => (
                <HomeItem
                  key={service.getIn(['id'])}
                  link={`/services/${service.getIn(['profile', 'handle'])}`}
                  primary={service.getIn(['profile', 'name'])}
                  // secondary={
                  //   <ServiceHealth
                  //     prefix="Status: "
                  //     health={service.getIn(['profile', 'health'])}
                  //   />
                  // }
                  thumbnail={service.getIn(['profile', 'thumbnail_url'])}
                />
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No Starred" />
              </ListItem>
            )}
          </List>
        </Sidenav>
      </Container>
    );
  }
}

export default Home;
