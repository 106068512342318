export const READ_TEAM = 'visr.team.read';
export const READ_SERVICE = 'visr.service.read';
export const MANAGE_SERVICE = 'visr.service.manage';
export const READ_RUNNER = 'visr.runner.read';
export const MANAGE_RUNNER = 'visr.runner.manage';
export const READ_ANNOUNCEMENT = 'visr.announcement.read';
export const MANAGE_ANNOUNCEMENT = 'visr.announcement.manage';
export const READ_RELEASE_NOTES = 'visr.release-notes.read';
export const MANAGE_RELEASE_NOTES = 'visr.release-notes.manage';
export const READ_API_DOCS = 'visr.api-docs.read';
export const MANAGE_API_DOCS = 'visr.api-docs.manage';
export const READ_USER = 'visr.user.read';
export const READ_NOTICE = 'visr.notice.read';
export const MANAGE_NOTICE = 'visr.notice.manage';
export const READ_HEALTH_CHECK = 'visr.health-check.read';
export const MANAGE_HEALTH_CHECK = 'visr.health-check.manage';

export const scopeOptions = [
  READ_TEAM,
  READ_SERVICE,
  MANAGE_SERVICE,
  READ_RUNNER,
  MANAGE_RUNNER,
  READ_ANNOUNCEMENT,
  READ_RELEASE_NOTES,
  MANAGE_RELEASE_NOTES,
  READ_API_DOCS,
  MANAGE_API_DOCS,
  READ_USER,
  READ_NOTICE,
  MANAGE_NOTICE,
  READ_HEALTH_CHECK,
  MANAGE_HEALTH_CHECK,
];
