import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  TextField
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useLocation, useParams, Redirect } from 'react-router-dom';
import slugify from '@/utils/slugify';
import { isHandle, isStrong } from '@/validators';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  alert: { margin: '0 16px' },
  formControl: {
    marginBottom: '20px'
  }
});

const Invite = props => {
  const { workspace, success, error, createUser } = props;
  const location = useLocation();
  const params = useParams();
  const styles = useStyles();
  const {
    control,
    errors,
    formState,
    handleSubmit,
    getValues,
    setValue
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      handle: '',
      password: ''
    }
  });

  const { isDirty, isValid } = formState;

  const onSubmit = values => {
    const { code } = queryString.parse(location.search);
    const body = {
      workspace: workspace,
      invite: { id: params.id, code },
      user: values
    };

    createUser(body);
  };

  const onNameBlur = e => {
    const { value = '' } = e.target;
    if (!getValues('handle') && value) {
      const slug = slugify(value);
      setValue('handle', slug, { shouldValidate: true });
    }
  };

  const onHandleKeyPress = e => {
    const regex = new RegExp('^[a-z-]+$');
    const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }

    e.preventDefault();
    return false;
  };

  if (success) {
    return <Redirect to={`/`} />;
  }

  return (
    <Container maxWidth="xs" className={styles.root}>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title="Join Visr" />
          {error ? (
            <Alert severity="error" className={styles.alert}>
              There was an error when submitting your data
            </Alert>
          ) : null}
          <CardContent>
            <FormControl fullWidth className={styles.formControl}>
              <Controller
                name="name"
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    label="Name"
                    onChange={onChange}
                    onBlur={e => {
                      onBlur(e);
                      onNameBlur(e);
                    }}
                    error={!!errors.name}
                    value={value}
                  />
                )}
                rules={{ required: true }}
                autoComplete="off"
              />
            </FormControl>
            <FormControl fullWidth className={styles.formControl}>
              <Controller
                name="handle"
                label="Handle"
                control={control}
                as={TextField}
                rules={{
                  required: true,
                  validate: {
                    isHandle: v => isHandle(v) || 'The handle is not valid'
                  }
                }}
                autoComplete="off"
                onKeyPress={onHandleKeyPress}
                error={!!errors.handle}
                helperText={errors.handle ? errors.handle.message : ''}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                name="password"
                label="Password"
                type="password"
                as={TextField}
                control={control}
                rules={{
                  required: true,
                  validate: {
                    isStrong: v => isStrong(v) || 'Password not strong enough'
                  }
                }}
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ''}
              />
            </FormControl>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isValid}
            >
              Join
            </Button>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
};

export default Invite;
