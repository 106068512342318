import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

import globalReducer, { NS as GLOBAL_NS } from '@/reducers/global';
import authReducer, { NS as AUTH_NS } from '@/reducers/auth';

import notFoundReducer, { NS as NOT_FOUND_NS } from '@/reducers/notFound';
import loginReducer, { NS as LOGIN_NS } from '@/screens/Login/reducer';
import forgotPasswordReducer, {
  NS as FORGOT_PASSWORD_NS,
} from '@/screens/ForgotPassword/reducer';
import resetPasswordReducer, {
  NS as RESET_PASSWORD_NS,
} from '@/screens/ResetPassword/ducks/reducer';
import homeReducer, { NS as HOME_NS } from '@/screens/Home/ducks/reducer';

import servicesReducer, {
  NS as SERVICES_NS,
} from '@/screens/Services/ducks/reducer';
import serviceReducer, {
  NS as SERVICE_NS,
} from '@/screens/Service/ducks/reducer';

import adminReducer, { NS as ADMIN_NS } from '@/screens/Admin/ducks/reducer';
import {
  BillingReducer,
  NS as BILLING_NS,
} from '@/screens/Billing/ducks/reducer';

import teamsReducer, { NS as TEAMS_NS } from '@/screens/Teams/ducks/reducer';
import teamReducer, { NS as TEAM_NS } from '@/screens/Team/ducks/reducer';

import usersReducer, { NS as USERS_NS } from '@/screens/Users/ducks/reducer';
import meReducer, { NS as ME_NS } from '@/reducers/user';
import userReducer, { NS as USER_NS } from '@/screens/User/ducks/reducer';

import createServiceReducer, {
  NS as CREATE_SERVICE_NS,
} from '@/screens/CreateService/reducer';
import createTeamReducer, {
  NS as CREATE_TEAM_NS,
} from '@/screens/CreateTeam/reducer';
import InviteReducer, { NS as INVITE_NS } from '@/screens/Invite/reducer';
import InviteUserReducer, {
  NS as INVITE_USER_NS,
} from '@/screens/InviteUser/ducks/reducer';

import WatchedReducer, {
  NS as WATCHED_NS,
} from '@/screens/Watched/ducks/reducer';

import RegisterReducer, {
  NS as REGISTER_NS,
} from '@/screens/Register/ducks/reducer';

import ConnectToSlackReducer, {
  NS as CONNECT_TO_SLACK_NS,
} from '@/screens/ConnectToSlack/ducks/reducer';

export const makeRootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    [GLOBAL_NS]: globalReducer,
    [AUTH_NS]: authReducer,
    [ADMIN_NS]: adminReducer,
    [BILLING_NS]: BillingReducer,
    [LOGIN_NS]: loginReducer,
    [FORGOT_PASSWORD_NS]: forgotPasswordReducer,
    [RESET_PASSWORD_NS]: resetPasswordReducer,
    [USERS_NS]: usersReducer,
    [ME_NS]: meReducer,
    [USER_NS]: userReducer,
    [TEAM_NS]: teamReducer,
    [HOME_NS]: homeReducer,
    [SERVICES_NS]: servicesReducer,
    [SERVICE_NS]: serviceReducer,
    [TEAMS_NS]: teamsReducer,
    [CREATE_SERVICE_NS]: createServiceReducer,
    [CREATE_TEAM_NS]: createTeamReducer,
    [NOT_FOUND_NS]: notFoundReducer,
    [INVITE_NS]: InviteReducer,
    [INVITE_USER_NS]: InviteUserReducer,
    [WATCHED_NS]: WatchedReducer,
    [REGISTER_NS]: RegisterReducer,
    [CONNECT_TO_SLACK_NS]: ConnectToSlackReducer,
    // ...asyncReducers
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
