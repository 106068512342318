import { combineEpics } from 'redux-observable';
import AuthEpics from './auth';
import GlobalEpics from './global';
import AdminEpics from '@/screens/Admin/ducks/epics';
import * as BillingEpics from '@/screens/Billing/ducks/epics';
import LoginEpics from '@/screens/Login/epics';
import ForgotPasswordEpics from '@/screens/ForgotPassword/epics';
import ResetPasswordEpics from '@/screens/ResetPassword/ducks/epics';
import HomeEpics from '@/screens/Home/ducks/epics';

import ServicesEpics from '@/screens/Services/ducks/epics';
import ServiceEpics from '@/screens/Service/ducks/epics';

import UsersEpics from '@/screens/Users/ducks/epics';
import Mepics from './user';
import UserEpics from '@/screens/User/ducks/epics';

import TeamsEpics from '@/screens/Teams/ducks/epics';
import TeamEpics from '@/screens/Team/ducks/epics';

import CreateServiceEpics from '@/screens/CreateService/epics';
import CreateTeamsEpics from '@/screens/CreateTeam/epics';
import InviteEpics from '@/screens/Invite/epics';
import InviteUser from '@/screens/InviteUser/ducks/epics';

import WatchedEpic from '@/screens/Watched/ducks/epics';

import RegisterEpics from '@/screens/Register/ducks/epics';

import ConnectToSlackEpics from '@/screens/ConnectToSlack/ducks/epics';

export default combineEpics(
  ...AuthEpics,
  ...GlobalEpics,
  ...AdminEpics,
  ...Object.values(BillingEpics),
  ...UsersEpics,
  ...UserEpics,
  ...Mepics,
  ...LoginEpics,
  ...ForgotPasswordEpics,
  ...ResetPasswordEpics,
  ...HomeEpics,
  ...ServicesEpics,
  ...ServiceEpics,
  ...TeamsEpics,
  ...TeamEpics,
  ...CreateServiceEpics,
  ...CreateTeamsEpics,
  ...InviteEpics,
  ...InviteUser,
  ...WatchedEpic,
  ...RegisterEpics,
  ...ConnectToSlackEpics
);
