import React, { PureComponent } from 'react';
import Register from './components/Register';
import CreateWorkspace from './components/CreateWorkspace';
import { useEffect } from 'react';

const RegisterView = props => {
  const {
    location,
    workspace,
    registerSuccess,
    createWorkspaceSuccess,
    reset
  } = props;

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  if (createWorkspaceSuccess) {
    const { protocol, host } = window.location;
    const [, root] = host.split(/\.(?=[a-z]+\.[^\.]+$)/);
    return (window.location = `${protocol}//${workspace}.${root}`);
  }

  if (registerSuccess || true) {
    return <CreateWorkspace />;
  }

  return <Register location={location} />;
};

export default RegisterView;
