import { Map, List, fromJS } from 'immutable';

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  success: false,
  teams: List([]),
  error: false,
  service: Map(),
  handleValid: null
});

export default function CreateServiceReducer(state = initialState, action) {
  switch (action.type) {
    // case ActionTypes.TEAMS_SEARCH_SUCCESS:
    //   return state.set('teams', action.payload);
    case ActionTypes.TEAMS_GET_SUCCESS:
      return state.set('teams', fromJS(action.payload));
    case ActionTypes.SERVICE_CREATE_SUCCESS:
      return state
        .set('success', true)
        .set('error', false)
        .set('service', Map(action.payload));

    case ActionTypes.CREATE_SERVICE_FAIL:
      return state.set('error', true);

    case ActionTypes.VALIDATE_SERVICE_HANDLE_SUCCESS:
      return state.set('handleValid', action.payload.valid);

    case ActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
}
