import { of, concat } from 'rxjs';
import {
  switchMap,
  startWith,
  flatMap,
  merge,
  map,
  catchError
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from '@/utils/ajax';

import ActionTypes from './actionTypes';

import { Actions as AuthActions } from '@/reducers/auth';

import { NS as GLOBAL_NS } from '@/reducers/global';

export const login$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.LOGIN),
    switchMap(action =>
      ajax(
        {
          url: `/session`,
          method: 'POST',
          body: {
            workspace: state$.value.getIn([GLOBAL_NS, 'workspace']),
            login: action.payload.email,
            password: action.payload.password
          }
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        map(AuthActions.getToken),
        catchError(error =>
          of({
            type: ActionTypes.LOGIN_FAIL,
            payload: error,
            error: true
          })
        )
      )
    )
  );

export default [login$];
