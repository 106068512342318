import { connect } from 'react-redux';
import { toggleViewNoticeModal } from '@/screens/Service/ducks/actions';
import { NS } from '@/screens/Service/ducks/reducer';
import NoticeViewModal from './NoticeViewModal';

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service']),
  notice: state.getIn([NS, 'notice']),
  visible: state.getIn([NS, 'noticeViewModalVisible'])
});

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(toggleViewNoticeModal(false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoticeViewModal);
