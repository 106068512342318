import { connect } from 'react-redux';

import { resendInvite } from '@/screens/Users/ducks/actions';

import InviteMenu from './InviteMenu';

const mapDispatchToProps = dispatch => ({
  resendInvite: id => dispatch(resendInvite(id))
});

export default connect(
  null,
  mapDispatchToProps
)(InviteMenu);
