import React, { PureComponent } from 'react';
import {
  Button,
  IconButton,
  Icon,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import { Aux } from '@/components';

export class SsoProviders extends PureComponent {
  componentDidMount() {
    const { getSsoProviders } = this.props;
    getSsoProviders();
  }

  toggleEdit = () => {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  };

  handleSave = ({ body, ssoProvider }) => {
    const { createSsoProvider, updateSsoProvider } = this.props;

    if (!ssoProvider) {
      createSsoProvider(body);
    } else {
      const { thumbnail, login_url, logout_url, handle, ...rest } = body;
      updateSsoProvider(handle, body);
    }
    this.setState({ edit: false });
  };

  getContent() {
    const { ssoProviders } = this.props;

    if (this.props.ssoProviders.size) {
      return (
        <Aux>
          <List className="SlackWorkspace">
            {ssoProviders.map(ssoProvider => (
              <ListItem key={ssoProvider.get('handle')}>
                <ListItemText
                  primary={ssoProvider.get('name')}
                  secondary={`${
                    ssoProvider.get('is_default') ? 'Default Provider' : ''
                  }`}
                />
                <ListItemSecondaryAction>
                <IconButton
                  raised
                  primary
                  component={Link}
                  to={`/admin/sso-providers/${ssoProvider.get('handle')}`}
                >
                  <Icon>edit</Icon>
                </IconButton></ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Aux>
      );
    } else {
      return (
        <div className="SlackWorkspace">
          <p>No Providers exist for this account</p>
        </div>
      );
    }
  }
  render() {
    const { pending } = this.props;

    if (pending) {
      return <CircularProgress />;
    }
    return this.getContent();
  }
}
