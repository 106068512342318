import { of, concat } from 'rxjs';
import {
  switchMap,
  startWith,
  flatMap,
  merge,
  map,
  catchError,
  tap
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from '@/utils/ajax';

import ActionTypes from './actionTypes';

import { NS as GLOBAL_NS } from '@/reducers/global';

export const forgotPassword$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.FORGOT_PASSWORD),
    switchMap(action =>
      ajax(
        {
          url: `/forgot-password`,
          method: 'POST',
          body: {
            workspace: state$.value.getIn([GLOBAL_NS, 'workspace']),
            email: action.payload.email
          }
        },
        { action$, state$ }
      ).pipe(
        map(({ response }) => response),
        flatMap(() => of({ type: ActionTypes.FORGOT_PASSWORD_SUCCESS })),
        catchError(error =>
          of({
            type: ActionTypes.FORGOT_PASSWORD_FAIL,
            payload: error,
            error: true
          })
        )
      )
    )
  );

export default [forgotPassword$];
