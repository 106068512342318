import { connect } from 'react-redux';
import {
  getHealthCheckResult,
  toggleHealthcheckModal
} from '@/screens/Service/ducks/actions';
// import { getFormattedHealthchecks } from '@/screens/Service/ducks/selectors';
import { NS } from '@/screens/Service/ducks/reducer';
import { getFormattedHealthCheckResults } from '@/screens/Service/ducks/selectors';
import HealthCheck from './HealthCheck';
import './HealthCheck.scss';

const mapStateToProps = state => ({
  state,
  healthCheckReports: state.getIn([NS, 'healthCheckReports']),
  canEdit: state.getIn([NS, 'team', 'is_member']),
  // healthChecks: state.getIn([NS, 'healthChecks'])
});

const mapDispatchToProps = dispatch => ({
  getHealthCheckResult: id => dispatch(getHealthCheckResult(id)),
  editHealtcheck: healthcheck =>
    dispatch(toggleHealthcheckModal(true, healthcheck))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    healthCheckResults: getFormattedHealthCheckResults(
      stateProps.healthCheckReports,
      ownProps.healthcheck.get('id')
    ),
    pending: stateProps.state.getIn([
      NS,
      'pending',
      'healthCheckReports',
      ownProps.healthcheck.get('id')
    ], true)
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(HealthCheck);
