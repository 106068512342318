import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NS } from '@/screens/Service/ducks/reducer';
import {
  getDraftReleaseNotes,
  deleteDraftReleaseNote,
  updateCrumbs
} from '@/screens/Service/ducks/actions';

import { DraftPages } from '../../components/DraftPages';

export const DraftReleaseNotes = props => {
  const { updateCrumbs } = props;
  useEffect(() => {
    updateCrumbs([
      {
        label: 'Drafts Release Notes'
      }
    ]);
  }, []);
  return <DraftPages {...props} />;
};

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service']),
  drafts: state.getIn([NS, 'draftReleaseNotes']),
  pending: state.getIn([NS, 'pending', 'draftReleaseNotes'])
});
const mapDispatchToProps = dispatch => ({
  getDrafts: handle => dispatch(getDraftReleaseNotes(handle)),
  deleteDraft: id => dispatch(deleteDraftReleaseNote(id)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraftReleaseNotes);
