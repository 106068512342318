import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { NS } from '@/screens/Service/ducks/reducer';
import Settings from './Settings';
import {
  updateService,
  updateServiceAvatar,
  updateParent,
  updateHealth,
  updateVisibility,
  updateStage,
  clearServiceIcon,
  deleteService,
  getTeams,
  exportPages,
  updateCrumbs
} from '@/screens/Service/ducks/actions';

import './Settings.scss';

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service']),
  team: state.getIn([NS, 'team']),
  teams: state.getIn([NS, 'teams'])
});

const mapDispatchToProps = dispatch => ({
  getTeams: payload => dispatch(getTeams(payload)),
  update: payload => dispatch(updateService(payload)),
  updateHealth: ({ id, body }) => dispatch(updateHealth(id, body.health)),
  updateVisibility: ({ id, body }) =>
    dispatch(updateVisibility(id, body.visibility)),
  updateStage: ({ id, body }) => dispatch(updateStage(id, body.stage)),
  updateParent: ({ id, body }) => dispatch(updateParent(id, body)),
  upload: payload => dispatch(updateServiceAvatar(payload)),
  exportPages: serviceHandle => dispatch(exportPages(serviceHandle)),
  clear: payload => dispatch(clearServiceIcon(payload)),
  deleteService: payload => dispatch(deleteService(payload)),
  updateCrumbs: payload => dispatch(updateCrumbs(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
