import React, { PureComponent } from 'react';

import {
  Card,
  Avatar,
  Button,
  IconButton,
  Icon,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';

import { Aux } from '@/components';

import { useEffect } from 'react';

const generateAuthUrl = (workspace, user) => {
  const host =
    window.location.hostname.replace(/^[a-zA-Z0-9-]+\./, '') +
    (window.location.port ? '' : '');
  const protocol = window.location.protocol;
  const redirect = encodeURIComponent(
    `${protocol}//slackapp.${host}/auth?workspace=${workspace}&user=${user.get(
      'handle'
    )}`
  );
  const url = `https://slack.com/oauth/authorize?client_id=299779671683.784956628594&scope=commands,chat:write:bot,im:write&redirect_uri=${redirect}`;
};

export default props => {
  const {
    slackWorkspaces,
    user,
    workspace,
    pending,
    getSlackWorkspaces
  } = props;

  useEffect(() => {
    getSlackWorkspaces();
  }, []);

  if (pending) {
    return <CircularProgress />;
  }

  if (slackWorkspaces.size) {
    return (
      <Aux>
        <List className="SlackWorkspace">
          {slackWorkspaces.map(slackWorkspace => (
            <ListItem key={slackWorkspace.get('id')}>
              <ListItemAvatar>
                <Avatar>
                  {slackWorkspace
                    .get('team')
                    .charAt(0)
                    .toUpperCase()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={slackWorkspace.get('team')}
                secondary={`Created By: ${slackWorkspace.get('user')}`}
              />
              <ListItemSecondaryAction>
                {/* <IconButton color="primary" onClick={this.toggleEdit}>
                    <Icon>edit</Icon>
                  </IconButton> */}
                {/* <IconButton color="primary" onClick={this.toggleEdit}>
                    <Icon>delete</Icon>
                  </IconButton> */}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Aux>
    );
  } else {
    const url = generateAuthUrl(workspace, user);

    return (
      <div className="SlackWorkspace">
        <p>No Slack accounts configured for this account</p>
        {/* <Button raised primary onClick={this.toggleEdit}>
            Add Token
          </Button> */}
        <a href={url}>
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
      </div>
    );
  }
};
