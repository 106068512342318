import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader
  // Subheader
} from '@material-ui/core';
import { matchPath } from 'react-router';

export default class ServiceMenu extends PureComponent {
  isActive(tab) {
    const {
      location: { pathname }
    } = this.props;

    if (
      matchPath(pathname, {
        path: tab.path,
        exact: tab.exact,
        strict: false
      })
    ) {
      return true;
    }

    if (tab.childPath && tab.childPath.length) {
      return tab.childPath.some(path =>
        matchPath(pathname, {
          path: path,
          strict: false
        })
      );
    }

    return false;
  }

  render() {
    const { serviceId: id, tabs: _tabs, serviceLinks, location } = this.props;

    const tabs = _tabs.filter(({ displayInNav }) => displayInNav !== false);
    const pathname = location.pathname;

    return (
      <List>
        {tabs.map(tab => {
          const active = this.isActive(tab);

          return (
            <ListItem
              key={tab.key}
              to={tab.path.replace(':id', id)}
              component={Link}
              params={{ id }}
              className={active ? 'active' : ''}
              selected={active}
              button
            >
              <ListItemText primary={tab.label} />
            </ListItem>
          );
        })}
        {/* <Divider />
        <ListSubheader>Links</ListSubheader>
        {serviceLinks.size ? (
          serviceLinks.map((link, index) => (
            <ListItem
              key={index}
              component="a"
              href={link.get('url')}
              target="_blank"
              button
            >
              <ListItemText primary={link.get('name')} />
            </ListItem>
          ))
        ) : (
          <ListItem disabled={true}>
            <ListItemText primary="No links" />
          </ListItem>
        )} */}
      </List>
    );
  }
}
