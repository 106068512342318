import React, { useState, useCallback } from 'react';
import {
  Icon,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

export default props => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleRemove = useCallback(() => {
    const { team, member, removeMember } = props;
    const handle = team.getIn(['handle']);
    const userHandle = member.getIn(['user', 'handle']);
    removeMember(handle, userHandle);
  });
  const handleClose = useCallback(() => setAnchorEl(null));

  return (
    <React.Fragment>
      <IconButton onClick={handleClick}>
        <Icon>more_vert</Icon>
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleRemove}>
          <ListItemIcon>
            <Icon>delete</Icon>
          </ListItemIcon>
          <ListItemText primary="Remove Member" />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
  // }
};
