import { createAction } from '@/utils/actionCreator';
import ActionTypes from './actionTypes';

export const register = createAction(ActionTypes.REGISTER, 'beta_key', 'email');

export const createWorkspace = createAction(
  ActionTypes.WORKSPACE_CREATE,
  'beta_key',
  'owner',
  'workspace'
);

export const reset = createAction(ActionTypes.RESET);
