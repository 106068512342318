import React, { useState, useCallback } from 'react';

import {
  CircularProgress,
  Avatar,
  Button,
  Container,
  Box,
  IconButton,
  Grid,
  Icon,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Menu,
  MenuItem
} from '@material-ui/core';

import Moment from 'react-moment';
import { Link } from 'react-router-dom';

const FeedItem = function({ event, mute }) {
  const handleShowMenu = useCallback(event => {
    setAnchorEl(event.currentTarget);
  });
  const handleClose = useCallback(event => {
    setAnchorEl(null);
  });
  const handleMute = useCallback(() => {
    mute(event.getIn(['profile', 'handle']));
    setAnchorEl(null);
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const type = event.getIn(['profile', 'type']);
  const handle = event.getIn(['profile', 'handle']);
  const subjectIcon = event.getIn(['profile', 'thumbnail_url']);
  const subjectName = event.getIn(['profile', 'name'], '');
  const message = event.get('title');
  const created = event.get('created_at');
  const subjectInitial = `${subjectName}`.charAt(0);

  return (
    <Card>
      <CardHeader
        title={subjectName}
        subheader={
          <div>
            {event.getIn(['agent_name'])} &middot;{' '}
            <Moment fromNow>{created}</Moment>
          </div>
        }
        avatar={
          <Avatar alt={subjectName} src={subjectIcon} role="presentation">
            {subjectInitial}
          </Avatar>
        }
      />
      <CardContent>{message}</CardContent>
      <CardActions>
        <Button color="primary" component={Link} to={`/${type}s/${handle}`}>
          View {type}
        </Button>
      </CardActions>
    </Card>
  );
};

const PublicFeed = function({ feed, loading }) {
  return (
    <div className="Activities">
      {loading ? (
        <Container>
          <Box p={20} mx="auto" display="flex" justifyContent="center">
            <CircularProgress mx="auto" size={48} />
          </Box>
        </Container>
      ) : !feed.size ? (
        <div className="Placeholder">
          <div className="Placeholder__content">
            <Icon>notifications</Icon>
            <p>No Events</p>
          </div>
        </div>
      ) : (
        <Grid container spacing={2}>
          {feed.map(item => (
            <Grid item md={12} key={item.get('id')}>
              <FeedItem event={item} />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default PublicFeed;
