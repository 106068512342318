import { ofType } from 'redux-observable';
import { of, concat } from 'rxjs';
import { map, mergeMap, switchMap, pluck, flatMap } from 'rxjs/operators';
import ActionTypes from './actionTypes';
import { addNotification } from '@/reducers/global';

import { ajax, catchApiError } from '@/utils/ajax';
import { replace } from 'connected-react-router';

export const getSlackWorkspaces$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SLACK_WORKSPACES_GET),
    switchMap(() =>
      ajax(
        {
          url: `/slack-workspaces`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(payload =>
          of({
            type: ActionTypes.SLACK_WORKSPACES_GET_SUCCESS,
            payload
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SLACK_WORKSPACES_GET_FAIL
          })
        )
      )
    )
  );

export const createSlackWorkspace$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SLACK_WORKSPACE_POST),
    switchMap(({ payload: { token } }) =>
      ajax(
        {
          url: `/slack-workspaces`,
          method: 'POST',
          body: { token }
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(payload =>
          of({
            type: ActionTypes.SLACK_WORKSPACE_POST_SUCCESS,
            payload
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SLACK_WORKSPACE_POST_FAIL
          })
        )
      )
    )
  );
export const updateSlackWorkspace$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SLACK_WORKSPACE_PATCH),
    switchMap(({ payload: { id, token } }) =>
      ajax(
        {
          url: `/slack-workspaces/${id}`,
          method: 'PATCH',
          body: { token }
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(payload =>
          of({
            type: ActionTypes.SLACK_WORKSPACE_PATCH_SUCCESS,
            payload
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SLACK_WORKSPACE_PATCH_FAIL
          })
        )
      )
    )
  );

// SSO Providers
export const getSsoProviders$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SSO_PROVIDERS_GET),
    switchMap(() =>
      ajax(
        {
          url: `/sso-providers`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(payload =>
          of({
            type: ActionTypes.SSO_PROVIDERS_GET_SUCCESS,
            payload
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SSO_PROVIDERS_GET_FAIL
          })
        )
      )
    )
  );

export const getSsoProvider$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SSO_PROVIDER_GET),
    switchMap(({ payload: { handle } }) =>
      ajax(
        {
          url: `/sso-providers/${handle}`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(payload =>
          of({
            type: ActionTypes.SSO_PROVIDER_GET_SUCCESS,
            payload
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SSO_PROVIDER_GET_FAIL
          })
        )
      )
    )
  );

export const createSsoProvider$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SSO_PROVIDER_POST),
    switchMap(({ payload: { provider } }) =>
      ajax(
        {
          url: `/sso-providers`,
          method: 'POST',
          body: provider
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(payload =>
          concat(
            of({
              type: ActionTypes.SSO_PROVIDER_POST_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'SSO Provider was created',
                options: { variant: 'success' }
              })
            ),
            of(replace(`/admin/sso-providers/${payload.handle}`))
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SSO_PROVIDER_POST_FAIL
          })
        )
      )
    )
  );
export const updateSsoProvider$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SSO_PROVIDER_PATCH),
    switchMap(({ payload: { handle, provider } }) =>
      ajax(
        {
          url: `/sso-providers/${handle}`,
          method: 'PATCH',
          body: provider
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(payload =>
          concat(
            of({
              type: ActionTypes.SSO_PROVIDER_PATCH_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'SSO Provider was updated',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.SSO_PROVIDER_PATCH_FAIL
            }),
            of(
              addNotification({
                message: 'There was an error while updating your SSO Provider',
                options: { variant: 'error' }
              })
            )
          )
        )
      )
    )
  );

export const exportPages$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.PAGES_EXPORT),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/pages/actions/export`,
          method: 'GET',
          responseType: 'arraybuffer'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(res =>
          concat(
            of({
              type: 'CALL_DOWNLOAD_METHOD',
              payload: { data: res, type: 'application/zip' }
            }),
            of({
              type: ActionTypes.PAGES_EXPORT_SUCCESS,
              payload: { success: true }
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.PAGES_EXPORT_FAIL
          })
        )
      )
    )
  );

export const listRemoteWorkspaces$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.REMOTE_WORKSPACES_GET),
    switchMap(() =>
      ajax(
        {
          url: `/remotes`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(res =>
          concat(
            of({
              type: ActionTypes.REMOTE_WORKSPACES_GET_SUCCESS,
              payload: res
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.REMOTE_WORKSPACES_GET_FAIL
          })
        )
      )
    )
  );

export const linkRemoteWorkspace$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.REMOTE_WORKSPACE_POST),
    switchMap(({ payload: { remote } }) =>
      ajax(
        {
          url: `/remotes`,
          method: 'POST',
          body: { remote }
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(res =>
          concat(
            of({
              type: ActionTypes.REMOTE_WORKSPACE_POST_SUCCESS,
              payload: res
            }),
            of({
              type: ActionTypes.REMOTE_WORKSPACES_GET,
              payload: res
            }),
            of(
              addNotification({
                message: 'Remote workspace was linked',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.REMOTE_WORKSPACE_POST_FAIL
            }),
            of(
              addNotification({
                message: 'Failed to link workspace',
                options: { variant: 'error' }
              })
            )
          )
        )
      )
    )
  );
export const unlinkRemoteWorkspace$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.REMOTE_WORKSPACE_DELETE),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/remotes/${payload.remote}`,
          method: 'DELETE'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(res =>
          concat(
            of({
              type: ActionTypes.REMOTE_WORKSPACE_DELETE_SUCCESS
            }),
            of({
              type: ActionTypes.REMOTE_WORKSPACES_GET,
              payload: res
            }),
            of(
              addNotification({
                message: 'Remote workspace was unlinked',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.REMOTE_WORKSPACE_DELETE_FAIL
            }),
            of(
              addNotification({
                message: 'Failed to unlink workspace',
                options: { variant: 'error' }
              })
            )
          )
        )
      )
    )
  );

export default [
  getSlackWorkspaces$,
  createSlackWorkspace$,
  updateSlackWorkspace$,
  getSsoProviders$,
  getSsoProvider$,
  createSsoProvider$,
  updateSsoProvider$,
  exportPages$,
  listRemoteWorkspaces$,
  linkRemoteWorkspace$,
  unlinkRemoteWorkspace$
];
