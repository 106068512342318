import { Map } from 'immutable';

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  token: null,
  error: false
});

export default function LoginReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOGIN:
      return state.set('error', false);
    case ActionTypes.LOGIN_FAIL:
      return state.set('error', true);
    case ActionTypes.RESET:
      return initialState;
    default:
      return state;
  }
}
