import React, { useEffect, useCallback, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Divider,
  Input,
  InputLabel,
  FormControl,
  InputAdornment,
  FormHelperText,
  Select,
  MenuItem,
  Checkbox,
  Chip,
} from '@material-ui/core';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { scopeOptions } from './client-scope';
// const scopes = ['visr.service.manage'];

const ClientForm = (props) => {
  const { client, getClient, cancel, save, updateClientSecret } = props;

  const [clientIdCopyText, setClientIdCopyText] = useState('copy');
  const [clientSecretCopyText, setClientSecretCopyText] = useState('copy');

  useEffect(() => {
    if (client.get('handle')) {
      getClient(client);
    }
  }, []);

  const _client = client.toJS();

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      name: _client.name || '',
      description: _client.description || '',
      scopes: _client.scopes || [],
    },
  });
  const { control, formState, handleSubmit, reset } = form;

  const scopes = useWatch({
    control,
    name: 'scopes',
    defaultValue: [],
  });

  const onSubmit = useCallback((values) => {
    save(client, { ...values });

    reset(values);
  });

  const { isValid, isDirty } = formState;

  const onCopyClientId = () => {
    setClientIdCopyText('Copied');
    setTimeout(() => {
      setClientIdCopyText('Copy');
    }, 1000);
  };
  const onCopyClientSecret = () => {
    setClientSecretCopyText('Copied');
    setTimeout(() => {
      setClientSecretCopyText('Copy');
    }, 1000);
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="Clients"></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                label="Name"
                name="name"
                as={TextField}
                control={control}
                autoComplete="off"
                fullWidth
                rules={{
                  required: true,
                }}
              />
            </Grid>{' '}
            <Grid item xs={12}>
              <Controller
                label="Description"
                name="description"
                as={TextField}
                control={control}
                autoComplete="off"
                fullWidth
                rules={{
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>Scopes</InputLabel>
                <Controller
                  label="Description"
                  name="scopes"
                  as={Select}
                  control={control}
                  autoComplete="off"
                  fullWidth
                  multiple
                  rules={{
                    required: true,
                  }}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </div>
                  )}
                >
                  {scopeOptions.map((scope) => (
                    <MenuItem key={scope} value={scope}>
                      <Checkbox checked={scopes.indexOf(scope) > -1} />
                      {scope}
                    </MenuItem>
                  ))}
                </Controller>
              </FormControl>
            </Grid>
            {client.get('client_id') ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel shrink={true}>Client ID</InputLabel>
                  <Input
                    label="Client ID"
                    value={client.get('client_id')}
                    readOnly={true}
                    disableUnderline={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <CopyToClipboard
                          text={client.get('client_id')}
                          onCopy={onCopyClientId}
                        >
                          <Button size="small">{clientIdCopyText}</Button>
                        </CopyToClipboard>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            ) : null}
            {client.get('client_secret') ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel shrink={true}>Client Secret</InputLabel>
                  <Input
                    readOnly={true}
                    disableUnderline={true}
                    value={client.get('client_secret')}
                    endAdornment={
                      <InputAdornment position="end">
                        <CopyToClipboard
                          text={client.get('client_secret')}
                          onCopy={onCopyClientSecret}
                        >
                          <Button size="small">{clientSecretCopyText}</Button>
                        </CopyToClipboard>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>
                    Copy it now, you will not be able to retrieve it afterwards
                  </FormHelperText>
                </FormControl>
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
        <CardActions>
          <Button onClick={cancel}>Done</Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!isValid || !isDirty}
          >
            Save Client
          </Button>
          {client.size && !client.get('client_secret') ? (
            <Button
              color="primary"
              variant="contained"
              onClick={updateClientSecret}
            >
              Generate New Secret
            </Button>
          ) : null}
        </CardActions>
      </form>
    </Card>
  );
};

const ClientsList = (props) => {
  const { clients, editClient, deleteClient } = props;
  if (clients.size) {
    return (
      <List>
        {clients.map((client, index) => (
          <React.Fragment key={client.get('handle')}>
            <ListItem>
              <ListItemText
                primary={client.get('name')}
                secondary={client.get('description')}
              />
              <ListItemSecondaryAction>
                <Button color="secondary" onClick={() => deleteClient(client)}>
                  Delete
                </Button>{' '}
                <Button color="primary" onClick={() => editClient(client)}>
                  Edit
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
            {index + 1 !== clients.size ? <Divider component="li" /> : null}
          </React.Fragment>
        ))}
      </List>
    );
  }
  return <Typography variant="body2">No Clients</Typography>;
};

const Clients = (props) => {
  const {
    clients,
    client,
    pending,
    team,
    getClients,
    getClient,
    createClient,
    updateClient,
    updateClientSecret,
    deleteClient,
    setClient,
  } = props;

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const teamId = team.get('handle');
    getClients(teamId);
  }, []);

  const onAddClient = useCallback(() => {
    setClient(null);
    setEditMode(true);
  });

  const onCancel = useCallback(() => {
    setClient(null);
    setEditMode(false);
  });

  const onEditClient = useCallback((client) => {
    setClient(client);
    setEditMode(true);
  });

  const onGetClient = useCallback((client) => {
    const teamId = team.get('handle');
    const clientId = client.get('handle');
    getClient(teamId, clientId);
  });

  const onDeleteClient = useCallback((client) => {
    const teamId = team.get('handle');
    const clientId = client.get('handle');
    deleteClient(teamId, clientId);
  });

  const onUpdateClientSecret = useCallback(() => {
    const teamId = team.get('handle');
    const clientId = client.get('handle');
    updateClientSecret(teamId, clientId);
  });

  const onSave = useCallback((client, values) => {
    const teamId = team.get('handle');
    if (client.size) {
      const clientId = client.get('handle');
      updateClient(teamId, clientId, values);
    } else {
      createClient(teamId, values);
    }
  });

  if (editMode) {
    return (
      <ClientForm
        client={client}
        getClient={onGetClient}
        save={onSave}
        updateClientSecret={onUpdateClientSecret}
        cancel={onCancel}
      />
    );
  }

  return (
    <Card>
      <CardHeader title="Clients"></CardHeader>
      <CardContent>
        {pending ? (
          <CircularProgress />
        ) : (
          <ClientsList
            clients={clients}
            editClient={onEditClient}
            deleteClient={onDeleteClient}
          />
        )}
      </CardContent>
      <CardActions>
        <Button color="primary" onClick={onAddClient}>
          Add Client
        </Button>
      </CardActions>
    </Card>
  );
};

export default Clients;
