import React, { useState, useEffect, useCallback } from 'react';

import { connect } from 'react-redux';
import { Actions as authActions } from '@/reducers/auth';
import { Link } from 'react-router-dom';

import { IconButton, Icon, Menu, MenuItem } from '@material-ui/core';
export const UserMenu = props => {
  const { user } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (
      user.size &&
      (user.get('role') === 'owner' || user.get('role') === 'admin')
    ) {
      setIsAdmin(true);
    }
  }, [user]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = useCallback(() => setAnchorEl(null));

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Icon style={{ color: '#fff' }} color="inherit">
          add
        </Icon>
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component={Link} to="/services/new" onClick={handleClose}>
          Create Service
        </MenuItem>
        <MenuItem component={Link} to="/teams/new" onClick={handleClose}>
          Create Team
        </MenuItem>
        {isAdmin ? (
          <MenuItem component={Link} to="/users/invite" onClick={handleClose}>
            Invite User
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  logout: payload => dispatch(authActions.logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMenu);
