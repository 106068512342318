import { connect } from 'react-redux';

import { NS } from '../../ducks/actionTypes';

import {
  linkRemoteWorkspace,
  changeRemoteWorkspaceModalVisibility
} from '../../ducks/actions';

import RemoteWorkspaceModal from './RemoteWorkspaceModal';

const mapStateToProps = state => ({
  visible: state.getIn([NS, 'visibility', 'remoteWorkspace']),
  remoteWorkspaces: state.getIn([NS, 'remoteWorkspaces'])
});

const mapDispatchToProps = dispatch => ({
  linkRemoteWorkspace: remote => dispatch(linkRemoteWorkspace(remote)),
  changeRemoteWorkspaceModalVisibility: visibility =>
    dispatch(changeRemoteWorkspaceModalVisibility(visibility))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoteWorkspaceModal);
