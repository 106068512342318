import React, { PureComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  CircularProgress,
  Avatar,
  Button,
  Toolbar,
  Icon,
  Card,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Link,
  Portal
} from '@material-ui/core';

import { TablePagination, ServiceHealth } from '@/components';

const ServiceContent = ({ services, loading }) => {
  if (loading) {
    return (
      <TableRow>
        <TableCell
          colSpan={5}
          align="center"
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <CircularProgress size={27} />
        </TableCell>
      </TableRow>
    );
  }
  if (services.size === 0) {
    return (
      <TableRow>
        <TableCell colSpan={5} align="center">
          No Services
        </TableCell>
      </TableRow>
    );
  }
  return services.map(service => {
    const name = service.get('name');
    const handle = service.get('handle');
    const initial = name.charAt(0).toUpperCase();
    const icon = service.getIn(['thumbnail_url']);
    const description = service.get('description');
    const visibility = service.get('visibility');
    const health = service.get('health');
    return (
      <TableRow key={handle}>
        <TableCell style={{ paddingTop: '0', paddingBottom: '0' }}>
          <Avatar alt={name} src={icon} />
          {/* {initial} */}
          {/* </Avatar> */}
        </TableCell>
        <TableCell>
          <Link component={RouterLink} to={`/services/${handle}`}>
            {name}
          </Link>
        </TableCell>
        <TableCell>{description}</TableCell>
        <TableCell>{visibility}</TableCell>
        <TableCell>
          <ServiceHealth health={health} />
        </TableCell>
      </TableRow>
    );
  });
};

class Services extends PureComponent {
  state = { limit: 20, offset: 0 };
  componentDidMount() {
    const { getTeamServices, updateCrumbs, team } = this.props;
    const id = team.get('handle');
    const { offset, limit } = this.state;
    getTeamServices(id, { cursor: offset, limit });
    updateCrumbs([{ label: 'Services' }]);
  }

  getTeamServices() {
    const { getTeamServices, team } = this.props;
    const id = team.get('handle');
    getTeamServices(id);
  }

  updateOffset = offset => {
    this.setState({ offset }, () => {
      this.getTeams();
    });
  };

  render() {
    const { services, team, loading, total, canEdit } = this.props;
    const { offset, limit } = this.state;
    return (
      <div className="container ">
        <Paper>
          {canEdit ? (
            <Portal container={document.getElementById('foo')}>
              <Button
                color="primary"
                component={Link}
                to={`/teams/${team.get('handle')}/services/new`}
              >
                Create Service
              </Button>
            </Portal>
          ) : null}

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '72px' }} />
                  <TableCell style={{ width: '30%' }}>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell style={{ width: '10%' }}>Visibility</TableCell>
                  <TableCell style={{ width: '10%' }}>Health</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <ServiceContent services={services} loading={loading} />
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            limit={limit}
            offset={offset}
            total={total}
            updateOffset={this.updateOffset}
          />
        </Paper>
      </div>
    );
  }
}

export default Services;
