import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  FormControl
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import queryString from 'query-string';

import { Redirect } from 'react-router-dom';

import { required, isStrong } from '@/validators';

const passwordsMatch = vals => vals.password === vals.confirmPassword;

const useStyles = makeStyles({
  root: {
    alignSelf: 'center',
    justifySelf: 'center',
    margin: 'auto',
    width: '80%',
    maxWidth: '400px'
  },
  formControl: {
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: '0px'
    }
  },
  actions: {
    justifyContent: 'space-between'
  },
  alert: { margin: '0 16px' }
});

const ResetPassword = props => {
  const { success, error, resetPassword, location, reset } = props;

  const styles = useStyles();

  const [submitting, setSubmitting] = useState(false);

  const { control, formState, errors, getValues, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: { password: '', confirm_password: '' }
  });

  const { isValid } = formState;

  useEffect(() => {
    if (success || error) {
      setSubmitting(false);
    }
  }, [success, error]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const onSubmit = values => {
    setSubmitting(true);
    const { key } = queryString.parse(location.search);

    resetPassword(key, values.password);
  };

  if (success) {
    return <Redirect to="/login" />;
  }

  return (
    <Card className={styles.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="Reset Password" />
        {error ? (
          <Alert severity="error" className={styles.alert}>
            {error}
          </Alert>
        ) : null}
        <CardContent>
          <FormControl fullWidth className={styles.formControl}>
            <Controller
              label="Password"
              name="password"
              control={control}
              // as={{ required, isStrong }}
              as={TextField}
              rules={{
                required: true,
                validate: {
                  isStrong: v => isStrong(v) || 'Password not strong enough'
                }
              }}
              type="password"
              autoComplete="off"
              error={!!errors.password}
              helperText={<ErrorMessage errors={errors} name="password" />}
            />
          </FormControl>

          <FormControl fullWidth className={styles.formControl}>
            <Controller
              label="Confirm Password"
              name="confirm_password"
              control={control}
              as={TextField}
              rules={{
                required: true,
                validate: value =>
                  (value && value === getValues('password')) ||
                  'Confirm Password does not match with Password'
              }}
              type="password"
              autoComplete="off"
              error={!!errors.confirm_password}
              helperText={
                <ErrorMessage errors={errors} name="confirm_password" />
              }
            />
          </FormControl>
        </CardContent>
        <CardActions>
          <Button
            className="ml-auto"
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitting || !isValid}
          >
            Submit
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default ResetPassword;
