import { connect } from 'react-redux';
import { NS } from './ducks/reducer';
import { NS as GLOBAL_NS } from '@/reducers/global';
import { getServices, reset } from './ducks/actions';
import Services from './Services';
import './Services.scss';

const mapStateToProps = state => ({
  services: state.getIn([NS, 'services']),
  loading: state.getIn([NS, 'loading']),
  starredPending: state.getIn([GLOBAL_NS, 'pending', 'starred'])
});

const mapDispatchToProps = dispatch => ({
  getServices: payload => dispatch(getServices(payload)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Services);
