import React from 'react';

import { Main } from '@/components';
import { Card, CardContent } from '@material-ui/core';
import './NoMatch.scss';

export const NoMatch = props => {
  const { title, text } = props;
  return (
    <Main>
      <Card className="NotMatch" >
        <CardContent>
          <h2>{title || '404 Not Found'}</h2>
          {text ? <p>{text}</p> : null}
        </CardContent>
      </Card>
    </Main>
  );
};
// }
