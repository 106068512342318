import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';

import { NS } from '../../ducks/ns';
import { updateCrumbs, getInvoices } from '../../ducks/actions';

import {
  CircularProgress,
  Container,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    alignSelf: 'center',
    justifySelf: 'center',
    margin: 'auto',
    width: '80%',
    maxWidth: '400px',
  },
  formControl: {
    marginBottom: '20px',
    fontSize: '16px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },

  actions: {
    justifyContent: 'space-between',
  },
  alert: { margin: '0 16px' },
});

const InvoiceRow = ({ invoice }) => {
  const id = invoice.getIn(['id']);
  const created = invoice.get('created');
  const amountDue = invoice.get('amount_due');
  const status = invoice.get('status');
  const invoiceUrl = invoice.get('hosted_invoice_url');
  //   const name = member.getIn(['user', 'name']);
  //   const role = member.get('role');
  //   const handle = member.getIn(['user', 'handle']);
  //   const initial = name.charAt(0).toUpperCase();
  //   const icon = member.getIn(['user', 'thumbnail_url']);
  //   const description = member.getIn(['user', 'description']);
  //   const types = ['admin', 'member'];

  return (
    <TableRow>
      <TableCell>
        <Moment format="MM/DD/YYYY">{created}</Moment>
      </TableCell>
      <TableCell>${amountDue / 100}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Button component="a" color="primary" href={invoiceUrl} target="_blank">
          View Invoice
        </Button>
      </TableCell>
    </TableRow>
  );
};

const InvoicesContent = ({ invoices, loading }) => {
  if (loading) {
    return (
      <TableRow>
        <TableCell
          align="center"
          colSpan={3}
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <CircularProgress size={27} />
        </TableCell>
      </TableRow>
    );
  } else if (invoices.size === 0) {
    return (
      <TableRow>
        <TableCell align="center" colSpan={3}>
          No Invoices
        </TableCell>
      </TableRow>
    );
  }
  return invoices.map((invoice) => (
    <InvoiceRow invoice={invoice} key={invoice.getIn(['id'])} />
  ));
};

export const Invoices = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateCrumbs([{ label: 'Invoices' }]));
    dispatch(getInvoices());
  }, []);

  const invoices = useSelector((state) => state.getIn([NS, 'invoices']));
  const loading = useSelector((state) =>
    state.getIn([NS, 'pending', 'invoices'])
  );
  return (
    <Container maxWidth="md">
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '30%' }}>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell style={{ width: '20%' }}></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <InvoicesContent invoices={invoices} loading={loading} />
            </TableBody>
          </Table>
        </TableContainer>
        {/* </CardContent> */}
      </Paper>
    </Container>
  );
};
