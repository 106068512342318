import { connect } from 'react-redux';

import { getUserTeams, updateCrumbs } from '@/screens/User/ducks/actions';
import { NS } from '@/screens/User/ducks/actionTypes';
import { NS as NS_ME } from '@/reducers/user';

import UserTeams from './Teams';

const mapStateToProps = state => ({
  //   pending: state.getIn([NS, 'pending', 'user']),
  user: state.getIn([NS, 'user']),
  teams: state.getIn([NS, 'teams']),
  pending: state.getIn([NS, 'pending', 'teams']),
  me: state.getIn([NS_ME, 'user'])
});

const mapDispatchToProps = dispatch => ({
  getTeams: handle => dispatch(getUserTeams(handle)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTeams);
