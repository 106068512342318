import { of, concat } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';

import queryString from 'query-string';

import ActionTypes from './actionTypes';

export const getTeams$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAMS_GET),
    switchMap(({ payload: { params } }) =>
      ajax(
        {
          url: `/search/teams?${queryString.stringify(params)}`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(({ response: teams, xhr }) => ({
          teams,
          total: parseInt(xhr.getResponseHeader('total'))
        })),
        map(payload => ({
          type: ActionTypes.TEAMS_GET_SUCCESS,
          payload
        })),
        catchApiError(action$, () => {
          of({
            type: ActionTypes.TEAMS_GET_FAIL
          });
        })
      )
    )
  );

export default [getTeams$];
