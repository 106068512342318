import { of, concat } from 'rxjs';
import { switchMap, mergeMap, flatMap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';

import { push } from 'react-router-redux';
import { addNotification } from '@/reducers/global';
import ActionTypes from './actionTypes';

export const getUser$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_GET),
    switchMap(action =>
      ajax(
        {
          url: `/users/${action.payload.handle}`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(res =>
          concat(
            of({
              type: ActionTypes.USER_GET_SUCCESS,
              payload: res,
              error: true
            }),
            // of({
            //   type: ActionTypes.USER_TEAMS_GET,
            //   payload: res,
            //   error: true
            // })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_GET_FAIL
          })
        )
      )
    )
  );

export const getUserTeams$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_TEAMS_GET),
    switchMap(action =>
      ajax(
        {
          url: `/users/${action.payload.handle}/teams`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(res =>
          of({
            type: ActionTypes.USER_TEAMS_GET_SUCCESS,
            payload: res,
            error: true
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_TEAMS_GET_FAIL
          })
        )
      )
    )
  );

export const getUserFeed$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_FEED_GET),
    switchMap(action =>
      ajax(
        {
          url: `/users/${action.payload.handle}/public-feed`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(res =>
          of({
            type: ActionTypes.USER_FEED_GET_SUCCESS,
            payload: res,
            error: true
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_FEED_GET_FAIL
          })
        )
      )
    )
  );
//USER_DELETE
export const deleteUser$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_DELETE),
    switchMap(action =>
      ajax(
        {
          url: `/users/${action.payload.handle}`,
          method: 'DELETE'
        },
        { action$, state$ }
      ).pipe(
        mergeMap(() =>
          concat(
            of(push('/users')),
            of(
              addNotification({
                message: 'User Deleted',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_DELETE_FAIL
          })
        )
      )
    )
  );

export default [getUser$, getUserTeams$, getUserFeed$, deleteUser$];
