import React, { useEffect } from 'react';

import { Card, CardContent, Grid } from '@material-ui/core';

import { PublicFeed } from '@/components';

export default props => {
  const {
    team,
    publicFeed,
    publicFeedPending,
    getPublicFeed,
    updateCrumbs
  } = props;

  useEffect(() => {
    updateCrumbs(null);
    getPublicFeed(team.get('handle'));
  }, []);

  const description = team && team.get('description', null);
  return (
    <React.Fragment>
      {description ? (
        <Grid item>
          <Card>
            <CardContent>{description}</CardContent>
          </Card>
        </Grid>
      ) : null}
      <PublicFeed feed={publicFeed} loading={publicFeedPending} />
    </React.Fragment>
  );
};
