import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  Portal,
} from '@material-ui/core';

import { TablePagination } from '@/components';

import { NS } from '@/screens/Team/ducks/actionTypes';

import { getRunners, updateCrumbs } from '../../ducks/actions';

import { Rows } from './rows';
import { ManageRunner } from './manage-runner';
export const Runners = () => {
  const limit = 20;

  const [modalVisible, setModalVisibility] = useState(false);
  const [selectedRunner, setSelectedRunner] = useState(null);

  const dispatch = useDispatch();

  const runners = useSelector((state) => state.getIn([NS, 'runners']));
  const pending = useSelector((state) =>
    state.getIn([NS, 'pending', 'runners'])
  );
  const team = useSelector((state) => state.getIn([NS, 'team']));
  const offset = useSelector((state) =>
    state.getIn([NS, 'pagination', 'offset'], 0)
  );
  const canEdit = useSelector((state) =>
    state.getIn([NS, 'team', 'is_admin'], false)
  );

  useEffect(() => {
    dispatch(updateCrumbs([{ label: 'Runners' }]));
  }, []);

  useEffect(() => {
    setModalVisibility(false);
  }, [runners]);

  useEffect(() => {
    !modalVisible && setSelectedRunner(null);
  }, [modalVisible]);

  useEffect(() => {
    const id = team.get('handle');
    dispatch(getRunners(id, {}));
  }, [offset]);

  const addRunner = useCallback(() => {
    setModalVisibility(true);
  }, []);

  const hideRunnerModal = () => {
    setModalVisibility(false);
  };

  const onEditRunner = (runner) => {
    setModalVisibility(true);
    setSelectedRunner(runner);
  };

  return (
    <div className="container">
      <Paper>
        {canEdit ? (
          <Portal container={document.getElementById('foo')}>
            <Button color="primary" onClick={addRunner}>
              Add Runner
            </Button>
          </Portal>
        ) : null}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Handle</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Last Sync</TableCell>
                {canEdit ? <TableCell style={{ width: '72px' }} /> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              <Rows
                runners={runners}
                pending={pending}
                team={team}
                canEdit={canEdit}
                editRunner={onEditRunner}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ManageRunner
        team={team}
        visible={modalVisible}
        runner={selectedRunner}
        hide={hideRunnerModal}
      />
    </div>
  );
};

export default Runners;
