import { of, concat } from 'rxjs';
import {
  switchMap,
  startWith,
  flatMap,
  exhaustMap,
  pluck,
  map,
  catchError
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from '@/utils/ajax';

import { ActionTypes, NS } from '@/reducers/auth';
import { ActionTypes as UserActionTypes } from '@/reducers/user';
import {
  NS as GLOBAL_NS,
  ActionTypes as GlobalActionTypes
} from '@/reducers/global';

export { NS };
export const initAuth$ = action$ =>
  action$.pipe(
    ofType(ActionTypes.INIT_AUTH),
    switchMap(() =>
      action$.pipe(
        ofType(ActionTypes.WORKSPACE_REQUEST_SUCCESS),
        switchMap(() =>
          of({
            type: ActionTypes.AUTH_REQUEST
          })
        ),
        startWith({
          type: ActionTypes.WORKSPACE_REQUEST
        })
      )
    )
  );

export const getWorkspace$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.WORKSPACE_REQUEST),
    switchMap(() =>
      ajax(
        {
          url: '/workspace',
          method: 'get'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.WORKSPACE_REQUEST_SUCCESS,
              payload: payload
            })
          )
        ),
        catchError(error =>
          of({
            type: ActionTypes.WORKSPACE_REQUEST_FAIL,
            payload: error
          })
        )
      )
    )
  );

export const getToken$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.AUTH_REQUEST),
    switchMap(() =>
      ajax(
        {
          url: `/token`,
          method: 'POST',
          withCredentials: true,
          body: {
            grant_type: 'session',
            workspace: state$.value.getIn([GLOBAL_NS, 'workspace'])
          }
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.AUTH_REQUEST_SUCCESS,
              payload: payload
            }),
            of({ type: UserActionTypes.USER_GET }),
            // of({ type: GlobalActionTypes.START_USER_EVENTS_GET }),
            of({ type: GlobalActionTypes.USER_STARRED_GET })
            // of({ type: GlobalActionTypes.USER_WATCHED_GET })
          )
        ),
        catchError(error => {
          return of({
            type: ActionTypes.AUTH_REQUEST_FAIL,
            payload: error,
            error: true
          });
        })
      )
    )
  );

export const refreshToken$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.AUTH_REFRESH),

    exhaustMap(() =>
      ajax(
        {
          url: `/token`,
          method: 'POST',
          withCredentials: true,
          body: {
            grant_type: 'session',
            workspace: state$.value.getIn([GLOBAL_NS, 'workspace'])
          }
        },
        { state$, action$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.AUTH_REFRESH_SUCCESS,
              payload: payload
            })
          )
        ),
        catchError(error =>
          of({
            type: ActionTypes.AUTH_REFRESH_FAIL,
            payload: error,
            error: true
          })
        )
      )
    )
  );

export const logout$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.LOGOUT),
    switchMap(() =>
      ajax(
        {
          url: `/session`,
          method: 'DELETE',
          withCredentials: true,
          body: {
            workspace: state$.value.getIn([GLOBAL_NS, 'workspace'])
          }
        },
        { state$, action$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.LOGOUT_SUCCESS,
              payload: payload
            })
          )
        ),
        catchError(error =>
          of({
            type: ActionTypes.LOGOUT_REJECTED,
            payload: error,
            error: true
          })
        )
      )
    )
  );

export default [initAuth$, getWorkspace$, getToken$, refreshToken$, logout$];
