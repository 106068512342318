import React, { PureComponent } from 'react';
import { Button, Card, CardHeader, CardContent, Grid } from '@material-ui/core';

import { GOOD, MAINTENANCE, WARNING, ERROR, CRITICAL } from '../../StatusTypes';

import {
  InputFieldGroup,
  SelectFieldGroup,
  AssetFieldGroup,
  ConfirmationModal
} from '@/components';

import Channels from '../Channels';

class Settings extends PureComponent {
  state = {
    showConfirmDelete: false,
    visibilities: [
      { name: 'Public', id: 'public' },
      { name: 'Workspace', id: 'workspace' },
      { name: 'Owner', id: 'owner' }
    ],
    healthStatuses: [
      { name: 'Good', id: GOOD },
      { name: 'Maintenance', id: MAINTENANCE },
      { name: 'Warning', id: WARNING },
      { name: 'Error', id: ERROR },
      { name: 'Critical', id: CRITICAL }
    ],
    stages: [
      { name: 'Development', id: 'development' },
      { name: 'Live', id: 'live' },
      { name: 'eol', id: 'End of Life' }
    ]
  };

  componentDidMount() {
    this.props.getTeams();

    this.props.updateCrumbs([{ label: 'Settings' }]);
  }

  handleShowConfirmDelete = () => {
    this.setState({ showConfirmDelete: true });
  };

  handleHideConfirmDelete = () => {
    this.setState({ showConfirmDelete: false });
  };

  handleConfirmDelete = () => {
    const { service, deleteService } = this.props;
    const handle = service.get('handle');
    this.setState({ showConfirmDelete: false });
    deleteService(handle);
  };

  getTeams() {
    const { teams, team } = this.props;

    if (!teams.find(t => t.get('id') == team.get('id'))) {
      return teams
        .push(team)
        .toJS()
        .map(({ handle, name }) => ({ id: handle, name }));
    }
    return teams.toJS().map(({ handle, name }) => ({ id: handle, name }));
  }

  handleExportPages = () => {
    const { service, exportPages } = this.props;
    const handle = service.get('handle');
    exportPages(handle);
  };

  render() {
    const {
      service,
      update,
      updateVisibility,
      updateStage,
      updateParent,
      updateHealth,
      upload,
      clear
    } = this.props;
    const entityId = service.get('handle');
    const {
      showConfirmDelete,
      visibilities,
      healthStatuses,
      stages
    } = this.state;

    const teams = this.getTeams();

    return (
      <div className="ServiceSetting">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AssetFieldGroup
              label="Service Photo"
              entity={service}
              entityId={entityId}
              asset={service.getIn(['thumbnail_url'])}
              fallbackName={service.get('name')}
              upload={upload}
              clear={clear}
            />
          </Grid>
          <Grid item xs={12}>
            <InputFieldGroup
              label="Name"
              entity={service}
              entityId={entityId}
              field="name"
              save={update}
              sublabel="The name of the service."
            />
          </Grid>
          <Grid item xs={12}>
            <InputFieldGroup
              label="Description"
              entity={service}
              entityId={entityId}
              field="description"
              rows={3}
              save={update}
              sublabel="A quick description of the service"
            />
          </Grid>
          <Grid item xs={12}>
            <SelectFieldGroup
              label="Owner"
              entity={service}
              entityId={entityId}
              field="owner.id"
              items={teams}
              save={updateParent}
              sublabel="The owner of the service"
            />
          </Grid>
          <Grid item xs={12}>
            <SelectFieldGroup
              label="Visibility"
              entity={service}
              entityId={entityId}
              field="visibility"
              items={visibilities}
              save={updateVisibility}
              sublabel="Visibility of the service"
            />
          </Grid>
          <Grid item xs={12}>
            <SelectFieldGroup
              label="Stage"
              entity={service}
              entityId={entityId}
              field="stage"
              items={stages}
              save={updateStage}
              sublabel="Development stage of the service"
            />
          </Grid>
          <Grid item xs={12}>
            <SelectFieldGroup
              label="Health Status"
              entity={service}
              entityId={entityId}
              field="health"
              items={healthStatuses}
              save={updateHealth}
              sublabel="The health status of the service"
            />

            <Channels />
          </Grid>
          <Grid item xs={12}>
            <Card style={{ marginBottom: '20px' }}>
              <CardHeader title="Export Service Pages" />
              <CardContent>
                <p>Export all your service published and draft pages</p>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.handleExportPages}
                >
                  Export Pages
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Delete Service" />
              <CardContent>
                <p>Deleting a service is permanent</p>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.handleShowConfirmDelete}
                >
                  Delete Service
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* ) : null} */}
        <ConfirmationModal
          title={'Delete Service'}
          visible={showConfirmDelete}
          text={'Are you sure you want to delete this service?'}
          confirm={this.handleConfirmDelete}
          hide={this.handleHideConfirmDelete}
        />
      </div>
    );
  }
}

export default Settings;
