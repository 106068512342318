import React, { useState, useCallback } from 'react';

import { Card, IconButton, Icon } from '@material-ui/core';

export default props => {
  const { placeholder = 'Search', onSearch } = props;

  const [query, setQuery] = useState('');

  const handleChange = useCallback(e => {
    const { value: query } = e.target;
    setQuery(query);
  });

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    onSearch(query);
  });

  return (
    <Card
      className="SearchInput"
      component={'form'}
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <IconButton className="SearchInput__icon" type="submit">
        <Icon>search</Icon>
      </IconButton>
      <input
        id="SearchInput__search"
        className="SearchInput__input"
        type="search"
        placeholder={placeholder}
        filter={null}
        value={query}
        onChange={handleChange}
      />
    </Card>
  );
  // }
};
