import { createTypes, asyncType, createNS } from '@/utils/actionCreator';

export const NS = createNS('ADMIN');

export default createTypes(
  [
    ...asyncType('SLACK_WORKSPACES_GET'),
    ...asyncType('SLACK_WORKSPACE_POST'),
    ...asyncType('SLACK_WORKSPACE_GET'),
    ...asyncType('SLACK_WORKSPACE_PATCH'),

    ...asyncType('SSO_PROVIDERS_GET'),
    ...asyncType('SSO_PROVIDER_POST'),
    ...asyncType('SSO_PROVIDER_GET'),
    ...asyncType('SSO_PROVIDER_PATCH'),

    ...asyncType('REMOTE_WORKSPACES_GET'),
    ...asyncType('REMOTE_WORKSPACE_POST'),
    ...asyncType('REMOTE_WORKSPACE_DELETE'),
    'REMOTE_WORKSPACE_MODAL_VISIBILITY_SET',
    ...asyncType('PAGES_EXPORT'),

    'UPDATE_CRUMBS',

    'SSO_PROVIDER_RESET',
    'RESET'
  ],
  `${NS}`
);
