import React, { useState } from 'react';
import {
  Button,
  Box,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormControl
} from '@material-ui/core';

import { CopyToClipboard } from 'react-copy-to-clipboard';

const SamlSettings = props => {
  const { ssoProvider } = props;

  const [acsUrlText, setAcsUrlText] = useState('Copy');
  const [entityIdText, setEntityIdText] = useState('Copy');

  const copyAcsUrl = () => {
    setAcsUrlText('Copied');
    setTimeout(() => {
      setAcsUrlText('Copy');
    }, 1000);
  };

  const copyEntityId = () => {
    setEntityIdText('Copied');
    setTimeout(() => {
      setEntityIdText('Copy');
    }, 1000);
  };

  const onDownloadCertificateClick = () => {
    const data = ssoProvider.getIn(['sp', 'cert']);
    const blob = new Blob([data], { type: 'text/plain' });
    const filename = 'visr.crt';
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
      window.URL.rejectObjectURL(blob);
    }
  };

  return (
    <Box component={Card} mb={2}>
      <CardHeader
        title="SAML Settings"
        subheader={ssoProvider.size ? '' : 'Disabled until provider is created'}
      />
      <CardContent>
        <div className="InputCopy__container">
          <FormControl fullWidth>
            <TextField
              id="acs_url"
              label="Visr Single sign-on URL"
              value={ssoProvider.getIn(['sp', 'acs_url'], '')}
              readOnly
              disabled={true}
            />
            {ssoProvider.size ? (
              <CopyToClipboard
                text={ssoProvider.getIn(['sp', 'acs_url'], '')}
                onCopy={copyAcsUrl}
              >
                <Button color="primary" className="InputCopy__action">
                  {acsUrlText}
                </Button>
              </CopyToClipboard>
            ) : null}
          </FormControl>
        </div>
        <div className="InputCopy__container">
          <FormControl fullWidth>
            <TextField
              id="entity_id"
              label="Visr Audience URL"
              value={ssoProvider.getIn(['sp', 'entity_id'], '')}
              disabled={true}
            />
            {ssoProvider.size ? (
              <CopyToClipboard
                text={ssoProvider.getIn(['sp', 'entity_id'], '')}
                onCopy={copyEntityId}
              >
                <Button color="primary" className="InputCopy__action">
                  {entityIdText}
                </Button>
              </CopyToClipboard>
            ) : null}
          </FormControl>
        </div>
      </CardContent>

      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onDownloadCertificateClick}
          disabled={!ssoProvider.size}
        >
          Download Certificate
        </Button>
      </CardActions>
    </Box>
  );
};

export default SamlSettings;
