import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import { updateCrumbs } from '../../ducks/actions';

import {
  Button,
  Card,
  CardContent,
  CardActions,
  Container,
  CardHeader,
  Grid,
  Divider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { NS } from '../../ducks/ns';
import { cancelSubscription } from '../../ducks/actions';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  cardType: {
    color: '#666',
  },
  cardBrand: {
    marginRight: '10px',
    textTransform: 'capitalize',
  },
}));

export const Overview = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.getIn([NS, 'account'], null));
  const subscription = useSelector((state) =>
    state.getIn([NS, 'subscription'], null)
  );

  const handleCancelSubscription = () => {
    dispatch(cancelSubscription());
  };

  useEffect(() => {
    dispatch(updateCrumbs());
  }, []);

  if (!account.getIn(['stripe', 'subscription'])) {
    return (
      <Container maxWidth="md">
        <Card>
          <CardHeader title="Plan Details" />
          <CardContent>
            <p>You are currently not subscribed to any plan</p>
          </CardContent>
          <CardActions>
            <Button component={Link} to={'/billing/create-subscription'}>
              Select Plan
            </Button>
          </CardActions>
        </Card>
      </Container>
    );
  }

  if (account.getIn(['stripe', 'subscription']) && !subscription.size) {
    return (
      <Container maxWidth="md">
        <Card>
          <CardHeader title="Plan Details" />
          <CardContent>
            <Typography variant="h6">Subscription Cancelled</Typography>
            <p>
              You will have access to{' '}
              <strong>
                <strong>
                  {account.getIn(['stripe', 'subscription', 'seats'])}
                </strong>{' '}
                seat(s){' '}
              </strong>
              on the{' '}
              <strong>
                {account.getIn(['stripe', 'subscription', 'product', 'name'])}
              </strong>{' '}
              plan until{' '}
              <strong>
                <Moment format="M/D/YYYY">
                  {account.getIn(['stripe', 'subscription', 'period_end'])}
                </Moment>
              </strong>
            </p>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              component={Link}
              to={'/billing/create-subscription'}
            >
              Select Plan
            </Button>
            <Button color="primary" component={Link} to={'/billing/invoices'}>
              View Previous Invoices
            </Button>
          </CardActions>
        </Card>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item>
          <Card>
            <CardHeader
              title="Plan Details"
              action={
                subscription ? (
                  <Button
                    component={Link}
                    color="primary"
                    to={'/billing/update-subscription'}
                  >
                    Change Plan
                  </Button>
                ) : null
              }
            />
            <CardContent>
              <p>
                You are subscribed to{' '}
                <strong>
                  <strong>{subscription.get('current_quantity')}</strong>{' '}
                  seat(s){' '}
                </strong>
                on the{' '}
                <strong>{subscription.get('product_description')}</strong> plan
              </p>
            </CardContent>
          </Card>
        </Grid>

        <Grid item>
          <Card>
            <CardHeader
              title="Payments"
              action={
                <Button
                  color="primary"
                  component={Link}
                  to={'/billing/invoices'}
                >
                  View Invoices
                </Button>
              }
            />
            <CardContent>
              <p>
                You are last payment was{' '}
                <strong>
                  ${subscription.getIn(['latest_invoice', 'amount_due']) / 100}
                </strong>
              </p>
              <p>
                You are next payment of{' '}
                <strong>
                  $
                  {subscription.getIn(['upcoming_invoice', 'amount_due']) / 100}
                </strong>{' '}
                will be due on{' '}
                <strong>
                  <Moment format="M/D/YYYY">
                    {subscription.getIn([
                      'upcoming_invoice',
                      'next_payment_attempt',
                    ]) * 1000}
                  </Moment>
                </strong>
              </p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <CardHeader
              title="Payment Method"
              action={
                <Button
                  color="primary"
                  component={Link}
                  to={'/billing/manage-payment-methods'}
                >
                  Manage Payment Methods
                </Button>
              }
            />
            <CardContent>
              <p className={styles.cardType}>
                <span className={styles.cardBrand}>
                  {subscription.getIn(['card', 'brand'])}
                </span>
                <span>**** {subscription.getIn(['card', 'last4'])}</span>
              </p>
            </CardContent>
          </Card>{' '}
        </Grid>

        <Grid item>
          <Card>
            <CardHeader title="End Subscription" />
            <CardContent>
              <p>Upon cancelling, you will return to the free plan.</p>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleCancelSubscription}
              >
                Cancel Subscription
              </Button>
            </CardContent>
          </Card>{' '}
        </Grid>
      </Grid>
    </Container>
  );
};
