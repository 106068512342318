import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { IconButton, Icon, Grid, Button } from '@material-ui/core';
// import { List, ListItem } from '@/components/List';
import ServiceCell from '@/components/ServiceCell';

import './ServiceDependencies.scss';

const ServiceDependencies = ({
  dependencies,
  hideView,
  hideStarred,
  noResultText,
  onDelete
}) => {
  if (!dependencies.size) {
    return <p>{noResultText}</p>;
  }

  const handleDelete = (event, service) => {
    event.preventDefault();
    onDelete(service);
  };

  return (
    <Grid container spacing={2}>
      {dependencies.map(service => {
        return (
          <Grid item xs={4} key={service.get('handle')}>
            <ServiceCell
              className="md-cell md-cell--4"
              name={service.get('name')}
              handle={service.get('handle')}
              icon={service.getIn(['thumbnail_url'])}
              description={service.get('description')}
              health={service.get('health')}
              hideView={hideView || false}
              hideStarred={hideStarred || false}
              starredCount={service.get('starred_count')}
            >
              {onDelete ? (
                <Button onClick={e => handleDelete(e, service)}>Remove</Button>
              ) : 
              null}
            </ServiceCell>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ServiceDependencies;
