import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Box,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormControl
} from '@material-ui/core';

const GeneralSettings = props => {
  const { ssoProvider, save } = props;
  const { control, errors, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      handle: ''
    }
  });

  const { isDirty, isValid } = formState;

  useEffect(() => {
    const name = ssoProvider.get('name', '');
    const handle = ssoProvider.get('handle', '');
    reset({ name, handle }, { isDirty: false });
  }, [ssoProvider]);

  const onSubmit = body => {
    save(body);
    reset(body, { isDirty: false });
  };


  return (
    <Box component={Card} mb={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="General Settings" />
        <CardContent>
          <FormControl fullWidth>
            <Controller
              label={'Provider handle'}
              name="handle"
              as={TextField}
              control={control}
              autoComplete="off"
              disabled={!!ssoProvider.get('handle')}
              error={!!errors.handle}
              rules={{
                required: true
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <Controller
              name="name"
              as={TextField}
              control={control}
              autoComplete="off"
              label={'Provider Name'}
              error={!!errors.name}
              rules={{
                required: true
              }}
            />
          </FormControl>
        </CardContent>
        {ssoProvider.size ? (
          isDirty ? (
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isValid}
              >
                Save
              </Button>
            </CardActions>
          ) : null
        ) : (
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isValid}
            >
              Create
            </Button>
          </CardActions>
        )}
      </form>
    </Box>
  );
};

export default GeneralSettings;
