import Settings from './Settings';
import Overview from './Overview';
import Notices from './Notices';

import HealthChecks from './HealthChecks';

import PublishedAnnouncements from './PublishedAnnouncements';
import PublishedAnnouncement from './PublishedAnnouncement';
import DraftAnnouncements from './DraftAnnouncements';
import DraftAnnouncement from './DraftAnnouncement';

import PublishedReleaseNotes from './PublishedReleaseNotes';
import PublishedReleaseNote from './PublishedReleaseNote';
import DraftReleaseNotes from './DraftReleaseNotes';
import DraftReleaseNote from './DraftReleaseNote';


import PublishedApiDocs from './PublishedApiDocs';
import PublishedApiDoc from './PublishedApiDoc';
import DraftApiDocs from './DraftApiDocs';
import DraftApiDoc from './DraftApiDoc';

import PublishedOpenApiSpecs from './PublishedOpenApiSpecs';
import PublishedOpenApiSpec from './PublishedOpenApiSpec';
import DraftOpenApiSpecs from './DraftOpenApiSpecs';
import DraftOpenApiSpec from './DraftOpenApiSpec';

import Channels from './Channels';

import ServiceGraph from './Graph';

export default {
  Overview,
  Notices,
  HealthChecks,
  Settings,
  Channels,

  
  PublishedAnnouncements,
  PublishedAnnouncement,
  DraftAnnouncements,
  DraftAnnouncement,

  PublishedReleaseNotes,
  PublishedReleaseNote,
  DraftReleaseNotes,
  DraftReleaseNote,

  PublishedApiDocs,
  PublishedApiDoc,
  DraftApiDocs,
  DraftApiDoc,

  PublishedOpenApiSpecs,
  PublishedOpenApiSpec,
  DraftOpenApiSpecs,
  DraftOpenApiSpec,

  ServiceGraph
};
