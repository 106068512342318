import { connect } from 'react-redux';

import { getUser, deleteUser } from './ducks/actions';
import { NS } from './ducks/actionTypes';
import { NS as NS_ME } from '@/reducers/user';
import User from './User';

const mapStateToProps = state => ({
  pending: state.getIn([NS, 'pending', 'user']),
  user: state.getIn([NS, 'user']),
  teams: state.getIn([NS, 'teams']),
  me: state.getIn([NS_ME, 'user']),
  crumbs: state.getIn([NS, 'crumbs'], [])

});

const mapDispatchToProps = dispatch => ({
  getUser: payload => dispatch(getUser(payload)),
  deleteUser: payload => dispatch(deleteUser(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User);
