import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { ServiceDependencies } from '../../components';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Link as MdLink,
  Paper,
  Portal
} from '@material-ui/core';
import {
  UptimeBar,
  ServiceHealth,
  ServiceVisibility,
  ServiceStage,
  StarService
} from '@/components';

import { List } from 'immutable';

import { Announcements, OpenIssues } from './components';

class Overview extends PureComponent {
  componentDidMount() {
    const { service, getDailyServiceHealthChecks, getNotices } = this.props;
    const handle = service.get('handle');
    const created = new Date(service.get('created_at'));
    const now = new Date();
    let from = new Date();
    from.setDate(from.getDate() - 90);

    if (from < created) {
      from = created;
    }

    getDailyServiceHealthChecks(service.get('handle'), from.toISOString());
    this.props.updateCrumbs();
  }

  deleteDependency = dependency => {
    const { service, deleteDependency } = this.props;
    deleteDependency(service.get('handle'), dependency.get('handle'));
  };

  deleteRemoteDependency = remote => {
    const { service, removeRemoteDependency } = this.props;
    removeRemoteDependency(
      service.get('handle'),
      remote.getIn(['workspace', 'handle']),
      remote.get('handle')
    );
  };

  render() {
    const {
      service,
      team,
      dependencies,
      remoteDependencies,
      dependents,
      serviceHealth,
      toggleDependencyModal,
      toggleRemoteDependencyModal,
      canEdit
    } = this.props;

    const title = service.get('name');
    const description = service.get('description');
    const icon = service.getIn(['thumbnail_url'], null);

    return (
      <div>
        <Portal container={document.getElementById('foo')}>
          <StarService
            style={{ marginTop: '-10px' }}
            handle={service.get('handle')}
          />
        </Portal>
        <Card style={{ margin: '0 0 20px' }}>
          <CardHeader
            className="ServiceDependencies__title"
            title="Overview"
          ></CardHeader>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="subtitle2">Owner</Typography>
                <Typography variant="body1">
                  <MdLink component={Link} to={`/teams/${team.get('handle')}`}>
                    {team.get('name')}
                  </MdLink>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">Visibility</Typography>
                <Typography variant="body1">
                  <ServiceVisibility visibility={service.get('visibility')} />
                  {/* {service.get('visibility')} */}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">Stage</Typography>
                <Typography variant="body1">
                  <ServiceStage stage={service.get('stage')} />
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">Health</Typography>
                <Typography variant="body1">
                  <ServiceHealth health={service.get('health')} />
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <OpenIssues />
        <Announcements />
        {serviceHealth.get('data', List()).size ? (
          <UptimeBar
            title={'Overall Health'}
            handle={serviceHealth.get('id')}
            data={serviceHealth.get('data')}
            uptime={serviceHealth.get('uptime')}
            style={{ margin: '0 0 2rem' }}
          >
            <CardActions className="md-divider-border md-divider-border--top md-dialog-footer md-dialog-footer--inline">
              <Button
                component={Link}
                color="primary"
                to={`/services/${service.get('handle')}/health-checks`}
              >
                View HealthChecks
              </Button>
            </CardActions>
          </UptimeBar>
        ) : (
          ''
        )}

        <Card className="ServiceDependencies" style={{ margin: '0 0 20px' }}>
          <CardHeader
            className="ServiceDependencies__title"
            title="Dependencies"
            action={
              canEdit ? (
                <Button color="primary" onClick={toggleDependencyModal}>
                  Add Dependency
                </Button>
              ) : null
            }
          ></CardHeader>
          <CardContent>
            <ServiceDependencies
              dependencies={dependencies}
              noResultText="No Dependencies"
              onDelete={this.deleteDependency}
            />
          </CardContent>
        </Card>

        <Card className="ServiceDependencies" style={{ margin: '0 0 20px' }}>
          <CardHeader
            className="ServiceDependencies__title"
            title="Remote Dependencies"
            action={
              canEdit ? (
                <Button color="primary" onClick={toggleRemoteDependencyModal}>
                  Add Remote Dependency
                </Button>
              ) : null
            }
          ></CardHeader>
          <CardContent>
            <ServiceDependencies
              dependencies={remoteDependencies}
              hideView={true}
              hideStarred={true}
              noResultText="No Dependencies"
              onDelete={this.deleteRemoteDependency}
            />
          </CardContent>
        </Card>

        <Card className="ServiceDependencies" style={{ margin: '0 0 20px' }}>
          <CardHeader
            className="ServiceDependencies__title"
            title="Dependents"
          />
          <CardContent>
            <ServiceDependencies
              dependencies={dependents}
              noResultText="No Dependents"
            />
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default Overview;
