export default [
  {
    id: 'intro',
    // attachTo: {element: '.first-element', on: 'bottom'},
    // beforeShowPromise: function() {
    //   return new Promise(function(resolve) {
    //     setTimeout(function() {
    //       window.scrollTo(0, 0);
    //       resolve();
    //     }, 500);
    //   });
    // },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Exit',
        type: 'cancel'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        type: 'next'
      }
    ],
    // classes: 'custom-class-name-1 custom-class-name-2',
    // highlightClass: 'highlight',
    scrollTo: false,
    showCancelLink: true,
    title: 'Welcome to Visr',
    text: [
      `Unlock your platform's potential`,
      `Visr connects your teams with the software that drives your business. Discover, document, inform, connect, with Visr.`
    ],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  },
  {
    id: 'profile',
    attachTo: { element: '#menu-user', on: 'bottom' },
    // beforeShowPromise: function() {
    //   return new Promise(function(resolve) {
    //     setTimeout(function() {
    //       window.scrollTo(0, 0);
    //       resolve();
    //     }, 500);
    //   });
    // },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Exit',
        type: 'cancel'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        type: 'next'
      }
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    highlightClass: 'highlight',
    scrollTo: false,
    showCancelLink: true,
    title: 'Your Profile',
    text: [
      `Find you're save services. Update your visr account profile such as bio, links or avatar.`
    ],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  },
  {
    id: 'create',
    attachTo: { element: '#menu-create', on: 'bottom' },
    // beforeShowPromise: function() {
    //   return new Promise(function(resolve) {
    //     setTimeout(function() {
    //       window.scrollTo(0, 0);
    //       resolve();
    //     }, 500);
    //   });
    // },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Exit',
        type: 'cancel'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        type: 'next'
      }
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    highlightClass: 'highlight',
    scrollTo: false,
    showCancelLink: true,
    title: 'Create a Team or Service',
    text: [`You'll want to created a team`],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  },
  {
    id: 'find',
    attachTo: { element: '#nav-services', on: 'bottom' },
    // beforeShowPromise: function() {
    //   return new Promise(function(resolve) {
    //     setTimeout(function() {
    //       window.scrollTo(0, 0);
    //       resolve();
    //     }, 500);
    //   });
    // },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Exit',
        type: 'cancel'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        type: 'next'
      }
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    highlightClass: 'highlight',
    scrollTo: false,
    showCancelLink: true,
    title: 'Explore your workspace services',
    text: [
      `After you've added a service. You can find it you the explore section`
    ],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  },
  {
    id: 'teams-n-service',
    attachTo: { element: '#home-sidenav-left', on: 'right' },
    // beforeShowPromise: function() {
    //   return new Promise(function(resolve) {
    //     setTimeout(function() {
    //       window.scrollTo(0, 0);
    //       resolve();
    //     }, 500);
    //   });
    // },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Exit',
        type: 'cancel'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        type: 'next'
      }
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    highlightClass: 'highlight',
    scrollTo: false,
    showCancelLink: true,
    title: 'Your teams & services',
    text: [`You can find services & team you or your team belong to`],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  },
  {
    id: 'favorite',
    attachTo: { element: '#home-sidenav-right', on: 'left' },
    // beforeShowPromise: function() {
    //   return new Promise(function(resolve) {
    //     setTimeout(function() {
    //       window.scrollTo(0, 0);
    //       resolve();
    //     }, 500);
    //   });
    // },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Exit',
        type: 'cancel'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        type: 'next'
      }
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    highlightClass: 'highlight',
    scrollTo: false,
    showCancelLink: true,
    title: 'Favorite',
    text: [
      `You'll find all the services you star, whether you belong to the team or not?`
    ],
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  }
];
