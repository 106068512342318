import React, { PureComponent } from 'react';
import { Map, List } from 'immutable';
import Moment from 'react-moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent
} from '@material-ui/core';

import './NoticeViewModal.scss';

const TITLE_DATE_FORMAT = 'MMM D, YYYY';
const DATE_FORMAT = 'MMM D, hh:mm a';

const Status = ({ status }) => {
  const statusString = status.charAt(0).toUpperCase() + status.slice(1);
  return <strong>{statusString}</strong>;
};

export const NoticeHistoryItem = ({ data }) => {
  return (
    <li>
      <p>
        <Status status={data.get('status')} />
      </p>
      <p className="StatusItem__message">{data.get('message')}</p>
      <Moment format={TITLE_DATE_FORMAT}>{data.get('time')}</Moment>
    </li>
  );
};

export default props => {
  const { visible, hide, notice } = props;

  let history = [];

  if (notice) {
    history = [
      Map({
        message: notice.get('message'),
        status: notice.get('status'),
        time: notice.get('updated_at')
      })
    ].concat(...notice.get('history'));
  }

  return (
    <Dialog open={visible} onClose={hide}>
      <DialogTitle>{notice && notice.get('title')}</DialogTitle>
      <DialogContent>
        <ul className="NoticeViewModal__history" style={{ width: '400px' }}>
          {history.length
            ? history.map(data => {
                return <NoticeHistoryItem key={data.get('time')} data={data} />;
              })
            : null}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={hide} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
