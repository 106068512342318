import { connect } from 'react-redux';
import {
  NS as NS_AUTH,
  getToken,
  getWorkspace,
  initAuth
} from '@/reducers/auth';
import { NS as NS_USER } from '@/reducers/user';
import { NS as NS_GLOBAL } from '@/reducers/global';

import AuthorizedRoute from './AuthorizedRoute';
import './AuthorizedRoute.scss';

const stateToProps = state => ({
  pending: state.getIn([NS_AUTH, 'pending', 'token']),
  userPending: state.getIn([NS_USER,'pending']),
  workspace: state.getIn([NS_AUTH, 'workspace']),
  workspaceNotFound: state.getIn([NS_AUTH, 'workspaceNotFound']),
  token: state.getIn([NS_AUTH, 'token']),
  user: state.getIn([NS_USER, 'user']),
  ready: state.getIn([NS_GLOBAL, 'ready'])
});

const mapDispatchToProps = dispatch => ({
  initAuth: () => dispatch(initAuth()),
  getWorkspace: () => dispatch(getWorkspace()),
  getToken: () => dispatch(getToken())
});

export default connect(
  stateToProps,
  mapDispatchToProps
)(AuthorizedRoute);
