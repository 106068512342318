import React, { useState, useCallback, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Button,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Icon,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormLabel,
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PLANS } from '../../Plans.constant';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   alignSelf: 'center',
  //   justifySelf: 'center',
  //   margin: 'auto',
  //   width: '80%',
  //   maxWidth: '400px',
  // },
  // formControl: {
  //   marginBottom: '20px',
  //   fontSize: '16px',
  //   '&:last-child': {
  //     marginBottom: '0px',
  //   },
  // },
  grid: {
    marginBottom: theme.spacing(2),
  },
  card: { height: '100%', display: 'flex', flexDirection: 'column' },
  planPrice: {
    marginBottom: '20px',
    fontSize: '32px',
    '& sup': {
      fontSize: '14px',
    },
    '& span': {
      fontSize: '14px',
    },
  },
  features: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  feature: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  featureIcon: {
    marginRight: '10px',
  },

  cardContent: { flex: 1 },
  // actions: {
  //   justifyContent: 'space-between',
  // },
  // alert: { margin: '0 16px' },
  button: {
    width: '100%',
  },
}));

export const Plan = ({ plan, selected, onSelect, className }) => {
  const styles = useStyles();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(selected === plan.id);
  }, [selected, plan]);

  return (
    <Grid item xs={6} className={styles.grid}>
      <Card className={styles.card}>
        <CardHeader title={plan.title} />
        <CardContent className={styles.cardContent}>
          <Typography
            variant="h4"
            color={'primary'}
            className={styles.planPrice}
          >
            {plan.price ? (
              <React.Fragment>
                <sup>$</sup>
                {`${plan.price}`}
                <span>/user</span>
              </React.Fragment>
            ) : (
              'Free'
            )}
          </Typography>

          <ul className={styles.features}>
            {plan.features.map((feature, _) => (
              <li className={styles.feature} key={_}>
                <Icon className={styles.featureIcon} color="primary">
                  done
                </Icon>
                {feature}
              </li>
            ))}
          </ul>
        </CardContent>
        <CardActions>
          <Button
            className={styles.button}
            color="primary"
            variant={isSelected ? 'contained' : 'outlined'}
            disableElevation
            onClick={() => onSelect(plan.id)}
          >
            {isSelected ? 'selected' : 'select'}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export const Plans = ({ selected, select }) => {
  const styles = useStyles();

  // const [selected, setSelected] = useState('free');

  const handleSelect = useCallback((selected) => {
    // setSelected(selected);
    select(selected);
  }, []);

  return (
    <Grid container spacing={2} justify="center">
      {PLANS.map((plan) => (
        <Plan
          key={plan.id}
          plan={plan}
          selected={selected}
          onSelect={handleSelect}
        />
      ))}
    </Grid>
  );
};
