import { connect } from 'react-redux';
import { createWorkspace, reset } from './../../ducks/actions';
import { NS } from './../../ducks/reducer';

import CreateWorkspace from './CreateWorkspace';

const mapStateToProps = state => ({
  email: state.getIn([NS, 'email']),
  submitting: state.getIn([NS, 'submitting', 'workspace']),
  error: state.getIn([NS, 'error', 'workspace'])
});

const mapDispatchToProps = dispatch => ({
  createWorkspace: (code, owner, workspace) =>
    dispatch(createWorkspace(code, owner, workspace)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateWorkspace);
