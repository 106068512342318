import React, { PureComponent, useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  CircularProgress,
  Avatar,
  Button,
  Toolbar,
  Icon,
  Card,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Link
} from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import { Main, Container, SearchInput, TablePagination } from '@/components';

const TeamContent = ({ teams, loading }) => {
  if (loading) {
    return (
      <TableRow>
        <TableCell
          colSpan={4}
          align="center"
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <CircularProgress size={27} />
        </TableCell>
      </TableRow>
    );
  }

  if (!teams.size) {
    return (
      <TableRow>
        <TableCell colSpan={4} align="center">
          No Teams.
        </TableCell>
      </TableRow>
    );
  }

  return teams.map(team => {
    const name = team.get('name');
    const handle = team.get('handle');
    const initial = name.charAt(0).toUpperCase();
    const icon = team.get('thumbnail_url', null);
    return (
      <TableRow key={handle}>
        <TableCell style={{ paddingTop: '0', paddingBottom: '0' }}>
          <Avatar alt={initial} src={icon}>
            {initial}
          </Avatar>
        </TableCell>
        <TableCell>
          <Link component={RouterLink} to={`/teams/${handle}`}>
            {name}
          </Link>
        </TableCell>
        {/* <TableCell>{team.get('handle')}</TableCell> */}
        <TableCell>{team.get('description') || '—'}</TableCell>
        <TableCell>
          <AvatarGroup max={4}>
            {team.get('members').map(member => (
              <Avatar
                key={member.get('id')}
                alt={member.get('name')}
                src={member.get('thumbnail')}
              >
                {member.get('name').charAt(0)}
              </Avatar>
            ))}
          </AvatarGroup>
        </TableCell>
      </TableRow>
    );
  });
};

export default props => {
  const { teams, loading, total, getTeams } = props;

  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState('');

  useEffect(() => {
    getTeams({ cursor: offset, limit: limit, q: query });
  }, [offset, limit, query]);

  const handleSearch = q => {
    setQuery(q);
    setOffset(0);
  };

  const updateOffset = offset => {
    setOffset(offset);
  };

  return (
    <Container>
      <Main>
        <div>
          <SearchInput onSearch={handleSearch} />
          <Paper className="container Teams">
            {/* <Toolbar>
              <Typography variant="h6" style={{ flex: '1' }}>
                Teams
              </Typography>
              <Button color="primary" component={RouterLink} to={`/teams/new`}>
                Create Team
              </Button>
            </Toolbar>
            <Divider /> */}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '72px' }} />
                    <TableCell style={{ width: '30%' }}>Name</TableCell>
                    {/* <TableCell style={{ width: '10%' }}>Handle</TableCell> */}
                    <TableCell>Description</TableCell>
                    <TableCell style={{ width: '20%' }}>Members</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TeamContent teams={teams} loading={loading} />
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              limit={limit}
              offset={offset}
              total={total}
              updateOffset={updateOffset}
            />
          </Paper>
        </div>
      </Main>
    </Container>
  );
  // }
};
