import { connect } from 'react-redux';
import { NS } from '@/screens/Team/ducks/actionTypes';
import {
  getTeamServicesById,
  updateCrumbs
} from '@/screens/Team/ducks/actions';

import Services from './Services';

const mapStateToProps = state => ({
  team: state.getIn([NS, 'team']),
  services: state.getIn([NS, 'services']),
  total: state.getIn([NS, 'pagination', 'services', 'total']),
  loading: state.getIn([NS, 'pending', 'services']),
  canEdit: state.getIn([NS, 'team', 'is_admin'], false)
});

const mapDispatchToProps = dispatch => ({
  getTeamServices: (id, params) => dispatch(getTeamServicesById(id, params)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Services);
