import { Activity } from './Activity';
import { connect } from 'react-redux';

// import { getUserServices, getUserTeams } from './ducks/actions';
import { NS as NS_GLOBAL, createMuted } from '@/reducers/global';


const mapStateToProps = state => ({
  // services: state.getIn([NS, 'services']),
  // servicesPending: state.getIn([NS, 'pending', 'services']),
  // teams: state.getIn([NS, 'teams']),
  // teamsPending: state.getIn([NS, 'pending', 'teams']),
  // starred: state.getIn([NS_GLOBAL, 'starred']),
  // events: state.getIn([NS_GLOBAL, 'events']),
  // eventsLoading: state.getIn([NS_GLOBAL, 'pending', 'events'])
});

const mapDispatchToProps = dispatch => ({
  // getServices: () => dispatch(getUserServices()),
  // getTeams: () => dispatch(getUserTeams()),
  mute: profile => dispatch(createMuted(profile))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Activity);
