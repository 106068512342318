import { of, concat } from 'rxjs';
import { switchMap, flatMap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';

import ActionTypes from '../actionTypes';

export const getPublishedPages$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.PUBLISHED_PAGES_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.serviceHandle}/published-pages?section=${payload.section}`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.PUBLISHED_PAGES_GET_SUCCESS,
              payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.PUBLISHED_PAGES_GET_FAIL
          })
        )
      )
    )
  );

export const getPublishedPage$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.PUBLISHED_PAGE_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.serviceHandle}/published-pages/${payload.section}/${payload.pageId}`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.PUBLISHED_PAGE_GET_SUCCESS,
              payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.PUBLISHED_PAGE_GET_FAIL
          })
        )
      )
    )
  );

export default [getPublishedPages$, getPublishedPage$];
