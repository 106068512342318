import { ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { addNotification } from '@/reducers/global';
import { ActionTypes } from '@/reducers/user';
import { ajax, catchApiError } from '@/utils/ajax';
import { NS as AUTH_NS } from '@/reducers/auth';

import UserActionTypes from '@/screens/User/ducks/actionTypes';

export const getUser$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_GET),
    switchMap(() =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}`,
          method: 'GET',
          withCredentials: true
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(payload =>
          of({
            type: ActionTypes.USER_GET_SUCCESS,
            payload
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_GET_FAIL
          })
        )
      )
    )
  );

export const updateUser$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_UPDATE),
    switchMap(({ payload: { id, body } }) =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}`,
          method: 'PATCH',
          body
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(payload =>
          concat(
            of({
              type: ActionTypes.USER_UPDATE_SUCCESS,
              payload
            }),
            of({
              type: UserActionTypes.USER_UPDATE_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Profile Updated',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_UPDATE_FAIL
          })
        )
      )
    )
  );

export const uploadUserAvatar$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_ICON_UPDATE),
    switchMap(({ payload: { id, body } }) =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/icon`,
          headers: {},
          method: 'POST',
          body
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(payload =>
          concat(
            of({
              type: ActionTypes.USER_ICON_UPDATE_SUCCESS,
              payload
            }),
            of({
              type: UserActionTypes.USER_UPDATE_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Profile Updated',
                options: { variant: 'success' }
              })
            )
          )
        ),

        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_ICON_UPDATE_FAIL
          })
        )
      )
    )
  );
export const deleteUserAvatar$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_ICON_DELETE),
    switchMap(({ payload: { id } }) =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/icon`,
          headers: {},
          method: 'DELETE'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(payload =>
          concat(
            of({
              type: ActionTypes.USER_ICON_DELETE_SUCCESS,
              payload
            }),
            of({
              type: UserActionTypes.USER_UPDATE_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Profile Updated',
                options: { variant: 'success' }
              })
            )
          )
        ),

        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_ICON_DELETE_FAIL
          })
        )
      )
    )
  );

export default [getUser$, updateUser$, uploadUserAvatar$, deleteUserAvatar$];
