import React, { useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    minWidth: 400
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default props => {
  const {
    service,
    visible,
    remoteServices,
    listRemoteServices,
    addRemoteDependency,
    hide
  } = props;

  const classes = useStyles();

  const { control, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: { remote: '' }
  });

  const { isValid } = formState;

  useEffect(() => {
    listRemoteServices();
  }, []);

  const onSubmit = useCallback(values => {
    const handle = service.get('handle');

    addRemoteDependency(
      handle,
      values.remote.getIn(['workspace', 'handle']),
      values.remote.getIn(['handle'])
    );
    hide();
  });

  return (
    <Dialog open={visible} onClose={hide} className={classes.modal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle> Add Remote Dependency</DialogTitle>

        <DialogContent>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth={true}
          >
            <InputLabel id="remote">Remote Dependency</InputLabel>
            <Controller
              placeholder="Selete Remote Dependency"
              name="remote"
              control={control}
              rules={{ required: true }}
              as={Select}
              fullWidth={true}
            >
              {remoteServices.size ? (
                remoteServices.map(item => (
                  <MenuItem key={item.get('id')} value={item}>
                    {item.get('name')}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled={true}>No services available</MenuItem>
              )}
            </Controller>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={hide} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={!isValid}>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
// }
