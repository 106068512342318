import React, { PureComponent, Fragment } from 'react';
import { Avatar, CircularProgress, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ServiceHealth } from '@/components';

export default props => {
  const {
    title,
    description,
    icon,
    children,
    pending,
    owner,
    private: _private,
    health
  } = props;

  if (pending) {
    return <CircularProgress id="TopHeaderLoader" />;
  }
  const initial = (title || '').charAt(0).toUpperCase();

  const renderDescription = string => {
    if (!string) return '-';

    return string.split('\n').map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <Paper className="TopHeader">
      <div className="TopHeader__icon">
        <Avatar src={icon}>{initial}</Avatar>
      </div>
      <div className="TopHeader__content">
        <h1>{title}</h1>
        <p>{renderDescription(description)}</p>
        <div className="TopHeader__tags">
          {health ? (
            <div className="TopHeader__tag">
              <ServiceHealth prefix="Status: " health={health} />
            </div>
          ) : null}
          {owner ? (
            <div className="TopHeader__tag">
              <Link to={`/teams/${owner.get('handle')}`}>
                Owner: {owner.get('name')}
              </Link>
            </div>
          ) : null}
          {_private ? (
            <div className="TopHeader__tag">Visibility: Private</div>
          ) : null}
        </div>
      </div>
      <div className="TopHeader__options">{children}</div>
    </Paper>
  );
};
