import { of, concat } from 'rxjs';
import { switchMap, flatMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from '@/utils/ajax';

import ActionTypes from './actionTypes';

export const resetPassword$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.RESET_PASSWORD),
    switchMap(action =>
      ajax(
        {
          url: `/reset-password`,
          method: 'POST',
          body: {
            key: action.payload.key,
            password: action.payload.password
          }
        },
        { action$, state$ }
      ).pipe(
        map(({ response }) => response),
        flatMap(() => of({ type: ActionTypes.RESET_PASSWORD_SUCCESS })),
        catchError(error => {
          return of({
            type: ActionTypes.RESET_PASSWORD_FAIL,
            payload: error.response || error,
            error: true
          });
        })
      )
    )
  );

export default [resetPassword$];
