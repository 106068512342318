import React from 'react';
import cn from 'classnames';

// import './ServiceHealth.scss';

export default props => {
  const { className, stage } = props;

  let classes = '';
  let text = stage === 'eol' ? 'End of Life' : stage;
  //   switch (health) {
  //     case 'good':
  //       healthClassName = `service-health-text--good`;
  //       break;
  //     case 'maintenance':
  //       healthClassName = `service-health-text--maintenance`;
  //       break;
  //     case 'warning':
  //       healthClassName = `service-health-text--warning`;
  //       break;
  //     case 'error':
  //       healthClassName = `service-health-text--error`;
  //       break;
  //     case 'critical':
  //       healthClassName = `service-health-text--critical`;
  //       break;
  //   }

  return (
    <span className="service-stage">
      <span className={cn(className, 'service-health-text', classes)}>
        {text}
      </span>
    </span>
  );
};
