import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress, TableRow, TableCell } from '@material-ui/core';

import { Row } from './row';

export const Rows = ({ runners, team, pending, canEdit, editRunner }) => {
  if (pending) {
    return (
      <TableRow>
        <TableCell
          align="center"
          colSpan={canEdit ? 6 : 5}
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <CircularProgress size={27} />
        </TableCell>
      </TableRow>
    );
  } else if (runners.size === 0) {
    return (
      <TableRow>
        <TableCell align="center" colSpan={canEdit ? 6 : 5}>
          No Runners
        </TableCell>
      </TableRow>
    );
  }

  return runners.map((runner) => (
    <Row
      runner={runner}
      team={team}
      key={runner.get('id')}
      canEdit={canEdit}
      editRunner={editRunner}
    />
  ));
};
