import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TableRow, TableCell } from '@material-ui/core';

import { Menu } from './menu';

export const Row = (params) => {
  const { runner, team, editRunner } = params;

  const handle = runner.get('handle');
  const name = runner.get('name');
  const description = runner.get('description');
  const syncStatus = runner.get('sync_status');
  const lastUpdate = runner.get('last_sync');

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>{handle}</TableCell>
      <TableCell>{syncStatus}</TableCell>
      <TableCell>{lastUpdate}</TableCell>

      <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Menu team={team} runner={runner} editRunner={editRunner} />
      </TableCell>
    </TableRow>
  );
};
