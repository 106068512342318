import { Map, List, fromJS } from 'immutable';

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  success: false,
  error: false,
  user: Map()
});

export default function InviteReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CREATE_USER_SUCCESS:
      return state
        .set('success', true)
        .set('error', false)
        .set('user', Map(action.payload));
    case ActionTypes.CREATE_USER_FAIL:
      return state.set('error', true);
    default:
      return state;
  }
}
