import { connect } from 'react-redux';

import { NS } from '@/screens/Team/ducks/reducer';
import {
  getClients,
  getClient,
  createClient,
  updateClient,
  updateClientSecret,
  deleteClient,
  setClient
} from '@/screens/Team/ducks/actions';

import Clients from './Clients';

const mapStateToProps = state => ({
  team: state.getIn([NS, 'team']),
  clients: state.getIn([NS, 'clients']),
  client: state.getIn([NS, 'client']),
  pending: state.getIn([NS, 'pending', 'clients'])
});

const mapDispatchToProps = dispatch => ({
  getClients: teamId => dispatch(getClients(teamId)),
  getClient: (teamId, clientId) => dispatch(getClient(teamId, clientId)),
  createClient: (teamId, client) => dispatch(createClient(teamId, client)),
  updateClient: (teamId, clientId, client) =>
    dispatch(updateClient(teamId, clientId, client)),
  updateClientSecret: (teamId, clientId) =>
    dispatch(updateClientSecret(teamId, clientId)),
  deleteClient: (teamId, clientId) => dispatch(deleteClient(teamId, clientId)),
  setClient: client => dispatch(setClient(client))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Clients);
