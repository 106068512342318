import { createTypes, asyncType, createNS } from '@/utils/actionCreator';

export const NS = createNS('USERS');

export default createTypes(
  [
    ...asyncType('USERS_GET'),
    ...asyncType('INVITES_GET'),
    ...asyncType('INVITE_RESEND'),
    ...asyncType('USER_ROLE_UPDATE'),
    'RESET',
  ],
  `${NS}`
);
