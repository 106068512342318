import { connect } from 'react-redux';
import { register, reset } from './ducks/actions';
import { NS } from './ducks/reducer';
import './Register.scss';
import Register from './Register';

const mapStateToProps = state => ({
  submitting: state.getIn([NS, 'submitting']),
  error: state.getIn([NS, 'error']),
  workspace: state.getIn([NS, 'workspace']),
  registerSuccess: state.getIn([NS, 'success', 'register']),
  createWorkspaceSuccess: state.getIn([NS, 'success', 'workspace'])
});

const mapDispatchToProps = dispatch => ({
  register: (token, password) => dispatch(register(token, password)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
