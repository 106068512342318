import { of, concat, merge } from 'rxjs';
import { switchMap, flatMap, map, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';

import { addNotification } from '@/reducers/global';
import ActionTypes from '../actionTypes';

export const getChannels$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.CHANNELS_GET),
    switchMap(({ payload: { handle } }) =>
      ajax(
        {
          url: `/services/${handle}/channels`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.CHANNELS_GET_SUCCESS,
              payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.CHANNELS_GET_FAIL
          })
        )
      )
    )
  );

export const createChannel$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.CHANNEL_CREATE),

    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.serviceId}/channels`,
          method: 'POST',
          body: {
            provider: 'slack',
            channel: payload.channel
          }
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.CHANNEL_CREATE_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Service Channel Added',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.CHANNEL_CREATE_FAIL
          })
        )
      )
    )
  );

export const updateChannel$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.CHANNEL_UPDATE),
    switchMap(({ payload: { serviceId, channelHandle = 'slack', channel } }) =>
      ajax(
        {
          url: `/services/${serviceId}/channels/${channelHandle}`,
          method: 'PATCH',
          body: {
            channel: {
              channel: channel
            }
          }
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.CHANNEL_UPDATE_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Service Channel Updated',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.CHANNEL_UPDATE_FAIL
          })
        )
      )
    )
  );

export const deleteChannel$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.CHANNEL_DELETE),
    switchMap(
      ({ payload: { serviceId, channelHandle = 'slack', channelId } }) =>
        ajax(
          {
            url: `/services/${serviceId}/channels/${channelHandle}`,
            method: 'DELETE'
          },
          { action$, state$ }
        ).pipe(
          map(res => res.response),
          flatMap(payload =>
            concat(
              of({
                type: ActionTypes.CHANNEL_DELETE_SUCCESS,
                payload: { id: channelId }
              }),
              of(
                addNotification({
                  message: 'Service Channel Removed',
                  options: { variant: 'success' }
                })
              )
            )
          ),
          catchApiError(action$, () =>
            of({
              type: ActionTypes.CHANNEL_DELETE_FAIL
            })
          )
        )
    )
  );

export default [getChannels$, createChannel$, updateChannel$, deleteChannel$];
