import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import {
  createSubscription,
  updateSubscription,
  getPaymentMethods,
  updateCrumbs,
  loadStripe,
} from '../../ducks/actions';

import { NS } from '../../ducks/ns';

import {
  Button,
  Card,
  CardContent,
  CardActions,
  FormControl,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Radio,
  TextField,
  FormLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CardField, Plans, PaymentMethods } from '../../components';
import { PLANS } from '../../Plans.constant';

const useStyles = makeStyles({
  root: {
    alignSelf: 'center',
    justifySelf: 'center',
    margin: 'auto',
    width: '80%',
    maxWidth: '400px',
  },
  formControl: {
    marginBottom: '20px',
    fontSize: '16px',
    '&:last-child': {
      marginBottom: '0px',
    },
    plans: {
      marginBottom: '20px',
    },
  },
  quantity: {
    display: 'block',
    padding: 20,
  },
  formControlQuantity: {
    marginBottom: '20px',
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  actions: {
    justifyContent: 'space-between',
  },
  alert: { margin: '0 16px' },
});

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const styles = useStyles();
  const [quantity, setQuantity] = useState(1);
  const [max, setMax] = useState(1);
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('team');
  const [checked, setChecked] = useState('');
  const [showMethods, setShowMethods] = useState(props.update);

  const paymentMethods = useSelector((state) =>
    state.getIn([NS, 'paymentMethods'])
  );

  const {
    control,
    handleSubmit: _handleSubmit,
    formState,
    reset: resetForm,
    getValues,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: { quantity: 1 },
  });

  const handleSelect = (selected) => {
    setSelected(selected);
  };

  useEffect(() => {
    props.update && dispatch(getPaymentMethods());
  }, []);

  useEffect(() => {
    const quantity = getValues('quantity');
    const plan = PLANS.find((plan) => selected === plan.id);
    const max = plan.maxSeats || null;

    setMax(max);
    if (max && max < quantity) {
      setQuantity(max);
    }
  }, [selected]);

  useEffect(() => {
    setValue('quantity', quantity);
  }, [quantity]);

  const handleToggle = (id) => {
    setChecked(id);
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    let paymentMethodId = checked;

    // Use your card Element with other Stripe.js APIs
    if (!showMethods) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        // console.log('[error]', error);
      } else {
        if (!props.update) {
          dispatch(createSubscription(selected, quantity, paymentMethod.id));
        } else {
          dispatch(updateSubscription(selected, quantity, paymentMethod.id));
        }
      }
    } else {
      if (!props.update) {
        dispatch(createSubscription(selected, quantity, paymentMethodId));
      } else {
        dispatch(updateSubscription(selected, quantity, paymentMethodId));
      }
    }
  };

  const increaseQty = () => {
    const quantity = getValues('quantity');
    if (!max || quantity < max) {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
    }
  };
  const decreaseQty = () => {
    const min = 1;
    const quantity = getValues('quantity');
    if (quantity > min) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
    }
  };

  return (
    <div style={{ width: 800, maxWidth: 800, margin: '0 auto' }}>
      <Plans
        className={styles.plans}
        selected={selected}
        select={handleSelect}
      />
      <Card>
        <form onSubmit={handleSubmit}>
          <CardHeader title="Subscription Details" />
          <CardContent>
            <FormControl className={styles.formControlQuantity}>
              <Button size="small" onClick={decreaseQty}>
                -
              </Button>
              <Controller
                name="quantity"
                as={TextField}
                control={control}
                type={'hidden'}
                InputProps={{
                  readOnly: true,
                }}
                rules={{
                  required: true,
                }}
              />
              <span className={styles.quantity}>{quantity}</span>
              <Button size="small" onClick={increaseQty}>
                +
              </Button>
            </FormControl>
            <FormControl fullWidth className={styles.formControl}>
              <FormLabel>Card Details</FormLabel>
              {showMethods ? (
                <PaymentMethods
                  paymentMethods={paymentMethods}
                  checked={checked}
                  handleToggle={handleToggle}
                />
              ) : (
                <CardField />
              )}
              {props.update ? (
                <div>
                  <Button
                    onClick={() => {
                      setShowMethods(!showMethods);
                    }}
                  >
                    {showMethods ? 'Add New' : 'Saved Payment Methods'}
                  </Button>
                </div>
              ) : null}
            </FormControl>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={!stripe}
            >
              Pay
            </Button>
            <Button color="primary" to={'/billing'} component={Link}>
              Cancel
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

export const Payment = (props) => {
  const dispatch = useDispatch();
  const stripe = useSelector((state) => state.getIn([NS, 'stripe']));

  useEffect(() => {
    dispatch(updateCrumbs([{ label: 'Susbcription' }]));
    !stripe && dispatch(loadStripe());
  }, []);

  if (!stripe) {
    return null;
  }

  return (
    <Elements stripe={stripe}>
      <CheckoutForm {...props} />
    </Elements>
  );
};
