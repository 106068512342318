import { connect } from 'react-redux';
import {
  addDependency,
  searchServices,
  toggleDependencyModal
} from '@/screens/Service/ducks/actions';
import { NS } from '@/screens/Service/ducks/reducer';
import DependencyForm from './DependencyForm';

const mapStateToProps = state => ({
  loading: state.getIn([NS, 'pending', 'filteredDependencies']),
  service: state.getIn([NS, 'service']),
  filteredDependencies: state.getIn([NS, 'filteredDependencies']),
  visible: state.getIn([NS, 'dependencyModalVisible'])
});

const mapDispatchToProps = dispatch => ({
  addDependency: payload => dispatch(addDependency(payload)),
  searchServices: q => dispatch(searchServices(q)),
  hide: () => dispatch(toggleDependencyModal({ show: false }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DependencyForm);
