import React, { PureComponent } from 'react';

import cn from 'classnames';
import { Switch, FormControlLabel } from '@material-ui/core';
export class NoticeFilter extends PureComponent {
  handleClick = () => {
    const { onSelect, type } = this.props;
    onSelect(type);
  };

  render() {
    const { name, selected } = this.props;
    const classNames = cn('NoticeFilters__type', { selected });
    return (
      <li onClick={this.handleClick} className={classNames}>
        <span>{name}</span>
      </li>
    );
  }
}

class NoticeFilters extends PureComponent {
  state = {
    type: 'incident,maintenance',
    filters: [
      {
        label: 'All',
        type: 'incident,maintenance',
        selected: true
      },
      {
        label: 'Maintenance',
        type: 'maintenance'
      },
      {
        label: 'Incidents',
        type: 'incident'
      }
    ],
    open: true
  };

  handleTypeChange = type => {
    const { filters, open } = this.state;
    const { onChange } = this.props;
    const updatedFilters = filters.map(filter =>
      Object.assign({}, filter, { selected: type === filter.type })
    );

    this.setState({ type, filters: updatedFilters });
    onChange({ type, open });
  };

  handleStatusChange = event => {
    const open = event.target.checked;

    const { type } = this.state;
    const { onChange } = this.props;
    this.setState({ open });
    onChange({ type, open });
  };

  render() {
    const { filters, open } = this.state;

    return (
      <div className="NoticeFilters">
        <ul className="NoticeFilters__types">
          {filters.map(filter => (
            <NoticeFilter
              key={filter.label}
              name={filter.label}
              type={filter.type}
              selected={filter.selected}
              onSelect={this.handleTypeChange}
            />
          ))}
        </ul>
        <FormControlLabel
          style={{ marginRight: '30px' }}
          control={
            <Switch
              checked={open}
              name="open"
              onChange={this.handleStatusChange}
            />
          }
          label="Open"
        />
      </div>
    );
  }
}

export default NoticeFilters;
