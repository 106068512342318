import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NS } from '@/screens/Service/ducks/reducer';

import {
  getDraftAnnouncement,
  createDraftAnnouncement,
  updateDraftAnnouncement,
  publishDraftAnnouncement,
  unpublishDraftAnnouncement,
  saveAndPublishDraftAnnouncement,
  resetDraftAnnouncement,
  updateCrumbs
} from '@/screens/Service/ducks/actions';
import { DraftPage } from '../../components/DraftPage';

export const DraftAnnoucement = props => {
  const { updateCrumbs } = props;
  useEffect(() => {
    updateCrumbs([
      {
        label: 'Drafts Announcements',
        path: `/services/${props.service.get('handle')}/draft-announcements`
      },
      { label: 'Draft Announcement' }
    ]);
  }, []);
  return <DraftPage {...props} />;
};

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service']),
  draft: state.getIn([NS, 'draftAnnouncement']),
  pending: state.getIn([NS, 'pending', 'draftAnnouncement'])
});
const mapDispatchToProps = dispatch => ({
  get: draftId => dispatch(getDraftAnnouncement(draftId)),
  create: (serviceId, draft) =>
    dispatch(createDraftAnnouncement(serviceId, draft)),
  update: (draftId, draft) => dispatch(updateDraftAnnouncement(draftId, draft)),
  publish: draftId => dispatch(publishDraftAnnouncement(draftId)),
  unpublish: draftId => dispatch(unpublishDraftAnnouncement(draftId)),
  saveAndPublish: (draftId, page) =>
    dispatch(saveAndPublishDraftAnnouncement(draftId, page)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs)),
  reset: () => dispatch(resetDraftAnnouncement())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraftAnnoucement);
