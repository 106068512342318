import React, { PureComponent, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { createRunner, updateRunner } from '../../ducks/actions';

export const ManageRunner = (props) => {
  const { visible, team, runner, hide } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(visible);
  const { control, handleSubmit, formState, reset, setValue } = useForm({
    mode: 'onChange',
    defaultValues: { name: '', description: '' },
  });

  const { isValid } = formState;

  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  useEffect(() => {
    if (runner) {
      reset({
        name: runner.get('name'),
        description: runner.get('description'),
      });
    } else {
      reset({ name: '', description: '' });
    }
  }, [runner]);

  const onSubmit = (values) => {
    const teamHandle = team.get('handle');
    if (!runner) {
      dispatch(createRunner(teamHandle, values));
    } else {
      const runnerHandle = runner.get('handle');
      dispatch(updateRunner(teamHandle, runnerHandle, values));
    }
    setOpen(false);
  };

  const onHide = () => {
    setOpen(false);
  };

  const onExited = () => {
    hide();
  };

  return (
    <Dialog
      open={open}
      onClose={onHide}
      onExited={onExited}
      style={{ minWidth: '400px' }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: '400px' }}>
        <DialogTitle>{!runner ? 'Add Runner' : 'Edit Runner'}</DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth={true}
            autoComplete="off"
            style={{ marginBottom: '20px' }}
          >
            <Controller
              label="Name"
              name="name"
              control={control}
              rules={{ required: true }}
              as={TextField}
              autoComplete="off"
            />
          </FormControl>
          <FormControl fullWidth={true} autoComplete="off">
            <Controller
              label="Description"
              name="description"
              control={control}
              rules={{ required: true }}
              as={TextField}
              autoComplete="off"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onHide} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={!isValid}>
            {!runner ? 'Add' : 'Edit'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
