import React, { PureComponent } from 'react';
import { CircularProgress, Typography, Grid } from '@material-ui/core';
import StatusItem from './StatusItem';

const List = ({ items, view, edit, canEdit }) => {
  return items.map(item => (
    <Grid item key={item.get('id')} md={4}>
      <StatusItem item={item} edit={edit} view={view} canEdit={canEdit} />
    </Grid>
  ));
};

export default class StatusList extends PureComponent {
  render() {
    const { items, pending, edit, view, canEdit } = this.props;
    return (
      <div className="StatusList">
        {pending ? (
          <CircularProgress />
        ) : !items.size ? (
          <Typography variant="h5" className="StatusList__empty">
            No notices
          </Typography>
        ) : (
          <Grid container spacing={3}>
            <List
              items={items}
              view={view}
              edit={edit}
              pending={pending}
              canEdit={canEdit}
            />
          </Grid>
        )}
      </div>
    );
  }
}
