import React from 'react';
import { useForm, Controller } from 'react-hook-form';

import { CardElement } from '@stripe/react-stripe-js';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#1e88e5',
      color: '#000',
      //   backgroundColor: '#e0e0e0',
      fontWeight: 500,
      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#999999' },
      '::placeholder': { color: '#999999' },
    },
    invalid: {
      iconColor: '#cc0000',
      color: '#cc0000',
    },
  },
};

export const CardField = () => <CardElement options={CARD_OPTIONS} />;
