import { createTypes, asyncType, createNS } from '@/utils/actionCreator';
export const NS = createNS('TEAM');

export default createTypes(
  [
    ...asyncType('TEAM_GET'),
    ...asyncType('TEAM_UPDATE'),
    ...asyncType('TEAM_AVATAR_UPDATE'),
    ...asyncType('TEAM_ICON_DELETE'),
    ...asyncType('TEAM_DELETE'),
    // ...asyncType('TEAM_POLICIES_GET'),
    // ...asyncType('TEAM_POLICIES_UPDATE'),
    ...asyncType('TEAM_SERVICES_GET'),
    ...asyncType('TEAM_TEAMS_GET'),
    ...asyncType('TEAM_MEMBERS_GET'),
    ...asyncType('TEAM_MEMBER_ADD'),
    ...asyncType('TEAM_MEMBERS_ROLE_PATCH'),
    ...asyncType('TEAM_MEMBER_REMOVE'),
    'TEAM_MEMBER_TOGGLE_MODAL',
    ...asyncType('TEAM_MEMBERS_AUTOCOMPLETE'),
    ...asyncType('TEAM_SERVICE_COUNT_GET'),
    'TEAM_DELETE_CONFIRM_MODAL',

    // CLIENTS
    ...asyncType('CLIENTS_GET'),
    ...asyncType('CLIENT_GET'),
    ...asyncType('CLIENT_CREATE'),
    ...asyncType('CLIENT_UPDATE'),
    ...asyncType('CLIENT_SECRET_UPDATE'),
    ...asyncType('CLIENT_DELETE'),
    'CLIENT_SET',

    // RUNNER
    ...asyncType('RUNNERS_GET'),
    ...asyncType('RUNNER_GET'),
    ...asyncType('RUNNER_CREATE'),
    ...asyncType('RUNNER_UPDATE'),
    ...asyncType('RUNNER_DELETE'),
    'RUNNER_SET',

    // HEALTH
    ...asyncType('HEALTH_GET'),

    // PUBLIC_FEED
    ...asyncType('PUBLIC_FEED_GET'),

    'UPDATE_CRUMBS',

    'RESET'
  ],
  `${NS}`
);
