import React, { useCallback, useEffect } from 'react';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Container,
  Typography
} from '@material-ui/core';

import {
  SlackWorkspace,
  SsoProviders,
  RemoteWorkspaces,
  RemoteWorkspaceModal
} from '../../components';
import { Link } from 'react-router-dom';

export default props => {
  useEffect(() => {
    props.updateCrumbs([]);
  }, []);

  const handleExportPages = useCallback(() => {
    const { exportPages } = this.props;
    exportPages();
  });

  return (
    <Container maxWidth="md">
      <Card style={{ marginBottom: '20px' }}>
        <CardHeader title="Slack Workspaces" />
        <CardContent>
          <SlackWorkspace />
        </CardContent>
      </Card>
      <Card style={{ marginBottom: '20px' }}>
        <CardHeader title="SSO Providers" />
        <CardContent>
          <SsoProviders />
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={'/admin/sso-providers/new'}
          >
            Add Provider
          </Button>
        </CardActions>
      </Card>

      <Card style={{ marginBottom: '20px' }}>
        <CardHeader title="Remote Workspaces" />
        <CardContent>
          <RemoteWorkspaces />
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            // component={Link}
            // to={'/admin/sso-providers/new'}
            onClick={() => props.changeRemoteWorkspaceModalVisibility(true)}
          >
            Add Remote Workspace
          </Button>
        </CardActions>
      </Card>
      <Card>
        <CardHeader title="Export Workspace Pages" />
        <CardContent>
          <p>Export all your workspace published and draft pages</p>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportPages}
          >
            Export Pages
          </Button>
        </CardActions>
      </Card>
      <RemoteWorkspaceModal></RemoteWorkspaceModal>
    </Container>
  );
};
