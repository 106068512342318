import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';

// import Autocomplete from '@material-ui/lab/Autocomplete';

export default props => {
  const {
    visible,
    linkRemoteWorkspace,
    changeRemoteWorkspaceModalVisibility
  } = props;

  const { control, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: { remote: '' }
  });

  const { isValid } = formState;

  const hide = useCallback(() => {

    changeRemoteWorkspaceModalVisibility(false);
  });

  const onSubmit = useCallback(values => {

    linkRemoteWorkspace(values.remote);
    hide();
  });

  return (
    <Dialog open={visible} onClose={hide}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Add Remote Workspace</DialogTitle>
        <DialogContent>
          <Controller
            label="Remote Workspace ID"
            name="remote"
            placeholder=""
            control={control}
            rules={{ required: true }}
            autoComplete="off"
            as={TextField}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={hide} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={!isValid}>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
// }
