import React, { useState, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { INCIDENT_STATUS, MAINTENANCE_STATUS } from './statuses';
import { GOOD, MAINTENANCE, WARNING, ERROR, CRITICAL } from '../../StatusTypes';

export const healthValidator = values => {
  return (
    (values.status !== 'resolved' && values.status !== 'completed') ||
    ((values.status == 'resolved' || values.status == 'completed') &&
      values.health === 'good')
  );
};

const NoticeTypeOptions = [
  {
    label: 'Incident',
    value: 'incident'
  },
  {
    label: 'Maintenance',
    value: 'maintenance'
  }
];

const ServiceHealthOptions = [
  { label: 'Good', value: GOOD },
  { label: 'Maintenance', value: MAINTENANCE },
  { label: 'Warning', value: WARNING },
  { label: 'Error', value: ERROR },
  { label: 'Critical', value: CRITICAL }
];

const getStatusOptionsByType = type => {
  switch (type) {
    case 'incident':
      return [...INCIDENT_STATUS];
    case 'maintenance':
      return [...MAINTENANCE_STATUS];
  }
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default props => {
  const {
    service,
    notice,
    visible,
    create,
    update,
    updateHealth,
    hide
  } = props;
  const classes = useStyles();

  const {
    control,
    handleSubmit,
    formState,
    getValues,
    setValue,
    watch,
    reset
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      type: 'incident',
      status: 'investigating',
      health: service.get('health'),
      message: ''
    }
  });
  const watchType = watch('type');

  useEffect(() => {
    const options = getStatusOptionsByType(watchType);
    setStatusOptions(options);
    const currentStatus = getValues('status');
    if (!options.find(o => o.value === currentStatus)) {
      const type = watchType === 'incident' ? 'investigating' : 'scheduled';
      setValue('status', type, { shouldDirty: true });
    }
  }, [watchType]);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  useEffect(() => {
    if (notice) {
      const values = {
        type: notice.get('type'),
        title: notice.get('title'),
        health: service.get('health'),
        status: notice.get('status'),
        message: ''
      };

      reset(values, { isDirty: false });
    } else {
      const values = {
        type: 'incident',
        title: '',
        health: service.get('health'),
        status: 'investigating',
        message: ''
      };
      reset(values, { isDirty: false });
    }
  }, [notice]);

  const { isValid } = formState;

  const [statusOptions, setStatusOptions] = useState([...INCIDENT_STATUS]);

  const onSubmit = body => {
    if (notice) {
      const id = notice.get('id');
      const payload = { message: body.message, status: body.status };
      update(id, payload);
    } else {
      const id = service.get('handle');
      const { health, ...payload } = body;
      create({ service: { id }, ...payload });
    }

    if (service.get('health') !== body.health) {
      updateHealth(service.get('handle'), body.health);
    }

    close();
  };

  const close = () => {
    setOpen(false);
  };

  const onExited = () => {
    hide();
  };

  return (
    <Dialog open={open} onClose={close} onExited={onExited}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{!notice ? 'Create' : 'Update'} Notice</DialogTitle>
        <DialogContent>
          {notice ? null : (
            <FormControl className={classes.formControl} fullWidth={true}>
              <InputLabel id="notice-type">Type</InputLabel>
              <Controller
                labelId="notice-type"
                placeholder="Select Type"
                name={'type'}
                control={control}
                rules={{ required: true }}
                as={Select}
                fullWidth={true}
              >
                {NoticeTypeOptions.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Controller>
            </FormControl>
          )}
          <FormControl className={classes.formControl} fullWidth={true}>
            <Controller
              label="Title"
              name="title"
              placeholder=""
              control={control}
              rules={{ required: true }}
              control={control}
              as={TextField}
              autoComplete="off"
              disabled={notice ? true : false}
            />
          </FormControl>
          <FormControl className={classes.formControl} fullWidth={true}>
            <InputLabel id="notice-statue">Status</InputLabel>
            <Controller
              labelId="notice-status"
              placeholder="status"
              name={'status'}
              control={control}
              rules={{ required: true }}
              as={Select}
              fullWidth={true}
            >
              {statusOptions.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Controller>
          </FormControl>
          <FormControl className={classes.formControl} fullWidth={true}>
            <InputLabel id="service-health">Service Health</InputLabel>
            <Controller
              labelId="service-health"
              placeholder="Select Service Health"
              name={'health'}
              control={control}
              rules={{
                required: true,
                validate: {
                  healthValidator: v =>
                    healthValidator(v) ||
                    'Service health should be "Good" if notice is resolved.'
                }
              }}
              as={Select}
              fullWidth={true}
            >
              {ServiceHealthOptions.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Controller>
          </FormControl>
          {/* <Errors
          className="errors"
          model="local"
          show="touched"
          wrapper="ul"
          component="li"
          messages={{
            healthValidator:
              'Service health should be "Good" if notice is resolved.'
          }}
        /> */}
          <FormControl className={classes.formControl} fullWidth={true}>
            <Controller
              label="Message"
              name={`message`}
              control={control}
              rules={{ required: true }}
              as={TextField}
              multiline
              fullWidth={true}
              rows={4}
              autoComplete="off"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={!isValid}>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
  // }
};
