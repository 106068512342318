import { connect } from 'react-redux';

import { NS } from './ducks/actionTypes';
import Watched from './Watched';
import { NS as NS_GLOBAL } from '@/reducers/global';
import { updateWatchedService } from '@/reducers/global';
// import { getUserServices } from './ducks/actions';
// import { getWatchableServices } from './ducks/selectors';

const mapStateToProps = state => ({
  // pending: state.getIn([NS, 'pending', 'services']),
  // services: getWatchableServices(state),
  services: state.getIn([NS_GLOBAL, 'watched'])
});

const mapDispatchToProps = dispatch => ({
  mute: id => dispatch(updateWatchedService(id, true)),
  unmute: id => dispatch(updateWatchedService(id, false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Watched);
