import { createAction } from '@/utils/actionCreator';

import ActionTypes from './actionTypes';

export const createUser = createAction(
  ActionTypes.CREATE_USER,
  'workspace',
  'invite',
  'user'
);

