import { connect } from 'react-redux';

import {
  getUser,
  getUserFeed,
  updateCrumbs,
  deleteUser
} from '@/screens/User/ducks/actions';
import { NS } from '@/screens/User/ducks/actionTypes';
import { NS as NS_ME } from '@/reducers/user';
import Overview from './Overview';

const mapStateToProps = state => ({
  //   pending: state.getIn([NS, 'pending', 'user']),
  user: state.getIn([NS, 'user']),
  teams: state.getIn([NS, 'teams']),
  feed: state.getIn([NS, 'feed']),
  feedPending: state.getIn([NS, 'feedPending']),
  me: state.getIn([NS_ME, 'user'])
});

const mapDispatchToProps = dispatch => ({
  getUser: payload => dispatch(getUser(payload)),
  getUserFeed: handle => dispatch(getUserFeed(handle)),
  deleteUser: payload => dispatch(deleteUser(payload)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview);
