import React, { useState, useEffect, useCallback } from 'react';

import { CircularProgress, Grid, Box, Typography } from '@material-ui/core';
import ServiceCell from '@/components/ServiceCell';
import { Main, SearchInput } from '@/components';

const List = ({ pending, services }) => {
  if (pending) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <CircularProgress scale={2} />
      </Box>
    );
  }

  if (services.size === 0) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <Typography variant={'h6'}>No services found</Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      {services.map(service => (
        <Grid item xs={4} key={service.get('id')}>
          <ServiceCell
            // className="md-cell md-cell--4"
            name={service.get('name')}
            handle={service.get('handle')}
            icon={service.getIn(['thumbnail_url'], null)}
            description={service.get('description')}
            health={service.get('health')}
            starredCount={service.get('starred_count')}
            displayCount={true}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default props => {
  const { services, starredPending, loading, getServices, reset } = props;
  const [payload, setPayload] = useState({ limit: 36, cursor: 0, q: '' });

  useEffect(() => {
    getServices(payload);
    return () => {
      reset();
    };
  }, [payload]);

  const handleSearch = useCallback(q => {
    setPayload({ ...payload, q });
  });

  // const { services, starredPending, loading } = this.props;

  return (
    <div className="Container">
      <Main>
        <div>
          <SearchInput onSearch={handleSearch} />
          <List services={services} pending={starredPending || loading} />
        </div>
      </Main>
    </div>
  );
};
