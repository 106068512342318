import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Icon,
  IconButton,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ServiceHealth } from '@/components';

const useStyles = makeStyles(theme => ({
  description: {
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    height: '42px',
    overflow: 'hidden',

    '-webkit-box-orient': 'vertical'
  }
}));

const ServiceGrid = props => {
  const {
    className,
    handle,
    name = '',
    description,
    health,
    icon = null,
    displayCount,
    children,
    hideView = false,
    hideStarred = false,
    unstar,
    star
  } = props;

  const [starredCount, setStarredCount] = useState(0);
  const [starred, setStarrred] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (props.isStarred) {
      setStarrred(true);
    }
    if (props.starredCount) {
      setStarrred(props.starredCount);
    }
  }, []);

  const handleStar = useCallback(event => {
    event.preventDefault();
    star(handle);
    setStarredCount(starredCount + 1);
    setStarrred(true);
  });

  const handleUnstar = useCallback(event => {
    event.preventDefault();
    unstar(handle);
    setStarredCount(starredCount - 1);
    setStarrred(false);
  });

  const initial = (name.charAt(0) || '').toUpperCase();

  return (
    <Card
      className={`${className} ServiceCell`}
      variant="outlined"
      square={true}
    >
      <CardHeader
        avatar={
          <Avatar alt={name} src={icon}>
            {initial}
          </Avatar>
        }
        title={name}
        subheader={<ServiceHealth prefix="Status: " health={health} />}
        action={
          !hideStarred ? (
            starred ? (
              <IconButton color="primary" onClick={handleUnstar}>
                <Icon>star</Icon>
              </IconButton>
            ) : (
              <IconButton onClick={handleStar}>
                <Icon>star_outline</Icon>
              </IconButton>
            )
          ) : null
        }
      ></CardHeader>
      <CardContent>
        <Typography variant="body2" className={classes.description}>
          {description || '—'}
        </Typography>
      </CardContent>
      <CardActions>
        {!hideView ? (
          <Button color="primary" component={Link} to={`/services/${handle}`}>
            View Service
          </Button>
        ) : null}
        {children ? children : null}
      </CardActions>
    </Card>
  );
};

export default ServiceGrid;
