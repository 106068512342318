import React, { PureComponent } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

export default props => {
  const {
    loading,
    addMemberModalVisible,
    filteredMembers,
    team,
    addMember,
    searchUsers,
    hide
  } = props;

  const { control, handleSubmit, formState, reset: resetForm } = useForm({
    mode: 'onChange',
    defaultValues: { user: null }
  });

  const { isValid } = formState;

  const onSubmit = values => {
    const teamHandle = team.get('handle');
    addMember(teamHandle, values.user.handle);
    hide();
  };

  const onHide = () => {
    hide();
  };

  const handleSearch = (event, value) => {
    if (value) {
      search(value);
    }
  };

  const search = value => {
    searchUsers(value);
  };

  const results = filteredMembers.size ? filteredMembers.toJS() : [];

  return (
    <Dialog open={addMemberModalVisible} onClose={onHide} title="Add Member">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Add Member</DialogTitle>
        <DialogContent>
          <Controller
            name="user"
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <Autocomplete
                style={{ width: 300 }}
                autoComplete
                options={results}
                getOptionLabel={option => option.name || ''}
                getOptionSelected={(option, value) =>
                  option.handle === value.handle
                }
                loading={loading}
                onInputChange={handleSearch}
                onChange={(e, v) => onChange(v)}
                onBlur={onBlur}
                value={value}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Find user"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onHide} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={!isValid}>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
