import { createAction } from '@/utils/actionCreator';
import ActionTypes from './actionTypes';

export const getSlackWorkspaces = createAction(
  ActionTypes.SLACK_WORKSPACES_GET
);

export const createSlackWorkspace = createAction(
  ActionTypes.SLACK_WORKSPACE_POST,
  'token'
);

export const updateSlackWorkspace = createAction(
  ActionTypes.SLACK_WORKSPACE_PATCH,
  'id',
  'token'
);

export const getSsoProviders = createAction(ActionTypes.SSO_PROVIDERS_GET);

export const getSsoProvider = createAction(
  ActionTypes.SSO_PROVIDER_GET,
  'handle'
);

export const createSsoProvider = createAction(
  ActionTypes.SSO_PROVIDER_POST,
  'provider'
);

export const updateSsoProvider = createAction(
  ActionTypes.SSO_PROVIDER_PATCH,
  'handle',
  'provider'
);

export const exportPages = createAction(ActionTypes.PAGES_EXPORT);

export const listRemoteWorkspaces = createAction(
  ActionTypes.REMOTE_WORKSPACES_GET
);
export const linkRemoteWorkspace = createAction(
  ActionTypes.REMOTE_WORKSPACE_POST,
  'remote'
);
export const unlinkRemoteWorkspace = createAction(
  ActionTypes.REMOTE_WORKSPACE_DELETE,
  'remote'
);

export const changeRemoteWorkspaceModalVisibility = createAction(
  ActionTypes.REMOTE_WORKSPACE_MODAL_VISIBILITY_SET,
  'visibility'
);



export const resetSsoProvider = createAction(ActionTypes.SSO_PROVIDER_RESET);

export const updateCrumbs = createAction(ActionTypes.UPDATE_CRUMBS, 'crumbs');
