import { connect } from 'react-redux';
import { NS as NS_USER } from '@/reducers/user';
import UserMenu from './UserMenu';
import './UserMenu.scss';

const stateToProps = state => ({
  user: state.getIn([NS_USER, 'user'])
});

const mapDispatchToProps = dispatch => ({
  // getToken: () => dispatch(getToken())
});

export default connect(stateToProps, mapDispatchToProps)(UserMenu);
