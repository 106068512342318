import { connect } from 'react-redux';
import { NS } from './ducks/reducer';
import { getUserServices, getUserTeams } from './ducks/actions';
import { NS as NS_GLOBAL, getUserEvents } from '@/reducers/global';

import Home from './Home';

const mapStateToProps = state => ({
  services: state.getIn([NS, 'services']),
  servicesPending: state.getIn([NS, 'pending', 'services']),
  teams: state.getIn([NS, 'teams']),
  teamsPending: state.getIn([NS, 'pending', 'teams']),
  starred: state.getIn([NS_GLOBAL, 'starred']),
  events: state.getIn([NS_GLOBAL, 'events']),
  eventsLoading: state.getIn([NS_GLOBAL, 'pending', 'events'])
});

const mapDispatchToProps = dispatch => ({
  getServices: () => dispatch(getUserServices()),
  getTeams: () => dispatch(getUserTeams()),
  getUserEvents: () => dispatch(getUserEvents())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
