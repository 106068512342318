import { connect } from 'react-redux';

import { NS } from '../../ducks/actionTypes';

import { NS as GLOBAL_NS } from '@/reducers/global';
import { NS as USER_NS } from '@/reducers/user';

import {
  getSlackWorkspaces,
  createSlackWorkspace,
  updateSlackWorkspace
} from '../../ducks/actions';

import SlackWorkspace from './SlackWorkspace';

const mapStateToProps = state => ({
  pending: state.getIn([NS, 'pending', 'slackWorkspaces']),
  slackWorkspaces: state.getIn([NS, 'slackWorkspaces']),
  workspace: state.getIn([GLOBAL_NS, 'workspace']),
  user: state.getIn([USER_NS, 'user'])
});

const mapDispatchToProps = dispatch => ({
  getSlackWorkspaces: () => dispatch(getSlackWorkspaces()),
  createSlackWorkspace: token => dispatch(createSlackWorkspace(token)),
  updateSlackWorkspace: (id, token) => dispatch(updateSlackWorkspace(id, token))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SlackWorkspace);
