import React from 'react';

import { InputFieldGroup, AssetFieldGroup } from '@/components';
import { Main, Container } from '@/components';
import { useEffect } from 'react';

export default props => {
  const { user, updateUser, upload, clear } = props;
  if (!user) return null;

  useEffect(() => {
    props.updateCrumbs([{ label: 'Settings' }]);
  },[]);

  return (
    <div>
      <AssetFieldGroup
        label="Avatar"
        entity={user}
        entityId={user.get('handle')}
        asset={user.get('thumbnail_url')}
        fallbackName={user.get('name')}
        upload={upload}
        clear={clear}
        buttonText={'Upload Avatar'}
        clearText={'Clear Avatar'}
        description={`This icon will be used to identify you in Visr.
          It will be visible to members of your organization.`}
      />

      <InputFieldGroup
        label="Name"
        entity={user}
        entityId={user.get('handle')}
        field={'name'}
        save={updateUser}
      />

      <InputFieldGroup
        label="Bio"
        entity={user}
        entityId={user.get('handle')}
        field={'bio'}
        save={updateUser}
      />

      <InputFieldGroup
        label="Profile Link"
        entity={user}
        entityId={user.get('handle')}
        field={'profile'}
        save={updateUser}
      />
    </div>
  );
};
