import React, { PureComponent } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress
} from '@material-ui/core';

import CytoscapeComponent from 'react-cytoscapejs';

import { config } from './config';

class ServiceGraph extends PureComponent {
  state = {
    creating: false
  };
  componentDidMount() {
    const { getServiceGraph, service } = this.props;
    const handle = service.get('handle');
    getServiceGraph(handle, 2);
    this.props.updateCrumbs([{ label: 'Service Graph' }]);
  }

  mapData(data) {
    const { service } = this.props;
    const handle = service.get('handle');
    return data.reduce(
      (previousState, curr) => {
        const dependencies = curr.dependencies
          .map(d => d.replace(/^([a-z\-]+\/)/, ''))
          .filter(d => {
            return data.find(s => s.handle === d);
          })
          .map(dependency => ({
            source: curr.handle,
            target: dependency
          }));

        const dependants = [];
        const dependants2 = curr.dependents
          .filter(d => data.find(s => s.handle === d))
          .map(dependant => ({
            source: dependant,
            target: curr.handle
          }));

        return {
          nodes: [
            ...previousState.nodes,
            {
              id: curr.handle,
              name: curr.name,
              health: curr.health,
              color: handle == curr.handle ? '#1e88e5' : '#000',
              svg: curr.thumbnail_url,
              size: 360
            }
          ],
          links: [...previousState.links, ...dependencies, ...dependants]
        };
      },
      {
        nodes: [],
        links: [],
        focusedNodeId: handle
      }
    );
  }

  getTooltipContent = () => {
    return 'hello';
  };

  mapData2(data) {
    const handles = data.map(s => s.handle);

    const { nodes, links } = data
      // .sort((a, b) => {
      //   return a.dependencies.length - b.dependencies.length;
      // })
      .reduce(
        (previousState, service, index, array) => {
          // if (previousState.length && service.handle === handle) {
          //   return previousState
          // }

          if (index !== array.findIndex(el => el.handle === service.handle)) {
            return previousState;
          }

          previousState.nodes.push({
            data: { id: service.handle, name: service.name }
          });

          // if (service.handle === 'draft-service') {
          previousState.links = previousState.links.concat(
            service.dependencies
              // .map(d => {
              //   // var r = d.replace(/^([a-z\-]+\/)/, '');
              //   var r = d.replace('dev/', '');
              //   console.log(service.handle, d, r);
              //   return r;
              // })
              .filter(handle => handles.includes(handle))
              .map(handle => ({
                data: {
                  source: service.handle,
                  target: handle
                }
              }))
          );
          // }

          return previousState;
        },
        { nodes: [], links: [] }
      );

    return nodes.concat(...links);
  }

  getStylesheet(data) {
    return [
      {
        selector: 'node',
        style: {
          height: 60,
          width: 60,
          'background-fit': 'cover',
          'border-color': '#c0c0c0',
          'border-width': 6,
          // 'border-opacity': 0.5
        }
      },
      {
        selector: 'node[name]',
        style: {
          content: 'data(name)',
          "color": "#999",
          'text-valign': 'bottom',
          'text-halign': 'center',
          'text-background-color': '#fff',
          'text-background-opacity': 0.5,
          'text-background-shape': 'roundrectangle'
          // 'text-border-color': '#000',
          // 'text-border-width': 1,
          // 'text-border-opacity': 0.5
        }
      },
      {
        selector: 'edge',
        style: {
          'curve-style': 'bezier',
          width: 3,
          'target-arrow-shape': 'triangle',
          'line-color': '#999',
          'target-arrow-color': '#999'
        }
      }
    ].concat(
      ...data.map(service => ({
        selector: `#${service.handle}`,
        style: {
          ...(service.thumbnail_url
            ? { 'background-image': service.thumbnail_url + '?graph=1' }
            : {
                'background-color': this._getHealthColor(service),
                'background-image': this.getIcon(service)
              }),
          ...(service.handle === this.props.service.get('handle')
            ? {
                width: 100,
                height: 100
              }
            : {}),
          'border-color': this._getHealthColor(service)
        }
      }))
    );
  }

  _getHealthColor(service) {
    switch (service.health) {
      case 'good':
        return '#4caf50';

      case 'maintenance':
        return '#03a9f4';

      case 'warning':
        return '#ff9800';

      case 'error':
        return '#fdd835';

      case 'critical':
        return '#f44336';
    }
    return '#c0c0c0';
  }

  getIcon(service) {
    const initial = service.name.charAt(0).toUpperCase();

    const c = document.createElement('canvas');

    const ctx = c.getContext('2d');
    ctx.font = '100px Helvetica';
    ctx.textColor = '#fff';
    ctx.fillStyle = '#fff';
    ctx.textAlign = 'center';
    ctx.textValign = 'center';
    ctx.fillText(initial, c.width / 2, c.height / 2 + 36);
    const dataUrl = c.toDataURL();

    return dataUrl;
  }

  getBorderColor(service) {}

  getContent2() {

    const { loading, graph } = this.props;
    if (loading && graph) {
      return <CircularProgress></CircularProgress>;
    }
    const elements = this.mapData2(graph);

    const stylesheet = this.getStylesheet(graph);

    const layout = {
      name: 'breadthfirst',
      roots: [this.props.service.get('handle')]
    };

    return (
      <div className="dependency-graph">
        <CytoscapeComponent
          layout={layout}
          elements={elements}
          stylesheet={stylesheet}
          style={{ width: '100%', height: '600px' }}
        ></CytoscapeComponent>
      </div>
    );

    <Cytoscape element={element}></Cytoscape>;
  }


  render() {
    return (
      <div>
        <Card className="service-graph">
          <CardContent style={{ height: '600px' }}>
            {this.getContent2()}
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default ServiceGraph;
