import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  nav: {
    listStyle: 'none',
    margin: '0 auto 0 0',
    padding: '0',
    display: 'flex'
  },
  navLink: {
    marginRight: '2rem',
    '& a': {
      color: '#fff',
      fontSize: '18px'
    }
  }
});

const Navigation = () => {
  const classes = useStyles();
  return (
    <ul className={classes.nav}>
      <li className={classes.navLink}>
        <Link to="/services">Services</Link>
      </li>
      <li className={classes.navLink}>
        <Link to="/teams">Teams</Link>
      </li>
      <li className={classes.navLink}>
        <Link to="/users">Users</Link>
      </li>
    </ul>
  );
};

export default Navigation;
