import React, { useState, useCallback } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Icon,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Portal
} from '@material-ui/core';
import Moment from 'react-moment';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect } from 'react';

const TITLE_DATE_FORMAT = 'MMM D, YYYY';

const DraftPageRow = props => {
  const {
    serviceHandle,
    section,
    page,
    nameField = 'title',
    deleteDraft
  } = props;
  const id = page.get('id');
  const title = page.get(nameField);
  const published = page.get('published_at') !== null;
  const updated_at = page.get('updated_at');
  const last_updated_by = page.getIn(['updated_by', 'name']);

  const [anchorEl, setAnchorEl] = useState(null);

  const onClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  });
  const onRemove = useCallback(() => {
    deleteDraft(id);
    setAnchorEl(null);
  });
  const onClose = useCallback(() => setAnchorEl(null));

  return (
    <TableRow key={id}>
      <TableCell>
        <Link
          component={RouterLink}
          to={`/services/${serviceHandle}/draft-${section}/${id}`}
        >
          {title}
        </Link>
      </TableCell>
      <TableCell>{published ? 'Published' : 'Draft'}</TableCell>
      <TableCell>
        <Moment format={TITLE_DATE_FORMAT}>{updated_at}</Moment>
      </TableCell>
      <TableCell>{last_updated_by}</TableCell>
      <TableCell>
        <IconButton onClick={onClick}>
          <Icon>more_vert</Icon>
        </IconButton>
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onClose}
        >
          <MenuItem
            component={RouterLink}
            to={`/services/${serviceHandle}/draft-${section}/${id}`}
          >
            <ListItemText primary="Edit Draft" />
          </MenuItem>
          <MenuItem onClick={onRemove}>
            <ListItemText primary="Delete Draft" />
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

const DraftPagesRows = ({
  rows,
  nameField,
  handle: serviceHandle,
  section,
  loading,
  noResultsText,
  deleteDraft
}) => {
  if (loading) {
    return (
      <TableRow>
        <TableCell colSpan={5}>
          <CircularProgress id="UsersLoading" />
        </TableCell>
      </TableRow>
    );
  }
  if (!rows.size) {
    return (
      <TableRow>
        <TableCell colSpan={5}>
          {noResultsText}{' '}
          <Link
            component={RouterLink}
            to={`/services/${serviceHandle}/draft-${section}/new`}
          >
            Create one
          </Link>
          .
        </TableCell>
      </TableRow>
    );
  }

  return rows.map(row => {
    return (
      <DraftPageRow
        key={row.get('id')}
        nameField={nameField}
        page={row}
        section={section}
        serviceHandle={serviceHandle}
        deleteDraft={deleteDraft}
      />
    );
  });
};

export const DraftPages = props => {
  const {
    title,
    section,
    drafts,
    pending,
    service,
    nameField,
    noResultsText,
    deleteDraft,
    getDrafts
  } = props;
  useEffect(() => {
    const handle = service.get('handle');
    getDrafts(handle, section);
  }, []);

  const handle = service.get('handle');

  return (
    <Card>
      {/* <CardHeader
        title={title} */}
      <Portal container={document.getElementById('foo')}>
        <Button
          color="primary"
          component={RouterLink}
          to={`/services/${handle}/draft-${section}/new`}
        >
          Create New
        </Button>
      </Portal>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>Modified By</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <DraftPagesRows
              nameField={nameField}
              rows={drafts}
              loading={pending}
              handle={handle}
              section={section}
              deleteDraft={deleteDraft}
              noResultsText={noResultsText}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default DraftPages;
