import { connect } from 'react-redux';

import { NS } from '@/screens/Service/ducks/reducer';
import { getChannels, createChannel } from '@/screens/Service/ducks/actions';

import Channels from './Channels';

import './Channels.scss';

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service']),
  channels: state.getIn([NS, 'channels']),
  pending: state.getIn([NS, 'pending', 'channels']),
  canEdit: state.getIn([NS, 'service', 'permissions', 'edit'])
});

const mapDispatchToProps = dispatch => ({
  getChannels: handle => dispatch(getChannels(handle)),
  create: (handle, slackChannelId) =>
    dispatch(createChannel(handle, slackChannelId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Channels);
