import { connect } from 'react-redux';
import {
  addRemoteDependency,
  listRemoteServices,
  toggleRemoteDependencyModal
} from '@/screens/Service/ducks/actions';
import { NS } from '@/screens/Service/ducks/reducer';
import RemoteDependencyForm from './RemoteDependencyForm';

const mapStateToProps = state => ({
  loading: state.getIn([NS, 'pending', 'remoteServices']),
  service: state.getIn([NS, 'service']),
  remoteServices: state.getIn([NS, 'remoteServices']),
  visible: state.getIn([NS, 'remoteDependencyModalVisible'])
});

const mapDispatchToProps = dispatch => ({
  addRemoteDependency: (service, workspace, remote) =>
    dispatch(addRemoteDependency(service, workspace, remote)),
  listRemoteServices: () => dispatch(listRemoteServices()),
  hide: () => dispatch(toggleRemoteDependencyModal(false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoteDependencyForm);
