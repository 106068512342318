import { connect } from 'react-redux';

import { removeTeamMember } from '@/screens/Team/ducks/actions';

import MemberMenu from './MemberMenu';

const mapDispatchToProps = dispatch => ({
  removeMember: (teamHandle, userHandle) =>
    dispatch(removeTeamMember(teamHandle, userHandle))
});

export default connect(
  null,
  mapDispatchToProps
)(MemberMenu);
