import { connect } from 'react-redux';

import { NS } from './ducks/reducer';
import { inviteUser } from './ducks/actions';
import InviteUser from './InviteUser';

const mapStateToProps = state => ({
  success: state.getIn([NS, 'success']),
  error: state.getIn([NS, 'error']),
  pending: state.getIn([NS, 'pending'])
});

const mapDispatchToProps = dispatch => ({
  inviteUser: email => dispatch(inviteUser(email))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteUser);
