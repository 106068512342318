import React, { PureComponent } from 'react';
import Moment from 'react-moment';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography
} from '@material-ui/core';

const TITLE_DATE_FORMAT = 'MMM D, YYYY';
const DATE_FORMAT = 'MMM D, hh:mm a';

const Status = ({ status }) => {
  const statusString = status.charAt(0).toUpperCase() + status.slice(1);
  return <strong>{statusString}</strong>;
};

class StatusItem extends PureComponent {
  handleEdit = () => {
    const { item, edit } = this.props;
    edit(item);
  };

  handleView = () => {
    const { item, view } = this.props;
    view(item);
  };

  render() {
    const { item, canEdit } = this.props;

    const historySize = item.get('history').size;
    const lastUpdate = item.get('history').first();

    return (
      <Card className="StatusItem" variant="outlined">
        <CardHeader
          title={item.get('title')}
          subheader={
            <span>
              <span className="StatusItem__type">{item.get('type')}</span>{' '}
              &middot; <Status status={item.get('status')} />
            </span>
          }
        >
          <Moment className="StatusItem__date" format={TITLE_DATE_FORMAT}>
            {item.get('created_at')}
          </Moment>
        </CardHeader>
        <CardContent>
          {/* <h3 className="StatusItem__date">
              <Moment format={TITLE_DATE_FORMAT}>
                {item.get('created_at')}
              </Moment>
            </h3> */}
          {/* <p className="StatusItem__type">{item.get('type')}</p> */}
          {/* <p className="StatusItem__title">{item.get('name')}</p> */}
          {/* {item.get('history').map(datum => ( */}
          <div className="StatusItem__history">
            <p className="StatusItem__message">
              {/* <p>
                  <Status status={lastUpdate.get('status')} />
                </p> */}
              {item.get('message')}
            </p>
            <Moment className="StatusItem__message_date" format={DATE_FORMAT}>
              {item.get('updated_at')}
            </Moment>

            {historySize > 1 ? (
              <p className="StatusItem__more">
                And more {historySize - 1} updates
              </p>
            ) : (
              ''
            )}
          </div>
          {/* ))} */}
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            onClick={this.handleView}
            style={{ marginRight: 'auto' }}
          >
            View Details
          </Button>
          {canEdit ? (
            <Button color="primary" onClick={this.handleEdit}>
              Update
            </Button>
          ) : null}
        </CardActions>
      </Card>
    );
  }
}

export default StatusItem;
