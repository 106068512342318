import { connect } from 'react-redux';
import { NS } from './reducer';
import { createService, getTeams, validateHandle, reset } from './actions';
import CreateService from './CreateService';

const mapStateToProps = state => ({
  teams: state.getIn([NS, 'teams']),
  success: state.getIn([NS, 'success']),
  service: state.getIn([NS, 'service']),
  handleValid: state.getIn([NS, 'handleValid'])
});

const mapDispatchToProps = dispatch => ({
  createService: (team, params) => dispatch(createService(team, params)),
  getTeams: () => dispatch(getTeams()),
  validateHandle: handle => dispatch(validateHandle(handle)),
  reset: () => dispatch(reset())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateService);
