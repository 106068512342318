import { Map, List, fromJS } from 'immutable';

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  success: false,
  error: false,
  team: Map(),
  teams: List(),
  handleValid: null
});

export default function CreateTeamReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.TEAMS_GET_SUCCESS:
      return state.set('teams', fromJS(action.payload));

    case ActionTypes.TEAM_CREATE_SUCCESS:
      return state
        .set('success', true)
        .set('error', false)
        .set('team', Map(action.payload));
    case ActionTypes.TEAM_CREATE_FAIL:
      return state.set('error', true);

    case ActionTypes.VALIDATE_TEAM_HANDLE_SUCCESS:
      return state.set('handleValid', action.payload.valid);

    case ActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
}
