import React, { Component } from 'react';
import cn from 'classnames';

const Sidenav = ({ children, className, ...rest }) => {
  return (
    <div className={cn(className, 'Sidenav')} {...rest}>
      {children}
    </div>
  );
};

export default Sidenav;
