import { createTypes, asyncType, createNS } from '@/utils/actionCreator';

export const NS = createNS('FORGOT_PASSWORD');

export const ActionTypes = createTypes(
  [...asyncType('FORGOT_PASSWORD'), 'RESET'],
  NS
);

export default ActionTypes;
