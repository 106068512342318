import { connect } from 'react-redux';
import { resetPassword, reset } from './ducks/actions';
import { NS } from './ducks/reducer';
import { NS as AUTH_NS } from '../../reducers/auth';
import './ResetPassword.scss';
import ResetPassword from './ResetPassword';

const mapStateToProps = state => ({
  //   token: state.getIn([AUTH_NS, 'token']), //.auth.token,
  submitting: state.getIn([NS, 'submitting']),
  error: state.getIn([NS, 'error']),
  success: state.getIn([NS, 'success']) //.auth.error
});

const mapDispatchToProps = dispatch => ({
  //   login: payload => dispatch(login(payload)),
  //   forgotPassword: email => dispatch(forgotPassword(email)),
  //   clearError: payload => dispatch(clearError(payload)),
  reset: () => dispatch(reset()),
  resetPassword: (token, password) => dispatch(resetPassword(token, password))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
