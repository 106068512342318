// import { CALL_HISTORY_METHOD } from './actions'

export const CALL_DOWNLOAD_METHOD = 'CALL_DOWNLOAD_METHOD';

/**
 * This middleware captures CALL_HISTORY_METHOD actions to redirect to the
 * provided history object. This will prevent these actions from reaching your
 * reducer or any middleware that comes after this one.
 */
export function downloadMiddleware() {
  return next => action => {
    if (action.type !== CALL_DOWNLOAD_METHOD) {
      return next(action);
    }

    const {
      payload: { data, type = 'application/zip' }
    } = action;

    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    setTimeout(() => {
      window.URL.revokeObjectURL(URL);
    }, 4E4);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  };
}
