import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
// import makeEpicMiddleware from "./epics";
import rootEpic from '@/epics';
import makeRootReducer from './reducers';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'connected-react-router/immutable';
import { downloadMiddleware } from './middleware';
import { Map } from 'immutable';

import ReduxThunk from 'redux-thunk';
export const history = createBrowserHistory();

const configureStore = (initialState = Map()) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const epicMiddleware = createEpicMiddleware();
  const middleware = [routerMiddleware(history), epicMiddleware, downloadMiddleware, ReduxThunk];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];
  let composeEnhancers = compose;

  if (process.env.NODE_ENV === 'development') {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================

  const store = createStore(
    makeRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );

  epicMiddleware.run(rootEpic);

  // store.asyncReducers = {};

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      console.log('reload reducers');
      // const nextRootReducer = require('./reducers').default;
      // store.replaceReducer(nextRootReducer(history));
      store.replaceReducer(createRootReducer(history));
    });
  }

  return store;
};

export default configureStore;
