import React, { PureComponent } from 'react';

import moment from 'moment';

import {
  ResponsiveContainer,
  AreaChart,
  Area,
  LineChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid
} from 'recharts';

const renderXTick = props => {
  const { x, y, value, payload } = props;

  return (
    <text x={x} y={y} fill="#999" textAnchor="middle" fontSize="13" dy={15}>
      {moment(payload.value).format('h:mm:ss a')}
    </text>
  );
};

const renderYTick = props => {
  const { x, y, value, payload } = props;

  return (
    <text x={x} y={y} fill="#999" textAnchor="end" fontSize="13">
      {payload.value}ms
    </text>
  );
};

export default class Sparkline extends PureComponent {
  tooltipFormatter = (value, name) => {
    if (name === 'time') {
      return `${Number.parseFloat(value).toFixed(2)}ms`;
    }
  };

  labelFormatter(value) {
    return moment(value).format('h:mm:ss a');
  }

  getLinearGradient(data, pending, handle) {
    if (pending) return null;
    const len = data.length;
    let currentColor = null;
    const stops = data.reduce((acc, { success }, i) => {
      const offset = i / len;
      const color = success ? '#4caf50' : '#f44336';
      if (currentColor !== color) {
        if (currentColor) {
          acc.push(
            <stop
              key={`s${i}`}
              offset={offset}
              stopColor={currentColor}
              stopOpacity={1}
            />
          );
        }

        acc.push(
          <stop
            key={`e${i}`}
            offset={offset}
            stopColor={color}
            stopOpacity={1}
          />
        );
        currentColor = color;
      }

      return acc; // if (success) {
      //   return (
      //     <stop key={i} offset={offset} stopColor="green" stopOpacity={1} />
      //   );
      //   // <stop offset={off} stopColor="red" stopOpacity={1} />
      // }

      return <stop key={i} offset={offset} stopColor={color} stopOpacity={1} />;
    }, []);

    return (
      <linearGradient id={`lg_${handle}`} x1="0" y1="0" x2="1" y2="0">
        {stops}
        {/* <Stops /> */}
      </linearGradient>
    );
  }

  tickFormatter(start) {
    return moment(start).format('h:mm:ss a');
  }

  render() {
    const { handle, data = [], pending } = this.props;
    const linearGradient = this.getLinearGradient(data, pending, handle);

    return pending ? null : (
      <ResponsiveContainer height={200} width="100%">
        <AreaChart
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        >
          {/* <CartesianGrid stroke="#ccc" strokeDasharray="5 5" /> */}
          <XAxis
            dataKey="start"
            tickFormatter={this.tickFormatter}
            tick={renderXTick}
            stroke="#aaaaaa"
          />
          <YAxis
            tick={renderYTick}
            unit={'ms'}
            tickLine={false}
            stroke="#aaaaaa"
          />
          <Tooltip
            labelFormatter={this.labelFormatter}
            formatter={this.tooltipFormatter}
          />
          <CartesianGrid stroke="#ddd" strokeDasharray="5 5" />
          <defs>{linearGradient}</defs>
          <Area
            type="linear"
            dataKey="time"
            stroke="none"
            fill={`url(#lg_${handle})`}
            dot={false}
            isAnimationActive={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
