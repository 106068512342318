import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Container,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import {
  required,
  isStrong,
  isHandle,
  isDomainMinLength,
  isCreateCode,
  isBetaCode
} from '@/validators';
import { isEmail } from '@/validators/index';

const useStyles = makeStyles({
  root: {
    alignSelf: 'center',
    justifySelf: 'center',
    margin: 'auto'
  },
  formControl: {
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: '0px'
    }
  },
  actions: {
    justifyContent: 'space-between'
  },
  alert: { margin: '0 16px' }
});

const RegisterView = props => {
  const { error, submitting, createWorkspace } = props;

  const styles = useStyles();
  const { control, handleSubmit, formState, errors } = useForm({
    mode: 'onChange',
    defaultValues: {
      beta_key: '',
      owner: {
        email: '', //props.email,
        name: '',
        handle: '',
        password: ''
      },
      workspace: {
        name: '',
        domain: ''
      }
    }
  });
  const { isValid, isDirty } = formState;

  const onSubmit = useCallback(values => {
    createWorkspace(values.beta_key, values.owner, values.workspace);
  });

  return (
    <Container maxWidth="sm" className={styles.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader title="Register" />
          {error ? (
            <Alert severity="error" className={styles.alert}>
              {error}
            </Alert>
          ) : null}
          <CardContent>
            <Controller
              name={'owner.email'}
              label="Email"
              control={control}
              rules={{ required: true }}
              as={TextField}
              autoComplete="off"
              fullWidth
              className={styles.formControl}
              rules={{
                required: true,
                validate: {
                  isEmail: v => isEmail(v) || 'Email is not valid'
                }
              }}
              error={!!(errors.owner && errors.owner.name)}
              helperText={
                errors.owner && errors.owner.email
                  ? errors.owner.email.message
                  : 'Make sure it\'s the same email you received your "Beta Key" from.'
              }
            />

            <Controller
              name={'owner.name'}
              label="Name"
              control={control}
              rules={{ required: true }}
              as={TextField}
              autoComplete="off"
              fullWidth
              className={styles.formControl}
              error={!!(errors.owner && errors.owner.name)}
            />

            <Controller
              name={'owner.handle'}
              label="Handle"
              control={control}
              as={TextField}
              rules={{
                required: true,
                validate: {
                  isHandle: v =>
                    isHandle(v) ||
                    'Handle is not valid, can only contain lowercase letters and dashes'
                }
              }}
              autoComplete="off"
              fullWidth
              className={styles.formControl}
              error={!!(errors.owner && errors.owner.handle)}
              helperText={
                errors.owner && errors.owner.handle
                  ? errors.owner.handle.message
                  : ''
              }
            />

            <Controller
              name={'owner.password'}
              label="Password"
              type="password"
              control={control}
              rules={{
                required: true,
                validate: {
                  isStrong: v =>
                    isStrong(v) ||
                    'Password is not strong enough. It must be at least 8 characters and include a lowercase and uppercase letter, a number and a special character.'
                }
              }}
              as={TextField}
              autoComplete="off"
              fullWidth
              className={styles.formControl}
              error={!!(errors.owner && errors.owner.password)}
              helperText={
                errors.owner && errors.owner.password
                  ? errors.owner.password.message
                  : ''
              }
            />

            <Controller
              name={'workspace.name'}
              label="Workspace Name"
              control={control}
              rules={{ required: true }}
              as={TextField}
              autoComplete="off"
              fullWidth
              className={styles.formControl}
              error={!!(errors.workspace && errors.workspace.name)}
            />

            <Controller
              id="workspace-domain"
              name={'workspace.domain'}
              label="Workspace Domain"
              control={control}
              rules={{
                required,
                validate: {
                  isDomainMinLength: v =>
                    isDomainMinLength(v) ||
                    'Domain must be at least 3 characters',
                  isHandle: v =>
                    isHandle(v) ||
                    'Handle is not valid, can only contain lowercase letters and dashes'
                }
              }}
              as={TextField}
              autoComplete="off"
              fullWidth
              className={styles.formControl}
              error={!!(errors.workspace && errors.workspace.domain)}
              helperText={
                errors.workspace && errors.workspace.domain
                  ? errors.workspace.domain.message
                  : ''
              }
            />

            <Controller
              name={'beta_key'}
              label="Beta Code"
              control={control}
              rules={{
                required: true,
                validate: {
                  isBetaCode: v => isBetaCode(v) || 'Invalid Beta Code'
                }
              }}
              as={TextField}
              autoComplete="off"
              fullWidth
              className={styles.formControl}
              error={!!errors.beta_key}
              helperText={errors.beta_key ? errors.beta_key.message : ''}
            />
          </CardContent>
          <CardActions>
            <Button
              className="ml-auto"
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
  // }
};

export default RegisterView;
