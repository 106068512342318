import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  IconButton,
  Button,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Redirect } from 'react-router-dom';

import { Main, Container } from '@/components';

import slugify from '@/utils/slugify';
import './CreateService.scss';

const useStyles = makeStyles({
  formControl: {
    marginBottom: '20px'
  }
});

const CreateService = props => {
  const {
    teams,
    service,
    success,
    handleValid,
    getTeams,
    createService,
    validateHandle,
    match: {
      params: { id = '' }
    }
  } = props;

  const [owner, setOwner] = useState(id);
  const [hasTeam] = useState(!!id);
  const [handleHelperText, setHandleHelperText] = useState('');

  const {
    formState,
    control,
    errors,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    triggerValidation,
    register
  } = useForm({
    mode: 'onChange',
    defaultValues: { name: '', handle: '', owner: owner }
  });

  useEffect(() => {
    getTeams();
    return () => {
      props.reset();
    };
  }, []);

  useEffect(() => {
    clearErrors('handle');
    if (handleValid === false) {
      setError('handle', 'exist', 'Service handle is already taken');
      setHandleHelperText('');
    } else if (handleValid === true) {
      clearErrors('handle');
      setHandleHelperText('Service handle is available');
    } else if (handleValid === null) {
      setHandleHelperText('');
    }
  }, [handleValid]);

  const _handleSubmit = form => {
    const { name, handle, owner } = form;
    const body = { name, handle};

    createService(owner, body);
  };

  const onNameBlur = e => {
    const handle = getValues('handle');
    const name = getValues('name');
    if (!handle && name) {
      const slug = slugify(name);
      setValue('handle', slug, true);
      validateHandle(handle);
    }
  };

  const onHandleBlur = () => {
    const handle = getValues('handle');
    if (handle) {
      validateHandle(handle);
    } else {
      triggerValidation('handle');
      setHandleHelperText('');
    }
  };

  const onHandleKeyPress = e => {
    const regex = new RegExp('^[a-z0-9-]+$');
    const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);

    if (!regex.test(str)) {
      e.preventDefault();
      return false;
    }
  };

  const classes = useStyles();

  if (service.size) {
    return <Redirect to={`/services/${service.get('handle')}`} />;
  }

  const isValid = formState.isValid;

  return (
    <Container>
      <Main>
        <Card className="CreateService">
          <form onSubmit={handleSubmit(_handleSubmit)}>
            <CardHeader title="Create Service" />
            <CardContent>
              <FormControl fullWidth={true} className={classes.formControl}>
                <Controller
                  label="Name"
                  name="name"
                  as={TextField}
                  control={control}
                  rules={{ required: true }}
                  onBlur={onNameBlur}
                  autoComplete="off"
                />
              </FormControl>

              <FormControl fullWidth={true} className={classes.formControl}>
                <Controller
                  name="handle"
                  as={TextField}
                  control={control}
                  label="Handle"
                  autoComplete="off"
                  rules={{ required: true }}
                  onKeyPress={onHandleKeyPress}
                  onBlur={onHandleBlur}
                  error={!!errors.handle}
                  helperText={
                    errors.handle ? errors.handle.message : handleHelperText
                  }
                />
              </FormControl>
              {hasTeam ? (
                <input name="owner" type="hidden" ref={register} />
              ) : (
                <FormControl fullWidth={true} className={classes.formControl}>
                  <InputLabel id="Owner">Owner</InputLabel>
                  <Controller
                    name={'owner'}
                    labelId="Owner"
                    rules={{ required: true }}
                    as={Select}
                    control={control}
                  >
                    {teams.map(team => (
                      <MenuItem key={team.get('id')} value={team.get('id')}>
                        {team.get('name')}
                      </MenuItem>
                    ))}
                  </Controller>
                </FormControl>
              )}
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isValid}
              >
                Create Service
              </Button>
            </CardActions>
          </form>
        </Card>
      </Main>
    </Container>
  );
};

export default CreateService;
