import ActionTypes from './actionTypes';
import { createAction } from '@/utils/actionCreator';

export const createTeam = createAction(ActionTypes.TEAM_CREATE, 'body');

export const getTeams = createAction(ActionTypes.TEAMS_GET);
export const searchTeams = createAction(ActionTypes.TEAMS_SEARCH, 'q');
export const reset = createAction(ActionTypes.RESET);

export const validateHandle = createAction(
    ActionTypes.VALIDATE_TEAM_HANDLE,
    'handle'
  );
  