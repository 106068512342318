import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Icon,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
} from '@material-ui/core';

import { CodeMirrorEditor } from '@/components';
import Highlight from 'react-highlight.js';
import { Markdown } from '@/components';
import { SwaggerPreview } from '../swagger-preview/swagger-preview';

import './DraftPage.scss';

export const DraftPage = (props) => {
  const {
    language = 'md',
    draft,
    pending,
    titlePlaceholder,
    service,
    get,
    create,
    update,
    publish,
    unpublish,
  } = props;

  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [canSave, setCanSave] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => setAnchorEl(null));

  useEffect(() => {
    const id = draft.get('id', null);
    const newTitle = draft.get('title', '');
    const newContent = draft.get('content', '');

    if (id && !params.draftId && id !== params.draftId) {
      history.replace({ pathname: location.pathname.replace('new', id) });
    }
    if (newTitle) {
      setTitle(newTitle);
    }
    setContent(newContent);
  }, [draft]);

  useEffect(() => {
    if (title && content && content !== draft.get('content')) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [title, content]);

  useEffect(() => {
    if (params.draftId) {
      get(params.draftId);
    }
    return () => {
      if (params.draftId) {
        props.reset();
      }
    };
  }, []);

  const handleTitleChange = useCallback((evt) => {
    setTitle(evt.target.value);
  });

  const handleContentChange = useCallback((evt) => {
    setContent(evt.target.value);
  });

  const handleSave = useCallback(() => {
    const serviceHandle = service.get('handle');

    if (draft.get('id')) {
      const lock = draft.get('lock');
      update(params.draftId, { title, content, lock });
    } else {
      create(serviceHandle, { title, content });
    }
  });

  const handlePublish = useCallback(() => {
    const lock = draft.get('lock');

    if (content === draft.get('content')) {
      publish(params.draftId);
    } else {
      saveAndPublish(params.draftId, { title, content, lock });
    }
    setAnchorEl(null);
  });

  const handleUnpublish = useCallback(() => {
    unpublish(draft.get('id'));
    setAnchorEl(null);
  });

  const handleVersions = () => {
    console.log('show version');
  };

  if (pending) {
    return <CircularProgress />;
  }

  return (
    <Paper className="DraftPage">
      <div className="DraftPage__header">
        <input
          value={title}
          onChange={handleTitleChange}
          placeholder={titlePlaceholder}
        />
        {/* <input
          value={handle}
          onChange={handleHandleChange}
          disabled={!!id}
          placeholder={handlePlaceholder}
        /> */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!canSave}
        >
          Save
        </Button>
        <IconButton onClick={handleClick}>
          <Icon>more_vert</Icon>
        </IconButton>
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handlePublish} disabled={!draft.get('id')}>
            <ListItemText primary="Publish" />
          </MenuItem>
          <MenuItem
            onClick={handleUnpublish}
            disabled={!draft.get('published_at')}
          >
            <ListItemText primary="Unpublish" />
          </MenuItem>
        </Menu>
      </div>
      <div className="DraftPage__content">
        <CodeMirrorEditor
          mode={language === 'md' ? 'markdown' : 'yaml'}
          theme="monokai"
          lineNumbers={true}
          onChange={handleContentChange}
          value={content}
          generateMarkdownPreview={false}
        />
        <div className="DraftPage__preview markdown-body">
          {language === 'md' ? (
            <Markdown source={content} />
          ) : (
            <SwaggerPreview spec={content} />
          )}
        </div>
      </div>
    </Paper>
  );
};

export default DraftPage;
