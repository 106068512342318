import { of } from 'rxjs';
import { switchMap, flatMap, pluck } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';

import ActionTypes from '../actionTypes';

export const getPublicFeed$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.PUBLIC_FEED_GET),
    switchMap(action =>
      ajax(
        {
          url: `/teams/${action.payload.teamId}/public-feed`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(res =>
          of({
            type: ActionTypes.PUBLIC_FEED_GET_SUCCESS,
            payload: res,
            error: true
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.PUBLIC_FEED_GET_FAIL
          })
        )
      )
    )
  );

export default [getPublicFeed$];
