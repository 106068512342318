import { Map, fromJS } from 'immutable';
import {
  createTypes,
  asyncType,
  createAction,
  createNS
} from '@/utils/actionCreator';

export const NS = createNS('ME');

export const ActionTypes = createTypes(
  [
    ...asyncType('USER_GET'),
    ...asyncType('USER_UPDATE'),
    ...asyncType('USER_ICON_UPDATE'),
    ...asyncType('USER_ICON_DELETE')
  ],
  NS
);
export const getUser = payload => ({
  type: ActionTypes.USER_GET,
  payload: payload
});
export const updateUser = payload => ({
  type: ActionTypes.USER_UPDATE,
  payload: payload
});

export const updateUserIcon = payload => ({
  type: ActionTypes.USER_ICON_UPDATE,
  payload: payload
});

export const clearUserIcon = createAction(ActionTypes.USER_ICON_DELETE, 'id');

const initialState = new Map({
  user: Map(),
  pending: true,
  error: true
});

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.USER_GET:
      return state.set('pending', true);

    case ActionTypes.USER_GET_SUCCESS:
    case ActionTypes.USER_UPDATE_SUCCESS:
    case ActionTypes.USER_ICON_UPDATE_SUCCESS:
    case ActionTypes.USER_ICON_DELETE_SUCCESS:
      return state
        .set('error', true)
        .set('pending', false)
        .set('user', fromJS(payload));

    case ActionTypes.USER_GET_FAIL:
      return state.set('error', true).set('pending', false);

    default:
      return state;
  }
};

export default userReducer;
