import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  CircularProgress,
  Grid,
  Container as MdContainer,
  Box,
} from '@material-ui/core';
import { Container, Main, Sidenav, Breadcrumb } from '@/components';

import scenes from './scenes';
import { AddMember, TeamMenu } from './components';
import Screens from '@/screens';
import { NoMatch } from '@/components/NoMatch';
export default class Team extends PureComponent {
  state = { tabs: [] };

  componentDidMount() {
    const {
      getTeam,
      match: {
        params: { id },
      },
    } = this.props;
    getTeam(id);
  }

  componentDidUpdate(previousProps) {
    if (this.props.match.params.id !== previousProps.match.params.id) {
      this.props.reset();
      this.props.getTeam(this.props.match.params.id);
    }
    if (previousProps.team !== this.props.team) {
      const tabs = this.getTabs();
      this.setState({ tabs });
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  getTabs() {
    const { team } = this.props;
    const id = team.get('handle');
    return [
      {
        label: 'Overview',
        to: `/teams/${id}`,
        key: '',
        exact: true,
        component: scenes.Overview,
      },
      {
        label: 'Health',
        to: `/teams/${id}/health`,
        key: 'health',
        component: scenes.Health,
      },
      {
        label: 'Services',
        to: `/teams/${id}/services`,
        key: 'services',
        component: scenes.Services,
      },
      {
        label: 'Members',
        to: `/teams/${id}/members`,
        key: 'members',
        component: scenes.Members,
      },
      // {
      //   label: 'Runners',
      //   to: `/teams/${id}/runners`,
      //   key: 'runners',
      //   component: scenes.Runners,
      //   permissions: ['is_admin'],
      // },
      {
        label: 'Settings',
        to: `/teams/${id}/settings`,
        key: 'settings',
        component: scenes.Settings,
        permissions: ['is_admin'],
      },
    ].filter((tab) => {
      if (!tab.permissions || team.get(tab.permissions[0])) {
        return true;
      }
      return false;
    });
  }

  goTo(pathname) {
    const { history } = this.props;
    history.push({ pathname });
  }

  render() {
    const { tabs } = this.state;
    const {
      team,
      crumbs,
      pending,
      notFound,
      match: {
        params: { id },
      },
      location,
    } = this.props;

    if (pending) {
      return (
        <MdContainer>
          <Box p={20} mx="auto" display="flex" justifyContent="center">
            <CircularProgress mx="auto" size={48} />
          </Box>
        </MdContainer>
      );
    }

    if (notFound) {
      return <NoMatch text="The team was not found" />;
    }

    const title = team.get('name');
    const description = team.get('description');
    const icon = team.getIn(['thumbnail_url'], null);

    return (
      <Container>
        <Main
          breadcrumb={
            <Breadcrumb
              icon={icon}
              name={title}
              path={`/teams/${team.get('handle')}`}
              crumbs={crumbs}
            />
          }
        >
          <Grid container wrap="nowrap" spacing={2} direction="column">
            {/* {description ? (
              <Grid item>
                <Card>
                  <CardContent>{description}</CardContent>
                </Card>
              </Grid>
            ) : null} */}

            {!pending && (
              <Grid item>
                <Switch>
                  <Route
                    path="/teams/:id/teams/new"
                    component={Screens.CreateTeam}
                  />
                  <Route
                    path="/teams/:id/services/new"
                    component={Screens.CreateService}
                  />
                  {tabs.map((tab) => (
                    <Route
                      key={tab.key}
                      exact={tab.exact}
                      path={`/teams/:id${tab.key ? `/${tab.key}` : ''}`}
                      component={tab.component}
                    />
                  ))}
                  <Route component={NoMatch} />;
                </Switch>
              </Grid>
            )}
          </Grid>
        </Main>

        <Sidenav>
          <TeamMenu tabs={tabs} location={location} team={team} />
        </Sidenav>
        <AddMember />
      </Container>
    );
  }
}
