import { of, concat, catchError } from 'rxjs';
import { switchMap, flatMap, map, pluck, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';
import { addNotification } from '@/reducers/global';

import ActionTypes, { NS } from '../actionTypes';

export const getAnnouncements$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.PUBLISHED_ANNOUNCEMENTS_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.serviceHandle}/announcements`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.PUBLISHED_ANNOUNCEMENTS_GET_SUCCESS,
              payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.PUBLISHED_ANNOUNCEMENTS_GET_FAIL
          })
        )
      )
    )
  );

export const getAnnouncement$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.PUBLISHED_ANNOUNCEMENT_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/announcements/${payload.pageId}`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.PUBLISHED_ANNOUNCEMENT_GET_SUCCESS,
              payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.PUBLISHED_ANNOUNCEMENT_GET_FAIL
          })
        )
      )
    )
  );

export const getDrafts$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_ANNOUNCEMENTS_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.serviceHandle}/announcement-drafts`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_ANNOUNCEMENTS_GET_SUCCESS,
              payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.DRAFT_ANNOUNCEMENTS_GET_FAIL
          })
        )
      )
    )
  );

export const getDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_ANNOUNCEMENT_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/announcement-drafts/${payload.draftId}`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_ANNOUNCEMENT_GET_SUCCESS,
              payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.DRAFT_ANNOUNCEMENT_GET_FAIL
          })
        )
      )
    )
  );

//

export const createDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_ANNOUNCEMENT_CREATE),
    switchMap(({ payload: { serviceHandle, draft } }) =>
      ajax(
        {
          url: `/services/${serviceHandle}/announcement-drafts`,
          method: 'POST',
          body: draft
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_ANNOUNCEMENT_CREATE_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Created Draft',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, err => {
          if (err.status === 422) {
            return concat(
              of(
                addNotification({
                  message: 'The handle has already been taken.',
                  options: { variant: 'error' }
                })
              ),
              of({ type: ActionTypes.DRAFT_ANNOUNCEMENT_CREATE_FAIL })
            );
          }
          return of({ type: ActionTypes.DRAFT_ANNOUNCEMENT_CREATE_FAIL });
        })
      )
    )
  );

export const updateDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_ANNOUNCEMENT_UPDATE),
    switchMap(({ payload: { draftId, draft } }) =>
      ajax(
        {
          url: `/announcement-drafts/${draftId}`,
          method: 'PATCH',
          body: draft
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_ANNOUNCEMENT_UPDATE_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Updated Draft',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, err => {
          if (err.status === 422) {
            return concat(
              of(
                addNotification({
                  message: 'The handle has already been taken.',
                  type: 'error'
                })
              ),
              of({ type: ActionTypes.DRAFT_ANNOUNCEMENT_UPDATE_FAIL })
            );
          }
          return of({ type: ActionTypes.DRAFT_ANNOUNCEMENT_UPDATE_FAIL });
        })
      )
    )
  );

export const deleteDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_ANNOUNCEMENT_DELETE),
    switchMap(({ payload: { draftId } }) =>
      ajax(
        {
          url: `/announcement-drafts/${draftId}`,
          method: 'DELETE'
        },
        { action$, state$ }
      ).pipe(
        flatMap(() =>
          concat(
            of({
              type: ActionTypes.DRAFT_ANNOUNCEMENT_DELETE_SUCCESS,
              payload: null
            }),
            of({
              type: ActionTypes.DRAFT_ANNOUNCEMENTS_GET,
              payload: {
                serviceHandle: state$.value.getIn([NS, 'service', 'handle'])
              }
            }),
            of(
              addNotification({
                message: 'Deleted Draft',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, error => {
          return of({
            type: ActionTypes.DRAFT_ANNOUNCEMENT_DELETE_FAIL
          });
        })
      )
    )
  );

export const publishDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_ANNOUNCEMENT_PUBLISH),
    switchMap(({ payload: { draftId } }) =>
      ajax(
        {
          url: `/announcement-drafts/${draftId}/actions/publish`,
          method: 'POST'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_ANNOUNCEMENT_PUBLISH_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Published Draft',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.DRAFT_ANNOUNCEMENT_PUBLISH_FAIL
          })
        )
      )
    )
  );
export const unpublishDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_ANNOUNCEMENT_UNPUBLISH),
    switchMap(({ payload: { draftId } }) =>
      ajax(
        {
          url: `/announcement-drafts/${draftId}/actions/unpublish`,
          method: 'POST'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_ANNOUNCEMENT_UNPUBLISH_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Unpublished Draft',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.DRAFT_ANNOUNCEMENT_UNPUBLISH_FAIL
          })
        )
      )
    )
  );

export const saveAndPublishDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_ANNOUNCEMENT_UPDATE_PUBLISH),
    switchMap(({ payload: { draftId, draft } }) =>
      action$.pipe(
        ofType(ActionTypes.DRAFT_ANNOUNCEMENT_UPDATE_SUCCESS),
        switchMap(() =>
          concat(
            of({
              type: ActionTypes.DRAFT_ANNOUNCEMENT_PUBLISH,
              payload: { draftId }
            })
          )
        ),
        startWith({
          type: ActionTypes.DRAFT_ANNOUNCEMENT_UPDATE,
          payload: { draftId, draft }
        })
      )
    )
  );

export default [
  getAnnouncements$,
  getAnnouncement$,
  getDrafts$,
  getDraft$,
  createDraft$,
  updateDraft$,
  deleteDraft$,
  publishDraft$,
  unpublishDraft$,
  saveAndPublishDraft$
];
