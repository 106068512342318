export const PLANS = [
  //   {
  //     title: 'Free',
  //     id: 'free',
  //     price: 0,
  //     features: ['5 users'],
  //   },
  {
    title: 'Team',
    id: 'team',
    price: 20,
    maxSeats: 20,
    features: ['20 users'],
  },
  {
    title: 'Business',
    id: 'business',
    price: 30,
    maxSeats: null,
    features: ['Unlimited users'],
  },
];
