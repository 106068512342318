import React from 'react';
import './List.scss';

const List = props => {
  const { children, className } = props;
  const classes = ['List', className].join(' ');
  return <ul className={classes}>{children}</ul>;
};

export default List;
