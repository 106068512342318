import React, { useState, useCallback } from 'react';

import {
  CircularProgress,
  Avatar,
  Box,
  Button,
  IconButton,
  Icon,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Menu,
  MenuItem
} from '@material-ui/core';

import Moment from 'react-moment';
import { Link } from 'react-router-dom';

const Event = ({ event, mute }) => {
  const handleShowMenu = useCallback(event => {
    setAnchorEl(event.currentTarget);
  });
  const handleClose = useCallback(event => {
    setAnchorEl(null);
  });
  const handleMute = useCallback(() => {
    mute(event.getIn(['profile', 'handle']));
    setAnchorEl(null);
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const type = event.getIn(['profile', 'type']);
  const handle = event.getIn(['profile', 'handle']);
  const subjectIcon = event.getIn(['profile', 'thumbnail_url']);
  const subjectName = event.getIn(['profile', 'name'], '');
  const message = event.get('title');
  const created = event.get('created_at');
  const subjectInitial = `${subjectName}`.charAt(0);

  return (
    <div className={'Activity'}>
      <Card>
        <CardHeader
          title={subjectName}
          subheader={
            <div>
              {event.getIn(['agent_name'])} &middot;{' '}
              <Moment fromNow>{created}</Moment>
            </div>
          }
          avatar={
            <Avatar alt={subjectName} src={subjectIcon} role="presentation">
              {subjectInitial}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings" onClick={handleShowMenu}>
              <Icon>more_horiz</Icon>
            </IconButton>
          }
        />
        <CardContent>{message}</CardContent>
        <CardActions>
          <Button color="primary" component={Link} to={`/${type}s/${handle}`}>
            View {type}
          </Button>
        </CardActions>
      </Card>
      <Menu
        // className={className}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleMute}>Mute</MenuItem>
      </Menu>
    </div>
  );
};

export const Activity = ({ events, loading, mute }) => {
  const handleMute = useCallback(handle => {
    mute(handle);
  });

  return (
    <div className="Activities">
      {loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : !events.size ? (
        <div className="Placeholder">
          <div className="Placeholder__content">
            <Icon>notifications</Icon>
            <p>No Events</p>
          </div>
        </div>
      ) : (
        events.map(event => (
          <Event key={event.get('id')} event={event} mute={handleMute} />
        ))
      )}
    </div>
  );
};
