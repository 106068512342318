import React, { useEffect } from 'react';
import Moment from 'react-moment';

import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  CircularProgress
} from '@material-ui/core';
import { Markdown } from '@/components';

const TITLE_DATE_FORMAT = 'MMM D, YYYY';
const DATE_FORMAT = 'MMM D, hh:mm a';

const Announcement = props => {
  const { item, canEdit } = props;
  return (
    <div className="ServiceAnnouncement">
      <div className="ServiceAnnouncement__content">
        <h4 className="ServiceAnnouncement__title">{item.get('title')}</h4>
        <div className="ServiceAnnouncement__message">
          <Markdown source={item.get('content')} />
        </div>
      </div>
      <div className="ServiceAnnouncement__details">
        <Moment className="ServiceAnnouncement__date" format={DATE_FORMAT}>
          {item.get('published_at')}
        </Moment>
      </div>
    </div>
  );
};

const AnnouncementList = ({ items, pending, edit, canEdit }) => {
  if (pending) {
    return <CircularProgress size={32} />;
  }

  if (!items.size && !pending) {
    return <p>No Announcements</p>;
  }

  return items.map(item => (
    <Announcement
      key={item.get('id')}
      item={item}
      edit={edit}
      canEdit={canEdit}
    />
  ));
};

const Announcements = props => {
  const {
    service,
    announcements = [],
    pending,
    canEdit,
    edit,
    getAnnouncements
  } = props;

  useEffect(() => {
    const handle = service.get('handle');
    getAnnouncements(handle);
  }, []);

  const handle = service.get('handle');

  return (
    <Card className="ServiceAnnouncements">
      <CardHeader title="What's New" />
      <CardContent>
        <AnnouncementList
          items={announcements}
          edit={edit}
          canEdit={canEdit}
          pending={pending}
        />
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="primary"
          component={Link}
          to={`/services/${handle}/announcements`}
        >
          View All Announcements
        </Button>
      </CardActions>
    </Card>
  );
};

export default Announcements;
