import React from 'react';

import cn from 'classnames';

const Main = ({ children, breadcrumb, className }) => {
  return (
    <div className={cn(className, 'Main')}>
      {breadcrumb ? breadcrumb : null}
      <div className="Main__inner">{children}</div>
    </div>
  );
};

export default Main;
