import React, { Component } from 'react';
import {
  Button,
  Box,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Icon,
  Typography,
  Portal
} from '@material-ui/core';
// import { Sparkline } from '@/components';
import HealthCheck from './components/HealthCheck';

export default class HealthChecks extends Component {
  componentDidMount() {
    const { service, healthchecks, getServiceHealthchecks } = this.props;
    const handle = service.get('handle');

    if (!healthchecks.size) {
      getServiceHealthchecks(handle);
    }

    this.props.updateCrumbs([{ label: 'Health Checks' }]);
  }

  getContent(healthchecks, pending) {
    if (pending) {
      return (
        <Box p={20} mx="auto">
          <CircularProgress size={48} />
        </Box>
      );
    } else if (!healthchecks.size) {
      return <Typography variant="h5" align="center">No HealthChecks</Typography>;
    }

    return healthchecks.map(healthcheck => (
      <HealthCheck key={healthcheck.get('id')} healthcheck={healthcheck} />
    ));
  }

  render() {
    const {
      healthchecks,
      toggleHealthcheckModal,
      canEdit,
      pending
    } = this.props;
    const content = this.getContent(healthchecks, pending);
    return (
      <div>
        {canEdit ? (
          <Portal container={document.getElementById('foo')}>
            <Button
              className="ml-auto"
              color="primary"
              onClick={toggleHealthcheckModal}
            >
              Add Healthcheck
            </Button>
          </Portal>
        ) : null}

        {content}
      </div>
    );
  }
}
