import React, { useCallback, useState, useEffect } from 'react';

import {
  Button,
  Card,
  CardContent,
  CardActions,
  FormControl,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Radio,
  TextField,
  FormLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  //   root: {
  //     alignSelf: 'center',
  //     justifySelf: 'center',
  //     margin: 'auto',
  //     width: '80%',
  //     maxWidth: '400px',
  //   },
  //   formControl: {
  //     marginBottom: '20px',
  //     fontSize: '16px',
  //     '&:last-child': {
  //       marginBottom: '0px',
  //     },
  //     plans: {
  //       marginBottom: '20px',
  //     },
  //   },
  quantity: {
    display: 'block',
    padding: 20,
  },
  formControlQuantity: {
    marginBottom: '20px',
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const PaymentMethod = (props) => {
  const { checked, paymentMethod, handleToggle } = props;

  return (
    <ListItem
      //   role={undefined}
      dense
      button
      onClick={() => handleToggle(paymentMethod.get('id'))}
    >
      <ListItemIcon>
        <Radio
          edge="start"
          checked={checked === paymentMethod.get('id')}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': paymentMethod.get('id') }}
        />
      </ListItemIcon>
      <ListItemText
        id={paymentMethod.get('id')}
        primary={
          <span>
            <strong>{paymentMethod.getIn(['card', 'brand'])}</strong> ending in{' '}
            {paymentMethod.getIn(['card', 'last4'])}{' '}
            {paymentMethod.getIn(['card', 'exp_month'])}/
            {paymentMethod.getIn(['card', 'exp_year']) - 2000}{' '}
            {paymentMethod.get('default') ? '(default)' : ''}
          </span>
        }
      />
    </ListItem>
  );
};

export const PaymentMethods = (props) => {
  const { paymentMethods, checked, handleToggle } = props;

  return (
    <List>
      {paymentMethods.map((paymentMethod) => (
        <PaymentMethod
          key={paymentMethod.get('id')}
          paymentMethod={paymentMethod}
          checked={checked}
          handleToggle={handleToggle}
        />
      ))}
    </List>
  );
};
