import { Map, List, fromJS } from 'immutable';

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  success: false,
  error: false,
  services: List(),
  pending: Map({ services: true, watched: true })
});

export default function WatchedReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.WATCHED_SERVICES_GET:
      return state.setIn(['pending', 'services'], true).set('error', false);
    case ActionTypes.WATCHED_SERVICES_GET_SUCCESS:
      return state
        .setIn(['pending', 'watched'], false)
        .set('error', false)
        .set('services', fromJS(action.payload));

    case ActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
}
