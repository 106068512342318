import Admin from './Admin';
import { Billing } from './Billing';
import CreateService from './CreateService';
import CreateTeam from './CreateTeam';
import ForgotPassword from './ForgotPassword';
import Home from './Home';
import InviteUser from './InviteUser';
import Profile from './Profile';
import Service from './Service';
import Services from './Services';
import Team from './Team';
import Teams from './Teams';
import User from './User';
import Users from './Users';
import Watched from './Watched';

import ConnectToSlack from './ConnectToSlack';
// import { NoMatch } from './NoMatch';

export default {
  Admin,
  Billing,
  CreateService,
  CreateTeam,
  ForgotPassword,
  Home,
  InviteUser,
  Profile,
  Service,
  Services,
  Team,
  Teams,
  User,
  Users,
  Watched,

  ConnectToSlack,
};
