import { of, concat } from 'rxjs';
import { switchMap, flatMap, map, tap, pluck } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';

import ActionTypes from '../actionTypes';

export const exportPages$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.PAGES_EXPORT),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.serviceHandle}/pages/actions/export`,
          method: 'GET',
          responseType: 'arraybuffer'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(res =>
          concat(
            of({
              type: 'CALL_DOWNLOAD_METHOD',
              payload: { data: res, type: 'application/zip' }
            }),
            of({
              type: ActionTypes.PAGES_EXPORT_SUCCESS,
              payload: { success: true }
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.PAGES_EXPORT_FAIL
          })
        )
      )
    )
  );

export default [exportPages$];
