import { connect } from 'react-redux';
import { NS } from './ducks/reducer';
import { getTeams } from './ducks/actions';
import Teams from './Teams';
import './Teams.scss';

const mapStateToProps = state => ({
  teams: state.getIn([NS, 'teams']),
  loading: state.getIn([NS, 'loading']),
  total: state.getIn([NS, 'pagination', 'teams', 'total'])
});

const mapDispatchToProps = dispatch => ({
  getTeams: params => dispatch(getTeams(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Teams);
