import { Map, List, fromJS } from 'immutable';

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  success: false,
  error: false,
  users: List(),
  invites: List(),
  pagination: Map({ users: Map({ total: 0 }) })
});

export default function ServicesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.USERS_GET:
      return state
        .set('loading', true)
        .set('users', List())
        .set('error', false);
    case ActionTypes.USERS_GET_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('users', fromJS(action.payload.users))
        .setIn(['pagination', 'users', 'total'], fromJS(action.payload.total));
    case ActionTypes.USERS_GET_FAIL:
      return state.set('error', true).set('loading', false);

    case ActionTypes.INVITES_GET:
      return state
        .set('loading', true)
        .set('invites', List())
        .set('error', false);
    case ActionTypes.INVITES_GET_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('invites', fromJS(action.payload.users))
        .setIn(['pagination', 'users', 'total'], fromJS(action.payload.total));
    case ActionTypes.INVITES_GET_FAIL:
      return state.set('error', true).set('loading', false);

    case ActionTypes.INVITE_RESEND_SUCCESS:
      return state.update('invites', invites =>
        invites.update(
          invites.findIndex(invite => invite.get('id') === action.payload.id),
          () => fromJS(action.payload)
        )
      );

    default:
      return state;
  }
}
