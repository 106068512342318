import { createTypes, asyncType, createNS } from '@/utils/actionCreator';
export const NS = createNS('CREATE_TEAM');

export const ActionTypes = createTypes(
  [
    ...asyncType('TEAM_CREATE'),
    ...asyncType('TEAMS_GET'),
    ...asyncType('TEAMS_SEARCH'),
    ...asyncType('VALIDATE_TEAM_HANDLE'),
    'RESET'
  ],
  NS
);

export default ActionTypes;
