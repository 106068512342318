import React, { PureComponent } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Box,
  Typography
} from '@material-ui/core';

import Channel from './components/Channel';
import ChannelForm from './components/ChannelForm';

class Channels extends PureComponent {
  state = {
    creating: false
  };
  componentDidMount() {
    const { getChannels, service } = this.props;
    const handle = service.get('handle');
    getChannels(handle);
  }

  addChannel = () => {
    this.setState({ creating: true });
  };

  handleSave = slackChannelId => {
    const { create, service } = this.props;
    const handle = service.get('handle');
    create(handle, slackChannelId);
    this.setState({ creating: false });
  };

  handleCancel = () => {
    this.setState({ creating: false });
  };

  getContent() {
    const { channels, pending } = this.props;
    const { creating } = this.state;
    if (pending) return <CircularProgress />;

    return channels.size ? (
      <div>
        {channels.map(channel => (
          <Channel
            className="ServiceChannel"
            key={channel.get('id')}
            channel={channel}
          />
        ))}
      </div>
    ) : creating ? (
      <ChannelForm onSave={this.handleSave} onCancel={this.handleCancel} />
    ) : (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle2" color="textSecondary">
          No Channels
        </Typography>
        <div></div>
        <Button variant="contained" color="primary" onClick={this.addChannel}>
          Add Channel
        </Button>
      </Box>
    );
  }

  render() {
    const { channels, pending } = this.props;
    const { creating } = this.state;
    const content = this.getContent();
    return (
      <Card>
        <CardHeader title="Channels" />
        <CardContent>{content}</CardContent>
      </Card>
    );
  }
}

export default Channels;
