import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import { NS as AUTH_NS } from '../../reducers/auth';
import { Header, Aux, NoMatch } from '@/components';

import Screens from '@/screens';

const Rest = (props) => {

  if (props.workspace && props.workspace.get('status') === 'suspended') {
    return (
      <Aux>
        <Header />
        <Switch>
          <Route path="/billing" component={Screens.Billing} />
          <Redirect to="/billing" />
        </Switch>
      </Aux>
    );
  }

  return (
    <Aux>
      <Header />
      <Switch>
        <Route exact path="/" component={Screens.Home} />
        <Route path="/admin" component={Screens.Admin} />
        <Route path="/billing" component={Screens.Billing} />
        <Route exact path="/services/new" component={Screens.CreateService} />
        <Route exact path="/teams/new" component={Screens.CreateTeam} />
        {/* <Route path="/teams/:id/team/new" component={Screens.CreateTeam} /> */}
        {/* <Route path="/teams/:id/services/new" component={Screens.CreateService} /> */}
        <Route path="/profile" component={Screens.Profile} />
        <Route exact path="/services" component={Screens.Services} />
        <Route path="/services/:id/:section?" component={Screens.Service} />
        <Route exact path="/teams" component={Screens.Teams} />
        <Route path="/teams/:id/:section?" component={Screens.Team} />
        <Route exact path="/users/invite" component={Screens.InviteUser} />
        <Route exact path="/users" component={Screens.Users} />
        <Route path="/users/:id" component={Screens.User} />
        <Route
          render={() => <NoMatch title="404 Not Found" text="No pages found" />}
        />
      </Switch>
    </Aux>
  );
};
const Main = ({ workspace }) => {
  return (
    <Aux>
      <Switch>
        <Route
          exact
          path="/callbacks/connect-to-slack"
          component={Screens.ConnectToSlack}
        />
        <Route render={() => <Rest workspace={workspace} />} />
      </Switch>
    </Aux>
  );
};

const mapStateToProps = (state) => ({
  token: state.getIn([AUTH_NS, 'token']),
  workspace: state.getIn([AUTH_NS, 'workspace']),
});

export default connect(mapStateToProps)(Main);
