import { Map } from 'immutable';
import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = new Map({
  error: false,
  pending: false,
  success: false
});

const inviteUserReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.INVITE_POST:
      return state.set('error', false).set('pending', true);

    case ActionTypes.INVITE_POST_SUCCESS:
      return state.set('error', false).set('pending', false);

    case ActionTypes.INVITE_POST_FAIL:
      return state.set('error', true).set('pending', false);

    case ActionTypes.RESET:
      return state.set('error', true).set('pending', false);
  }
  return state;
};

export default inviteUserReducer;
