import { of, concat } from 'rxjs';
import { switchMap, mergeMap, map, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';

import { addNotification } from '@/reducers/global';
import ActionTypes from '../actionTypes';

export const getRunners$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.RUNNERS_GET),
    switchMap(({ payload: { teamId } }) =>
      ajax(
        {
          url: `/teams/${teamId}/runners`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.RUNNERS_GET_SUCCESS,
              payload,
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.RUNNERS_GET_FAIL,
          })
        )
      )
    )
  );

export const getRunner$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.RUNNER_GET),
    switchMap(({ payload: { teamId, runnerId } }) =>
      ajax(
        {
          url: `/teams/${teamId}/runners/${runnerId}`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.RUNNER_GET_SUCCESS,
              payload,
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.RUNNER_GET_FAIL,
          })
        )
      )
    )
  );

export const createRunner$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.RUNNER_CREATE),
    switchMap(({ payload: { teamId, runner } }) =>
      ajax(
        {
          url: `/teams/${teamId}/runners`,
          method: 'POST',
          body: runner,
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.RUNNER_CREATE_SUCCESS,
              payload,
            }),
            // of({
            //   type: ActionTypes.RUNNERS_GET,
            //   payload: { teamId },
            // }),
            of(
              addNotification({
                message: 'Team Runner Created',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.RUNNER_CREATE_FAIL,
          })
        )
      )
    )
  );

export const updateRunner$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.RUNNER_UPDATE),
    switchMap(({ payload: { teamId, runnerId, runner } }) =>
      ajax(
        {
          url: `/teams/${teamId}/runners/${runnerId}`,
          method: 'PUT',
          body: runner,
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.RUNNER_UPDATE_SUCCESS,
              payload,
            }),
            // of({
            //   type: ActionTypes.RUNNERS_GET,
            //   payload: { teamId },
            // }),
            of(
              addNotification({
                message: 'Team Runner Updated',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.RUNNER_UPDATE_FAIL,
          })
        )
      )
    )
  );

export const deleteRunner$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.RUNNER_DELETE),
    switchMap(({ payload: { teamId, runnerId } }) =>
      ajax(
        {
          url: `/teams/${teamId}/runners/${runnerId}`,
          method: 'DELETE',
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap(() =>
          concat(
            of({
              type: ActionTypes.RUNNER_DELETE_SUCCESS,
              payload: { runnerId },
            }),
            of(
              addNotification({
                message: 'Team Runner Removed',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, (error) =>
          concat(
            // tap(() => {
            //   console.log(error);
            // }),
            of({
              type: ActionTypes.RUNNER_DELETE_FAIL,
            }),
            of(
              addNotification({
                message: 'Runner is currently linked to a healthcheck',
                options: { variant: 'error' },
              })
            )
          )
        )
      )
    )
  );

export default [
  getRunners$,
  getRunner$,
  createRunner$,
  updateRunner$,
  deleteRunner$,
];
