import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

export default props => {
  const { title, text, visible, confirm, hide } = props;

  return (
    <Dialog open={visible} onClose={hide}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{text}</DialogContent>

      <DialogActions>
        <Button color="primary" onClick={hide}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={confirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
