import { of, concat } from 'rxjs';
import {
  switchMap,
  flatMap,
  mergeMap,
  map,
  debounceTime,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';
import { push } from 'connected-react-router/immutable';
import { addNotification } from '@/reducers/global';

import ActionTypes from './actionTypes';
import queryString from 'query-string';

import ClientsEpics from './epics/Clients';
import PublicFeedEpics from './epics/PublicFeed';
import HealthEpics from './epics/Health';
import RunnersEpics from './epics/runners';

export const getTeam$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/teams/${payload.id}`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          of({
            type: ActionTypes.TEAM_GET_SUCCESS,
            payload,
          })
        ),
        catchApiError(action$, (error) =>
          of({
            type: ActionTypes.TEAM_GET_FAIL,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

export const updateTeam$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_UPDATE),
    switchMap(({ payload: { id, data: body } }) =>
      ajax(
        {
          url: `/teams/${id}`,
          method: 'PATCH',
          body,
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.TEAM_UPDATE_SUCCESS,
              payload,
            }),
            of(
              addNotification({
                message: 'Team Updated',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, (error) =>
          of({
            type: ActionTypes.TEAM_UPDATE_FAIL,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

export const deleteTeam$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_DELETE),
    switchMap(({ payload: { id } }) =>
      ajax(
        {
          url: `/teams/${id}`,
          method: 'DELETE',
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.TEAM_DELETE_SUCCESS,
              payload,
            }),
            of(push('/teams')),
            of(
              addNotification({
                message: 'Team Deleted',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, (error) =>
          concat(
            of({
              type: ActionTypes.TEAM_DELETE_FAIL,
              payload: error,
              error: true,
            }),
            of(
              addNotification({
                message: 'There was an error while deleted the team',
                options: { variant: 'error' },
              })
            )
          )
        )
      )
    )
  );

export const uploadTeamAvatar$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_AVATAR_UPDATE),
    switchMap(({ payload: { id, data: body } }) =>
      ajax(
        {
          url: `/teams/${id}/icon`,
          headers: {},
          method: 'POST',
          body,
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.TEAM_AVATAR_UPDATE_SUCCESS,
              payload,
            }),
            of(
              addNotification({
                message: 'Team Updated',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, (error) =>
          of({
            type: ActionTypes.TEAM_AVATAR_UPDATE_FAIL,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

export const deleteTeamAvatar$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_ICON_DELETE),
    switchMap(({ payload: { id } }) =>
      ajax(
        {
          url: `/teams/${id}/icon`,
          headers: {},
          method: 'DELETE',
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.TEAM_ICON_DELETE_SUCCESS,
              payload,
            }),
            of(
              addNotification({
                message: 'Team Updated',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, (error) =>
          of({
            type: ActionTypes.TEAM_ICON_DELETE_FAIL,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

export const getTeamPolicies$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_POLICIES_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/teams/${payload.id}/policies`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          of({
            type: ActionTypes.TEAM_POLICIES_GET_SUCCESS,
            payload,
          })
        ),
        catchApiError(action$, (error) =>
          of({
            type: ActionTypes.TEAM_POLICIES_GET_FAIL,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

export const updateTeamPolicies$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_POLICIES_UPDATE),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/teams/${payload.id}/policies`,
          method: 'PATCH',
          body: payload.body,
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          of({
            type: ActionTypes.TEAM_POLICIES_UPDATE_SUCCESS,
            payload,
          })
        ),
        catchApiError(action$, (error) =>
          of({
            type: ActionTypes.TEAM_POLICIES_UPDATE_FAIL,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

export const getMembers$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_MEMBERS_GET),
    switchMap((action) =>
      ajax(
        {
          url: `/teams/${action.payload.id}/members`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        map(({ response: members, xhr }) => ({
          members,
          total: parseInt(xhr.getResponseHeader('total')),
        })),
        map((payload) => ({
          type: ActionTypes.TEAM_MEMBERS_GET_SUCCESS,
          payload,
        })),
        catchApiError(action$, (error) =>
          of({
            type: ActionTypes.TEAM_MEMBERS_GET_FAIL,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

export const autocompleteUsers$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_MEMBERS_AUTOCOMPLETE),
    debounceTime(500),
    switchMap(({ payload: { q } }) =>
      ajax(
        {
          url: `/autocomplete/users?q=${q}`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          of({
            type: ActionTypes.TEAM_MEMBERS_AUTOCOMPLETE_SUCCESS,
            payload,
          })
        ),
        catchApiError(action$, (error) =>
          of({
            type: ActionTypes.TEAM_MEMBERS_AUTOCOMPLETE_FAIL,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

export const addTeamMember$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_MEMBER_ADD),
    switchMap(({ payload: { id, userHandle } }) =>
      ajax(
        {
          url: `/teams/${id}/members`,
          method: 'POST',
          body: { user: { id: userHandle } },
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        flatMap((res) =>
          concat(
            of({
              type: ActionTypes.TEAM_MEMBER_ADD_SUCCESS,
              payload: res,
            }),
            of({
              type: ActionTypes.TEAM_MEMBERS_GET,
              payload: { id },
            }),
            of(
              addNotification({
                message: 'Team Member Added',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, (error) =>
          of({
            type: ActionTypes.TEAM_MEMBER_ADD_FAIL,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

//updateMemberRole

export const updateMemberRole$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_MEMBERS_ROLE_PATCH),
    switchMap(({ payload: { teamId, userId, role } }) =>
      ajax(
        {
          url: `/teams/${teamId}/members/${userId}`,
          method: 'PATCH',
          body: { role },
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        flatMap((res) =>
          concat(
            of({
              type: ActionTypes.TEAM_MEMBERS_ROLE_PATCH_SUCCESS,
              payload: res,
            }),
            of(
              addNotification({
                message: 'Team Member Role Update',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.TEAM_MEMBERS_ROLE_PATCH_FAIL,
          })
        )
      )
    )
  );

export const removeTeamMember$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_MEMBER_REMOVE),
    switchMap(({ payload: { id, userHandle } }) =>
      ajax(
        {
          url: `/teams/${id}/members/${userHandle}`,
          method: 'DELETE',
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        flatMap((res) =>
          concat(
            of({
              type: ActionTypes.TEAM_MEMBER_REMOVE_SUCCESS,
              payload: res,
            }),
            of({
              type: ActionTypes.TEAM_MEMBERS_GET,
              payload: { id },
            }),
            of(
              addNotification({
                message: 'Team Member Removed',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.TEAM_MEMBER_REMOVE_FAIL,
          })
        )
      )
    )
  );

export const getServices$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_SERVICES_GET),
    switchMap(({ payload: { id } }) =>
      ajax(
        {
          url: `/teams/${id}/services`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        map(({ response: services, xhr }) => ({
          services,
          total: parseInt(xhr.getResponseHeader('total')),
        })),
        map((payload) => ({
          type: ActionTypes.TEAM_SERVICES_GET_SUCCESS,
          payload,
        })),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.TEAM_SERVICES_GET_FAIL,
          })
        )
      )
    )
  );

export const getServiceCount$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_SERVICE_COUNT_GET),
    switchMap(({ payload: { id } }) =>
      concat(
        ajax(
          {
            url: `/teams/${id}/services?limit=1`,
            method: 'GET',
          },
          { action$, state$ }
        ),
        ajax(
          {
            url: `/teams/${id}/teams?limit=1`,
            method: 'GET',
          },
          { action$, state$ }
        )
      ).pipe(
        map(
          (services, teams) =>
            parseInt(services.xhr.getResponseHeader('total')) +
            parseInt(teams.xhr.getResponseHeader('total'))
        ),
        map((payload) => ({
          type: ActionTypes.TEAM_SERVICE_COUNT_GET_SUCCESS,
          payload,
        })),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.TEAM_SERVICE_COUNT_GET_FAIL,
          })
        )
      )
    )
  );

export const getTeams$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_TEAMS_GET),
    switchMap(({ payload: { id, params } }) =>
      ajax(
        {
          url: `/teams/${id}/teams?${queryString.stringify(params)}`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        map(({ response: teams, xhr }) => ({
          teams,
          total: parseInt(xhr.getResponseHeader('total')),
        })),
        map((payload) => ({
          type: ActionTypes.TEAM_TEAMS_GET_SUCCESS,
          payload,
        })),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.TEAM_TEAMS_GET_FAIL,
          })
        )
      )
    )
  );

//TEAM_MEMBER_INVITE

export default [
  deleteTeam$,
  getTeam$,
  updateTeam$,
  uploadTeamAvatar$,
  deleteTeamAvatar$,
  getTeamPolicies$,
  updateTeamPolicies$,
  getMembers$,
  autocompleteUsers$,
  addTeamMember$,
  updateMemberRole$,
  removeTeamMember$,
  getServices$,
  getTeams$,
  getServiceCount$,
  ...ClientsEpics,
  ...PublicFeedEpics,
  ...HealthEpics,
  ...RunnersEpics,
];
