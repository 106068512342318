import { connect } from 'react-redux';
import { forgotPassword, reset } from './actions';
import { NS } from './actionTypes';
import './login.scss';
import ForgotPassword from './ForgotPassword';

const mapStateToProps = state => ({
  error: state.getIn([NS, 'error']),
  success: state.getIn([NS, 'success'])
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: email => dispatch(forgotPassword(email)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
