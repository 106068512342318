import Members from './Members';
import Services from './Services';
import Settings from './Settings';
import Overview from './Overview';
import Health from './Health';
import Runners from './runners/runners';

export default {
  Members,
  Services,
  Settings,
  Health,
  Runners,
  Overview,
};
