import { ofType } from 'redux-observable';
import { concat, merge, of, timer } from 'rxjs';
import {
  flatMap,
  map,
  mergeMap,
  takeUntil,
  mapTo,
  switchMap,
  tap
} from 'rxjs/operators';
import { NS as AUTH_NS } from './auth';
import { ActionTypes, NS, addNotification } from '@/reducers/global';
import { ajax, catchApiError } from '@/utils/ajax';

const FIVE_MINUTES = 1000 * 60 * 5;

export const startGetUserEvents$ = action$ =>
  action$.pipe(
    ofType(ActionTypes.START_USER_EVENTS_GET),

    switchMap(() =>
      timer(0, FIVE_MINUTES).pipe(
        takeUntil(action$.ofType(ActionTypes.STOP_USER_EVENTS_GET)),
        mapTo({
          type: ActionTypes.USER_EVENTS_GET
        })
      )
    )
  );

export const getUserEvents$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_EVENTS_GET),
    switchMap(() =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/feed`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(payload =>
          of({
            type: ActionTypes.USER_EVENTS_GET_SUCCESS,
            payload
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_EVENTS_GET_FAIL
          })
        )
      )
    )
  );

export const getUserStarred$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_STARRED_GET),
    switchMap(() =>
      merge(
        ajax(
          {
            url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/starred`,
            method: 'GET'
          },
          { action$, state$ }
        ).pipe(
          map(res => res.response),
          mergeMap(payload =>
            of({
              type: ActionTypes.USER_STARRED_GET_SUCCESS,
              payload
            })
          ),
          catchApiError(action$, () =>
            of({
              type: ActionTypes.USER_STARRED_GET_FAIL
            })
          )
        )
      )
    )
  );

export const addUserStarred$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_STARRED_PUT),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/starred/${
            payload.handle
          }`,
          method: 'PUT'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(payload =>
          of({
            type: ActionTypes.USER_STARRED_PUT_SUCCESS,
            payload
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_STARRED_PUT_FAIL
          })
        )
      )
    )
  );

export const removeUserStarred$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_STARRED_DELETE),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/starred/${
            payload.handle
          }`,
          method: 'DELETE'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(res =>
          of({
            type: ActionTypes.USER_STARRED_DELETE_SUCCESS,
            payload
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_STARRED_DELETE_FAIL
          })
        )
      )
    )
  );

export const getMuted$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_MUTED_GET),
    switchMap(action =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/muted`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(res =>
          concat(
            of({
              type: ActionTypes.USER_MUTED_GET_SUCCESS,
              payload: res,
              error: true
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_MUTED_GET_FAIL
          })
        )
      )
    )
  );

export const createMuted$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_MUTED_CREATE),
    switchMap(
      ({
        payload: {
          profile,
          body = {
            activity: true,
            notifications: true
          }
        }
      }) =>
        ajax(
          {
            url: `/users/${state$.value.getIn([
              AUTH_NS,
              'username'
            ])}/muted/${profile}`,
            method: 'PUT',
            body: body
          },
          { action$, state$ }
        ).pipe(
          map(res => res.response),
          flatMap(res =>
            concat(
              of({
                type: ActionTypes.USER_MUTED_CREATE_SUCCESS,
                payload: res,
                error: true
              })
            )
          ),
          catchApiError(action$, () =>
            of({
              type: ActionTypes.USER_MUTED_CREATE_FAIL
            })
          )
        )
    )
  );

export const updateMuted$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_MUTED_UPDATE),
    switchMap(
      ({
        payload: {
          profile,
          body = {
            activity: true,
            notifications: true
          }
        }
      }) =>
        ajax(
          {
            url: `/users/${state$.value.getIn([
              AUTH_NS,
              'username'
            ])}/muted/${profile}`,
            method: 'PATCH',
            body: body
          },
          { action$, state$ }
        ).pipe(
          map(res => res.response),
          flatMap(res =>
            concat(
              of({
                type: ActionTypes.USER_MUTED_UPDATE_SUCCESS,
                payload: res,
                error: true
              })
            )
          ),
          catchApiError(action$, () =>
            of({
              type: ActionTypes.USER_MUTED_UPDATE_FAIL
            })
          )
        )
    )
  );

export const deleteMuted$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_MUTED_DELETE),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/muted/${
            payload.profile
          }`,
          method: 'DELETE'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(res =>
          concat(
            of({
              type: ActionTypes.USER_MUTED_DELETE_SUCCESS,
              payload: payload,
              error: true
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_MUTED_DELETE_FAIL
          })
        )
      )
    )
  );

// export const getUserWatched$ = (action$, state$) =>
//   action$.pipe(
//     ofType(ActionTypes.USER_WATCHED_GET),
//     switchMap(action =>
//       ajax(
//         {
//           url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/watched`,
//           method: 'GET'
//         },
//         { action$, state$ }
//       ).pipe(
//         map(res => res.response),
//         flatMap(res =>
//           concat(
//             of({
//               type: ActionTypes.USER_WATCHED_GET_SUCCESS,
//               payload: res,
//               error: true
//             })
//           )
//         ),
//         catchApiError(action$, () =>
//           of({
//             type: ActionTypes.USER_WATCHED_GET_FAIL
//           })
//         )
//       )
//     )
//   );
// USER_WATCHED_PUT
// export const updateUserWatched$ = (action$, state$) =>
//   action$.pipe(
//     ofType(ActionTypes.USER_WATCHED_MUTE_PATCH),
//     switchMap(({ payload: { handle, muted } }) =>
//       ajax(
//         {
//           url: `/users/${state$.value.getIn([
//             AUTH_NS,
//             'username'
//           ])}/watched/${handle}`,
//           method: 'PATCH',
//           body: { muted }
//         },
//         { action$, state$ }
//       ).pipe(
//         map(res => res.response),
//         flatMap(res =>
//           concat(
//             of({
//               type: ActionTypes.USER_WATCHED_MUTE_PATCH_SUCCESS,
//               payload: res,
//               error: false
//             }),
//             of(
//               addNotification({
//                 message: `Service ${muted ? 'muted' : 'unmuted'}`,
//                 options: { variant: 'success' }
//               })
//             )
//           )
//         ),
//         catchApiError(action$, () =>
//           of({
//             type: ActionTypes.USER_WATCHED_MUTE_PATCH_FAIL
//           })
//         )
//       )
//     )
//   );

export default [
  startGetUserEvents$,
  getUserEvents$,
  getUserStarred$,
  addUserStarred$,
  removeUserStarred$,

  getMuted$,
  createMuted$,
  updateMuted$,
  deleteMuted$

  // getUserWatched$,
  // updateUserWatched$
  // deleteUserWatched$
];
