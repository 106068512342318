import { of, concat } from 'rxjs';
import { switchMap, flatMap, map, pluck, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';
import { addNotification } from '@/reducers/global';

import ActionTypes from './actionTypes';
import queryString from 'query-string';

export const getUsers$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USERS_GET),
    switchMap(({ payload: { params } }) =>
      ajax(
        {
          url: `/search/users?${queryString.stringify(params)}`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        map(({ response: users, xhr }) => ({
          users,
          total: parseInt(xhr.getResponseHeader('total')),
        })),
        flatMap((payload) =>
          of({
            type: ActionTypes.USERS_GET_SUCCESS,
            payload,
          })
        ),
        catchApiError(action$, () => {
          of({
            type: ActionTypes.USERS_GET_FAIL,
          });
        })
      )
    )
  );

export const getInvites$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.INVITES_GET),
    switchMap(({ payload: { params } }) =>
      ajax(
        {
          url: `/invites?${queryString.stringify(params)}`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        map(({ response: users, xhr }) => ({
          users,
          total: parseInt(xhr.getResponseHeader('total')),
        })),
        flatMap((payload) =>
          of({
            type: ActionTypes.INVITES_GET_SUCCESS,
            payload,
          })
        ),
        catchApiError(action$, () => {
          of({
            type: ActionTypes.INVITES_GET_FAIL,
          });
        })
      )
    )
  );

export const resendInvite$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.INVITE_RESEND),
    switchMap(({ payload: { inviteId } }) =>
      ajax(
        {
          url: `/invites/${inviteId}/actions/resend`,
          method: 'POST',
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.INVITE_RESEND_SUCCESS,
              payload,
            }),
            of(
              addNotification({
                message: 'Invite resent',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, (error) =>
          concat(
            of({
              type: ActionTypes.INVITE_RESEND_FAIL,
              payload: error,
              error: true,
            }),
            of(
              addNotification({
                message: 'There was an error sending your invite',
                options: { variant: 'error' },
              })
            )
          )
        )
      )
    )
  );

export const updateUserRole$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_ROLE_UPDATE),
    switchMap(({ payload: { userId, role } }) =>
      ajax(
        {
          url: `/users/${userId}/actions/change-role`,
          method: 'POST',
          body: { role },
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        flatMap((res) =>
          concat(
            of({
              type: ActionTypes.USER_ROLE_UPDATE_SUCCESS,
              payload: res,
            }),
            of(
              addNotification({
                message: 'User Role Update',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_ROLE_UPDATE_FAIL,
          })
        )
      )
    )
  );

export default [getUsers$, getInvites$, resendInvite$, updateUserRole$];
