// import React, { PureComponent } from 'react';

import React, { PureComponent, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Avatar,
  Button,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  },
  input: {
    display: 'none'
  }
}));

const AssetFieldGroup = props => {
  const {
    label,
    description,
    entityId,
    asset,
    buttonText,
    clearText,
    fallbackName = '',
    upload,
    clear
  } = props;

  const styles = useStyles();

  const [fileValue, setFileValue] = useState('');

  const initial = fallbackName.charAt(0).toUpperCase();

  const onFileChange = event => {
    const {
      target: { files }
    } = event;
    if (files.length) {
      const file = files[0];
      const formData = new FormData();
      formData.append('icon', file, file.name);
      setFileValue('');
      upload({
        id: entityId,
        body: formData
      });
    }
  };

  const onClear = () => {
    clear({
      id: entityId
    });
  };

  return (
    <Card className="AssetFieldGroup">
      <CardHeader title={label} />
      <CardContent className="AssetFieldGroup__text">
        <Grid container spacing={3}>
          <Grid item className="AssetFieldGroup__logo">
            <label htmlFor={`media-file-${entityId}`}>
              <Avatar className={styles.large} src={asset}>
                {initial}
              </Avatar>
            </label>
          </Grid>
          <Grid item className="AssetFieldGroup__content">
            <Box component="div" mb={2}>
              <label htmlFor={`media-file-${entityId}`}>
                <Button variant="contained" color="primary" component="span">
                  {buttonText || 'Upload Logo'}
                </Button>
              </label>
              {asset ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onClear}
                  style={{ marginLeft: '20px' }}
                >
                  {clearText || 'Clear Logo'}
                </Button>
              ) : null}
            </Box>
            <Typography variant="body2" color="textSecondary">
              {description ||
                `This icon will be visible to all members of your organization`}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Some tips: Use a graphical logo or image rather than text and
              upload an image that is 240px square or larger.
            </Typography>
          </Grid>
          <input
            id={`media-file-${entityId}`}
            className={styles.input}
            type="file"
            accept="image/*"
            onChange={onFileChange}
            value={fileValue}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AssetFieldGroup;

class _AssetFieldGroup extends PureComponent {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  handleFileUpload = event => {
    const {
      target: { files }
    } = event;
    const { upload, entityId: id } = this.props;

    const file = files[0];
    const formData = new FormData();
    formData.append('icon', file, file.name);

    upload({
      id,
      body: formData
    });
  };

  onClick = () => {
    this.fileInput.current.click();
  };

  onClear = () => {
    const { clear, entityId: id } = this.props;
    clear({
      id
    });
  };

  render() {
    const {
      label,
      asset,
      buttonText,
      clearText,
      description,
      fallbackName = ''
    } = this.props;
    const initial = fallbackName.charAt(0).toUpperCase();

    return (
      <Card className="AssetFieldGroup">
        <CardHeader title={label} />
        <CardContent className="AssetFieldGroup__text">
          <div className="AssetFieldGroup__logo">
            <Avatar src={asset} onClick={this.onClick}>
              {initial}
            </Avatar>
          </div>
          <div className="AssetFieldGroup__content">
            <Button variant="contained" color="primary" onClick={this.onClick}>
              {buttonText || 'Upload Logo'}
            </Button>
            {asset ? (
              <Button
                raised
                onClick={this.onClear}
                style={{ marginLeft: '20px' }}
              >
                {clearText || 'Clear Logo'}
              </Button>
            ) : null}

            <p>
              {description ||
                `This icon will be used to identify your organization in Visr.
              It will be visible to members of your organization and your users
              with the correct permission.`}
            </p>
            <p>
              Some tips: Use a graphical logo or image rather than text and
              upload an image that is 240px square or larger.
            </p>
          </div>
          <input
            ref={this.fileInput}
            className="AssetFieldGroup__input"
            type="file"
            accept="image/*"
            onChange={this.handleFileUpload}
          />
        </CardContent>
      </Card>
    );
  }
}
