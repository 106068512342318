import React, { PureComponent, useState, useEffect } from 'react';

import { TablePagination } from '@material-ui/core';

export default props => {
  const { offset, limit, total, updateOffset } = props;
  const [page, setPage] = useState(0);

  useEffect(() => {
    const newPage = Math.floor(offset / limit);
    setPage(newPage);
  }, [offset, limit, total]);

  const handleChangePage = (event, nextPage) => {
    const offset = nextPage * limit;
    updateOffset(offset);
  };

  return (
    <TablePagination
      className="TablePagination"
      rowsPerPageOptions={[]}
      component="div"
      count={total}
      rowsPerPage={limit}
      page={page}
      onChangePage={handleChangePage}
    />
  );
};
