import { connect } from 'react-redux';

import { NS } from './ducks/actionTypes';
import Admin from './Admin';

const mapStateToProps = state => ({
  crumbs: state.getIn([NS, 'crumbs'])
});

const mapDispatchToProps = dispatch => ({
  //   getSlackWorkspaces: () => dispatch(getSlackWorkspaces()),
  //   createSlackWorkspace: token => dispatch(createSlackWorkspace(token)),
  //   updateSlackWorkspace: (id, token) => dispatch(updateSlackWorkspace(id, token))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);
