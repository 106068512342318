import { connect } from 'react-redux';

import { updateCrumbs } from '../../ducks/actions';
import { NS as NS_ME } from '@/reducers/user';
import {
  NS as NS_GLOBAL,
  getMuted,
  updateMuted,
  deleteMuted
} from '@/reducers/global';

import Muted from './Muted';

const mapStateToProps = state => ({
  user: state.getIn([NS_ME, 'user']),
  muted: state.getIn([NS_GLOBAL, 'muted']),
  pending: state.getIn([NS_GLOBAL, 'pending', 'muted'])
});

const mapDispatchToProps = dispatch => ({
  getMuted: handle => dispatch(getMuted(handle)),
  updateMuted: (profileId, values) => dispatch(updateMuted(profileId, values)),
  deleteMuted: profile => dispatch(deleteMuted(profile)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Muted);
