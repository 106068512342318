import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { NS } from './ducks/reducer';
import { connectToSlack } from './ducks/actions';

import { Box, Typography } from '@material-ui/core';

const ConnectSlack = props => {
  const location = useLocation();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    props.connectToSlack(token);
  }, []);

  if (props.complete) {
    return <Redirect to="/" />;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100vh' }}
    >
      <Typography variant="h5">Linking Slack to Visr</Typography>
    </Box>
  );
};

const mapStateToProps = state => ({
  complete: state.getIn([NS, 'complete'])
});

const mapDispatchToProps = dispatch => ({
  connectToSlack: token => dispatch(connectToSlack(token))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectSlack);

/*
http://dev.visr.localhost:8082/callbacks/connect-to-slack?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImp0aSI6IjRmMWNlZDc0MDFiYmRlOTgifQ.eyJ1c2VyX2lkIjoiVThUTlhLUlM3IiwidGVhbV9pZCI6IlQ4VE5YS1JMMyIsInVzZXJfbmFtZSI6Im1uZGV2ZWwiLCJ3cyI6ImRldiIsImp0aSI6IjRmMWNlZDc0MDFiYmRlOTgiLCJhdWQiOiJodHRwOlwvXC9hcGkudmlzci5sb2NhbGhvc3QiLCJpc3MiOiJodHRwOlwvXC9hcGkudmlzci5sb2NhbGhvc3QiLCJpYXQiOjE1OTUxMjg4NDAsImV4cCI6MTU5NTEzNDg0MH0.9s4ypKaeA4bK1kb4gSe-eUFxVgoKKmwkULx-kaP8yoQ
*/
