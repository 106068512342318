import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, TextField, Box } from '@material-ui/core';

const ChannelForm = props => {
  const { channel, onSave, onCancel } = props;

  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: { slack_channel: { id: '' } }
  });

  useEffect(() => {
    if (channel) {
      const id = channel.getIn(['slack_channel', 'id']);
      reset({ slack_channel: { id } }, { isDirty: false });
    }
  }, [channel]);

  const onSubmit = values => {
    onSave(values.slack_channel.id);
  };

  const { isDirty, isValid } = formState;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={2}>
        <Controller
          label="Slack Channel"
          name="slack_channel.id"
          as={TextField}
          control={control}
          autoComplete="off"
          fullWidth
          rules={{
            required: true
          }}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        disabled={!isDirty || !isValid}
        type="submit"
        style={{ marginRight: '20px' }}
      >
        Save
      </Button>
      <Button onClick={onCancel}>Cancel</Button>
    </form>
  );
};

export default ChannelForm;
