import { connect } from 'react-redux';
import { NS } from './ducks/actionTypes';
import {
  getTeamById,
  updateTeamById,
  updateTeamAvatarById,
  toggleAddMemberModal,
  toggleInviteModal,
  reset
} from './ducks/actions';

import Team from './Team';

const mapStateToProps = state => ({
  pending: state.getIn([NS, 'pending', 'team']),
  team: state.getIn([NS, 'team']),
  notFound: state.getIn([NS, 'notFound'], false),
  canEdit: state.getIn([NS, 'team', 'is_admin'], false),
  crumbs: state.getIn([NS, 'crumbs'], [])
});

const mapDispatchToProps = dispatch => ({
  getTeam: payload => dispatch(getTeamById(payload)),
  update: payload => dispatch(updateTeamById(payload)),
  upload: payload => dispatch(updateTeamAvatarById(payload)),
  showAddMember: () => dispatch(toggleAddMemberModal(true)),
  showInviteUser: () => dispatch(toggleInviteModal(true)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Team);
