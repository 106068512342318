import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { NS, updateUser, updateUserIcon, clearUserIcon } from '@/reducers/user';

import { updateCrumbs } from '@/screens/User/ducks/actions';

import Settings from './Settings';

const mapStateToProps = state => ({
  user: state.getIn([NS, 'user'])
});

const mapDispatchToProps = dispatch => ({
  updateUser: payload => dispatch(updateUser(payload)),
  upload: payload => dispatch(updateUserIcon(payload)),
  clear: ({ id }) => dispatch(clearUserIcon(id)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
