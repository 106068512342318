import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Box,
  TextField,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  FormControl,
  FormLabel,
  Typography,
} from '@material-ui/core';

const IdentityProviderSettings = (props) => {
  const { ssoProvider, save } = props;
  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      idp: { sso_url: '', slo_url: '', entity_id: '' },
      certificate: '',
    },
  });

  const [showCertificate, setShowCertificate] = useState(true);

  const { isDirty, isValid } = formState;

  useEffect(() => {
    // const name = ssoProvider.get('idp', '');
    // const handle = ssoProvider.get('handle', '');

    const values = {
      'idp.sso_url': ssoProvider.getIn(['idp', 'sso_url'], ''),
      'idp.slo_url': ssoProvider.getIn(['idp', 'slo_url'], ''),
      'idp.entity_id': ssoProvider.getIn(['idp', 'entity_id'], ''),
    };

    if (ssoProvider.getIn(['idp', 'has_x509'], false)) {
      setShowCertificate(false);
    }

    reset(values, { isDirty: false });
  }, [ssoProvider]);

  const toggleCertificate = () => {
    setShowCertificate(!showCertificate);
  };

  const onSubmit = (body) => {
    save(body);
    reset(body, { isDirty: false });
  };

  return (
    <Box component={Card} mb={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader
          title="Identity Provider Settings"
          subheader={
            ssoProvider.size ? '' : 'Disabled until provider is created'
          }
        />
        <CardContent>
          <FormControl fullWidth>
            <Controller
              name="idp.sso_url"
              control={control}
              as={TextField}
              label="IDP Single Sign-On URL"
              autoComplete="off"
              disabled={!ssoProvider.size}
            />
          </FormControl>
          <FormControl fullWidth>
            <Controller
              name="idp.slo_url"
              control={control}
              as={TextField}
              label="IDP  Single Logout URL"
              autoComplete="off"
              disabled={!ssoProvider.size}
            />
          </FormControl>
          <FormControl fullWidth>
            <Controller
              name="idp.entity_id"
              control={control}
              as={TextField}
              label="IDP Entity Issuer"
              autoComplete="off"
              disabled={!ssoProvider.size}
            />
          </FormControl>{' '}
          <FormControl fullWidth style={{ marginTop: '20px' }}>
            <FormLabel>Certificate</FormLabel>

            {showCertificate ? (
              <Controller
                name="certificate"
                control={control}
                as={TextField}
                // label="Certificate"
                multiline
                rows={3}
                autoComplete="off"
                disabled={!ssoProvider.size}
              />
            ) : (
              <React.Fragment>
                <Typography variant="subtitle2" style={{ marginTop: '10px' }}>
                  Certificate is Configured
                </Typography>
                <div>
                  <Button color="primary" onClick={toggleCertificate}>
                    Update Certificate
                  </Button>
                </div>
              </React.Fragment>
            )}
          </FormControl>
        </CardContent>
        {isDirty ? (
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isValid}
            >
              Save
            </Button>
          </CardActions>
        ) : null}
      </form>
    </Box>
  );
};

export default IdentityProviderSettings;
