import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Portal
} from '@material-ui/core';

import Highlight from 'react-highlight.js';

import { Markdown } from '@/components';
import { Link } from 'react-router-dom';

export const PublishedPage = props => {
  const {
    language = 'md',
    service,
    publishedPage,
    pending,
    section,
    canEdit,
    getPublishedPage
  } = props;

  const params = useParams();

  useEffect(() => {
    getPublishedPage(params.pageId);
  }, []);

  if (pending) {
    return <CircularProgress />;
  }
  const content = publishedPage.get('content');

  const id = publishedPage.get('id');

  return (
    <div>
      {canEdit ? (
        <Portal container={document.getElementById('foo')}>
          <Button
            className="ml-auto center"
            color="primary"
            component={Link}
            to={`/services/${service.get('handle')}/draft-${section}/${
              params.pageId
            }`}
          >
            Edit
          </Button>
        </Portal>
      ) : null}
      <Card>
        <CardHeader title={publishedPage.get('title')} />

        <CardContent>
          <div className="ApiDoc">
            <div className="markdown-body">
              {language === 'md' ? (
                <Markdown source={content} />
              ) : (
                <Highlight language={language}>{content}</Highlight>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
// }

// export { PublishedPage: withRouter(PublishedPage)};

// export const ublishedPage = withRouter(_PublishedPage);
