import React, { useEffect } from 'react';
import { Map } from 'immutable';
import Moment from 'react-moment';

import { Paper } from '@material-ui/core';

const DATE_FORMAT = 'MMM D, hh:mm a';

const Issue = props => {
  const { item } = props;

  const current = item.get('history').first() || Map();
  return (
    <div className="ServiceIssue">
      <div className="ServiceIssue__content">
        <h4 className="ServiceIssue__title">{item.get('title')}</h4>
        <p className="ServiceIssue__message">{current.get('message')}</p>
      </div>
      <div className="ServiceIssue__details">
        <Moment className="ServiceIssue__date" format={DATE_FORMAT}>
          {current.get('time')}
        </Moment>
      </div>
    </div>
  );
};

const Announcements = props => {
  const { issues, pending, getIssues, service } = props;

  useEffect(() => {
    const handle = service.get('handle');
    const status = 'open';
    const type = 'incident';
    getIssues(handle, type, status);
  }, []);

  if (pending || !issues.size) return null;
  const issue = issues.first();
  return <Issue item={issue} />;
};

export default Announcements;
