import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NS } from '@/screens/Service/ducks/reducer';
import {
  getPublishedReleaseNote,
  updateCrumbs
} from '@/screens/Service/ducks/actions';

import { PublishedPage } from '../../components/PublishedPage';

export const PublishedReleaseNote = props => {
  const { updateCrumbs } = props;
  useEffect(() => {
    updateCrumbs([
      {
        label: 'Release Notes',
        path: `/services/${props.service.get('handle')}/release-notes`
      },
      { label: 'Release Note' }
    ]);
  }, []);
  return <PublishedPage language="md" {...props} />;
};

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service']),
  publishedPage: state.getIn([NS, 'publishedReleaseNote']),
  pending: state.getIn([NS, 'pending', 'publishedReleaseNote']),
  canEdit: state.getIn([NS, 'team', 'is_member'], false)
});
const mapDispatchToProps = dispatch => ({
  getPublishedPage: pageId => dispatch(getPublishedReleaseNote(pageId)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishedReleaseNote);
