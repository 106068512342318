import { createAction } from '@/utils/actionCreator';

import ActionTypes from './actionTypes';

export const getTeamById = createAction(ActionTypes.TEAM_GET, 'id');

export const updateTeamById = createAction(
  ActionTypes.TEAM_UPDATE,
  'id',
  'data'
);

export const updateTeamAvatarById = createAction(
  ActionTypes.TEAM_AVATAR_UPDATE,
  'id',
  'data'
);

export const clearTeamIconById = createAction(
  ActionTypes.TEAM_ICON_DELETE,
  'id'
);

export const getTeamPoliciesById = createAction(
  ActionTypes.TEAM_POLICIES_GET,
  'id'
);

export const updateTeamPoliciesById = createAction(
  ActionTypes.TEAM_POLICIES_UPDATE,
  'id',
  'body'
);

export const getTeamMembersById = createAction(
  ActionTypes.TEAM_MEMBERS_GET,
  'id',
  'params'
);

export const addTeamMember = createAction(
  ActionTypes.TEAM_MEMBER_ADD,
  'id',
  'userHandle'
);

export const removeTeamMember = createAction(
  ActionTypes.TEAM_MEMBER_REMOVE,
  'id',
  'userHandle'
);

export const searchUsers = createAction(
  ActionTypes.TEAM_MEMBERS_AUTOCOMPLETE,
  'q'
);

export const toggleAddMemberModal = createAction(
  ActionTypes.TEAM_MEMBER_TOGGLE_MODAL,
  'show'
);

export const queryUsers = createAction(ActionTypes.TEAM_MEMBERS_AUTOCOMPLETE);

export const getTeamServicesById = createAction(
  ActionTypes.TEAM_SERVICES_GET,
  'id',
  'params'
);

export const getTeamTeamsById = createAction(
  ActionTypes.TEAM_TEAMS_GET,
  'id',
  'params'
);

export const toggleInviteModal = createAction(
  ActionTypes.TEAM_TOGGLE_INVITE_MODAL,
  'inviteModalVisible'
);

export const inviteMember = createAction(
  ActionTypes.TEAM_MEMBER_INVITE,
  'email'
);

export const updateMemberRole = createAction(
  ActionTypes.TEAM_MEMBERS_ROLE_PATCH,
  'teamId',
  'userId',
  'role'
);

export const getServiceCount = createAction(
  ActionTypes.TEAM_SERVICE_COUNT_GET,
  'id'
);

export const deleteTeam = createAction(ActionTypes.TEAM_DELETE, 'id');

export const toggleDeleteModal = createAction(
  ActionTypes.TEAM_DELETE_CONFIRM_MODAL,
  'show'
);

// Clients

export const getClients = createAction(ActionTypes.CLIENTS_GET, 'teamId');
export const getClient = createAction(
  ActionTypes.CLIENT_GET,
  'teamId',
  'clientId'
);
export const createClient = createAction(
  ActionTypes.CLIENT_CREATE,
  'teamId',
  'client'
);
export const updateClient = createAction(
  ActionTypes.CLIENT_UPDATE,
  'teamId',
  'clientId',
  'client'
);
export const updateClientSecret = createAction(
  ActionTypes.CLIENT_SECRET_UPDATE,
  'teamId',
  'clientId'
);
export const deleteClient = createAction(
  ActionTypes.CLIENT_DELETE,
  'teamId',
  'clientId'
);
export const setClient = createAction(ActionTypes.CLIENT_SET, 'client');

export const getPublicFeed = createAction(
  ActionTypes.PUBLIC_FEED_GET,
  'teamId'
);

export const getHealth = createAction(ActionTypes.HEALTH_GET, 'handle');

// Clients

export const getRunners = createAction(ActionTypes.RUNNERS_GET, 'teamId');
export const getRunner = createAction(
  ActionTypes.RUNNER_GET,
  'teamId',
  'runnerId'
);
export const createRunner = createAction(
  ActionTypes.RUNNER_CREATE,
  'teamId',
  'runner'
);
export const updateRunner = createAction(
  ActionTypes.RUNNER_UPDATE,
  'teamId',
  'runnerId',
  'runner'
);
export const deleteRunner = createAction(
  ActionTypes.RUNNER_DELETE,
  'teamId',
  'runnerId'
);
export const setRunner = createAction(ActionTypes.RUNNER_SET, 'runner');

export const updateCrumbs = createAction(ActionTypes.UPDATE_CRUMBS, 'crumbs');

export const reset = createAction(ActionTypes.RESET);
