import { Map, List, fromJS } from 'immutable';
import parseDomain from 'parse-domain';

import {
  createTypes,
  asyncType,
  createAction,
  createNS
} from '@/utils/actionCreator';

export const NS = createNS('GLOBAL');

export const ActionTypes = createTypes(
  [
    'READY',
    ...asyncType('USER_EVENTS_GET'),
    ...asyncType('USER_STARRED_GET'),
    ...asyncType('USER_STARRED_PUT'),
    ...asyncType('USER_STARRED_DELETE'),
    // ...asyncType('USER_WATCHED_GET'),
    // ...asyncType('USER_WATCHED_MUTE_PATCH'),

    ...asyncType('USER_MUTED_GET'),
    ...asyncType('USER_MUTED_CREATE'),
    ...asyncType('USER_MUTED_UPDATE'),
    ...asyncType('USER_MUTED_DELETE'),

    'START_USER_EVENTS_GET',
    'ABORT_USER_EVENTS_GET',

    'NOTIFICATION_ADD',
    'NOTIFICATION_DELETE',

    'ENQUEUE_SNACKBAR',
    'CLOSE_SNACKBAR',
    'REMOVE_SNACKBAR'
  ],
  `${NS}`
);

export const starService = createAction(ActionTypes.USER_STARRED_PUT, 'handle');

export const unstarService = createAction(
  ActionTypes.USER_STARRED_DELETE,
  'handle'
);

export const updateWatchedService = createAction(
  ActionTypes.USER_WATCHED_MUTE_PATCH,
  'handle',
  'muted'
);

export const getMuted = createAction(ActionTypes.USER_MUTED_GET, 'profile');

export const createMuted = createAction(
  ActionTypes.USER_MUTED_CREATE,
  'profile',
  'body'
);

export const updateMuted = createAction(
  ActionTypes.USER_MUTED_UPDATE,
  'profile',
  'body'
);

export const deleteMuted = createAction(
  ActionTypes.USER_MUTED_DELETE,
  'profile'
);

// export const unmuteWatchedService = createAction(
//   ActionTypes.USER_WATCHED_UNMUTE,
//   'handle'
// );

// export const addNotification = createAction(
//   ActionTypes.NOTIFICATION_ADD,
//   'notification'
// );

export const getUserEvents = createAction(ActionTypes.USER_EVENTS_GET);

export const removeNotification = createAction(ActionTypes.NOTIFICATION_DELETE);

export const addNotification = notification => {
  const key = notification.options && notification.options.key;

  return {
    type: ActionTypes.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random()
    }
  };
};

export const closeSnackbar = key => ({
  type: ActionTypes.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key
});

export const removeSnackbar = key => ({
  type: ActionTypes.REMOVE_SNACKBAR,
  key
});

const { subdomain: workspace } = parseDomain(window.location.hostname, {
  customTlds: ['localhost']
});

const INITIAL_STATE = Map({
  ready: false,
  events: List(),
  starred: List(),
  watched: List(),
  muted: [],
  notifications: [],
  workspace,
  pending: Map({
    user: true,
    starred: true,
    watched: true,
    muted: true,
    events: true
  })
});

export default function global(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.READY:
      return state.set('ready', true);

    case ActionTypes.USER_EVENTS_GET:
      return state.setIn(['pending', 'events'], true);

    case ActionTypes.USER_EVENTS_GET_SUCCESS:
      return state
        .set('events', fromJS(action.payload))
        .setIn(['pending', 'events'], false);

    case ActionTypes.USER_STARRED_GET_SUCCESS:
      return state
        .set('starred', fromJS(action.payload))
        .setIn(['pending', 'starred'], false);

    case ActionTypes.USER_STARRED_PUT_SUCCESS:
      return state
        .update('starred', starred => starred.push(fromJS(action.payload)))
        .setIn(['pending', 'starred'], false);

    case ActionTypes.USER_STARRED_DELETE_SUCCESS:
      return state.update('starred', starred =>
        starred.filter(
          item => item.getIn(['profile', 'handle']) !== action.payload.handle
        )
      );

    case ActionTypes.USER_MUTED_GET:
      return state.setIn(['muted'], List()).setIn(['pending', 'muted'], true);

    case ActionTypes.USER_MUTED_GET_SUCCESS:
      return state
        .setIn(['muted'], action.payload)
        .setIn(['pending', 'muted'], false);

    case ActionTypes.USER_MUTED_DELETE_SUCCESS:
      return state.update('muted', muted =>
        muted.filter(item => item.profile.id !== action.payload.profile)
      );

    // case ActionTypes.USER_WATCHED_GET_SUCCESS:
    //   return state
    //     .set('watched', fromJS(action.payload))
    //     .setIn(['pending', 'watched'], false);

    // case ActionTypes.USER_WATCHED_MUTE_PATCH_SUCCESS:
    //   return state.update('watched', watched =>
    //     watched.update(
    //       watched.findIndex(
    //         item => item.getIn(['service', 'id']) === action.payload.service.id
    //       ),
    //       () => fromJS(action.payload)
    //     )
    //   );

    // case ActionTypes.USER_WATCHED_DELETE_SUCCESS:
    //   return state.update('watched', watched =>
    //     watched.filter(
    //       item => item.getIn(['service', 'handle']) !== action.payload.handle
    //     )
    //   );

    case ActionTypes.NOTIFICATION_ADD:
      return state.update('notifications', notifications =>
        notifications.push(action.payload.notification)
      );

    case ActionTypes.NOTIFICATION_DELETE:
      return state.update('notifications', notifications =>
        notifications.delete(0)
      );

    case ActionTypes.ENQUEUE_SNACKBAR:
      return state.update('notifications', notifications => [
        ...notifications,
        {
          key: action.key,
          ...action.notification
        }
      ]);

    case ActionTypes.CLOSE_SNACKBAR:
      return state.update('notifications', notifications =>
        notifications.map(notification =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      );

    case ActionTypes.REMOVE_SNACKBAR:
      return state.update('notifications', notifications =>
        notifications.filter(notification => notification.key !== action.key)
      );

    default:
      return state;
  }
}
