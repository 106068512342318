import { connect } from 'react-redux';
import { NS } from './reducer';
import { NS as NS_GLOBAL } from '@/reducers/global';
import { createUser } from './actions';
import Invite from './Invite';

const mapStateToProps = state => ({
  success: state.getIn([NS, 'success']),
  error: state.getIn([NS, 'error']),
  workspace: state.getIn([NS_GLOBAL, 'workspace'])
});

const mapDispatchToProps = dispatch => ({
  createUser: ({ workspace, invite, user }) =>
    dispatch(createUser(workspace, invite, user))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invite);
