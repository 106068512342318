import { connect } from 'react-redux';

import { NS } from '@/screens/Team/ducks/actionTypes';
import {
  addTeamMember,
  toggleAddMemberModal,
  searchUsers
} from '@/screens/Team/ducks/actions';
import AddMember from './AddMember';

const mapStateToProps = state => ({
  loading: state.getIn([NS, 'pending', 'filteredMembers']),
  team: state.getIn([NS, 'team']),
  addMemberModalVisible: state.getIn([NS, 'addMemberModalVisible']),
  filteredMembers: state.getIn([NS, 'filteredMembers'])
});

const mapDispatchToProps = dispatch => ({
  addMember: (teamId, handle) => dispatch(addTeamMember(teamId, handle)),
  searchUsers: q => dispatch(searchUsers(q)),
  hide: () => dispatch(toggleAddMemberModal(false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMember);
