import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import {
  updateCrumbs,
  getPaymentMethods,
  updateDefaultPaymentMethod,
  deletePaymentMethod,
  loadStripe,
} from '../../ducks/actions';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Container,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Modal,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CardField, Plans, PaymentMethods } from '../../components';

import { NS } from '../../ducks/ns';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 500,
  },
}));

export const PaymentMethod = (props) => {
  const { paymentMethod, onDelete, onSetAsDefault } = props;

  const handleDelete = useCallback(() => {
    onDelete(paymentMethod);
  });
  const handleSetAsDefault = useCallback(() => {
    onSetAsDefault(paymentMethod);
  });

  return (
    <ListItem dense>
      <ListItemText
        id={paymentMethod.get('id')}
        primary={
          <span>
            <strong>{paymentMethod.getIn(['card', 'brand'])}</strong> ending in{' '}
            {paymentMethod.getIn(['card', 'last4'])}{' '}
            {paymentMethod.getIn(['card', 'exp_month'])}/
            {paymentMethod.getIn(['card', 'exp_year']) - 2000}{' '}
            {paymentMethod.get('default') ? '(default)' : ''}
          </span>
        }
      />
      <ListItemSecondaryAction>
        {paymentMethod.get('default') ? null : (
          <React.Fragment>
            <Button color="primary" onClick={handleDelete}>
              Delete
            </Button>
            <Button color="primary" onClick={handleSetAsDefault}>
              Set as Default
            </Button>
          </React.Fragment>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export const ManagePaymentMethods = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const paymentMethods = useSelector((state) =>
    state.getIn([NS, 'paymentMethods'])
  );
  useEffect(() => {
    dispatch(getPaymentMethods());
    // !stripe && dispatch(loadStripe());
    dispatch(updateCrumbs([{ label: 'Manage Payment Methods' }]));
  }, []);
  const [modalVibility, setModalVisibility] = useState(false);

  const onSetAsDefault = useCallback((card) => {
    dispatch(updateDefaultPaymentMethod(card.get('id')));
  });
  const onDelete = useCallback((card) => {
    dispatch(deletePaymentMethod(card.get('id')));
  });

  const toggleModalVisibility = useCallback(() => {
    setModalVisibility(!modalVibility);
  });

  //   const onCreateClick = async () => {
  //     const cardElement = elements.getElement(CardElement);
  //     const { error, paymentMethod } = await stripe.createPaymentMethod({
  //       type: 'card',
  //       card: cardElement,
  //     });
  //     if (paymentMethod) {
  //       dispatch(getPaymentMethods());
  //       toggleModalVisibility();
  //     }
  //   };

  return (
    <Container maxWidth="md">
      <Card>
        <CardHeader title="Manage Payment Methods" />
        <CardContent>
          <List>
            {paymentMethods.map((paymentMethod) => (
              <PaymentMethod
                key={paymentMethod.get('id')}
                paymentMethod={paymentMethod}
                onDelete={onDelete}
                onSetAsDefault={onSetAsDefault}
              />
            ))}
          </List>
        </CardContent>
      </Card>
      {/* <CardActions>
        <Button
          onClick={toggleModalVisibility}
          to={'/billing/create-subscription'}
        >
          Add New Method
        </Button>
      </CardActions>

      <Dialog
        open={modalVibility}
        onClose={toggleModalVisibility}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        // className={styles.dialog}
      >
        <DialogTitle>Create Payment Method</DialogTitle>
        <DialogContent className={styles.dialog}>
          <CardField />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onCreateClick}>
            Create
          </Button>
          <Button onClick={toggleModalVisibility}>Close</Button>
        </DialogActions>
      </Dialog> */}
    </Container>
  );
};

// export const ManagePaymentMethods = (props) => {
//   const stripe = useSelector((state) => state.getIn([NS, 'stripe']));
//   const dispatch = useDispatch();

//   if (!stripe) {
//     return null;
//   }
//   return (
//     <Elements stripe={stripe}>
//       <ManagePaymentMethodsForm />
//     </Elements>
//   );
// };
