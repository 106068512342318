import { connect } from 'react-redux';
import { NS as NS_USER } from '@/reducers/user';
import CreateMenu from './CreateMenu';

const stateToProps = state => ({
  user: state.getIn([NS_USER, 'user'])
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  stateToProps,
  mapDispatchToProps
)(CreateMenu);
