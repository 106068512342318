import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { CircularProgress, Box } from '@material-ui/core';

import { Main, Container, Breadcrumb } from '@/components';

import { getAccount, reset } from './ducks/actions';
import { NS } from './ducks/ns';

import { Payment } from './scenes/payment/payment';
import { Overview } from './scenes/overview/overview';
import { Invoices } from './scenes/invoices/invoices';
import { ManagePaymentMethods } from './scenes/manage-payment-methods/manage-paymenent-methods';

export const Billing = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAccount());
    return () => {
      dispatch(reset());
    };
  }, []);

  const crumbs = useSelector((state) => state.getIn([NS, 'crumbs']));
  const pending = useSelector((state) =>
    state.getIn([NS, 'pending', 'subscription'])
  );

  if (pending) {
    return (
      <Container>
        <Box p={20} mx="auto" display="flex" justifyContent="center">
          <CircularProgress mx="auto" size={48} />
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Main
        breadcrumb={
          <Breadcrumb name={'Billing'} path={'/billing'} crumbs={crumbs} />
        }
      >
        {pending ? (
          'loading'
        ) : (
          <Switch>
            <Route path="/billing" exact>
              <Overview />
            </Route>
            <Route path="/billing/create-subscription">
              <Payment />
            </Route>
            <Route path="/billing/update-subscription">
              <Payment update={true} />
            </Route>
            <Route path="/billing/invoices">
              <Invoices />
            </Route>
            <Route path="/billing/manage-payment-methods">
              <ManagePaymentMethods />
            </Route>
          </Switch>
        )}
      </Main>
    </Container>
  );
};
