import { createTypes, asyncType, createNS } from '@/utils/actionCreator';

export const NS = createNS('SERVICE');

export const ActionTypes = createTypes(
  [
    // SERVICE GET
    ...asyncType('SERVICE_GET'),

    // SERVICE UPDATE
    ...asyncType('SERVICE_UPDATE'),

    ...asyncType('SERVICE_REPARENT_POST'),
    ...asyncType('SERVICE_HEALTH_POST'),
    ...asyncType('SERVICE_STAGE_POST'),
    ...asyncType('SERVICE_VISIBILITY_POST'),

    // SERVICE DELETE
    ...asyncType('SERVICE_DELETE'),

    // SERVICE UPLOAD
    ...asyncType('SERVICE_UPLOAD'),
    ...asyncType('SERVICE_ICON_DELETE'),

    // SERVICE HEALTH
    ...asyncType('SERVICE_HEALTH_CHECKS_GET'),

    // Service Team
    ...asyncType('SERVICE_TEAM_GET'),

    // DEPENDENCIES
    ...asyncType('SERVICE_DEPENDENCIES_GET'),
    ...asyncType('SERVICES_AUTOCOMPLETE'),
    ...asyncType('SERVICE_DEPENDENCIES_ADD'),
    ...asyncType('SERVICE_DEPENDENCIES_DELETE'),
    'DEPENDENCIES_MODAL_VISIBLE',

    // REMOTE DEPENDENCIES
    'DEPENDENCIES_MODAL_VISIBLE',
    ...asyncType('SERVICES_REMOTE_SERVICES_GET'),
    ...asyncType('SERVICES_REMOTE_DEPENDENCIES_GET'),
    ...asyncType('SERVICES_REMOTE_DEPENDENCY_CREATE'),
    ...asyncType('SERVICES_REMOTE_DEPENDENCY_DELETE'),
    'REMOTE_DEPENDENCIES_MODAL_VISIBLE',

    // SERVICE dependents
    ...asyncType('SERVICE_DEPENDENTS_GET'),

    // OVERVIEW_ANNOUNCEMENTS
    ...asyncType('OVERVIEW_ANNOUNCEMENTS_GET'),
    ...asyncType('OVERVIEW_ISSUES_GET'),

    // NOTICES
    ...asyncType('NOTICES_GET'),
    ...asyncType('NOTICE_CREATE'),
    ...asyncType('NOTICE_UPDATE'),
    'NOTICE_MODAL_VISIBLE',
    'NOTICE_VIEW_MODAL_VISIBLE',

    ...asyncType('SERVICE_HEALH_CHECK_DAILY_GET'),

    ...asyncType('SERVICE_HEALTH_CHECK_RESULTS_GET'),

    // HEALTHCHECK MODAL
    'HEALTHCHECK_MODAL_VISIBLE',
    // HEALTHCHECK CREATE
    ...asyncType('HEALTHCHECK_CREATE'),
    // HEALTHCHECK UPDATE
    ...asyncType('HEALTHCHECK_UPDATE'),

    // PUBLISHED PAGES
    ...asyncType('PUBLISHED_PAGES_GET'),
    ...asyncType('PUBLISHED_PAGE_GET'),

    // DRAFT PAGES
    ...asyncType('DRAFT_PAGES_GET'),
    ...asyncType('DRAFT_PAGE_GET'),
    ...asyncType('DRAFT_PAGE_CREATE'),
    ...asyncType('DRAFT_PAGE_UPDATE'),
    ...asyncType('DRAFT_PAGE_DELETE'),
    ...asyncType('DRAFT_PAGE_PUBLISH'),
    ...asyncType('DRAFT_PAGE_UNPUBLISH'),
    ...asyncType('DRAFT_PAGE_UPDATE_PUBLISH'),
    ...asyncType('DRAFT_PAGE_REVISIONS_GET'),
    ...asyncType('DRAFT_PAGE_REVISION_GET'),

    // PUBLISHED ANNOUNCEMENTS
    ...asyncType('PUBLISHED_ANNOUNCEMENTS_GET'),
    ...asyncType('PUBLISHED_ANNOUNCEMENT_GET'),

    // DRAFT ANNOUNCEMENTS
    ...asyncType('DRAFT_ANNOUNCEMENTS_GET'),
    ...asyncType('DRAFT_ANNOUNCEMENT_GET'),
    ...asyncType('DRAFT_ANNOUNCEMENT_CREATE'),
    ...asyncType('DRAFT_ANNOUNCEMENT_UPDATE'),
    ...asyncType('DRAFT_ANNOUNCEMENT_DELETE'),
    ...asyncType('DRAFT_ANNOUNCEMENT_PUBLISH'),
    ...asyncType('DRAFT_ANNOUNCEMENT_UNPUBLISH'),
    ...asyncType('DRAFT_ANNOUNCEMENT_UPDATE_PUBLISH'),
    ...asyncType('DRAFT_ANNOUNCEMENT_REVISIONS_GET'),
    ...asyncType('DRAFT_ANNOUNCEMENT_REVISION_GET'),
    'DRAFT_ANNOUNCEMENT_RESET',

    // PUBLISHED RELEASE_NOTES
    ...asyncType('PUBLISHED_RELEASE_NOTES_GET'),
    ...asyncType('PUBLISHED_RELEASE_NOTE_GET'),

    // DRAFT RELEASE_NOTES
    ...asyncType('DRAFT_RELEASE_NOTES_GET'),
    ...asyncType('DRAFT_RELEASE_NOTE_GET'),
    ...asyncType('DRAFT_RELEASE_NOTE_CREATE'),
    ...asyncType('DRAFT_RELEASE_NOTE_UPDATE'),
    ...asyncType('DRAFT_RELEASE_NOTE_DELETE'),
    ...asyncType('DRAFT_RELEASE_NOTE_PUBLISH'),
    ...asyncType('DRAFT_RELEASE_NOTE_UNPUBLISH'),
    ...asyncType('DRAFT_RELEASE_NOTE_UPDATE_PUBLISH'),
    ...asyncType('DRAFT_RELEASE_NOTE_REVISIONS_GET'),
    ...asyncType('DRAFT_RELEASE_NOTE_REVISION_GET'),
    'DRAFT_RELEASE_NOTE_RESET',

    // PUBLISHED API DOCS
    ...asyncType('PUBLISHED_API_DOCS_GET'),
    ...asyncType('PUBLISHED_API_DOC_GET'),

    // DRAFT API DOCS
    ...asyncType('DRAFT_API_DOCS_GET'),
    ...asyncType('DRAFT_API_DOC_GET'),
    ...asyncType('DRAFT_API_DOC_CREATE'),
    ...asyncType('DRAFT_API_DOC_UPDATE'),
    ...asyncType('DRAFT_API_DOC_DELETE'),
    ...asyncType('DRAFT_API_DOC_PUBLISH'),
    ...asyncType('DRAFT_API_DOC_UNPUBLISH'),
    ...asyncType('DRAFT_API_DOC_UPDATE_PUBLISH'),
    ...asyncType('DRAFT_API_DOC_REVISIONS_GET'),
    ...asyncType('DRAFT_API_DOC_REVISION_GET'),
    'DRAFT_API_DOC_RESET',

    // PUBLISHED OPEN API SPECS
    ...asyncType('PUBLISHED_OPEN_API_SPECS_GET'),
    ...asyncType('PUBLISHED_OPEN_API_SPEC_GET'),
    // DRAFT OPEN API SPECS
    ...asyncType('DRAFT_OPEN_API_SPECS_GET'),
    ...asyncType('DRAFT_OPEN_API_SPEC_GET'),
    ...asyncType('DRAFT_OPEN_API_SPEC_CREATE'),
    ...asyncType('DRAFT_OPEN_API_SPEC_UPDATE'),
    ...asyncType('DRAFT_OPEN_API_SPEC_DELETE'),
    ...asyncType('DRAFT_OPEN_API_SPEC_PUBLISH'),
    ...asyncType('DRAFT_OPEN_API_SPEC_UNPUBLISH'),
    ...asyncType('DRAFT_OPEN_API_SPEC_UPDATE_PUBLISH'),
    ...asyncType('DRAFT_OPEN_API_SPEC_REVISIONS_GET'),
    ...asyncType('DRAFT_OPEN_API_SPEC_REVISION_GET'),
    'DRAFT_OPEN_API_SPEC_RESET',

    // DRAFT PAGES
    ...asyncType('PAGES_EXPORT'),

    // GRAPH
    ...asyncType('GRAPH_GET'),

    // CHANNELS
    ...asyncType('CHANNELS_GET'),
    ...asyncType('CHANNEL_CREATE'),
    ...asyncType('CHANNEL_UPDATE'),
    ...asyncType('CHANNEL_DELETE'),

    ...asyncType('TEAMS_GET'),

    ...asyncType('RUNNERS_GET'),

    'UPDATE_CRUMBS',
    'RESET',
  ],
  NS
);

export default ActionTypes;
