import { addNotification } from '@/reducers/global';
import { ajax, catchApiError } from '@/utils/ajax';
import queryString from 'query-string';
import { push } from 'connected-react-router/immutable';
import { ofType } from 'redux-observable';
import { concat, of, Observable, iif, defer } from 'rxjs';
import {
  debounceTime,
  flatMap,
  map,
  mergeMap,
  switchMap,
  pluck,
  tap,
} from 'rxjs/operators';
import { NS as AUTH_NS } from '@/reducers/auth';
import ActionTypes, { NS } from './actionTypes';

import ChannelsEpics from './epics/Channels';
import HealthchecksEpics from './epics/Healthchecks';

import PublishedPagesEpics from './epics/PublishedPages';
import DraftPagesEpics from './epics/DraftPages';
import PagesEpics from './epics/Pages';

// import DraftPagesEpics from './epics/DraftPages';
import AnnoucementsEpics from './epics/Annoucements';
import ReleaseNotesEpics from './epics/ReleaseNotes';
import ApiDocsEpics from './epics/ApiDocs';
import OpenApiSpecs from './epics/OpenApiSpecs';
import DependenciesEpics from './epics/Dependencies';
import GraphEpics from './epics/Graph';
import RunnersEpics from './epics/runners';

export const getService$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.id}`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.SERVICE_GET_SUCCESS,
              payload: payload,
            }),
            of({
              type: ActionTypes.SERVICE_DEPENDENCIES_GET,
              payload,
            }),
            of({
              type: ActionTypes.SERVICE_DEPENDENTS_GET,
              payload,
            }),
            of({
              type: ActionTypes.SERVICES_REMOTE_DEPENDENCIES_GET,
              payload: { service: payload.handle },
            }),
            of({
              type: ActionTypes.SERVICE_TEAM_GET,
              payload: { teamId: payload.owner.id },
            }),
            of({
              type: ActionTypes.RUNNERS_GET,
              payload: { teamId: payload.owner.id },
            })
          )
        ),
        catchApiError(action$, (error) => {
          const _e = of({
            type: ActionTypes.SERVICE_GET_FAIL,
            payload: error,
            error: true,
          });

          if (error instanceof Observable) {
            return concat(_e, error);
          }

          return of({
            type: ActionTypes.SERVICE_GET_FAIL,
            payload: error,
            error: true,
          });
        })
      )
    )
  );

export const getServiceTeam$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_TEAM_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/teams/${payload.teamId}`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.SERVICE_TEAM_GET_SUCCESS,
              payload: payload,
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_TEAM_GET_FAIL,
          })
        )
      )
    )
  );

export const updateService$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_UPDATE),
    switchMap(({ payload: { id, body } }) =>
      ajax(
        {
          url: `/services/${id}`,
          method: 'PATCH',
          body,
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.SERVICE_UPDATE_SUCCESS,
              payload: payload,
            }),
            of(
              addNotification({
                message: 'Service Updated',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_UPDATE_FAIL,
          })
        )
      )
    )
  );
export const reparentService$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_REPARENT_POST),
    switchMap(({ payload: { id, body } }) =>
      ajax(
        {
          url: `/services/${id}/actions/reparent`,
          method: 'POST',
          body,
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.SERVICE_REPARENT_POST_SUCCESS,
              payload: payload,
            }),
            of(
              addNotification({
                message: 'Service Owner Updated',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_REPARENT_POST_FAIL,
          })
        )
      )
    )
  );

export const updateServiceHealth$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_HEALTH_POST),
    switchMap(({ payload: { id, health } }) =>
      ajax(
        {
          url: `/services/${id}/actions/change-health`,
          method: 'POST',
          body: { health },
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.SERVICE_HEALTH_POST_SUCCESS,
              payload: payload,
            }),
            of(
              addNotification({
                message: 'Service Health Updated',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_HEALTH_POST_FAIL,
          })
        )
      )
    )
  );

export const updateServiceVisibility$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_VISIBILITY_POST),
    switchMap(({ payload: { id, visibility } }) =>
      ajax(
        {
          url: `/services/${id}/actions/change-visibility`,
          method: 'POST',
          body: { visibility },
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.SERVICE_VISIBILITY_POST_SUCCESS,
              payload: payload,
            }),
            of(
              addNotification({
                message: 'Service Visibility Updated',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_VISIBILITY_POST_FAIL,
          })
        )
      )
    )
  );

export const updateServiceStage$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_STAGE_POST),
    switchMap(({ payload: { id, stage } }) =>
      ajax(
        {
          url: `/services/${id}/actions/change-stage`,
          method: 'POST',
          body: { stage },
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.SERVICE_STAGE_POST_SUCCESS,
              payload: payload,
            }),
            of(
              addNotification({
                message: 'Service Stage Updated',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_STAGE_POST_FAIL,
          })
        )
      )
    )
  );

export const deleteService$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_DELETE),
    switchMap(({ payload: { handle } }) =>
      ajax(
        {
          url: `/services/${handle}`,
          method: 'DELETE',
        },
        { action$, state$ }
      ).pipe(
        flatMap(() =>
          concat(
            of(push('/services')),
            of(
              addNotification({
                message: 'Service Delete',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_DELETE_FAIL,
          })
        )
      )
    )
  );

export const uploadServiceIcon$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_UPLOAD),
    switchMap(({ payload: { id, body } }) =>
      ajax(
        {
          url: `/services/${id}/icon`,
          headers: {},
          method: 'POST',
          body,
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.SERVICE_UPLOAD_SUCCESS,
              payload: payload,
            }),
            of(
              addNotification({
                message: 'Service Updated',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_UPLOAD_FAIL,
          })
        )
      )
    )
  );
export const deleteServiceIcon$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_ICON_DELETE),
    switchMap(({ payload: { id } }) =>
      ajax(
        {
          url: `/services/${id}/icon`,
          headers: {},
          method: 'DELETE',
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.SERVICE_ICON_DELETE_SUCCESS,
              payload: payload,
            }),
            of(
              addNotification({
                message: 'Service Updated',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_ICON_DELETE_FAIL,
          })
        )
      )
    )
  );

export const getServiceStatuses$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_HEALTH_CHECKS_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.id}/health-checks/`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.SERVICE_HEALTH_CHECKS_GET_SUCCESS,
              payload: payload,
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_HEALTH_CHECKS_GET_FAIL,
          })
        )
      )
    )
  );

export const getServiceDependencies$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_DEPENDENCIES_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.handle}/dependencies`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          of({
            type: ActionTypes.SERVICE_DEPENDENCIES_GET_SUCCESS,
            payload,
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_DEPENDENCIES_GET_FAIL,
          })
        )
      )
    )
  );

export const addServiceDependency$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_DEPENDENCIES_ADD),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.handle}/dependencies`,
          method: 'POST',
          body: {
            service: payload.dependency,
          },
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.SERVICE_DEPENDENCIES_ADD_SUCCESS,
              payload,
            }),
            of(
              addNotification({
                message: 'Service dependency added',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.SERVICE_DEPENDENCIES_ADD_FAIL,
            }),
            of(
              addNotification({
                message: 'There was an error adding your dependency',
                options: { variant: 'error' },
              })
            )
          )
        )
      )
    )
  );
export const deleteServiceDependency$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_DEPENDENCIES_DELETE),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.handle}/dependencies/${payload.dependencyHandle}`,
          method: 'DELETE',
          body: {
            service: { id: payload.dependency },
          },
        },
        { action$, state$ }
      ).pipe(
        // pluck('response'),
        flatMap(() =>
          concat(
            of({
              type: ActionTypes.SERVICE_DEPENDENCIES_DELETE_SUCCESS,
              payload: payload,
            }),
            of(
              addNotification({
                message: 'Service dependency Removed',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_DEPENDENCIES_DELETE_FAIL,
          })
        )
      )
    )
  );

export const getServiceDependents$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_DEPENDENTS_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.handle}/dependents`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.SERVICE_DEPENDENTS_GET_SUCCESS,
              payload,
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_DEPENDENTS_GET_FAIL,
          })
        )
      )
    )
  );

//OVERVIEW_ANNOUNCEMENTS_GET
export const getOverviewAnnouncements$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.OVERVIEW_ANNOUNCEMENTS_GET),
    switchMap(({ payload: { id } }) =>
      ajax(
        {
          url: `/services/${id}/announcements?${queryString.stringify({
            limit: 1,
          })}`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        pluck('response', '0'),

        mergeMap((page) =>
          iif(
            () => {
              return page !== void 0;
            },

            defer(() =>
              ajax(
                {
                  url: `/announcements/${page.id}`,
                  method: 'GET',
                },
                { action$, state$ }
              ).pipe(
                pluck('response'),
                flatMap((response) =>
                  concat(
                    of({
                      type: ActionTypes.OVERVIEW_ANNOUNCEMENTS_GET_SUCCESS,
                      payload: [response],
                    })
                  )
                )
              )
            ),
            of({
              type: ActionTypes.OVERVIEW_ANNOUNCEMENTS_GET_SUCCESS,
              payload: [],
            })
          )
        ),

        catchApiError(action$, () =>
          of({
            type: ActionTypes.OVERVIEW_ANNOUNCEMENTS_GET_FAIL,
          })
        )
      )
    )
  );

//OVERVIEW_ISSUES_GET
export const getOverviewIssues$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.OVERVIEW_ISSUES_GET),
    switchMap(({ payload: { id, ...rest } }) =>
      ajax(
        {
          url: `/services/${id}/notices?${queryString.stringify(rest)}`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.OVERVIEW_ISSUES_GET_SUCCESS,
              payload,
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.OVERVIEW_ISSUES_GET_FAIL,
          })
        )
      )
    )
  );

export const getServiceNotices$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.NOTICES_GET),
    switchMap(({ payload: { id, ...rest } }) => {
      return ajax(
        {
          url: `/services/${id}/notices?${queryString
            .stringify(rest)
            .replace(/%2C/g, ',')}`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.NOTICES_GET_SUCCESS,
              payload,
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.NOTICES_GET_FAIL,
          })
        )
      );
    })
  );

//NOTICE_CREATE
export const createServiceNotice$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.NOTICE_CREATE),
    switchMap(({ payload: { body: { service, ...body } } }) =>
      ajax(
        {
          url: `/services/${service.id}/notices`,
          method: 'POST',
          body,
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.NOTICE_CREATE_SUCCESS,
              payload: payload,
            }),

            of({
              type: ActionTypes.NOTICES_GET,
              payload: {
                id: state$.value.getIn([NS, 'service', 'id']),
                type: state$.value.getIn([NS, 'noticesParams', 'type']),
                status: state$.value.getIn([NS, 'noticesParams', 'status']),
              },
            }),
            of(
              addNotification({
                message: 'Service Notice Created',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.NOTICE_CREATE_FAIL,
          })
        )
      )
    )
  );

export const updateServiceNotice$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.NOTICE_UPDATE),
    switchMap(({ payload: { id, body } }) =>
      ajax(
        {
          url: `/notices/${id}`,
          method: 'PATCH',
          body,
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap((payload) =>
          concat(
            of({
              type: ActionTypes.NOTICE_UPDATE_SUCCESS,
              payload: payload,
            }),
            of({
              type: ActionTypes.NOTICES_GET,
              payload: {
                id: state$.value.getIn([NS, 'service', 'id']),
                type: state$.value.getIn([NS, 'noticesParams', 'type']),
                status: state$.value.getIn([NS, 'noticesParams', 'status']),
              },
            }),
            of(
              addNotification({
                message: 'Service Notice Updated',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.NOTICE_UPDATE_FAIL,
          })
        )
      )
    )
  );

// CHANNELS_GET

//CHANNELS_UPDATE

export const autocompleteServices$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICES_AUTOCOMPLETE),
    debounceTime(500),
    switchMap(({ payload: q }) =>
      ajax(
        {
          url: `/autocomplete/services?q=${q}`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        mergeMap((payload) =>
          of({
            type: ActionTypes.SERVICES_AUTOCOMPLETE_SUCCESS,
            payload,
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICES_AUTOCOMPLETE_FAIL,
          })
        )
      )
    )
  );

export const getTeams$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAMS_GET),
    switchMap(() =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/teams`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        map((payload) => ({
          type: ActionTypes.TEAMS_GET_SUCCESS,
          payload,
        })),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.TEAMS_GET_FAIL,
          })
        )
      )
    )
  );

export default [
  getService$,
  updateService$,
  reparentService$,
  deleteService$,
  uploadServiceIcon$,
  deleteServiceIcon$,

  updateServiceHealth$,
  updateServiceVisibility$,
  updateServiceStage$,

  getServiceTeam$,

  getServiceStatuses$,

  getServiceDependencies$,
  addServiceDependency$,
  deleteServiceDependency$,

  getServiceDependents$,

  getOverviewAnnouncements$,
  getOverviewIssues$,

  getServiceNotices$,
  createServiceNotice$,
  updateServiceNotice$,

  autocompleteServices$,
  getTeams$,

  ...ChannelsEpics,
  ...HealthchecksEpics,
  ...PublishedPagesEpics,
  ...DraftPagesEpics,
  ...PagesEpics,
  ...GraphEpics,
  ...DependenciesEpics,

  ...AnnoucementsEpics,
  ...ReleaseNotesEpics,
  ...ApiDocsEpics,
  ...OpenApiSpecs,

  ...RunnersEpics,
];
