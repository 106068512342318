import React, { PureComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import Moment from 'react-moment';
import { Card, CardHeader, CardContent } from '@material-ui/core';

import { List } from 'immutable';

const list = List(Array.from(Array(90)));

const mapColor = status => {
  switch (status) {
    case 'GOOD':
      return '#4caf50';
    case 'MAINTENANCE':
      return '#03a9f4';
    case 'ERROR':
      return '#ff9800';
    case 'WARNING':
      return '#fdd835';
    case 'CRITICAL':
      return '#f44336';
    // case 'UNKNOWN':
    //   return '#f5f5f5';
    default:
      return '#f5f5f5';
  }
};

export class FillBar extends PureComponent {
  render() {
    const { index } = this.props;

    return (
      <rect
        className="fill-bar"
        height="34"
        width="3"
        x={index * 5}
        y="0"
        fill="#e0e0e0"
      />
    );
  }
}

export class Bar extends PureComponent {
  render() {
    const { index, handle, data } = this.props;

    return (
      <rect
        height="34"
        width="3"
        x={index * 5}
        y="0"
        fill={mapColor(data.get('status'))}
        data-tip={data.get('time')}
        data-for={handle}
      />
    );
  }
}

export default class UpdatimeBar extends PureComponent {
  getContent = time => {
    if (!time) {
      return null;
    }
    const datum = this.props.data.find(datum => datum.get('time') === time);
    const messages = datum.get('messages', []);
    return (
      <div className="UptimeBar__tip">
        <p>
          <Moment format={'MMM D'}>{time}</Moment>
        </p>
        {messages.length ? (
          messages.map((message, i) => <p key={`message_${i}`}>{message}</p>)
        ) : (
          <p>No downtime to report</p>
        )}
      </div>
    );
  };

  render() {
    const {
      title = '-',
      handle,
      data = list,
      uptime = 0,
      children
    } = this.props;

    const fill = data.size < 90 ? 90 - data.size : 0;

    return (
      <Card className="UptimeBar">
        <CardHeader className="UptimeBar__title" title={title} />
        <CardContent>
          {' '}
          <svg
            className="UptimeBar__graph"
            preserveAspectRatio="none"
            height="34"
            // viewBox={`0 0 ${5 * (data.size || 2) - 2} 34`}
            viewBox={`0 0 ${5 * 90 - 2} 34`}
          >
            {new Array(fill).fill(0).map((_, i) => (
              <FillBar key={`k-${i}`} index={i} />
            ))}
            {data.map((datum, i) => (
              <Bar
                key={`k-${i}`}
                index={i + fill}
                data={datum}
                handle={`updtimeBar_${handle}`}
              />
            ))}
          </svg>
          <div className="UptimeBar__legend">
            <div className="UptimeBar__legend_item">
              <span>{data.size}</span> days ago
            </div>
            <div className="UptimeBar__legend_spacer" />
            <div className="UptimeBar__legend_item">
              <span>{uptime}</span>% uptime
            </div>
            <div className="UptimeBar__legend_spacer" />
            <div className="UptimeBar__legend_item">Today</div>
          </div>
          <ReactTooltip
            id={`updtimeBar_${handle}`}
            effect="solid"
            delayShow={200}
            getContent={this.getContent}
          />
        </CardContent>
        {children}
      </Card>
    );
  }
}
