import React, { Component, useContext } from 'react';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import { Fab, Icon } from '@material-ui/core';

import newSteps from './steps';
import 'shepherd.js/dist/css/shepherd.css';

import './Tour.scss';

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    }
  },
  useModalOverlay: true
};

const TourFab = () => {
  const tour = useContext(ShepherdTourContext);

  return (
    <div className="tour-button">
      <Fab color="primary" size="small" onClick={tour.start}>
        <Icon>help_outline</Icon>
      </Fab>
    </div>
  );
};

export class TourButton extends Component {
  render() {
    return (
      <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
        <TourFab className="tour-button" />
      </ShepherdTour>
    );
  }
}
