import React, { useEffect, useState, useCallback } from 'react';

import {
  Switch,
  Route,
  Link,
  useParams,
  Redirect,
  useLocation
} from 'react-router-dom';

import {
  Card,
  Button,
  CircularProgress,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Avatar,
  Chip,
  Typography
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { NoMatch } from '@/components/NoMatch';
import scenes from './scenes';
import UserMenu from './components/UserMenu';

import {
  Main,
  Container,
  ConfirmationModal,
  Sidenav,
  Breadcrumb
} from '@/components';

const useStyles = makeStyles(theme => ({
  group: {
    marginBottom: theme.spacing(2)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: theme.spacing(1),
      '&:last-child': {
        marginRight: 0
      }
    }
  }
}));

export default props => {
  const { pending, user, crumbs, me, deleted, getUser, deleteUser } = props;

  const params = useParams();
  const styles = useStyles();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const { id } = params;
    getUser(id);
  }, []);

  useEffect(() => {
    let tabs = [
      {
        label: 'Overview',
        to: `/users/${params.id}`,
        key: '',
        exact: true,
        component: scenes.Overview
      },
      {
        label: 'Teams',
        to: `/users/${params.id}/teams`,
        key: 'teams',
        // exact: true,
        component: scenes.Teams
      },
      {
        label: 'Muted',
        to: `/users/${params.id}/muted`,
        key: 'muted',
        // exact: true,
        component: scenes.Muted,
        hidden: params.id !== me.get('handle')
      },
      {
        label: 'Settings',
        to: `/users/${params.id}/settings`,
        key: 'settings',
        // exact: true,
        component: scenes.Settings,
        hidden: params.id !== me.get('handle')
      }
    ].filter(tab => !tab.hidden);

    setTabs(tabs);
  }, []);

  // const handleShowConfirmDelete = () => {
  //   setShowConfirmDelete(true);
  // };

  // const handleHideConfirmDelete = () => {
  //   setShowConfirmDelete(false);
  // };

  // const handleConfirmDelete = () => {
  //   setShowConfirmDelete(false);

  //   deleteUser(user.get('handle'));
  // };

  if (deleted) {
    <Redirect to="/users" />;
  }

  if (pending) return <CircularProgress />;

  const icon = user.get('thumbnail_url');
  const name = user.get('name', '');
  const initial = name.charAt(0).toUpperCase();

  const canDelete =
    me.get('super_admin') && me.get('handle') !== user.get('handle');

  return (
    <Container>
      <Main
        breadcrumb={
          <Breadcrumb icon={icon} name={user.get('name', '')} crumbs={crumbs} />
        }
      >
        <Switch>
          <Route path={`/users/:id/settings`} component={scenes.Settings} />
          {/* <Route
            path="/teams/:id/services/new"
            component={Screens.CreateService}
          /> */}
          {tabs.map(tab => (
            <Route
              key={tab.key}
              exact={tab.exact}
              path={`/users/:id${tab.key ? `/${tab.key}` : ''}`}
              component={tab.component}
            />
          ))}
          <Route component={NoMatch} />;
        </Switch>
      </Main>
      <Sidenav>
        <UserMenu tabs={tabs} />
      </Sidenav>
    </Container>
  );
  // }
};
