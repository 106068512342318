import React from 'react';
import cn from 'classnames';

import './ServiceHealth.scss';

export const ServiceHealth = props => {
  const { className, health, prefix } = props;

  let healthClassName = '';
  let healthText = health;
  switch (health) {
    case 'good':
      healthClassName = `service-health-text--good`;
      break;
    case 'maintenance':
      healthClassName = `service-health-text--maintenance`;
      break;
    case 'warning':
      healthClassName = `service-health-text--warning`;
      break;
    case 'error':
      healthClassName = `service-health-text--error`;
      break;
    case 'critical':
      healthClassName = `service-health-text--critical`;
      break;
  }

  return (
    <span className="service-health">
      {prefix ? <span>{prefix}</span> : null}
      <span className={cn(className, 'service-health-text', healthClassName)}>
        {healthText}
      </span>
    </span>
  );
};

export default ServiceHealth;
