import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { NS } from './ducks/reducer';
import { getService, reset } from './ducks/actions';
import Service from './Service';

const mapStateToProps = state => ({
  pending: state.getIn([NS, 'pending', 'service']),
  teamPending: state.getIn([NS, 'pending', 'team']),
  service: state.getIn([NS, 'service']),
  team: state.getIn([NS, 'team']),
  canEdit: state.getIn([NS, 'team', 'is_member'], false),
  notFound: state.getIn([NS, 'notFound', 'service']),
  crumbs: state.getIn([NS, 'crumbs'], [])
});

const mapDispatchToProps = dispatch => ({
  getService: id => dispatch(getService({ id })),
  reset: () => dispatch(reset())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Service);
