import { ofType } from 'redux-observable';
import { of, concat, iif, from, defer } from 'rxjs';
import { map, mergeMap, switchMap, shareReplay, tap } from 'rxjs/operators';
import { loadStripe } from '@stripe/stripe-js/pure';

import { ajax, catchApiError } from '@/utils/ajax';
import { push } from 'connected-react-router';

import { addNotification } from '@/reducers/global';
import { ActionTypes } from './action-types';
import { getAccount } from './actions';

const _loadStripe$ = defer(()=>from(
  loadStripe(
    'pk_test_51HvUWwGGDzlOu26LnRjsodxhiEwYIJOGjWQJHwHV6EXAVKWa2Z7rXehn5IGK92u8MRjqEuKViAW9JcecRR0OQKXc00RF7r3O7T'
  )
).pipe(
  shareReplay(1),
  tap((r) => console.log(r))
));

export const getAccount$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.ACCOUNT_GET),
    switchMap(() =>
      ajax(
        {
          url: `/account`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          iif(
            () => payload.stripe && payload.stripe.subscription,
            concat(
              of({
                type: ActionTypes.ACCOUNT_GET_SUCCESS,
                payload,
              }),
              of({ type: ActionTypes.SUBSCRIPTION_GET, payload: {} })
              //   of({ type: ActionTypes.NEXT_INVOICE_GET, payload })
            ),
            concat(
              of({
                type: ActionTypes.ACCOUNT_GET_SUCCESS,
                payload,
              }),
              of({
                type: ActionTypes.SUBSCRIPTION_GET_FAIL,
                payload,
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.ACCOUNT_GET_FAIL,
          })
        )
      )
    )
  );

export const getSubscription$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SUBSCRIPTION_GET),
    switchMap(() =>
      ajax(
        {
          url: `/account/subscriptions/actions/retrieve-subscription-information`,
          method: 'POST',
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          of({
            type: ActionTypes.SUBSCRIPTION_GET_SUCCESS,
            payload,
          })
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.SUBSCRIPTION_GET_FAIL,
            })
            // of(
            //   addNotification({
            //     message: 'There was an error while retriving your subscription',
            //     options: { variant: 'error' },
            //   })
            // )
          )
        )
      )
    )
  );
export const createSubcription$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SUBSCRIPTION_CREATE),
    switchMap(({ payload: { plan, payment_method_id, quantity } }) =>
      ajax(
        {
          url: `/account/subscriptions/actions/create-subscription`,
          method: 'POST',
          body: { plan, payment_method_id, quantity },
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.SUBSCRIPTION_CREATE_SUCCESS,
              payload,
            }),
            of(
              addNotification({
                message: 'Your subscription has been updated',
                options: { variant: 'success' },
              })
            ),
            of(getAccount()),
            of(push('/billing'))
          )
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.SUBSCRIPTION_CREATE_FAIL,
            }),
            of(
              addNotification({
                message: 'There was an error while creating your subscription',
                options: { variant: 'error' },
              })
            )
          )
        )
      )
    )
  );

export const updateSubscription$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SUBSCRIPTION_UPDATE),
    switchMap(({ payload: { plan, quantity } }) =>
      ajax(
        {
          url: `/account/subscriptions/actions/update-subscription`,
          method: 'POST',
          body: { plan, quantity },
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.SUBSCRIPTION_UPDATE_SUCCESS,
              payload,
            }),
            of(
              addNotification({
                message: 'Your subscription has been updated',
                options: { variant: 'success' },
              })
            ),
            of(getAccount()),
            of(push('/billing'))
          )
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.SUBSCRIPTION_UPDATE_FAIL,
            }),
            of(
              addNotification({
                message: 'There was an error while updating your subscription',
                options: { variant: 'error' },
              })
            )
          )
        )
      )
    )
  );

export const cancelSubscription$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SUBSCRIPTION_CANCEL),
    switchMap(() =>
      ajax(
        {
          url: `/account/subscriptions/actions/cancel-subscription`,
          method: 'POST',
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.SUBSCRIPTION_CANCEL_SUCCESS,
              payload,
            }),
            of(
              addNotification({
                message: 'Your subscription has been cancelled',
                options: { variant: 'success' },
              })
            ),
            of(getAccount())
          )
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.SUBSCRIPTION_CANCEL_FAIL,
            }),
            of(
              addNotification({
                message:
                  'There was an error while cancelling your subscription',
                options: { variant: 'error' },
              })
            )
          )
        )
      )
    )
  );

export const getPaymentMethods$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.PAYMENT_METHODS_GET),
    switchMap(({ payload: { payment_method_id } }) =>
      ajax(
        {
          url: `/account/subscriptions/actions/list-payment-methods`,
          method: 'POST',
          body: { payment_method_id },
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.PAYMENT_METHODS_GET_SUCCESS,
              payload,
            })
            // of(
            //   addNotification({
            //     message: 'SSO Provider was created',
            //     options: { variant: 'success' },
            //   })
            // )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.PAYMENT_METHODS_FAIL,
          })
        )
      )
    )
  );

export const getPaymentMethod$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.PAYMENT_METHOD_GET),
    switchMap(({ payload: { payment_method_id } }) =>
      ajax(
        {
          url: `/account/subscriptions/actions/retrieve-payment-method`,
          method: 'POST',
          body: { payment_method_id },
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.PAYMENT_METHOD_GET_SUCCESS,
              payload,
            })
            // of(
            //   addNotification({
            //     message: 'SSO Provider was created',
            //     options: { variant: 'success' },
            //   })
            // )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.PAYMENT_METHOD_FAIL,
          })
        )
      )
    )
  );

export const updateDefaultPaymentMethod$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.PAYMENT_METHOD_SET_DEFAULT),
    switchMap(({ payload: { id } }) =>
      ajax(
        {
          url: `/account/subscriptions/actions/update-default-payment-method`,
          method: 'POST',
          body: { id },
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.PAYMENT_METHOD_SET_DEFAULT_SUCCESS,
              payload: { id },
            })
            // of(
            //   addNotification({
            //     message: 'SSO Provider was created',
            //     options: { variant: 'success' },
            //   })
            // )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.PAYMENT_METHOD_SET_DEFAULT_FAIL,
          })
        )
      )
    )
  );

export const deletePaymentMethod$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.PAYMENT_METHOD_DELETE),
    switchMap(({ payload: { id } }) =>
      ajax(
        {
          url: `/account/subscriptions/actions/detach-payment-method`,
          method: 'POST',
          body: { id },
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.PAYMENT_METHOD_DELETE_SUCCESS,
              payload: { id },
            })
            // of(
            //   addNotification({
            //     message: 'SSO Provider was created',
            //     options: { variant: 'success' },
            //   })
            // )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.PAYMENT_METHOD_DELETE_FAIL,
          })
        )
      )
    )
  );

///
///

export const retryInvoice$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.INVOICE_RETRY),
    switchMap(({ payload: { invoice_id, payment_method_id } }) =>
      ajax(
        {
          url: `/account/subscriptions/actions/retry-invoice`,
          method: 'POST',
          body: { invoice_id, payment_method_id },
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.INVOICE_RETRY_SUCCESS,
              payload,
            }),
            of(
              addNotification({
                message: 'Invoice was successfully paid',
                options: { variant: 'success' },
              })
            )
          )
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.INVOICE_RETRY_FAIL,
            }),
            of(
              addNotification({
                message: 'There was an error while paying your invoice',
                options: { variant: 'error' },
              })
            )
          )
        )
      )
    )
  );

export const getNextInvoice$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.NEXT_INVOICE_GET),
    switchMap(({ payload: account }) =>
      ajax(
        {
          url: `/account/subscriptions/actions/retrieve-upcoming-invoice`,
          method: 'POST',
          body: {
            plan: account.stripe.subscription.product.name,
            quantity: account.stripe.subscription.seats,
          },
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.NEXT_INVOICE_GET_SUCCESS,
              payload,
            })
            // of(
            //   addNotification({
            //     message: 'SSO Provider was updated',
            //     options: { variant: 'success' },
            //   })
            // )
          )
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.NEXT_INVOICE_GET_FAIL,
            }),
            of(
              addNotification({
                message: 'There was an error while get your next invoice ',
                options: { variant: 'error' },
              })
            )
          )
        )
      )
    )
  );

export const getInvoices$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.INVOICES_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/account/invoices`,
          method: 'GET',
          //   body: { plan, quantity },
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.INVOICES_GET_SUCCESS,
              payload,
            })
            // of(
            //   addNotification({
            //     message: 'SSO Provider was updated',
            //     options: { variant: 'success' },
            //   })
            // )
          )
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.INVOICES_GET_SUCCESS_FAIL,
            }),
            of(
              addNotification({
                message: 'There was an error while get your invoices ',
                options: { variant: 'error' },
              })
            )
          )
        )
      )
    )
  );

export const loadStripe$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.STRIPE_LOAD),
    switchMap(() =>
      _loadStripe$.pipe(
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.STRIPE_LOAD_SUCCESS,
              payload,
            })
          )
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.STRIPE_LOAD_FAIL,
            }),
            of(
              addNotification({
                message: 'There was an error while loading stripe.',
                options: { variant: 'error' },
              })
            )
          )
        )
      )
    )
  );
