import { ajax, catchApiError } from '@/utils/ajax';
import { ofType } from 'redux-observable';
import { concat, of, defer, iif } from 'rxjs';
import {
  debounceTime,
  flatMap,
  map,
  mergeMap,
  switchMap
} from 'rxjs/operators';
import { NS as AUTH_NS } from '@/reducers/auth';
import ActionTypes from './actionTypes';

export const searchTeams$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAMS_SEARCH),
    debounceTime(500),
    switchMap(({ payload: { q } }) =>
      ajax(
        {
          url: `/autocomplete/teams?q=${q}`,
          method: 'GET',
          body: {}
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.TEAMS_SEARCH_SUCCESS,
              payload: payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.TEAMS_SEARCH_FAIL
          })
        )
      )
    )
  );

export const getTeams$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAMS_GET),
    switchMap(() =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/teams`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        map(payload => ({
          type: ActionTypes.TEAMS_GET_SUCCESS,
          payload
        })),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.TEAMS_GET_FAIL
          })
        )
      )
    )
  );

export const createService$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_CREATE),
    switchMap(({ payload: { team, body } }) =>
      ajax(
        {
          url: `/teams/${team}/services`,
          method: 'POST',
          body
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(res =>
          of({
            type: ActionTypes.SERVICE_CREATE_SUCCESS,
            payload: res,
            error: true
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_CREATE_FAIL
          })
        )
      )
    )
  );

export const validateServiceHandle$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.VALIDATE_SERVICE_HANDLE),
    // debounceTime(500),
    switchMap(({ payload: { handle } }) =>
      iif(
        () => handle,
        defer(() =>
          ajax(
            {
              url: `/services/${handle}`,
              method: 'GET'
            },
            { action$, state$ }
          ).pipe(
            mergeMap(res =>
              of({
                type: ActionTypes.VALIDATE_SERVICE_HANDLE_SUCCESS,
                payload: { valid: res.status === 404 }
              })
            ),
            catchApiError(action$, res =>
              of({
                type: ActionTypes.VALIDATE_SERVICE_HANDLE_SUCCESS,
                payload: { valid: res.status === 404 }
              })
            )
          )
        ),
        of({
          type: ActionTypes.VALIDATE_SERVICE_HANDLE_SUCCESS,
          payload: { valid: null }
        })
      )
    )
  );

export default [
  searchTeams$,
  getTeams$,
  createService$,
  validateServiceHandle$
];
