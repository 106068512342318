import React, { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Container } from '@/components';

const AuthorizedRoute = (props) => {
  const {
    component: Component,
    pending,
    userPending,
    workspace,
    workspaceNotFound,
    token,
    user,
    ready,
    getToken,
    initAuth,
    ...rest
  } = props;

  const location = useLocation();

  useEffect(() => {
    initAuth();
  }, []);

  useEffect(() => {
    if (!pending && !token) {
      localStorage.setItem(
        'auth_redirect',
        `${location.pathname}${location.search}`
      );
    }
  }, [pending, token]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (pending || (token && userPending)) {
          return (
            <div className="AutorizedRouter__loader">
              <CircularProgress scale={2} />
            </div>
          );
        }

        if (workspaceNotFound) {
          return (
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="h2"
                style={{ textAlign: 'center', margin: '20px' }}
              >
                Workspace Not Found
              </Typography>
            </div>
          );
        }

        if (workspace && workspace.get('status') === 'inactive') {
          return (
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="h2"
                style={{ textAlign: 'center', margin: '20px' }}
              >
                Workspace Inactive
              </Typography>
              <p>Please contact the adminstrator to reactivate your account.</p>
            </div>
          );
        }

        if (workspace && workspace.get('status') === 'suspended') {
          if (user.get('role') == 'admin') {
            return <Component {...props} />;
          }
          return (
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="h2"
                style={{ textAlign: 'center', margin: '20px' }}
              >
                Workspace Suspended
              </Typography>
              <p>Please contact the adminstrator to reactivate your account.</p>
            </div>
          );
        }

        if (workspace && workspace.get('login', '').includes(process.env.AUTH_HOST)) {
          window.location = workspace.get('login');
          return null;
        }

        if (!token) {
          return <Redirect to="/login" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};
// }

export default AuthorizedRoute;
