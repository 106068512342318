import validator from 'validator';

export const required = val => val && !validator.isEmpty(val);

export const isUrl = val => val && validator.isURL(val);

export const isStrong = value => {
  const r = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
  return r.test(value);
};

export const isHandle = value => {
  return /^[A-Za-z0-9](?!.*--)[A-Za-z0-9-]+[A-Za-z0-9]$/.test(value);
};

export const isDomainMinLength = value => {
  return validator.isLength(value, { min: 3 });
};

export const isPasswordStrong = value => {
  return /[a-z]/.test(value) && /[0-9]/.test(value) && /[a-z]/.test(value);
};

export const isEmail = value => value && validator.isEmail(value);

export const isBetaCode = value =>
  value && validator.isLength(value, { min: 6, max: 6 });

export const isCreateCode = value =>
  value &&
  validator.isLength(value, { min: 6, max: 6 }) &&
  validator.isNumeric(value, { no_symbols: true });
