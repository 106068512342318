import React, { Component } from 'react';
import {
  Button,
  Icon,
  Typography,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';
import { Sparkline } from '@/components';
export default class HealthChecks extends Component {
  componentDidMount() {
    const {
      healthcheck,
      healthCheckResults,
      getHealthCheckResult
    } = this.props;
    const id = healthcheck.get('id');
    if (!healthCheckResults.size) {
      getHealthCheckResult(id);
    }
  }

  handleEdit = () => {
    const { editHealtcheck, healthcheck } = this.props;
    editHealtcheck(healthcheck);
  };

  getContent() {
    const { healthCheckResults, healthcheck, pending } = this.props;
    if (pending) {
      return 'loading';
    } else if (!healthCheckResults.size) {
      return 'No Data Available';
    }
    return (
      <Sparkline
        title={healthcheck.get('name')}
        handle={healthcheck.get('id')}
        data={healthCheckResults.toArray()}
      />
    );
  }

  render() {
    const { healthcheck, healthCheckResults, canEdit } = this.props;
    return (
      <Card
        className="ServiceHealthCheck"
        id={healthcheck.get('id')}
      >
        <CardHeader
          className="ServiceHealthCheck__title"
          title={
            <Typography variant="h5">{healthcheck.get('name')}</Typography>
          }
          action={
            canEdit ? (
              <Button
                className="ml-auto"
                color="primary"
                onClick={this.handleEdit}
              >
                <Icon>edit</Icon> Edit
              </Button>
            ) : null
          }
        >
          {/* <Typography variant="h5">{healthcheck.get('name')}</Typography> */}
        </CardHeader>
        <CardContent className="ServiceHealthCheck__content">
          {this.getContent()}
        </CardContent>
      </Card>
    );
  }
}
