const SUCCESS = 'SUCCESS';
const FAIL = 'FAIL';

export function createAction(type, ...params) {
  return (...args) =>
    params.reduce(
      (action, param, index) => {
        action.payload[param] = args[index];
        return action;
      },
      { type, payload: {} }
    );
}

export function createTypes(types, namespace) {
  return types.reduce((typeMap, type) => {
    typeMap[type] = namespace ? `${namespace}/${type}` : type;
    return typeMap;
  }, {});
}

export function asyncType(type) {
  return [type, `${type}_${SUCCESS}`, `${type}_${FAIL}`];
}

export function createNS(NS) {
  return `@@visr/${NS}`;
}
