import { createAction } from '@/utils/actionCreator';
import { ActionTypes } from './action-types';

export const getAccount = createAction(ActionTypes.ACCOUNT_GET);

export const createSubscription = createAction(
  ActionTypes.SUBSCRIPTION_CREATE,
  'plan',
  'quantity',
  'payment_method_id'
);

export const updateSubscription = createAction(
  ActionTypes.SUBSCRIPTION_UPDATE,
  'plan',
  'quantity',
  'payment_method_id'
);

export const cancelSubscription = createAction(ActionTypes.SUBSCRIPTION_CANCEL);

export const getPaymentMethods = createAction(ActionTypes.PAYMENT_METHODS_GET);

export const updateDefaultPaymentMethod = createAction(
  ActionTypes.PAYMENT_METHOD_SET_DEFAULT,
  'id'
);
export const deletePaymentMethod = createAction(
  ActionTypes.PAYMENT_METHOD_DELETE,
  'id'
);

export const getInvoices = createAction(ActionTypes.INVOICES_GET);

export const loadStripe = createAction(ActionTypes.STRIPE_LOAD);

export const updateCrumbs = createAction(ActionTypes.UPDATE_CRUMBS, 'crumbs');

export const reset = createAction(ActionTypes.RESET);
