import React, { useState, useEffect } from 'react';

import {
  Card,
  Container,
  CardHeader,
  CardContent,
  Switch,
  FormControlLabel
} from '@material-ui/core';

import { Main } from '@/components';

const Service = props => {
  const { service, mute, unmute } = props;

  const [disabled, setDisabled] = useState(false);
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    disabled && setDisabled(false);
  }, [service]);

  useEffect(() => {
    const muted = service.get('muted');
    setMuted(muted);
  }, []);

  const onChange = event => {
    const checked = event.target.checked;
    if (disabled) return;
    setMuted(checked);
    if (checked) {
      mute(service.getIn(['service', 'id']));
    } else {
      unmute(service.getIn(['service', 'id']));
    }
  };

  return (
    <div>
      <h4>{service.getIn(['service', 'name'])}</h4>
      <div>
        <FormControlLabel
          label="Mute Service"
          control={<Switch onChange={onChange} checked={!!muted} />}
        />
      </div>
    </div>
  );
};

export default props => {
  const { services, mute, unmute } = props;

  return (
    <Main>
      <Container maxWidth="md">
        <Card>
          <CardHeader
            title="Watched Services"
            subheader="Mute the notification for your watched services"
          />
          <CardContent>
            {services.map(service => (
              <Service
                key={service.getIn(['service', 'id'])}
                service={service}
                mute={mute}
                unmute={unmute}
              />
            ))}
          </CardContent>
        </Card>
      </Container>
    </Main>
  );
};
