import React, { useState, useCallback } from 'react';
import {
  Icon,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

export default props => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     menuItems: [
  //       {
  //         primaryText: 'Resend Invite',
  //         onClick: this.resendInvite
  //       }
  //     ]
  //   };
  // }

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = useCallback(() => setAnchorEl(null));

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const resendInvite = () => {
    const { invite, resendInvite } = props;
    const id = invite.getIn(['id']);
    resendInvite(id);
  };

  // render() {
  // const { menuItems } = this.state;
  return (
    <React.Fragment>
      <IconButton onClick={handleClick}>
        <Icon>more_vert</Icon>
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={resendInvite}>
          {/* <ListItemIcon>
            <Icon>delete</Icon>
          </ListItemIcon> */}
          <ListItemText primary="Resend Invite" />
        </MenuItem>
      </Menu>
      {/* <MenuButtonColumn icon menuItems={menuItems}>
        <FontIcon>more_vert</FontIcon>
      </MenuButtonColumn> */}
    </React.Fragment>
  );
  // }
};
