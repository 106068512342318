import { connect } from 'react-redux';
import {
  toggleNoticeModal,
  createNotice,
  updateNotice,
  updateHealth,
  updateService
} from '@/screens/Service/ducks/actions';
import { NS } from '@/screens/Service/ducks/reducer';
import NoticeForm from './NoticeForm';

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service']),
  notice: state.getIn([NS, 'notice']),
  visible: state.getIn([NS, 'noticeModalVisible'])
});

const mapDispatchToProps = dispatch => ({
  create: payload => dispatch(createNotice(payload)),
  update: (id, body) => dispatch(updateNotice(id, body)),
  updateService: payload => dispatch(updateService(payload)),
  updateHealth: (id, health) => dispatch(updateHealth(id, health)),
  hide: () => dispatch(toggleNoticeModal(false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoticeForm);
