import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Box,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormGroup,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { useState } from 'react';

const ProviderSettings = props => {
  const { ssoProvider, save } = props;
  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      sso_enabled: false,
      slo_enabled: false,
      is_default: false
    }
  });

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const values = {
      sso_enabled: ssoProvider.get('sso_enabled', false),
      slo_enabled: ssoProvider.get('slo_enabled', false),
      is_default: ssoProvider.get('is_default', false)
    };

    setDisabled(ssoProvider.size === 0);

    reset(values, { isDirty: false });
  }, [ssoProvider]);

  const onSubmit = body => {
    save(body);
    reset(body, { isDirty: false });
  };

  const { isDirty, isValid } = formState;

  return (
    <Box component={Card} mb={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader
          title="Authentication Settings"
          subheader={disabled ? 'Disabled until provider is created' : ''}
        />
        <CardContent>
          <FormGroup>
            <FormControlLabel
              label={'Enable Single Sign On'}
              control={
                <Controller
                  name="sso_enabled"
                  control={control}
                  as={Switch}
                  disabled={disabled}
                />
              }
            />
            <FormControlLabel
              label={'Enable Single Log Out'}
              control={
                <Controller
                  name="slo_enabled"
                  control={control}
                  as={Switch}
                  disabled={disabled}
                />
              }
            />
            <FormControlLabel
              label={'Use this provider as your primary auth'}
              control={
                <Controller
                  name="is_default"
                  control={control}
                  as={Switch}
                  disabled={disabled}
                />
              }
            />
          </FormGroup>
        </CardContent>
        {isDirty ? (
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isValid}
            >
              Save
            </Button>
          </CardActions>
        ) : null}
      </form>
    </Box>
  );
  // }
};

export default ProviderSettings;
