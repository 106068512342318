import { connect } from 'react-redux';

import { NS } from '../../ducks/actionTypes';

import {
  getSsoProvider,
  createSsoProvider,
  updateSsoProvider,
  resetSsoProvider,
  updateCrumbs
} from '../../ducks/actions';

import SsoProvider from './SsoProvider';

const mapStateToProps = state => ({
  pending: state.getIn([NS, 'pending', 'ssoProvider']),
  ssoProvider: state.getIn([NS, 'ssoProvider'])
});

const mapDispatchToProps = dispatch => ({
  getSsoProvider: handle => dispatch(getSsoProvider(handle)),
  createSsoProvider: body => dispatch(createSsoProvider(body)),
  updateSsoProvider: (id, body) => dispatch(updateSsoProvider(id, body)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs)),
  reset: () => dispatch(resetSsoProvider())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SsoProvider);
