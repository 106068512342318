import React, { useState, useEffect, useCallback } from 'react';

import {
  useLocation,
  useHistory,
  useParams,
  Link as RouterLink,
} from 'react-router-dom';

import {
  Box,
  Container,
  CircularProgress,
  Icon,
  Typography,
  Link,
} from '@material-ui/core';
import { Main } from '@/components';
import GeneralSettings from './components/GeneralSettings';
import SamlSettings from './components/SamlSettings';
import IdentityProviderSettings from './components/IdentityProviderSettings';
import ProviderSettings from './components/ProviderSettings';

const SsoProvider = (props) => {
  const {
    ssoProvider,
    getSsoProvider,
    createSsoProvider,
    updateSsoProvider,
    reset,
    updateCrumbs,
  } = props;

  const { handle } = useParams();
  // let history = useHistory();
  // let location = useLocation();

  const [pending, setPending] = useState(true);

  useEffect(() => {
    if (handle) {
      getSsoProvider(handle);
    } else {
      setPending(false);
    }

    updateCrumbs([{ label: 'SSO Provider' }]);

    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (ssoProvider.size) {
      setPending(false);
    }

    // if (!handle && ssoProvider.get('handle')) {
    //   history.replace(
    //     location.pathname.replace('new', ssoProvider.get('handle'))
    //   );
    // }
  }, [ssoProvider]);

  const handleSubmit = useCallback(
    (body) => {
      const { certificate, idp, sp, ...rest } = body;
      let payload = rest;
      if (idp) {
        let _idp = idp;
        if (certificate) {
          _idp = Object.assign({}, _idp, {
            x509: certificate,
          });
        }
        payload = { ...payload, idp: _idp };
      }

      if (ssoProvider.get('handle')) {
        updateSsoProvider(ssoProvider.get('handle'), payload);
      } else {
        createSsoProvider(payload);
      }
    },
    [ssoProvider]
  );

  if (pending) {
    return (
      <Box display="flex" justifyContent={'center'}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <GeneralSettings ssoProvider={ssoProvider} save={handleSubmit} />
      <SamlSettings ssoProvider={ssoProvider} save={handleSubmit} />
      <IdentityProviderSettings ssoProvider={ssoProvider} save={handleSubmit} />
      <ProviderSettings ssoProvider={ssoProvider} save={handleSubmit} />
    </Container>
  );
};

export default SsoProvider;
