import React, { useState, useCallback } from 'react';
import {
  Icon,
  IconButton,
  Menu as MdMenu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { updateRunner, deleteRunner } from '../../ducks/actions';

export const Menu = (props) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRemove = useCallback(() => {
    const { team, runner } = props;
    const teamHandle = team.get('handle');
    const userHandle = runner.get('handle');
    dispatch(deleteRunner(teamHandle, userHandle));
    handleClose();
  });

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const onEditRunner = () => {
    props.editRunner(props.runner);
    handleClose();
  };
  return (
    <React.Fragment>
      <IconButton onClick={handleClick}>
        <Icon>more_vert</Icon>
      </IconButton>
      <MdMenu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onEditRunner}>
          <ListItemIcon>
            <Icon>edit</Icon>
          </ListItemIcon>
          <ListItemText primary="Edit Runner" />
        </MenuItem>
        <MenuItem onClick={handleRemove}>
          <ListItemIcon>
            <Icon>delete</Icon>
          </ListItemIcon>
          <ListItemText primary="Remove Runner" />
        </MenuItem>
      </MdMenu>
    </React.Fragment>
  );
};
