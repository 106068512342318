import { Map, List, fromJS } from 'immutable';

import { ActionTypes } from './action-types';

export { NS } from './ns';

const initialState = Map({
  success: false,
  error: false,

  account: Map(),
  nextInvoice: Map(),
  subscription: Map(),
  invoices: List(),
  paymentMethods: List(),

  pending: Map({
    account: true,
    subscription: true,
    paymentMethods: false,
    nextInvoice: false,
  }),

  stripe: null,

  crumbs: [],
});

export function BillingReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ACCOUNT_GET:
      return state.setIn(['pending', 'account'], true);

    case ActionTypes.ACCOUNT_GET_SUCCESS:
      return state
        .setIn(['pending', 'account'], false)
        .set('error', false)
        .set('account', fromJS(action.payload));
    case ActionTypes.ACCOUNT_GET_FAIL:
      return state.setIn(['pending', 'account'], false).set('account', Map());

    case ActionTypes.SUBSCRIPTION_GET:
      return state.setIn(['pending', 'subscription'], true);
    case ActionTypes.SUBSCRIPTION_GET_SUCCESS:
      return state
        .setIn(['pending', 'subscription'], false)
        .set('error', false)
        .set('subscription', fromJS(action.payload));
    case ActionTypes.SUBSCRIPTION_GET_FAIL:
      return state
        .setIn(['pending', 'subscription'], false)
        .setIn(['subscription'], Map());

    case ActionTypes.SUBSCRIPTION_CANCEL_SUCCESS:
      return state; //.set('account', Map()).set('subscription', Map());

    case ActionTypes.PAYMENT_METHODS_GET:
      return state
        .setIn(['pending', 'paymentMethods'], true)
        .set('paymentMethods', List());
    case ActionTypes.PAYMENT_METHODS_GET_SUCCESS:
      return state
        .setIn(['pending', 'paymentMethods'], false)
        .set('paymentMethods', fromJS(action.payload));

    case ActionTypes.PAYMENT_METHOD_SET_DEFAULT_SUCCESS:
      return state.update('paymentMethods', (paymentMethods) =>
        paymentMethods.map((paymentMethod) => {
          return paymentMethod.set(
            'default',
            paymentMethod.get('id') === action.payload.id
          );
        })
      );

    case ActionTypes.PAYMENT_METHOD_DELETE_SUCCESS:
      return state.update('paymentMethods', (paymentMethods) =>
        paymentMethods.filter((paymentMethod) => {
          return paymentMethod.get('id') !== action.payload.id;
        })
      );

    case ActionTypes.INVOICES_GET:
      return state.setIn(['pending', 'invoices'], true).set('invoices', List());
    case ActionTypes.INVOICES_GET_SUCCESS:
      return state
        .setIn(['pending', 'invoices'], false)
        .set('invoices', fromJS(action.payload));
    case ActionTypes.INVOICES_GET_FAIL:
      return state
        .setIn(['pending', 'invoices'], false)
        .set('invoices', List());

    case ActionTypes.STRIPE_LOAD_SUCCESS:
      return state.setIn(['stripe'], action.payload);

    case ActionTypes.UPDATE_CRUMBS:
      return state.set('crumbs', action.payload.crumbs || []);

    case ActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
}
