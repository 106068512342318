import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';

import Landing from '@/screens/Landing';
import Register from '@/screens/Register';

const RootApp = () => {
  return (
    <Switch>
      <Route path="/register" component={Register} />
      <Route exact path="/" component={Landing} />
      <Route push render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default RootApp;
