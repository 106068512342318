import { of, concat } from 'rxjs';
import { switchMap, flatMap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';
import { addNotification } from '@/reducers/global';

import ActionTypes from './actionTypes';

export const inviteMember$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.INVITE_POST),
    switchMap(({ payload: { email } }) =>
      ajax(
        {
          url: `/invites`,
          method: 'POST',
          body: { email }
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(res =>
          concat(
            of({
              type: ActionTypes.INVITE_POST_SUCCESS,
              payload: res
            }),
            of(
              addNotification({
                message: `Successfully invited "${email}"`,
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, error => {
          let message = 'Unable to send your invite';
          if (
            error.status === 409 &&
            error.response.errors &&
            error.response.errors.email
          ) {
            message = error.response.errors.email;
          }

          return concat(
            of(
              addNotification({
                message: message,
                options: { variant: 'error' }
              })
            ),
            of({
              type: ActionTypes.INVITE_POST_FAIL
            })
          );
        })
      )
    )
  );
export default [inviteMember$];
