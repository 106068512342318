import { connect } from 'react-redux';
import {
  toggleHealthcheckModal,
  createHealthcheck,
  updateHealthcheck
} from '@/screens/Service/ducks/actions';
import { NS } from '@/screens/Service/ducks/reducer';
import HealthcheckForm from './HealthcheckForm';

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service']),
  record: state.getIn([NS, 'healthcheck']),
  visible: state.getIn([NS, 'healthcheckModalVisible']),
  runners: state.getIn([NS, 'runners'])
});

const mapDispatchToProps = dispatch => ({
  create: (service, payload) => dispatch(createHealthcheck(service, payload)),
  update: payload => dispatch(updateHealthcheck(payload)),
  hide: () => dispatch(toggleHealthcheckModal(false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthcheckForm);
