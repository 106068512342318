import { connect } from 'react-redux';
import {
  getServiceHealthchecks,
  toggleHealthcheckModal,
  updateCrumbs
} from '@/screens/Service/ducks/actions';
// import { getFormattedHealthchecks } from '@/screens/Service/ducks/selectors';
import { NS } from '@/screens/Service/ducks/reducer';
import HealthChecks from './HealthChecks';

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service']),
  healthchecks: state.getIn([NS, 'healthchecks']),
  canEdit: state.getIn([NS, 'team', 'is_member'], false),
  pending: state.getIn([NS, 'pending', 'healthchecks'])
});

const mapDispatchToProps = dispatch => ({
  getServiceHealthchecks: serviceId =>
    dispatch(getServiceHealthchecks(serviceId)),
  toggleHealthcheckModal: () => dispatch(toggleHealthcheckModal(true)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthChecks);
