import { createTypes, asyncType, createNS } from '@/utils/actionCreator';

export const NS = createNS('CREATE_SERVICE');

export const ActionTypes = createTypes(
  [
    ...asyncType('SERVICE_CREATE'),
    ...asyncType('TEAMS_GET'),
    ...asyncType('TEAMS_SEARCH'),
    ...asyncType('VALIDATE_SERVICE_HANDLE'),
    'RESET'
  ],
  NS
);

export default ActionTypes;
