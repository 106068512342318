import { connect } from 'react-redux';
import { NS } from '@/screens/Service/ducks/reducer';
import { getFormattedServiceHealth } from '@/screens/Service/ducks/selectors';
import {
  getDailyServiceHealthChecks,
  toggleDependencyModal,
  toggleRemoteDependencyModal,
  deleteDependency,
  removeRemoteDependency,
  updateCrumbs
} from '@/screens/Service/ducks/actions';

import Overview from './Overview';
import './Overview.scss';

const mapStateToProps = state => ({
  pending: state.getIn([NS, 'pending']),
  service: state.getIn([NS, 'service']),
  team: state.getIn([NS, 'team']),
  canEdit: state.getIn([NS, 'team', 'is_member'], false),
  health: state.getIn([NS, 'health']),
  links: state.getIn([NS, 'links']),
  dependencies: state.getIn([NS, 'dependencies']),
  remoteDependencies: state.getIn([NS, 'remoteDependencies']),
  dependents: state.getIn([NS, 'dependents']),
  serviceHealth: getFormattedServiceHealth(state)
});
const mapDispatchToProps = dispatch => ({
  getDailyServiceHealthChecks: (id, from) =>
    dispatch(getDailyServiceHealthChecks(id, from)),
  toggleDependencyModal: () => dispatch(toggleDependencyModal({ show: true })),
  toggleRemoteDependencyModal: () =>
    dispatch(toggleRemoteDependencyModal(true)),
  deleteDependency: (id, dependencyId) =>
    dispatch(deleteDependency(id, dependencyId)),
  removeRemoteDependency: (service, workspace, remote) =>
    dispatch(removeRemoteDependency(service, workspace, remote)),
  // getNotices: id => dispatch(getNotices(id))
  updateCrumbs: payload => dispatch(updateCrumbs(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview);
