import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NS } from '@/screens/Service/ducks/reducer';

import {
  getDraftReleaseNote,
  createDraftReleaseNote,
  updateDraftReleaseNote,
  publishDraftReleaseNote,
  unpublishDraftReleaseNote,
  saveAndPublishDraftReleaseNote,
  resetDraftReleaseNote,
  updateCrumbs
} from '@/screens/Service/ducks/actions';
import { DraftPage } from '../../components/DraftPage';

export const DraftReleaseNote = props => {
  const { updateCrumbs } = props;
  useEffect(() => {
    updateCrumbs([
      {
        label: 'Drafts Announcements'
      }
    ]);
  }, []);
  return <DraftPage {...props} />;
};

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service']),
  draft: state.getIn([NS, 'draftReleaseNote']),
  pending: state.getIn([NS, 'pending', 'draftReleaseNote'])
});
const mapDispatchToProps = dispatch => ({
  get: draftId => dispatch(getDraftReleaseNote(draftId)),
  create: (serviceId, draft) =>
    dispatch(createDraftReleaseNote(serviceId, draft)),
  update: (draftId, draft) => dispatch(updateDraftReleaseNote(draftId, draft)),
  publish: draftId => dispatch(publishDraftReleaseNote(draftId)),
  unpublish: draftId => dispatch(unpublishDraftReleaseNote(draftId)),
  saveAndPublish: (draftId, page) =>
    dispatch(saveAndPublishDraftReleaseNote(draftId, page)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs)),

  reset: () => dispatch(resetDraftReleaseNote())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraftReleaseNote);
