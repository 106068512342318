import React from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

export default props => {
  const {
    loading,
    service,
    visible,
    searchServices,
    filteredDependencies,
    hide,
    addDependency
  } = props;

  const { control, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: { service: null }
  });

  const { isValid } = formState;

  const onSearch = (event, value) => {
    if (value) {
      searchServices(value);
    }
  };

  const onSubmit = values => {
    const handle = service.get('handle');
    addDependency({
      handle,
      dependency: values.service.handle
    });
    hide();
  };

  const handle = service.get('handle');
  const results = filteredDependencies.size
    ? filteredDependencies.toJS().filter(_service => _service.handle !== handle)
    : [];

  return (
    <Dialog open={visible} onClose={hide}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle> Add Dependency</DialogTitle>

        {/* <Autocomplete
            id="services-autocomplete"
            label="Select some services"
            data={results}
            dataLabel="name"
            dataValue="username"
            onChange={this.handleSearch}
            onAutocomplete={this.handleAutocomplete}
            clearOnAutocomplete
            deleteKeys="abbreviation"
            fixedTo={window}
          />
          <Control model={`.dependencies`} component={Services} /> */}
        <DialogContent>
          <Controller
            name="service"
            control={control}
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <Autocomplete
                style={{ width: 300 }}
                autoComplete
                options={results}
                getOptionLabel={option => option.name || ''}
                getOptionSelected={(option, value) =>
                  option.handle === value.handle
                }
                loading={loading}
                onInputChange={onSearch}
                onChange={(e, v) => onChange(v)}
                onBlur={onBlur}
                value={value}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Find service"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={hide} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={!isValid}>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
// }
