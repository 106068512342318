import React from 'react';

import './Landing.scss';

export default () => {
  return (
    <div className="Landing">
      <div className="Landing__logo">
        <span className="Landing__logo-text">Visr</span>
      </div>
    </div>
  );
};
