import React, { PureComponent } from 'react';
import { Button, Card, CardHeader, Box, Typography } from '@material-ui/core';

import ChannelForm from '../ChannelForm';

class Channel extends PureComponent {
  state = { editing: false };

  editChannel = () => {
    this.setState({ editing: true });
  };

  deleteChannel = () => {
    const { service, channel, deleteChannel } = this.props;
    deleteChannel(service.get('handle'), channel.get('id'));
  };

  handleSave = (channelId, channel) => {
    const { service, edit } = this.props;

    edit(service.get('handle'), channelId, channel);
    this.setState({ editing: false });
  };

  handleCancel = () => {
    this.setState({ editing: false });
  };

  render() {
    const { channel, className } = this.props;
    const { editing } = this.state;

    if (editing) {
      return (
        <ChannelForm
          channel={channel}
          onSave={this.handleSave}
          onCancel={this.handleCancel}
        />
      );
    }

    return (
      <Card variant="outlined">
        <CardHeader
          title={
            <Typography variant="h6">
              #{channel.getIn(['slack_channel', 'id'])}{' '}
            </Typography>
          }
          subheader={channel.getIn(['provider'])}
          action={
            <Box>
              {' '}
              <Button
                variant="contained"
                color="primary"
                onClick={this.editChannel}
                style={{ marginRight: '10px' }}
              >
                Edit
              </Button>
              <Button variant="contained" onClick={this.deleteChannel}>
                Delete
              </Button>
            </Box>
          }
        />
      </Card>
    );
  }
}

export default Channel;
