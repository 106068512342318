import React, { PureComponent, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Avatar,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Icon,
  InputBase,
  Link,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';

import { Main, Container, TablePagination, SearchInput } from '@/components';

import InviteMenu from './components/InviteMenu';

const TableInput = withStyles((theme) => ({
  input: {
    border: '1px solid transparent',
  },
}))(InputBase);

const UserRows = ({
  users,
  loading,
  canEdit,
  currentUserRole,
  onRoleChange,
}) => {
  const handleRoleChange = (user, role) => {
    onRoleChange(user, role);
  };

  if (loading) {
    return (
      <TableRow>
        <TableCell
          colSpan={5}
          align="center"
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <CircularProgress size={27} />
        </TableCell>
      </TableRow>
    );
  }

  if (!users.size) {
    return (
      <TableRow>
        <TableCell colSpan={5} align="center">
          No Users.
        </TableCell>
      </TableRow>
    );
  }

  return users.map((user) => {
    const name = user.get('name');
    const handle = user.get('handle');
    const initial = name.charAt(0).toUpperCase();
    const icon = user.get('thumbnail_url');
    const last_login = user.get('last_login');
    const role = user.get('role');
    const roleDisplay = role.charAt(0).toUpperCase() + role.slice(1);
    return (
      <TableRow key={handle}>
        <TableCell>
          <Avatar alt={name} src={icon}>
            {initial}
          </Avatar>
        </TableCell>
        <TableCell>
          <Link component={RouterLink} to={`/users/${handle}`}>
            {name}
          </Link>
        </TableCell>
        <TableCell>{user.get('description')}</TableCell>
        {canEdit && role !== 'owner' && currentUserRole === 'admin' ? (
          <TableCell>
            <Select
              defaultValue={role}
              onChange={(event) =>
                handleRoleChange(user.get('handle'), event.target.value)
              }
              input={<TableInput />}
            >
              <MenuItem value="guest">Guest</MenuItem>
              <MenuItem value="member">Member</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              {currentUserRole === 'owner' ? (
                <MenuItem value="owner">Owner</MenuItem>
              ) : (
                <MenuItem value="owner" disabled>
                  Owner
                </MenuItem>
              )}
            </Select>
          </TableCell>
        ) : (
          <TableCell>{roleDisplay}</TableCell>
        )}
        <TableCell>
          {last_login ? <Moment>{last_login}</Moment> : '-'}
        </TableCell>
      </TableRow>
    );
  });
};

const InviteRows = ({ invites, loading }) => {
  if (loading) {
    return (
      <TableRow>
        <TableCell colSpan={3}>
          <CircularProgress id="InvitesLoading" />
        </TableCell>
      </TableRow>
    );
  }

  if (!invites.size) {
    return (
      <TableRow>
        <TableCell colSpan={3}>No Invites.</TableCell>
      </TableRow>
    );
  }

  return invites.map((invite) => {
    const id = invite.get('id');
    return (
      <TableRow key={id}>
        <TableCell>{invite.get('email')}</TableCell>
        <TableCell>
          <Moment fromNow>{invite.get('last_sent')}</Moment>
        </TableCell>
        <TableCell>
          <InviteMenu invite={invite} />
        </TableCell>
      </TableRow>
    );
  });
};

export default (props) => {
  const {
    users,
    invites,
    loading,
    total,
    role,
    getUsers,
    getInvites,
    updateUserRole,
  } = props;

  const [isAdmin, setIsAdmin] = useState(false);
  const [limit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState('');
  const [showPending, setShowPending] = useState(false);

  useEffect(() => {
    if (role === 'owner' || role === 'admin') {
      setIsAdmin(true);
    }
  }, [role]);

  useEffect(() => {
    !showPending
      ? getUsers({ cursor: offset, limit, q: query })
      : getInvites({ cursor: offset, limit, q: query });
  }, [limit, offset, query, showPending]);

  const handleSearch = (q) => {
    setQuery(q);
    setOffset(0);
  };

  const updateOffset = (offset) => {
    setOffset(offset);
  };

  const onTypeChange = (event) => {
    setShowPending(event.target.checked);
  };

  const onRoleChange = (user, role) => {
    updateUserRole(user, role);
  };

  return (
    <Container>
      {' '}
      <Main>
        <div>
          <SearchInput onSearch={handleSearch} />

          <Paper>
            <Toolbar>
              <Typography style={{ flex: '1' }}>Users</Typography>

              {isAdmin ? (
                <FormControlLabel
                  control={
                    <Switch
                      checked={showPending}
                      onChange={onTypeChange}
                      name="pending"
                    />
                  }
                  label="Pending Users"
                />
              ) : null}
            </Toolbar>
            <Divider />
            <TableContainer>
              {!showPending ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '72px' }} />
                      <TableCell style={{ width: '25%' }}>Name</TableCell>
                      <TableCell>Bio</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell style={{ width: '25%' }}>Last Login</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <UserRows
                      users={users}
                      loading={loading}
                      onRoleChange={onRoleChange}
                      canEdit={isAdmin}
                      currentUserRole={role}
                    />
                  </TableBody>
                </Table>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Last Sent</TableCell>
                      <TableCell style={{ width: '72px' }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <InviteRows invites={invites} loading={loading} />
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <TablePagination
              limit={limit}
              offset={offset}
              total={total}
              updateOffset={updateOffset}
            />
          </Paper>
        </div>
      </Main>
    </Container>
  );
  // }
};
