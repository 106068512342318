import ActionTypes from './actionTypes';

import { createAction } from '@/utils/actionCreator';

export const clearError = createAction(ActionTypes.CLEAR_ERROR);

export const forgotPassword = createAction(
  ActionTypes.FORGOT_PASSWORD,
  'email'
);

export const reset = createAction(ActionTypes.RESET);
