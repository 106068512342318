import React, { useEffect, useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Divider,
} from '@material-ui/core';

export const RunnerForm = (props) => {
  const { runner, getRunner, cancel, save } = props;

  useEffect(() => {
    if (runner.get('handle')) {
      getRunner(runner);
    }
  }, []);
  console.log(runner);

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      name: runner.get('name', ''),
      description: runner.get('description', ''),
    },
  });
  const { control, formState, handleSubmit, reset } = form;

  const onSubmit = useCallback((values) => {
    save(runner, { ...values });
    reset(values);
  });

  const { isValid, isDirty } = formState;

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="Runner"></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                label="Name"
                name="name"
                as={TextField}
                control={control}
                autoComplete="off"
                fullWidth
                rules={{
                  required: true,
                }}
              />
            </Grid>{' '}
            <Grid item xs={12}>
              <Controller
                label="Description"
                name="description"
                as={TextField}
                control={control}
                autoComplete="off"
                fullWidth
                rules={{
                  required: true,
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button onClick={cancel}>Done</Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!isValid || !isDirty}
          >
            Save Runner
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export const RunnerList = (props) => {
  const { runners, editRunner, deleteRunner } = props;
  if (runners.size) {
    return (
      <List>
        {runners.map((runner, index) => (
          <React.Fragment key={runner.get('handle')}>
            <ListItem>
              <ListItemText
                primary={runner.get('name')}
                secondary={runner.get('description')}
              />
              <ListItemSecondaryAction>
                <Button color="secondary" onClick={() => deleteRunner(runner)}>
                  Delete
                </Button>{' '}
                <Button color="primary" onClick={() => editRunner(runner)}>
                  Edit
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
            {index + 1 !== runner.size ? <Divider component="li" /> : null}
          </React.Fragment>
        ))}
      </List>
    );
  }
  return <Typography variant="body2">No Runners</Typography>;
};

export const Runners = (props) => {
  const {
    runners,
    runner,
    pending,
    team,
    getRunners,
    getRunner,
    createRunner,
    updateRunner,
    deleteRunner,
    setRunner,
  } = props;

  // const [runner, setRunner] = useState(Map());
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const teamId = team.get('handle');
    getRunners(teamId);
  }, []);

  const onAddRunner = useCallback(() => {
    setRunner(null);
    setEditMode(true);
  });

  const onCancel = useCallback(() => {
    setRunner(null);
    setEditMode(false);
  });

  const onEditRunner = useCallback((runner) => {
    setRunner(runner);
    setEditMode(true);
  });

  const onGetRunner = useCallback((runner) => {
    const teamId = team.get('handle');
    const runnerId = runner.get('handle');
    getRunner(teamId, runnerId);
  });

  const onDeleteRunner = useCallback((runner) => {
    const teamId = team.get('handle');
    const runnerId = runner.get('handle');
    deleteRunner(teamId, runnerId);
  });

  const onSave = useCallback((runner, values) => {
    const teamId = team.get('handle');
    if (runner.size) {
      const runnerId = runner.get('handle');
      updateRunner(teamId, runnerId, values);
    } else {
      createRunner(teamId, values);
    }
    // setEditMode(false);
  });

  if (editMode) {
    return (
      <RunnerForm
        runner={runner}
        getRunner={onGetRunner}
        save={onSave}
        cancel={onCancel}
      />
    );
  }

  return (
    <Card>
      <CardHeader title="Runners"></CardHeader>
      <CardContent>
        {pending ? (
          <CircularProgress />
        ) : (
          <RunnerList
            runners={runners}
            editRunner={onEditRunner}
            deleteRunner={onDeleteRunner}
          />
        )}
      </CardContent>
      <CardActions>
        <Button color="primary" onClick={onAddRunner}>
          Add Runner
        </Button>
      </CardActions>
    </Card>
  );
};

export default Runners;
