import { createAction } from '@/utils/actionCreator';

import ActionTypes from './actionTypes';

export const connectToSlack = createAction(
  ActionTypes.CONNECT_TO_SLACK,
  'token'
);

export const reset = createAction(ActionTypes.RESET);
