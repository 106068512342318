// export const ANNOUNCEMENT_STATUS = [
//   {
//     label: 'Open',
//     value: 'open'
//   },
//   {
//     label: 'Closed',
//     value: 'closed'
//   }
// ];

export const INCIDENT_STATUS = [
  {
    label: 'Investigating',
    value: 'investigating'
  },
  {
    label: 'Identified',
    value: 'identified'
  },
  {
    label: 'Monitoring',
    value: 'monitoring'
  },
  {
    label: 'Resolved',
    value: 'resolved'
  }
];

export const MAINTENANCE_STATUS = [
  {
    label: 'Scheduled',
    value: 'scheduled'
  },
  {
    label: 'In Progress',
    value: 'in_progress'
  },
  {
    label: 'Verifying',
    value: 'verifying'
  },
  {
    label: 'Completed',
    value: 'completed'
  }
];

export const RELEASE_STATUS = [
  {
    label: 'Announced',
    value: 'announced'
  },
  {
    label: 'Schedule',
    value: 'scheduled'
  },
  {
    label: 'Available',
    value: 'available'
  }
];
