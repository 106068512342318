import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NS } from '@/screens/Service/ducks/reducer';

import {
  getDraftOpenApiSpec,
  createDraftOpenApiSpec,
  updateDraftOpenApiSpec,
  publishDraftOpenApiSpec,
  unpublishDraftOpenApiSpec,
  saveAndPublishDraftOpenApiSpec,
  resetDraftOpenApiSpec,
  updateCrumbs
} from '@/screens/Service/ducks/actions';
import { DraftPage } from '../../components/DraftPage';

export const DraftApiDoc = props => {
  const { updateCrumbs } = props;
  useEffect(() => {
    updateCrumbs([
      {
        label: 'Drafts Open API Spec'
      }
    ]);
  }, []);
  return <DraftPage language="yml" {...props} />;
};

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service']),
  draft: state.getIn([NS, 'draftOpenApiSpec']),
  pending: state.getIn([NS, 'pending', 'draftOpenApiSpec'])
});
const mapDispatchToProps = dispatch => ({
  get: draftId => dispatch(getDraftOpenApiSpec(draftId)),
  create: (serviceId, draft) =>
    dispatch(createDraftOpenApiSpec(serviceId, draft)),
  update: (draftId, draft) => dispatch(updateDraftOpenApiSpec(draftId, draft)),
  publish: draftId => dispatch(publishDraftOpenApiSpec(draftId)),
  unpublish: draftId => dispatch(unpublishDraftOpenApiSpec(draftId)),
  saveAndPublish: (draftId, page) =>
    dispatch(saveAndPublishDraftOpenApiSpec(draftId, page)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs)),

  reset: () => dispatch(resetDraftOpenApiSpec())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraftApiDoc);
