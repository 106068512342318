import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import SwaggerUI from 'swagger-ui-react';
import YAML from 'yaml';

import 'swagger-ui-react/swagger-ui.css';

export const SwaggerPreview = ({ spec }) => {
  const [jsonSpec, setSpec] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (spec) {
      try {
        spec = YAML.parse(spec, { prettyErrors: true });
        setSpec(spec);
        setError(false);
      } catch (e) {
        setError(e.message);
      }
    }
  }, [spec]);

  return (
    <React.Fragment>
      {error ? (
        <Alert severity="error">
          <AlertTitle>Parsing Error</AlertTitle>
          {error}
        </Alert>
      ) : null}
      {jsonSpec ? (
        <div className="markdown-body">
          <SwaggerUI spec={jsonSpec} />
        </div>
      ) : (
        <h2>No config provided</h2>
      )}
    </React.Fragment>
  );
};
