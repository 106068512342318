import { connect } from 'react-redux';

import { NS } from '@/screens/Team/ducks/reducer';
import {
  getRunners,
  getRunner,
  createRunner,
  updateRunner,
  deleteRunner,
  setRunner,
} from '@/screens/Team/ducks/actions';

import Runners from './runners';

const mapStateToProps = (state) => ({
  team: state.getIn([NS, 'team']),
  runners: state.getIn([NS, 'runners']),
  runner: state.getIn([NS, 'runner']),
  pending: state.getIn([NS, 'pending', 'runner']),
});

const mapDispatchToProps = (dispatch) => ({
  getRunners: (teamId) => dispatch(getRunners(teamId)),
  getRunner: (teamId, runnerId) => dispatch(getRunner(teamId, runnerId)),
  createRunner: (teamId, runner) => dispatch(createRunner(teamId, runner)),
  updateRunner: (teamId, runnerId, client) =>
    dispatch(updateRunner(teamId, runnerId, client)),
  deleteRunner: (teamId, runnerId) => dispatch(deleteRunner(teamId, runnerId)),
  setRunner: (runner) => dispatch(setRunner(runner)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Runners);
