import { connect } from 'react-redux';

import { NS } from '@/screens/Service/ducks/reducer';
import { getOverviewAnnouncements } from '@/screens/Service/ducks/actions';

import Announcements from './Announcements';
import './Announcements.scss';

const mapStateToProps = state => ({
  announcements: state.getIn([NS, 'overviewAnnouncements']),
  service: state.getIn([NS, 'service']),
  pending: state.getIn([NS, 'pending', 'overviewAnnouncements'])
});

const mapDispatchToProps = dispatch => ({
  getAnnouncements: id => dispatch(getOverviewAnnouncements(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Announcements);
