import { Map, List, fromJS } from 'immutable';

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  success: false,
  error: false,
  services: List()
});

export default function ServicesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_SERVICES:
      return state.set('loading', true).set('error', false);
    case ActionTypes.GET_SERVICES_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('services', fromJS(action.payload));
    case ActionTypes.GET_SERVICES_FAIL:
      return state.set('error', true).set('loading', false);
    case ActionTypes.RESET:
      return initialState;
    default:
      return state;
  }
}
