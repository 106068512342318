import { connect } from 'react-redux';
import ServiceMenu from './ServiceMenu';
import {
  toggleHealthcheckModal,
  toggleNoticeModal,
  toggleDependencyModal
} from '@/screens/Service/ducks/actions';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  toggleHealthcheckModal: () => dispatch(toggleHealthcheckModal(true)),
  toggleNoticeModal: () => dispatch(toggleNoticeModal({ show: true })),
  toggleDependencyModal: () => dispatch(toggleDependencyModal({ show: true }))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceMenu);
