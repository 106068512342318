import { Map, List, fromJS } from 'immutable';

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  success: Map({
    register: false,
    workspace: false
  }),
  error: Map({
    register: false,
    workspace: false
  }),
  submitting: Map({
    register: false,
    workspace: false
  }),
  email: null,
  workspace: null
});

export default function AdminReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.REGISTER:
      return state
        .setIn(['submitting', 'register'], true)
        .setIn(['error', 'register'], false)
        .set('email', action.payload.email);

    case ActionTypes.REGISTER_SUCCESS:
      return state
        .setIn(['submitting', 'register'], true)
        .setIn(['success', 'register'], true)
        .setIn(['error', 'register'], false);

    case ActionTypes.REGISTER_FAIL:
      return state
        .setIn(['submitting', 'register'], false)
        .setIn(['success', 'register'], false)
        .setIn(['error', 'register'], action.payload.message);

    case ActionTypes.WORKSPACE_CREATE:
      return state
        .setIn(['submitting', 'workspace'], true)
        .setIn(['error', 'workspace'], false);

    case ActionTypes.WORKSPACE_CREATE_SUCCESS:
      return state
        .setIn(['submitting', 'workspace'], false)
        .setIn(['success', 'workspace'], true)
        .setIn(['error', 'workspace'], false)
        .set('workspace', action.payload.domain);

    case ActionTypes.WORKSPACE_CREATE_FAIL:
      const message = action.payload.errors
        ? Object.values(action.payload.errors)[0][0]
        : action.payload.message;

      return state
        .setIn(['submitting', 'workspace'], false)
        .setIn(['success', 'workspace'], false)
        .setIn(['error', 'workspace'], message);

    case ActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
}
