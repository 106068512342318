import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { NS } from '@/screens/Service/ducks/reducer';
import ServiceGraph from './Graph';
import { getServiceGraph, updateCrumbs } from '@/screens/Service/ducks/actions';

import './Graph.scss';

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service']),
  loading: state.getIn([NS, 'pending', 'graph']),
  graph: state.getIn([NS, 'graph'])
});

const mapDispatchToProps = dispatch => ({
  getServiceGraph: (serviceHandle, depth) =>
    dispatch(getServiceGraph(serviceHandle, depth)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceGraph);
