import { Map } from 'immutable';

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  complete: false,
  error: false
});

export default function ConnectToSlackReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CONNECT_TO_SLACK:
      return state.set('complete', false);
    case ActionTypes.CONNECT_TO_SLACK_SUCCESS:
    case ActionTypes.CONNECT_TO_SLACK_FAIL:
      return state.set('complete', true);
    case ActionTypes.RESET:
      return initialState;
    default:
      return state;
  }
}
