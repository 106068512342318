import { addNotification } from '@/reducers/global';
import { ajax, catchApiError } from '@/utils/ajax';
import { ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { flatMap, map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import queryString from 'query-string';
import ActionTypes from '../actionTypes';

export const createHealthcheck$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.HEALTHCHECK_CREATE),
    switchMap(({ payload: { service, body } }) =>
      ajax(
        {
          url: `/services/${service}/health-checks`,
          method: 'POST',
          body
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.HEALTHCHECK_CREATE_SUCCESS,
              payload: payload
            }),
            of(
              addNotification({
                message: 'Service Healhcheck Added',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.HEALTHCHECK_CREATE_FAIL
            }),
            of(
              addNotification({
                message: 'Unable to create healthcheck',
                options: { variant: 'success' }
              })
            )
          )
        )
      )
    )
  );

export const updateHealthcheck$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.HEALTHCHECK_UPDATE),
    switchMap(({ payload: { id, body } }) =>
      ajax(
        {
          url: `/health-checks/${id}`,
          method: 'PATCH',
          body
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.HEALTHCHECK_UPDATE_SUCCESS,
              payload: payload
            }),
            of(
              addNotification({
                message: 'Service Healhcheck Updated',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.HEALTHCHECK_UPDATE_FAIL
          })
        )
      )
    )
  );

export const getDailyServiceHealthChecks$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_HEALH_CHECK_DAILY_GET),
    switchMap(({ payload: { id, ...rest } }) =>
      ajax(
        {
          url: `/services/${id}/reports/service-health-daily?${queryString.stringify(
            rest || {}
          )}`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.SERVICE_HEALH_CHECK_DAILY_GET_SUCCESS,
              payload: payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_HEALH_CHECK_DAILY_GET_FAIL
          })
        )
      )
    )
  );

export const getHealthCheckResults$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICE_HEALTH_CHECK_RESULTS_GET),
    pluck('payload', 'id'),
    mergeMap(id =>
      ajax(
        {
          url: `/health-checks/${id}/reports/health-check-results`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(data =>
          concat(
            of({
              type: ActionTypes.SERVICE_HEALTH_CHECK_RESULTS_GET_SUCCESS,
              payload: { data, id }
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICE_HEALTH_CHECK_RESULTS_GET_FAIL
          })
        )
      )
    )
  );

export default [
  createHealthcheck$,
  updateHealthcheck$,
  getDailyServiceHealthChecks$,
  getHealthCheckResults$
];
