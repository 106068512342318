import { of, concat, catchError } from 'rxjs';
import { switchMap, flatMap, map, pluck, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';
import { addNotification } from '@/reducers/global';

import ActionTypes, { NS } from '../actionTypes';

export const getPublishedPages$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.PUBLISHED_OPEN_API_SPECS_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.serviceHandle}/open-api-specs`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.PUBLISHED_OPEN_API_SPECS_GET_SUCCESS,
              payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.PUBLISHED_OPEN_API_SPECS_GET_FAIL
          })
        )
      )
    )
  );

export const getPublishedPage$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.PUBLISHED_OPEN_API_SPEC_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/open-api-specs/${payload.pageId}/pages/openapi.yml`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.PUBLISHED_OPEN_API_SPEC_GET_SUCCESS,
              payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.PUBLISHED_OPEN_API_SPEC_GET_FAIL
          })
        )
      )
    )
  );

export const getDrafts$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_OPEN_API_SPECS_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.serviceHandle}/open-api-spec-drafts`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_OPEN_API_SPECS_GET_SUCCESS,
              payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.DRAFT_OPEN_API_SPECS_GET_FAIL
          })
        )
      )
    )
  );

export const getDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_OPEN_API_SPEC_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/open-api-spec-drafts/${payload.draftId}`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        switchMap(draft =>
          ajax(
            {
              url: `/open-api-spec-drafts/${payload.draftId}/pages/openapi.yml`,
              method: 'GET'
            },
            { action$, state$ }
          ).pipe(
            pluck('response'),
            flatMap(payload =>
              concat(
                of({
                  type: ActionTypes.DRAFT_OPEN_API_SPEC_GET_SUCCESS,
                  payload: { ...payload, title: draft.version }
                })
              )
            ),
            catchApiError(action$, () =>
              of({
                type: ActionTypes.DRAFT_OPEN_API_SPEC_GET_FAIL
              })
            )
          )
        )
      )
    )
  );

//

export const createDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_OPEN_API_SPEC_CREATE),
    switchMap(({ payload: { serviceHandle, draft } }) =>
      ajax(
        {
          url: `/services/${serviceHandle}/open-api-spec-drafts`,
          method: 'POST',
          body: { version: draft.title }
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        switchMap(project =>
          ajax(
            {
              url: `/open-api-spec-drafts/${project.id}/pages/openapi.yml`,
              method: 'PATCH',
              body: { ...draft, lock: 1 }
            },
            { action$, state$ }
          ).pipe(
            pluck('response'),
            map(res => ({ ...res, id: project.id }))
          )
        ),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_OPEN_API_SPEC_CREATE_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Created Draft',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, err => {
          if (err.status === 422) {
            return concat(
              of(
                addNotification({
                  message: 'The handle has already been taken.',
                  options: { variant: 'error' }
                })
              ),
              of({ type: ActionTypes.DRAFT_OPEN_API_SPEC_CREATE_FAIL })
            );
          }
          return of({ type: ActionTypes.DRAFT_OPEN_API_SPEC_CREATE_FAIL });
        })
      )
    )
  );

export const updateDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_OPEN_API_SPEC_UPDATE),
    switchMap(({ payload: { draftId, draft } }) =>
      ajax(
        {
          url: `/open-api-spec-drafts/${draftId}/pages/openapi.yml`,
          method: 'PATCH',
          body: draft
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(({title,...rest}) =>
          concat(
            of({
              type: ActionTypes.DRAFT_OPEN_API_SPEC_UPDATE_SUCCESS,
              payload: rest
            }),
            of(
              addNotification({
                message: 'Updated Draft',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, err => {
          if (err.status === 422) {
            return concat(
              of(
                addNotification({
                  message: 'The handle has already been taken.',
                  type: 'error'
                })
              ),
              of({ type: ActionTypes.DRAFT_OPEN_API_SPEC_UPDATE_FAIL })
            );
          }
          return of({ type: ActionTypes.DRAFT_OPEN_API_SPEC_UPDATE_FAIL });
        })
      )
    )
  );

export const deleteDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_OPEN_API_SPEC_DELETE),
    switchMap(({ payload: { draftId } }) =>
      ajax(
        {
          url: `/open-api-spec-drafts/${draftId}`,
          method: 'DELETE'
        },
        { action$, state$ }
      ).pipe(
        flatMap(() =>
          concat(
            of({
              type: ActionTypes.DRAFT_OPEN_API_SPEC_DELETE_SUCCESS,
              payload: null
            }),
            of({
              type: ActionTypes.DRAFT_OPEN_API_SPECS_GET,
              payload: {
                serviceHandle: state$.value.getIn([NS, 'service', 'handle'])
              }
            }),
            of(
              addNotification({
                message: 'Deleted Draft',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, error => {
          return of({
            type: ActionTypes.DRAFT_OPEN_API_SPEC_DELETE_FAIL
          });
        })
      )
    )
  );

export const publishDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_OPEN_API_SPEC_PUBLISH),
    switchMap(({ payload: { draftId } }) =>
      ajax(
        {
          url: `/open-api-spec-drafts/${draftId}/actions/publish`,
          method: 'POST'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_OPEN_API_SPEC_PUBLISH_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Published Draft',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.DRAFT_OPEN_API_SPEC_PUBLISH_FAIL
          })
        )
      )
    )
  );
export const unpublishDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_OPEN_API_SPEC_UNPUBLISH),
    switchMap(({ payload: { draftId } }) =>
      ajax(
        {
          url: `/open-api-spec-drafts/${draftId}/actions/unpublish`,
          method: 'POST'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_OPEN_API_SPEC_UNPUBLISH_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Unpublished Draft',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.DRAFT_OPEN_API_SPEC_UNPUBLISH_FAIL
          })
        )
      )
    )
  );

export const saveAndPublishDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_OPEN_API_SPEC_UPDATE_PUBLISH),
    switchMap(({ payload: { draftId, draft } }) =>
      action$.pipe(
        ofType(ActionTypes.DRAFT_OPEN_API_SPEC_UPDATE_SUCCESS),
        switchMap(() =>
          concat(
            of({
              type: ActionTypes.DRAFT_OPEN_API_SPEC_PUBLISH,
              payload: { draftId }
            })
          )
        ),
        startWith({
          type: ActionTypes.DRAFT_OPEN_API_SPEC_UPDATE,
          payload: { draftId, draft }
        })
      )
    )
  );

export default [
  getPublishedPages$,
  getPublishedPage$,
  getDrafts$,
  getDraft$,
  createDraft$,
  updateDraft$,
  deleteDraft$,
  publishDraft$,
  unpublishDraft$,
  saveAndPublishDraft$
];
