import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';

import {
  Card,
  Button,
  CircularProgress,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Avatar,
  Chip,
  Typography
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import {
  Main,
  Container,
  ConfirmationModal,
  Sidenav,
  Breadcrumb,
  PublicFeed
} from '@/components';

const useStyles = makeStyles(theme => ({
  group: {
    marginBottom: theme.spacing(2)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: theme.spacing(1),
      '&:last-child': {
        marginRight: 0
      }
    }
  }
}));

export default props => {
  const {
    pending,
    user,
    me,
    deleted,
    getUserFeed,
    deleteUser,
    feed,
    feedPending,
    updateCrumbs
  } = props;
  const params = useParams();
  const styles = useStyles();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(() => {
    const { id } = params;
    getUserFeed(id);
    updateCrumbs([]);
  }, []);

  const handleShowConfirmDelete = useCallback(() => {
    setShowConfirmDelete(true);
  });

  const handleHideConfirmDelete = useCallback(() => {
    setShowConfirmDelete(false);
  });

  const handleConfirmDelete = useCallback(() => {
    setShowConfirmDelete(false);

    deleteUser(user.get('handle'));
  });

  if (deleted) {
    <Redirect to="/users" />;
  }

  if (pending) return <CircularProgress />;

  const icon = user.get('thumbnail_url');
  const name = user.get('name', '');
  const initial = name.charAt(0).toUpperCase();

  const canDelete =
    me.get('super_admin') && me.get('handle') !== user.get('handle');

  return (
    <React.Fragment>
      <Card style={{ marginBottom: '20px' }}>
        <CardHeader
          title={user.get('name', '')}
          subheader={user.get('email', '')}
          avatar={
            <Avatar src={icon} role="presentation">
              {initial}
            </Avatar>
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <div className={styles.group}>
                <Typography variant="h6">Bio</Typography>
                <div>{user.get('bio') || 'Not set'}</div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.group}>
                <Typography variant="h6">Profile</Typography>
                <div>{user.get('profile') || 'Not set'}</div>
              </div>
            </Grid>
          </Grid>
        </CardContent>

        {canDelete ? (
          <CardActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleShowConfirmDelete}
            >
              Delete User
            </Button>
          </CardActions>
        ) : null}
        <ConfirmationModal
          title={'Delete User'}
          visible={showConfirmDelete}
          text={'Are you sure you want to delete this user?'}
          confirm={handleConfirmDelete}
          hide={handleHideConfirmDelete}
        />
      </Card>
      <PublicFeed feed={feed} loading={feedPending} />
    </React.Fragment>
  );
};
