import React, { useState, useCallback, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const toApi = (value) => {
  return value == 'false' || value == 'true' ? Boolean(value) : value;
};
const fromApi = (value) => {
  return value == false || value == true ? String(value) : value;
};

const initialState = {
  name: '',
  endpoint: '',
  runner: '',
  frequency: 15,
  allowed_failures: 0,
  importance: 'warning',
  disabled: 'false',
  paused: 'false',
  timeout: 1,
};

export default (props) => {
  const { visible, record, service, runners, create, update, hide } = props;

  const {
    control,
    handleSubmit,
    formState,
    reset: resetForm,
  } = useForm({
    mode: 'onChange',
    defaultValues: { ...initialState },
  });

  const classes = useStyles();

  useEffect(() => {
    if (record) {
      const state = Object.keys(initialState).reduce(
        (state, key) => ({
          ...state,
          [key]: fromApi(record.get(key, initialState[key])),
        }),
        {}
      );

      resetForm(state, { isDirty: false });
    } else {
      resetForm({ ...initialState }, { isDirty: false });
    }
  }, [record]);

  const { isValid } = formState;

  const onSubmit = (values) => {
    const body = Object.entries(values).reduce(
      (state, [key, value]) => ({ ...state, [key]: toApi(value) }),
      {}
    );

    if (body.runner === '') {
      delete body.runner;
    }

    if (record) {
      const id = record.get('id');
      update({ id, body });
      hide();
    } else {
      const { disabled, paused, ...rest } = body;
      create(service.get('handle'), rest);
      hide();
    }
  };

  return (
    <Dialog open={visible} onClose={hide}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Health Check</DialogTitle>
        <DialogContent>
          <Controller
            className={classes.formControl}
            label="Name"
            placeholder=""
            name="name"
            as={TextField}
            control={control}
            rules={{ required: true }}
            autoComplete="off"
            fullWidth
          />
          <Controller
            className={classes.formControl}
            label="Endpoint"
            name="endpoint"
            as={TextField}
            control={control}
            rules={{ required: true }}
            type={'url'}
            autoComplete="off"
            fullWidth
          />
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel shrink id="heath-check-runner">
              Runner
            </InputLabel>
            <Controller
              labelId="heath-check-runner"
              label="Runner"
              name="runner"
              as={Select}
              control={control}
              displayEmpty
            >
              <MenuItem value={''}>Shared Runner</MenuItem>
              {runners.map((runner) => (
                <MenuItem key={runner.id} value={runner.handle}>
                  {runner.name}
                </MenuItem>
              ))}
            </Controller>
          </FormControl>
          <Controller
            className={classes.formControl}
            label="Frequency"
            name="frequency"
            as={TextField}
            control={control}
            rules={{ required: true }}
            type="number"
            min={1}
            step={1}
            autoComplete="off"
            fullWidth
          />
          <Controller
            className={classes.formControl}
            label="Allowed Failures"
            name="allowed_failures"
            as={TextField}
            rules={{ required: true }}
            type="number"
            control={control}
            min={0}
            step={1}
            fullWidth
          />
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="heath-check-importance">Importance</InputLabel>
            <Controller
              labelId="heath-check-importance"
              label="Importance"
              name="importance"
              rules={{ required: true }}
              as={Select}
              control={control}
            >
              <MenuItem value="warning">Warning</MenuItem>
              <MenuItem value="error">Error</MenuItem>
              <MenuItem value="critical">Critical</MenuItem>
            </Controller>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="heath-check-disabled">Disabled</InputLabel>
            <Controller
              labelId="heath-check-disabled"
              name="disabled"
              as={Select}
              control={control}
              rules={{ required: true }}
              fullWidth
            >
              <MenuItem value={`true`}>Yes</MenuItem>
              <MenuItem value={`false`}>No</MenuItem>
            </Controller>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="heath-check-paused">Paused</InputLabel>
            <Controller
              labelId="heath-check-paused"
              name="paused"
              as={Select}
              control={control}
              rules={{ required: true }}
              fullWidth
            >
              <MenuItem value={`true`}>Yes</MenuItem>
              <MenuItem value={`false`}>No</MenuItem>
            </Controller>
          </FormControl>

          <Controller
            className={classes.formControl}
            label="Timeout"
            name={`timeout`}
            as={TextField}
            control={control}
            rules={{ required: true }}
            type="number"
            min="1"
            max="15"
            step="1"
            autoComplete="off"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={hide}>
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={!isValid}
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
