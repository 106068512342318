import { connect } from 'react-redux';
import { login, forgotPassword, clearError, reset } from './actions';
import { NS } from './actionTypes';
import { NS as AUTH_NS, getWorkspace } from '../../reducers/auth';
import './login.scss';
import Login from './Login';

const mapStateToProps = state => ({
  fetchingWorkspace: state.getIn([AUTH_NS, 'pending', 'workspace']),
  workspace: state.getIn([AUTH_NS, 'workspace']),
  token: state.getIn([AUTH_NS, 'token']), //.auth.token,
  success: state.getIn([NS, 'success']),
  error: state.getIn([NS, 'error'])
});

const mapDispatchToProps = dispatch => ({
  getWorkspace: () => dispatch(getWorkspace()),
  login: (email, password) => dispatch(login(email, password)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
