import { of, concat } from 'rxjs';
import { switchMap, startWith, flatMap, pluck } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';

import ActionTypes, { NS } from '../actionTypes';

import { addNotification } from '@/reducers/global';

export const getDrafts$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_PAGES_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.serviceHandle}/draft-pages?section=${payload.section}`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_PAGES_GET_SUCCESS,
              payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.DRAFT_PAGES_GET_FAIL
          })
        )
      )
    )
  );

export const getDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_PAGE_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.serviceHandle}/draft-pages/${payload.pageSection}/${payload.pageHandle}`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_PAGE_GET_SUCCESS,
              payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.DRAFT_PAGE_GET_FAIL
          })
        )
      )
    )
  );

//

export const createDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_PAGE_CREATE),
    switchMap(({ payload: { serviceHandle, page } }) =>
      ajax(
        {
          url: `/services/${serviceHandle}/draft-pages`,
          method: 'POST',
          body: page
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_PAGE_CREATE_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Created Draft',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, err => {
          if (err.status === 422) {
            return concat(
              of(
                addNotification({
                  message: 'The handle has already been taken.',
                  options: { variant: 'error' }
                })
              ),
              of({ type: ActionTypes.DRAFT_PAGE_CREATE_FAIL })
            );
          }
          return of({ type: ActionTypes.DRAFT_PAGE_CREATE_FAIL });
        })
      )
    )
  );

export const updateDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_PAGE_UPDATE),
    switchMap(
      ({
        payload: {
          serviceHandle,
          page: { id, handle, section, ...rest }
        }
      }) =>
        ajax(
          {
            url: `/services/${serviceHandle}/draft-pages/${section}/${handle}`,
            method: 'PATCH',
            body: rest
          },
          { action$, state$ }
        ).pipe(
          pluck('response'),
          flatMap(payload =>
            concat(
              of({
                type: ActionTypes.DRAFT_PAGE_UPDATE_SUCCESS,
                payload
              }),
              of(
                addNotification({
                  message: 'Updated Draft',
                  options: { variant: 'success' }
                })
              )
            )
          ),
          catchApiError(action$, err => {
            if (err.status === 422) {
              return concat(
                of(
                  addNotification({
                    message: 'The handle has already been taken.',
                    type: 'error'
                  })
                ),
                of({ type: ActionTypes.DRAFT_PAGE_UPDATE_FAIL })
              );
            }
            return of({ type: ActionTypes.DRAFT_PAGE_UPDATE_FAIL });
          })
        )
    )
  );

export const deleteDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_PAGE_DELETE),
    switchMap(({ payload: { serviceHandle, page: { section, handle } } }) =>
      ajax(
        {
          url: `/services/${serviceHandle}/draft-pages/${section}/${handle}`,
          method: 'DELETE'
        },
        { action$, state$ }
      ).pipe(
        flatMap(() =>
          concat(
            of({
              type: ActionTypes.DRAFT_PAGE_DELETE_SUCCESS,
              payload
            }),
            of({
              type: ActionTypes.DRAFT_PAGES_GET,
              payload: {
                serviceHandle: state$.value.getIn([NS, 'service', 'handle'])
              }
            }),
            of(
              addNotification({
                message: 'Deleted Draft',
                options: { variant: 'success' }
              })
            )
          ).pipe(
            catchError(error => {
              return of({
                type: ActionTypes.DRAFT_PAGE_DELETE_FAIL
              });
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.DRAFT_PAGE_DELETE_FAIL
          })
        )
      )
    )
  );

export const publishDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_PAGE_PUBLISH),
    switchMap(({ payload: { serviceHandle, page: { section, handle } } }) =>
      ajax(
        {
          url: `/services/${serviceHandle}/draft-pages/${section}/${handle}/actions/publish`,
          method: 'POST'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_PAGE_PUBLISH_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Published Draft',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.DRAFT_PAGE_PUBLISH_FAIL
          })
        )
      )
    )
  );
export const unpublishDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_PAGE_UNPUBLISH),
    switchMap(({ payload: { serviceHandle, page: { section, handle } } }) =>
      ajax(
        {
          url: `/services/${serviceHandle}/draft-pages/${section}/${handle}/actions/unpublish`,
          method: 'POST'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.DRAFT_PAGE_UNPUBLISH_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Unpublished Draft',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.DRAFT_PAGE_UNPUBLISH_FAIL
          })
        )
      )
    )
  );

export const saveAndPublishDraft$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.DRAFT_PAGE_UPDATE_PUBLISH),
    switchMap(({ payload: { serviceHandle, page } }) =>
      action$.pipe(
        ofType(ActionTypes.DRAFT_PAGE_UPDATE_SUCCESS),
        switchMap(() =>
          concat(
            of({
              type: ActionTypes.DRAFT_PAGE_PUBLISH,
              payload: { serviceHandle, page }
            })
          )
        ),
        startWith({
          type: ActionTypes.DRAFT_PAGE_UPDATE,
          payload: { serviceHandle, page }
        })
      )
    )
  );

export default [
  getDrafts$,
  getDraft$,
  createDraft$,
  updateDraft$,
  deleteDraft$,
  publishDraft$,
  unpublishDraft$,
  saveAndPublishDraft$
];
