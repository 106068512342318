import { createTypes, asyncType, createNS } from '@/utils/actionCreator';

export const NS = createNS('REGISTER');

export default createTypes(
  [
    ...asyncType('REGISTER'),
    ...asyncType('WORKSPACE_CREATE'),
    'RESET'
  ],
  `${NS}`
);
