import { createTypes, asyncType } from '@/utils/actionCreator';

import { NS } from './ns';

export const ActionTypes = createTypes(
  [
    ...asyncType('ACCOUNT_GET'),
    ...asyncType('SUBSCRIPTION_GET'),
    ...asyncType('SUBSCRIPTION_CREATE'),
    ...asyncType('SUBSCRIPTION_UPDATE'),
    ...asyncType('SUBSCRIPTION_CANCEL'),

    ...asyncType('PAYMENT_METHOD_GET'),
    ...asyncType('INVOICE_RETRY'),
    ...asyncType('NEXT_INVOICE_GET'),
    ...asyncType('INVOICES_GET'),
    ...asyncType('PAYMENT_METHODS_GET'),
    ...asyncType('PAYMENT_METHOD_SET_DEFAULT'),
    ...asyncType('PAYMENT_METHOD_DELETE'),

    ...asyncType('STRIPE_LOAD'),

    'UPDATE_CRUMBS',
    'RESET',
  ],
  `${NS}`
);
