import { connect } from 'react-redux';
import { NS as GLOBAL_NS } from '@/reducers/global';
import { NS as AUTH_NS, logout } from '@/reducers/auth';

import ActionMenu from './ActionMenu';

const stateToProps = state => ({
  events: state.getIn([GLOBAL_NS, 'events'])
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default connect(
  stateToProps,
  mapDispatchToProps
)(ActionMenu);
