import React from 'react';

import './Admin.scss';

import {
  Main,
  Container,
  ConfirmationModal,
  Sidenav,
  Breadcrumb
} from '@/components';

import Overview from './screens/Overview';
import SsoProvider from './screens/SsoProvider';
import { Switch, Route, Link } from 'react-router-dom';

export default ({ crumbs = [] }) => {
  return (
    <Container className="Admin">
      <Main
        breadcrumb={
          <Breadcrumb name={'Admin'} path={'/admin'} crumbs={crumbs} />
        }
      >
        <Switch>
          <Route exact path={'/admin'} component={Overview} />
          <Route
            exact
            path="/admin/sso-providers/new"
            component={SsoProvider}
          />
          <Route
            exact
            path="/admin/sso-providers/:handle"
            component={SsoProvider}
          />
        </Switch>
      </Main>
    </Container>
  );
};
