import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Container,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';

import { required, isEmail } from '@/validators';

const useStyles = makeStyles({
  root: {
    alignSelf: 'center',
    justifySelf: 'center',
    margin: 'auto'
  },
  formControl: {
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: '0px'
    }
  },
  actions: {
    justifyContent: 'space-between'
  },
  alert: { margin: '0 16px' }
});

const RegisterView = props => {
  const { error, submitting, register, location } = props;
  const { control, formState, errors, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: { beta_code: '', email: '' }
  });
  const { isValid, isDirty } = formState;
  const styles = useStyles();
  useEffect(() => {
    const { beta_code } = queryString.parse(location.search);
    setValue('beta_code', beta_code, { shouldValidate: true });
  }, []);

  const onSubmit = values => {
    register(values.beta_code, values.email);
  };

  return (
    <Container className={styles.root} maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="RegisterCard">
          <CardHeader title="Register" />
          {error ? (
            <Alert severity="error" className={styles.alert}>
              {error}
            </Alert>
          ) : null}
          <CardContent>
            <Controller
              name="email"
              label="Email"
              control={control}
              rules={{
                required: true,
                validate: {
                  isEmail: v => isEmail(v) || 'Invalid Email provided'
                }
              }}
              as={TextField}
              autoComplete="off"
              fullWidth
              className={styles.formControl}
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ''}
            />

            <Controller
              name="beta_code"
              label="Beta Code"
              control={control}
              rules={{ required: true }}
              as={TextField}
              autoComplete="off"
              fullWidth
              className={styles.formControl}
              error={!!errors.beta_code}
              fullWidth
            />
          </CardContent>
          <CardActions>
            <Button
              className="ml-auto"
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitting || !isValid}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
  // }
};

export default RegisterView;
