import React, { useState } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
import { SnackbarProvider } from 'notistack';

import AuthorizedRoute from '@/components/AuthorizedRoute';
import { Route, Switch } from 'react-router-dom';

import Main from '@/screens/Main';
import Notifier from '../Notifier/Notifier';

import ForgotPassword from '@/screens/ForgotPassword';
import Login from '@/screens/Login';
import Invite from '@/screens/Invite';
import ResetPassword from '@/screens/ResetPassword';
import Register from '@/screens/Register';
import { Redirect } from 'react-router';

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: blue[600]
    },
    secondary: {
      // This is green.A700 as hex.
      main: blue[900]
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
});

const isAuthHostname = process.env.AUTH_HOST.includes(window.location.hostname);

const App = () => {
  const [showRegister] = useState(isAuthHostname);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Notifier />
        <Switch>
          {showRegister ? (
            <Switch>
              <Route path="/register" component={Register} />
              <Redirect to="/register" />
            </Switch>
          ) : (
            <Switch>
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/login" component={Login} />
              <Route path="/invite/:id" component={Invite} />
              <Route path="/reset-password" component={ResetPassword} />
              <AuthorizedRoute path="/" component={Main} />
            </Switch>
          )}
        </Switch>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
