import { Map, List, fromJS } from 'immutable';

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  success: false,
  error: false,
  submitting: false
  //   slackWorkspaces: List(),
  //   pending: Map({ slackWorkspaces: true })
});

export default function AdminReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_PASSWORD:
      return state.set('submitting', true).set('error', false);

    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return state
        .set('submitting', false)
        .set('success', true)
        .set('error', false);

    case ActionTypes.RESET_PASSWORD_FAIL:
      return state
        .set('submitting', false)
        .set('success', false)
        .set('error', action.payload.message || 'Server Error');

    case ActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
}
