import { fromJS, Map, List } from 'immutable';
import ActionTypes, { NS } from './actionTypes';

const initialState = new Map({
  team: Map(),
  policies: Map(),
  pending: Map({
    team: true,
    services: true,
    teams: true,
    members: true,
    filteredMembers: false,
    clients: false,
    feed: false,
    health: false,
    runners: false,
  }),
  notFound: false,
  pagination: Map({
    teams: Map({ total: 0 }),
    services: Map({ total: 0 }),
    members: Map({ total: 0 }),
    runners: Map({ total: 0 }),
  }),
  error: false,
  members: List(),
  addMemberModalVisible: false,
  filteredMembers: List(),
  services: List(),
  teams: List(),
  clients: List(),
  client: Map(),

  runners: List(),
  runner: Map(),

  health: List(),

  inviteModalVisible: false,
  deleteModalVisible: false,
  serviceCount: 0,

  feed: List(),
  crumbs: [],
});

const teamReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.TEAM_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'team'], true)
        .setIn(['pending', 'teams'], true)
        .setIn(['pending', 'members'], true)
        .setIn(['pending', 'services'], true)
        .setIn(['pending', 'runners'], true)
        .set('team', Map());

    case ActionTypes.TEAM_GET_SUCCESS:
    case ActionTypes.TEAM_UPDATE_SUCCESS:
    case ActionTypes.TEAM_AVATAR_UPDATE_SUCCESS:
    case ActionTypes.TEAM_ICON_DELETE_SUCCESS:
      return state
        .set('error', false)
        .set('notFound', false)
        .setIn(['pending', 'team'], false)
        .set('team', fromJS(payload));

    case ActionTypes.TEAM_POLICIES_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'policies'], true)
        .set('policies', Map());
    case ActionTypes.TEAM_POLICIES_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'policies'], false)
        .set('policies', fromJS(payload));

    case ActionTypes.TEAM_GET_FAIL:
      return state
        .set('error', true)
        .set('notFound', true)
        .setIn(['pending', 'team'], false)
        .set('team', Map());

    case ActionTypes.TEAM_MEMBERS_GET:
      return state.set('members', List()).setIn(['pending', 'members'], true);

    case ActionTypes.TEAM_MEMBERS_GET_SUCCESS:
      return state
        .set('members', fromJS(payload.members))
        .setIn(['pagination', 'members', 'total'], payload.total)
        .setIn(['pending', 'members'], false);

    case ActionTypes.TEAM_MEMBERS_GET_FAIL:
      return state.set('members', List()).setIn(['pending', 'members'], false);

    case ActionTypes.TEAM_MEMBER_TOGGLE_MODAL:
      return state.set('addMemberModalVisible', payload.show);

    case ActionTypes.TEAM_MEMBERS_AUTOCOMPLETE:
      return state.setIn(['pending', 'filteredMembers'], true);
    case ActionTypes.TEAM_MEMBERS_AUTOCOMPLETE_SUCCESS:
      return state
        .set('filteredMembers', fromJS(payload))
        .setIn(['pending', 'filteredMembers'], false);
    case ActionTypes.TEAM_MEMBERS_AUTOCOMPLETE_FAIL:
      return state
        .set('filteredMembers', List())
        .setIn(['pending', 'filteredMembers'], false);

    case ActionTypes.TEAM_SERVICES_GET:
      return state.set('services', List()).setIn(['pending', 'services'], true);

    case ActionTypes.TEAM_SERVICES_GET_SUCCESS:
      return state
        .set('services', fromJS(payload.services))
        .setIn(['pagination', 'services', 'total'], payload.total)
        .setIn(['pending', 'services'], false);

    case ActionTypes.TEAM_SERVICES_GET_FAIL:
      return state.set('services', List()).set('servicesPending', false);

    case ActionTypes.TEAM_TEAMS_GET:
      return state.set('teams', List()).setIn(['pending', 'teams'], true);

    case ActionTypes.TEAM_TEAMS_GET_SUCCESS:
      return state
        .set('teams', fromJS(payload.teams))
        .setIn(['pagination', 'teams', 'total'], payload.total)
        .setIn(['pending', 'teams'], false);

    case ActionTypes.TEAM_TEAMS_GET_FAIL:
      return state.set('teams', List()).setIn(['pending', 'teams'], false);

    case ActionTypes.TEAM_DELETE_CONFIRM_MODAL:
      return state.set('deleteModalVisible', payload.show);

    case ActionTypes.TEAM_SERVICE_COUNT_GET_SUCCESS:
      return state.set('serviceCount', payload);

    case ActionTypes.CLIENTS_GET:
      return state.set('clients', List()).setIn(['pending', 'clients'], true);

    case ActionTypes.CLIENTS_GET_SUCCESS:
      return state
        .set('clients', fromJS(payload))
        .setIn(['pending', 'clients'], false);

    case ActionTypes.CLIENT_SET:
      return state.set('client', payload.client ? payload.client : Map());

    case ActionTypes.CLIENT_UPDATE_SUCCESS:
      return state.update('clients', (clients) =>
        clients.update(
          clients.findIndex(
            (client) => client.get('handle') === payload.handle
          ),
          () => fromJS(payload)
        )
      );

    case ActionTypes.CLIENT_CREATE_SUCCESS:
      return state
        .set('client', Map(payload))
        .update('clients', (clients) =>
          clients.push(fromJS(payload)).sortBy((d) => d.get('name'))
        );

    case ActionTypes.CLIENT_SECRET_UPDATE_SUCCESS:
    case ActionTypes.CLIENT_GET_SUCCESS:
      return state.set('client', Map(payload));

    case ActionTypes.CLIENT_DELETE_SUCCESS:
      return state.update('clients', (clients) =>
        clients.filter((client) => client.get('handle') !== payload.clientId)
      );

    // Runners

    case ActionTypes.RUNNERS_GET:
      return state.set('runners', List()).setIn(['pending', 'runners'], true);

    case ActionTypes.RUNNERS_GET_SUCCESS:
      return state
        .set('runners', fromJS(payload))
        .setIn(['pending', 'runners'], false);

    case ActionTypes.RUNNER_UPDATE_SUCCESS:
      return state.update('runners', (runners) =>
        runners.update(
          runners.findIndex(
            (runner) => runner.get('handle') === payload.handle
          ),
          () => fromJS(payload)
        )
      );

    case ActionTypes.RUNNER_CREATE_SUCCESS:
      return state.update('runners', (clients) =>
        clients.push(fromJS(payload)).sortBy((d) => d.get('name'))
      );

    case ActionTypes.RUNNER_DELETE_SUCCESS:
      return state.update('runners', (runners) =>
        runners.filter((runner) => runner.get('handle') !== payload.runnerId)
      );
    case ActionTypes.RUNNER_SET:
      return state.set('runner', payload.runner ? payload.runner : Map());

    case ActionTypes.PUBLIC_FEED_GET:
      return state.set('feed', List()).setIn(['pending', 'feed'], true);
    case ActionTypes.PUBLIC_FEED_GET_SUCCESS:
      return state
        .set('feed', fromJS(payload || []))
        .setIn(['pending', 'feed'], false);

    case ActionTypes.HEALTH_GET:
      return state.set('health', List()).setIn(['pending', 'health'], true);
    case ActionTypes.HEALTH_GET_SUCCESS:
      return state
        .set('health', fromJS(payload || []))
        .setIn(['pending', 'health'], false);

    case ActionTypes.UPDATE_CRUMBS:
      return state.set('crumbs', payload.crumbs);

    case ActionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export { NS };

export default teamReducer;
