import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  Box,
  CircularProgress,
  Button,
  CardActions
} from '@material-ui/core';
import { UptimeBar } from '@/components';
export default props => {
  const { team, health, pending, getHealth, updateCrumbs } = props;

  useEffect(() => {
    updateCrumbs([{ label: 'Health' }]);
    getHealth(team.get('handle'));
  }, []);
  if (pending) {
    return (
      <Container>
        <Box p={20} mx="auto" display="flex" justifyContent="center">
          <CircularProgress mx="auto" size={48} />
        </Box>
      </Container>
    );
  }
  return (
    <React.Fragment>
      {health.map(s => (
        <UptimeBar
          key={s.get('id')}
          title={s.get('name')}
          handle={s.get('id')}
          data={s.get('data')}
          uptime={s.get('uptime')}
          style={{ margin: '0 0 2rem' }}
        >
          <CardActions className="md-divider-border md-divider-border--top md-dialog-footer md-dialog-footer--inline">
            <Button
              component={Link}
              color="primary"
              to={`/services/${s.get('handle')}/health-checks`}
            >
              View HealthChecks
            </Button>
          </CardActions>
        </UptimeBar>
      ))}
    </React.Fragment>
  );
};
