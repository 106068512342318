import { createAction } from '@/utils/actionCreator';

import ActionTypes from './actionTypes';

export const createService = createAction(ActionTypes.SERVICE_CREATE, 'team', 'body');
export const getTeams = createAction(ActionTypes.TEAMS_GET);
export const searchTeams = createAction(ActionTypes.TEAMS_SEARCH, 'q');
export const reset = createAction(ActionTypes.RESET);

export const validateHandle = createAction(
  ActionTypes.VALIDATE_SERVICE_HANDLE,
  'handle'
);
