import { of, defer, throwError } from 'rxjs';
import {
  catchError,
  startWith,
  take,
  mergeMapTo,
  mergeMap,
  merge,
  takeUntil,
  tap
} from 'rxjs/operators';
import { ajax as _ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';

import { NS as NS_AUTH, ActionTypes } from '@/reducers/auth';
import { NS as NS_GLOBAL } from '@/reducers/global';

const API_URL = process.env.API_URL.replace('localhost', 'localhost:8080');
const AUTH_URL = process.env.AUTH_HOST.replace('localhost', 'localhost:8080');

let retry = 0;
let refreshing = false;

const isFunction = function(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  );
};

export const ajax = (config, options) => {
  return defer(() => {
    const { url, headers, ...rest } = config;
    const { state$, action$ } = options;

    const workspace = state$.value.getIn([NS_GLOBAL, 'workspace']);
    const accessToken = state$.value.getIn([NS_AUTH, 'token']);

    const mappedHeaders = Object.assign(
      headers
        ? headers
        : {
            'Content-Type': 'application/json'
          },
      accessToken
        ? {
            Authorization: `Bearer ${accessToken}`
          }
        : {},
      headers
    );

    const baseUrl =
      /^\/workspace$/.test(url) && workspace
        ? window.location.protocol + '//' + workspace + '.' + AUTH_URL
        : API_URL;

    let withCredentials = false;
    if (/^\/token$/.test(url) || /^\/session$/.test(url)) {
      withCredentials = true;
    }

    const params = {
      url: `${baseUrl}${url}`,
      headers: mappedHeaders,
      crossDomain: true,
      withCredentials: withCredentials,
      responseType: 'json',
      ...rest
    };

    return _ajax(params);
  }).pipe(
    tap(() => {
      retry = 0;
      refreshing = false;
    })
  );
};

export const refreshToken = (action$, err, source) => {
  if (refreshing) {
    return action$.pipe(
      ofType(ActionTypes.AUTH_REFRESH_SUCCESS),
      takeUntil(action$.pipe(ofType(ActionTypes.AUTH_REFRESH_FAIL))),
      take(1),
      mergeMapTo(source)
    );
  } else {
    // TODO: REFACTOR TO USE STATE
    refreshing = true;
    retry++;
    return action$.pipe(
      ofType(ActionTypes.AUTH_REFRESH_SUCCESS),
      takeUntil(action$.pipe(ofType(ActionTypes.AUTH_REFRESH_FAIL))),
      take(1),
      mergeMapTo(source),
      merge(of({ type: ActionTypes.AUTH_REFRESH }))
    );
  }
};

export const catchApiError = (action$, callback) => {
  return function(source) {
    return source.pipe(
      catchError(error => {
        if (error.status === 401 && retry < 2) {
          return refreshToken(action$, error, source);
        } else if (isFunction(callback)) {
          return callback(error, source);
        } else {
          return of(Object.assign({ error: true, payload: error }, defaults));
        }
      })
    );
  };
};

export default ajax;
