import { Map, List, fromJS } from 'immutable';

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  success: false,
  error: false,
  user: Map(),
  teams: List(),
  feed: List(),
  pending: Map({ user: true, teams: true, feed: true }),
  deleted: false,
  crumbs: []
});

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.USER_GET:
      return state.setIn(['pending', 'user'], true).set('error', false);
    case ActionTypes.USER_GET_SUCCESS:
      return state
        .setIn(['pending', 'user'], false)
        .set('error', false)
        .set('user', fromJS(action.payload));
    case ActionTypes.USER_UPDATE_SUCCESS:
      return state.set('user', fromJS(action.payload));
    case ActionTypes.GET_USERS_FAIL:
      return state
        .setIn(['pending', 'user'], false)
        .set('error', false)
        .set('user', Map());

    case ActionTypes.USER_TEAMS_GET_SUCCESS:
      return state
        .setIn(['pending', 'teams'], false)
        .set('error', false)
        .set('teams', fromJS(action.payload));

    case ActionTypes.USER_FEED_GET_SUCCESS:
      return state
        .setIn(['pending', 'feed'], false)
        .set('error', false)
        .set('feed', fromJS(action.payload));

    case ActionTypes.USER_DELETE_SUCCESS:
      return state.set('deleted', true);

    case ActionTypes.UPDATE_CRUMBS:
      return state.set('crumbs', action.payload.crumbs || []);

    case ActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
}
