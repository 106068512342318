import { ajax, catchApiError } from '@/utils/ajax';
import queryString from 'query-string';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { flatMap, map, switchMap, tap } from 'rxjs/operators';
import ActionTypes from './actionTypes';

export const getServices$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.GET_SERVICES),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/search/services?${queryString.stringify(payload)}`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(res =>
          of({
            type: ActionTypes.GET_SERVICES_SUCCESS,
            payload: res,
            error: false
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.GET_SERVICES_FAILURE
          })
        )
      )
    )
  );

export default [getServices$];
