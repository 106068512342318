import { of, concat } from 'rxjs';
import { switchMap, flatMap, mergeMap, map, pluck } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';
import { addNotification } from '@/reducers/global';

import ActionTypes from '../actionTypes';

export const listRemoteServices$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICES_REMOTE_SERVICES_GET),
    switchMap(() =>
      ajax(
        {
          url: `/search-remotes/services`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        mergeMap(payload =>
          of({
            type: ActionTypes.SERVICES_REMOTE_SERVICES_GET_SUCCESS,
            payload
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICES_REMOTE_SERVICES_GET_FAIL
          })
        )
      )
    )
  );

export const listRemoteDependencies$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICES_REMOTE_DEPENDENCIES_GET),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.service}/remote-dependencies`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        mergeMap(payload =>
          of({
            type: ActionTypes.SERVICES_REMOTE_DEPENDENCIES_GET_SUCCESS,
            payload
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.SERVICES_REMOTE_DEPENDENCIES_GET_FAIL
          })
        )
      )
    )
  );

export const addRemoteDependency$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICES_REMOTE_DEPENDENCY_CREATE),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.service}/remote-dependencies`,
          method: 'POST',
          body: {
            workspace: payload.workspace,
            service: payload.remote
          }
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.SERVICES_REMOTE_DEPENDENCY_CREATE_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Service Dependency Added',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.SERVICES_REMOTE_DEPENDENCY_CREATE_FAIL
            }),
            of(
              addNotification({
                message: 'Failed to add remote dependency',
                options: { variant: 'error' }
              })
            )
          )
        )
      )
    )
  );
export const removeRemoteDependency$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.SERVICES_REMOTE_DEPENDENCY_DELETE),
    switchMap(({ payload }) =>
      ajax(
        {
          url: `/services/${payload.service}/remote-dependencies/${payload.workspace}/${payload.remote}`,
          method: 'DELETE'
        },
        { action$, state$ }
      ).pipe(
        flatMap(() =>
          concat(
            of({
              type: ActionTypes.SERVICES_REMOTE_DEPENDENCY_DELETE_SUCCESS,
              payload: payload
            }),
            of(
              addNotification({
                message: 'Service Dependency Removed',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          concat(
            of({
              type: ActionTypes.SERVICES_REMOTE_DEPENDENCY_DELETE_FAIL
            }),
            of(
              addNotification({
                message: 'Failed to remove dependency',
                options: { variant: 'error' }
              })
            )
          )
        )
      )
    )
  );

export default [
  listRemoteServices$,
  listRemoteDependencies$,
  addRemoteDependency$,
  removeRemoteDependency$
];
