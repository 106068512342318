import './SelectFieldGroup.scss';

import React, { useEffect } from 'react';
import {
  Button,
  Select,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  MenuItem
} from '@material-ui/core';

import { useForm, Controller } from 'react-hook-form';

const getFieldValue = (entity, field) => {
  return entity.getIn(field.split('.')) || '';
};

const SelectFieldGroup = props => {
  const { label, field, sublabel, entity, entityId, items = [], save } = props;
  const { control, handleSubmit, formState, errors, setValue, reset } = useForm(
    {
      mode: 'onChange',
      defaultValues: {
        [field]: ''
      }
    }
  );

  useEffect(() => {
    const value = getFieldValue(entity, field);
    reset({ [field]: value }, { isDirty: false });
  }, [entity]);

  const onSubmit = form => {
    reset(form, { isDirty: false });
    save({ id: entityId, body: form });
  };

  const { isDirty } = formState;

  return (
    <Card className="SelectFieldGroup">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title={label} />
        <CardContent className="">
          <Controller
            name={field}
            control={control}
            render={({ onChange, onBlur, value }) => (
              <Select
                style={{ width: '100%', maxWidth: '400px' }}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              >
                {items.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <p className="SelectFieldGroup__sublabel">{sublabel}</p>
        </CardContent>
        {isDirty ? (
          <CardActions>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </CardActions>
        ) : null}
      </form>
    </Card>
  );
};

export default SelectFieldGroup;
