export const config = {
  directed: true,

  collapsible: true,
  highlightDegree: 1,
  highlightOpacity: 0.2,
  nodeHighlightBehavior: true,
  linkHighlightBehavior: true,
  maxZoom: 12,
  minZoom: 1,
  panAndZoom: true,
  height: 500,
  width: 800,

  node: {
    color: 'blue',
    color: n => (n.health == 'good' ? 'green' : 'red'),
    fontColor: 'black',
    fontSize: 12,
    fontWeight: 'normal',
    highlightColor: '#0d47a1',
    highlightFontSize: 12,
    highlightStrokeColor: '#0d47a1',
    highlightStrokeWidth: 1.5,
    labelProperty: n => (n.name ? `${n.name}` : n.id),
    mouseCursor: 'crosshair',
    renderLabel: true,
    size: 200,
    strokeColor: 'none',
    strokeWidth: 4,
    svg: '',
    symbolType: 'circle',
    viewGenerator: null
  },
  link: {
    color: 'lightgray',
    highlightColor: '#000',
    mouseCursor: 'pointer',
    opacity: 1,
    semanticStrokeWidth: true,
    strokeWidth: 3,
    type: 'STRAIGHT',
    markerHeight: 8,
    markerWidth: 8
  },
  d3: {
    alphaTarget: 0.05,
    gravity: -300,
    linkLength: 200,
    linkStrength: 3
    // disableLinkForce: true
  }
};
