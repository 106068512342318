import ActionTypes from './actionTypes';
import { createAction } from '@/utils/actionCreator';

export const getUsers = createAction(ActionTypes.USERS_GET, 'params');
export const getInvites = createAction(ActionTypes.INVITES_GET, 'params');
export const resendInvite = createAction(ActionTypes.INVITE_RESEND, 'inviteId');
export const updateUserRole = createAction(
  ActionTypes.USER_ROLE_UPDATE,
  'userId',
  'role'
);
