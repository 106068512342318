import { createAction } from '@/utils/actionCreator';

import ActionTypes from './actionTypes';

export const getService = payload => ({
  type: ActionTypes.SERVICE_GET,
  payload
});

export const setService = payload => ({
  type: ActionTypes.SERVICE_GET_SUCCESS,
  payload
});

export const updateService = payload => ({
  type: ActionTypes.SERVICE_UPDATE,
  payload
});

export const updateParent = createAction(
  ActionTypes.SERVICE_REPARENT_POST,
  'id',
  'body'
);

export const updateHealth = createAction(
  ActionTypes.SERVICE_HEALTH_POST,
  'id',
  'health'
);

export const updateStage = createAction(
  ActionTypes.SERVICE_STAGE_POST,
  'id',
  'stage'
);

export const updateVisibility = createAction(
  ActionTypes.SERVICE_VISIBILITY_POST,
  'id',
  'visibility'
);

export const deleteService = createAction(ActionTypes.SERVICE_DELETE, 'handle');

export const getServiceHealthchecks = createAction(
  ActionTypes.SERVICE_HEALTH_CHECKS_GET,
  'id'
);

export const getServiceLinks = payload => ({
  type: ActionTypes.SERVICE_LINKS_REQUEST,
  payload
});

export const getServiceDependencies = payload => ({
  type: ActionTypes.SERVICE_DEPENDENCIES_GET,
  payload
});

export const toggleDependencyModal = payload => ({
  type: ActionTypes.DEPENDENCIES_MODAL_VISIBLE,
  payload
});

export const searchServices = payload => ({
  type: ActionTypes.SERVICES_AUTOCOMPLETE,
  payload
});

export const addDependency = payload => ({
  type: ActionTypes.SERVICE_DEPENDENCIES_ADD,
  payload
});

export const deleteDependency = createAction(
  ActionTypes.SERVICE_DEPENDENCIES_DELETE,
  'handle',
  'dependencyHandle'
);

// External Dependencies
export const listRemoteServices = createAction(
  ActionTypes.SERVICES_REMOTE_SERVICES_GET
);

export const listRemoteDependencies = createAction(
  ActionTypes.SERVICES_REMOTE_DEPENDENCIES_GET
);

export const addRemoteDependency = createAction(
  ActionTypes.SERVICES_REMOTE_DEPENDENCY_CREATE,
  'service',
  'workspace',
  'remote'
);

export const removeRemoteDependency = createAction(
  ActionTypes.SERVICES_REMOTE_DEPENDENCY_DELETE,
  'service',
  'workspace',
  'remote'
);

export const toggleRemoteDependencyModal = createAction(
  ActionTypes.REMOTE_DEPENDENCIES_MODAL_VISIBLE,
  'visibility'
);

// Dependents
export const getServiceDependents = createAction(
  ActionTypes.SERVICE_DEPENDENTS_GET,
  'handle'
);

// Avatar
export const updateServiceAvatar = payload => ({
  type: ActionTypes.SERVICE_UPLOAD,
  payload
});

export const clearServiceIcon = payload => ({
  type: ActionTypes.SERVICE_ICON_DELETE,
  payload
});

export const getDailyServiceHealthChecks = createAction(
  ActionTypes.SERVICE_HEALH_CHECK_DAILY_GET,
  'id',
  'from',
  'to'
);

export const getHealthCheckResult = createAction(
  ActionTypes.SERVICE_HEALTH_CHECK_RESULTS_GET,
  'id'
);

export const toggleHealthcheckModal = createAction(
  ActionTypes.HEALTHCHECK_MODAL_VISIBLE,
  'visible',
  'record'
);

export const createHealthcheck = createAction(
  ActionTypes.HEALTHCHECK_CREATE,
  'service',
  'body'
);

export const updateHealthcheck = payload => ({
  type: ActionTypes.HEALTHCHECK_UPDATE,
  payload
});

export const getOverviewAnnouncements = createAction(
  ActionTypes.OVERVIEW_ANNOUNCEMENTS_GET,
  'id'
);
export const getOverviewIssues = createAction(
  ActionTypes.OVERVIEW_ISSUES_GET,
  'id',
  'type',
  'status'
);

// NOTICES
export const getNotices = createAction(
  ActionTypes.NOTICES_GET,
  'id',
  'type',
  'status'
);
export const createNotice = createAction(ActionTypes.NOTICE_CREATE, 'body');
export const updateNotice = createAction(
  ActionTypes.NOTICE_UPDATE,
  'id',
  'body'
);
export const toggleNoticeModal = createAction(
  ActionTypes.NOTICE_MODAL_VISIBLE,
  'show',
  'notice'
);
export const toggleViewNoticeModal = createAction(
  ActionTypes.NOTICE_VIEW_MODAL_VISIBLE,
  'show',
  'notice'
);

// Published Pages Actions
export const getPublishedPages = createAction(
  ActionTypes.PUBLISHED_PAGES_GET,
  'serviceHandle',
  'section'
);

export const getPublishedPage = createAction(
  ActionTypes.PUBLISHED_PAGE_GET,
  'serviceHandle',
  'section',
  'pageId'
);

// Draft Pages Actions
export const getDraftPages = createAction(
  ActionTypes.DRAFT_PAGES_GET,
  'serviceHandle',
  'section'
);

export const getDraftPage = createAction(
  ActionTypes.DRAFT_PAGE_GET,
  'serviceHandle',
  'pageSection',
  'pageHandle'
);

export const createDraftPage = createAction(
  ActionTypes.DRAFT_PAGE_CREATE,
  'serviceHandle',
  'page'
);
export const updateDraftPage = createAction(
  ActionTypes.DRAFT_PAGE_UPDATE,
  'serviceHandle',
  'page'
);
export const deleteDraftPage = createAction(
  ActionTypes.DRAFT_PAGE_DELETE,
  'serviceHandle',
  'page'
);
export const publishDraftPage = createAction(
  ActionTypes.DRAFT_PAGE_PUBLISH,
  'serviceHandle',
  'page'
);
export const unpublishDraftPage = createAction(
  ActionTypes.DRAFT_PAGE_UNPUBLISH,
  'serviceHandle',
  'page'
);
export const saveAndPublishDraftPage = createAction(
  ActionTypes.DRAFT_PAGE_UPDATE_PUBLISH,
  'serviceHandle',
  'page'
);
export const getDraftPageRevisions = createAction(
  ActionTypes.DRAFT_PAGE_REVISIONS_GET,
  'serviceHandle',
  'page'
);
export const getDraftPagetRevision = createAction(
  ActionTypes.DRAFT_PAGE_REVISION_GET,
  'serviceHandle',
  'page'
);

// Published Announcements Actions
export const getPublishedAnnouncements = createAction(
  ActionTypes.PUBLISHED_ANNOUNCEMENTS_GET,
  'serviceHandle',
  'section'
);

export const getPublishedAnnouncement = createAction(
  ActionTypes.PUBLISHED_ANNOUNCEMENT_GET,
  'pageId'
);

// Draft Announcements Actions
export const getDraftAnnouncements = createAction(
  ActionTypes.DRAFT_ANNOUNCEMENTS_GET,
  'serviceHandle'
);

export const getDraftAnnouncement = createAction(
  ActionTypes.DRAFT_ANNOUNCEMENT_GET,
  'draftId'
);

export const createDraftAnnouncement = createAction(
  ActionTypes.DRAFT_ANNOUNCEMENT_CREATE,
  'serviceHandle',
  'draft'
);
export const updateDraftAnnouncement = createAction(
  ActionTypes.DRAFT_ANNOUNCEMENT_UPDATE,
  'draftId',
  'draft'
);
export const deleteDraftAnnouncement = createAction(
  ActionTypes.DRAFT_ANNOUNCEMENT_DELETE,
  'draftId'
);
export const publishDraftAnnouncement = createAction(
  ActionTypes.DRAFT_ANNOUNCEMENT_PUBLISH,
  'draftId'
);
export const unpublishDraftAnnouncement = createAction(
  ActionTypes.DRAFT_ANNOUNCEMENT_UNPUBLISH,
  'draftId'
);
export const saveAndPublishDraftAnnouncement = createAction(
  ActionTypes.DRAFT_ANNOUNCEMENT_UPDATE_PUBLISH,
  'pagedraftIdId',
  'draft'
);
export const getDraftAnnouncementRevisions = createAction(
  ActionTypes.DRAFT_ANNOUNCEMENT_REVISIONS_GET,
  'draftId'
);
export const getDraftAnnouncementRevision = createAction(
  ActionTypes.DRAFT_ANNOUNCEMENT_REVISION_GET,
  'draftId'
);

export const resetDraftAnnouncement = createAction(
  ActionTypes.DRAFT_ANNOUNCEMENT_RESET
);

// Published ReleaseNotes Actions
export const getPublishedReleaseNotes = createAction(
  ActionTypes.PUBLISHED_RELEASE_NOTES_GET,
  'serviceHandle',
  'section'
);

export const getPublishedReleaseNote = createAction(
  ActionTypes.PUBLISHED_RELEASE_NOTE_GET,
  'pageId'
);

// Draft ReleaseNotes Actions
export const getDraftReleaseNotes = createAction(
  ActionTypes.DRAFT_RELEASE_NOTES_GET,
  'serviceHandle'
);

export const getDraftReleaseNote = createAction(
  ActionTypes.DRAFT_RELEASE_NOTE_GET,
  'draftId'
);

export const createDraftReleaseNote = createAction(
  ActionTypes.DRAFT_RELEASE_NOTE_CREATE,
  'serviceHandle',
  'draft'
);
export const updateDraftReleaseNote = createAction(
  ActionTypes.DRAFT_RELEASE_NOTE_UPDATE,
  'draftId',
  'draft'
);
export const deleteDraftReleaseNote = createAction(
  ActionTypes.DRAFT_RELEASE_NOTE_DELETE,
  'draftId'
);
export const publishDraftReleaseNote = createAction(
  ActionTypes.DRAFT_RELEASE_NOTE_PUBLISH,
  'draftId'
);
export const unpublishDraftReleaseNote = createAction(
  ActionTypes.DRAFT_RELEASE_NOTE_UNPUBLISH,
  'draftId'
);
export const saveAndPublishDraftReleaseNote = createAction(
  ActionTypes.DRAFT_RELEASE_NOTE_UPDATE_PUBLISH,
  'pagedraftIdId',
  'draft'
);
export const getDraftReleaseNoteRevisions = createAction(
  ActionTypes.DRAFT_RELEASE_NOTE_REVISIONS_GET,
  'draftId'
);
export const getDraftReleaseNoteRevision = createAction(
  ActionTypes.DRAFT_RELEASE_NOTE_REVISION_GET,
  'draftId'
);

export const resetDraftReleaseNote = createAction(
  ActionTypes.DRAFT_RELEASE_NOTE_RESET
);

// Published ApiDocs Actions
export const getPublishedApiDocs = createAction(
  ActionTypes.PUBLISHED_API_DOCS_GET,
  'serviceHandle',
  'section'
);

export const getPublishedApiDoc = createAction(
  ActionTypes.PUBLISHED_API_DOC_GET,
  'pageId'
);

// Draft ApiDocs Actions
export const getDraftApiDocs = createAction(
  ActionTypes.DRAFT_API_DOCS_GET,
  'serviceHandle'
);

export const getDraftApiDoc = createAction(
  ActionTypes.DRAFT_API_DOC_GET,
  'draftId'
);

export const createDraftApiDoc = createAction(
  ActionTypes.DRAFT_API_DOC_CREATE,
  'serviceHandle',
  'draft'
);
export const updateDraftApiDoc = createAction(
  ActionTypes.DRAFT_API_DOC_UPDATE,
  'draftId',
  'draft'
);
export const deleteDraftApiDoc = createAction(
  ActionTypes.DRAFT_API_DOC_DELETE,
  'draftId'
);
export const publishDraftApiDoc = createAction(
  ActionTypes.DRAFT_API_DOC_PUBLISH,
  'draftId'
);
export const unpublishDraftApiDoc = createAction(
  ActionTypes.DRAFT_API_DOC_UNPUBLISH,
  'draftId'
);
export const saveAndPublishDraftApiDoc = createAction(
  ActionTypes.DRAFT_API_DOC_UPDATE_PUBLISH,
  'pagedraftIdId',
  'draft'
);
export const getDraftApiDocRevisions = createAction(
  ActionTypes.DRAFT_API_DOC_REVISIONS_GET,
  'draftId'
);
export const getDraftApiDocRevision = createAction(
  ActionTypes.DRAFT_API_DOC_REVISION_GET,
  'draftId'
);

export const resetDraftApiDoc = createAction(ActionTypes.DRAFT_API_DOC_RESET);


// Published OpenApiSpecs Actions
export const getPublishedOpenApiSpecs = createAction(
  ActionTypes.PUBLISHED_OPEN_API_SPECS_GET,
  'serviceHandle',
  'section'
);

export const getPublishedOpenApiSpec = createAction(
  ActionTypes.PUBLISHED_OPEN_API_SPEC_GET,
  'pageId'
);

// Draft OpenApiSpecs Actions
export const getDraftOpenApiSpecs = createAction(
  ActionTypes.DRAFT_OPEN_API_SPECS_GET,
  'serviceHandle'
);

export const getDraftOpenApiSpec = createAction(
  ActionTypes.DRAFT_OPEN_API_SPEC_GET,
  'draftId'
);

export const createDraftOpenApiSpec = createAction(
  ActionTypes.DRAFT_OPEN_API_SPEC_CREATE,
  'serviceHandle',
  'draft'
);
export const updateDraftOpenApiSpec = createAction(
  ActionTypes.DRAFT_OPEN_API_SPEC_UPDATE,
  'draftId',
  'draft'
);
export const deleteDraftOpenApiSpec = createAction(
  ActionTypes.DRAFT_OPEN_API_SPEC_DELETE,
  'draftId'
);
export const publishDraftOpenApiSpec = createAction(
  ActionTypes.DRAFT_OPEN_API_SPEC_PUBLISH,
  'draftId'
);
export const unpublishDraftOpenApiSpec = createAction(
  ActionTypes.DRAFT_OPEN_API_SPEC_UNPUBLISH,
  'draftId'
);
export const saveAndPublishDraftOpenApiSpec = createAction(
  ActionTypes.DRAFT_OPEN_API_SPEC_UPDATE_PUBLISH,
  'pagedraftIdId',
  'draft'
);
export const getDraftOpenApiSpecRevisions = createAction(
  ActionTypes.DRAFT_OPEN_API_SPEC_REVISIONS_GET,
  'draftId'
);
export const getDraftOpenApiSpecRevision = createAction(
  ActionTypes.DRAFT_OPEN_API_SPEC_REVISION_GET,
  'draftId'
);

export const resetDraftOpenApiSpec = createAction(ActionTypes.DRAFT_OPEN_API_SPEC_RESET);



// Pages Actions
export const exportPages = createAction(
  ActionTypes.PAGES_EXPORT,
  'serviceHandle'
);

// Graph Actions
export const getServiceGraph = createAction(
  ActionTypes.GRAPH_GET,
  'serviceHandle',
  'depth'
);

// CHANNELS
export const getChannels = createAction(ActionTypes.CHANNELS_GET, 'handle');
export const createChannel = createAction(
  ActionTypes.CHANNEL_CREATE,
  'serviceId',
  'channel'
);
export const editChannel = createAction(
  ActionTypes.CHANNEL_UPDATE,
  'serviceId',
  'channelId',
  'channel'
);
export const deleteChannel = createAction(
  ActionTypes.CHANNEL_DELETE,
  'serviceId',
  'channelId'
);

export const updateCrumbs = createAction(ActionTypes.UPDATE_CRUMBS, 'crumbs');

export const getTeams = createAction(ActionTypes.TEAMS_GET);

// RESET
export const reset = () => ({
  type: ActionTypes.RESET
});
