import { of, concat, iif, defer } from 'rxjs';
import {
  switchMap,
  mergeMap,
  startWith,
  flatMap,
  merge,
  debounceTime,
  map,
  catchError
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';
import queryString from 'query-string';
import { NS as AUTH_NS } from '@/reducers/auth';

import ActionTypes from './actionTypes';

export const createTeam$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAM_CREATE),
    switchMap(({ payload: { body } }) =>
      ajax(
        {
          url: `/teams`,
          method: 'POST',
          body
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        map(payload => ({
          type: ActionTypes.TEAM_CREATE_SUCCESS,
          payload
        })),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.TEAM_CREATE_SUCCESS_FAIL
          })
        )
      )
    )
  );

export const getTeams$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAMS_GET),
    switchMap(() =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/teams`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        map(payload => ({
          type: ActionTypes.TEAMS_GET_SUCCESS,
          payload
        })),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.TEAMS_GET_FAIL
          })
        )
      )
    )
  );

export const searchTeams$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.TEAMS_SEARCH),
    debounceTime(500),
    switchMap(({ payload: { q } }) =>
      ajax(
        {
          url: `/autocomplete/teams?q=${q}`,
          method: 'GET',
          body: {}
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        map(payload => ({
          type: ActionTypes.TEAMS_SEARCH_SUCCESS,
          payload
        })),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.TEAMS_SEARCH_FAIL
          })
        )
      )
    )
  );

export const validateTeamHandle$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.VALIDATE_TEAM_HANDLE),
    switchMap(({ payload: { handle } }) =>
      iif(
        () => handle,
        defer(() =>
          ajax(
            {
              url: `/team/${handle}`,
              method: 'GET'
            },
            { action$, state$ }
          ).pipe(
            mergeMap(res =>
              of({
                type: ActionTypes.VALIDATE_TEAM_HANDLE_SUCCESS,
                payload: { valid: res.status === 404 }
              })
            ),
            catchApiError(action$, res =>
              of({
                type: ActionTypes.VALIDATE_TEAM_HANDLE_SUCCESS,
                payload: { valid: res.status === 404 }
              })
            )
          )
        ),
        of({
          type: ActionTypes.VALIDATE_TEAM_HANDLE_SUCCESS,
          payload: { valid: null }
        })
      )
    )
  );

export default [createTeam$, getTeams$, searchTeams$, validateTeamHandle$];
