import { connect } from 'react-redux';
import { NS } from '../../ducks/actionTypes';
import {
  addTeamMember,
  toggleAddMemberModal,
  toggleInviteModal
} from '@/screens/Team/ducks/actions';
import TeamMenu from './TeamMenu';

const mapStateToProps = state => ({
  team: state.getIn([NS, 'team'])
});

const mapDispatchToProps = dispatch => ({
  showAddMember: () => dispatch(toggleAddMemberModal(true)),
  showInviteUser: () => dispatch(toggleInviteModal(true))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMenu);
