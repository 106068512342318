import { createAction } from '@/utils/actionCreator';
import ActionTypes from './actionTypes';

export const getUser = createAction(ActionTypes.USER_GET, 'handle');

export const getUserTeams = createAction(ActionTypes.USER_TEAMS_GET, 'handle');

export const getUserFeed = createAction(ActionTypes.USER_FEED_GET, 'handle');

export const deleteUser = createAction(ActionTypes.USER_DELETE, 'handle');

export const updateCrumbs = createAction(ActionTypes.UPDATE_CRUMBS, 'crumbs');
