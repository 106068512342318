import { connect } from 'react-redux';
import { NS as NS_AUTH } from '../../reducers/auth';
import Header from './Header';

const mapStateToProps = (state) => ({
  workspace: state.getIn([NS_AUTH, 'workspace']),
});

// const mapDispatchToProps = dispatch => ({
//   toggleOpen: open => dispatch(Actions.open(open)),
// });

export default connect(mapStateToProps)(Header);
