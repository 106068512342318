import React, { PureComponent } from 'react';
import CodeBlock from './CodeBlock';
import ReactMarkdown from 'react-markdown';

export default class Markdown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      htmlMode: 'raw'
    };
  }

  render() {
    return (
      <ReactMarkdown
        className="result"
        source={this.props.source}
        skipHtml={this.state.htmlMode === 'skip'}
        escapeHtml={this.state.htmlMode === 'escape'}
        renderers={{ code: CodeBlock }}
      />
    );
  }
}
