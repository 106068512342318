import React from 'react';

import { Paper, Typography } from '@material-ui/core';
import { List, ListItem } from '@/components/List';

import './ServiceHealth.scss';

const ServiceHealth = ({ health = [] }) => {
  return (
    <Paper>
      <List>
        <Typography variant="subtitle1">Heath Checks</Typography>
        {health.map(healthcheck => (
          <ListItem key={healthcheck.get('id')} className="ServiceHealth">
            <h4 className="title">{healthcheck.get('name')}</h4>
            <div className="bar" />
            <div className="description">{healthcheck.get('disposition')}</div>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default ServiceHealth;
