import { of, concat } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';

import { NS as AUTH_NS } from '@/reducers/auth';

import ActionTypes from './actionTypes';
import Actions from './actions';

export const getUserServices$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_SERVICES_REQUEST),
    switchMap(action =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/services`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        map(Actions.setUserServices),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_SERVICES_REJECTED
          })
        )
      )
    )
  );

export const getUserTeams$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.USER_TEAMS_REQUEST),
    switchMap(action =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/teams`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        map(Actions.setUserTeams),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.USER_TEAMS_REJECTED
          })
        )
      )
    )
  );

export default [getUserServices$, getUserTeams$];
