import { connect } from 'react-redux';

import { NS } from '../../ducks/actionTypes';
import { SsoProviders } from './SsoProviders';

import {
  getSsoProviders,
  getSsoProvider,
  createSsoProvider,
  updateSsoProvider
} from '../../ducks/actions';

const mapStateToProps = state => ({
  pending: state.getIn([NS, 'pending', 'ssoProviders']),
  ssoProviders: state.getIn([NS, 'ssoProviders'])
});

const mapDispatchToProps = dispatch => ({
  getSsoProviders: () => dispatch(getSsoProviders()),
  getSsoProvider: handle => dispatch(getSsoProvider(handle)),
  createSsoProvider: body => dispatch(createSsoProvider(body)),
  updateSsoProvider: (id, body) => dispatch(updateSsoProvider(id, body))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SsoProviders);
