import { connect } from 'react-redux';
import { editChannel, deleteChannel } from '@/screens/Service/ducks/actions';
import { NS } from '@/screens/Service/ducks/reducer';

import Channel from './Channel';

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service'])
});

const mapDispatchToProps = dispatch => ({
  edit: (serviceId, channelId, channel) =>
    dispatch(editChannel(serviceId, channelId, channel)),
  deleteChannel: (serviceId, channelId) =>
    dispatch(deleteChannel(serviceId, channelId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Channel);
