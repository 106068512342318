import { createTypes, asyncType, createNS } from '@/utils/actionCreator';

export const NS = createNS('USER');

export default createTypes(
  [
    ...asyncType('USER_GET'),

    ...asyncType('USER_UPDATE'),

    ...asyncType('USER_TEAMS_GET'),
    ...asyncType('USER_FEED_GET'),
    ...asyncType('USER_DELETE'),

    'UPDATE_CRUMBS',
    'RESET'
  ],
  `${NS}`
);
