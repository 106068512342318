import { of, concat } from 'rxjs';
import {
  switchMap,
  startWith,
  flatMap,
  merge,
  map,
  catchError
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from '@/utils/ajax';

import ActionTypes from './actionTypes';

export const register$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.REGISTER),
    switchMap(action =>
      ajax(
        {
          url: `/register`,
          method: 'POST',
          body: {
            beta_key: action.payload.beta_key,
            email: action.payload.email
          }
        },
        { action$, state$ }
      ).pipe(
        map(({ response }) => response),
        flatMap(() => of({ type: ActionTypes.REGISTER_SUCCESS })),
        catchError(error =>
          of({
            type: ActionTypes.REGISTER_FAIL,
            payload: error.response,
            error: true
          })
        )
      )
    )
  );

export const createWorkspace$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.WORKSPACE_CREATE),
    switchMap(action =>
      ajax(
        {
          url: `/registration/register-workspace`,
          method: 'POST',
          body: {
            beta_key: action.payload.beta_key,
            owner: action.payload.owner,
            workspace: action.payload.workspace
          }
        },
        { action$, state$ }
      ).pipe(
        map(({ response }) => response),
        flatMap(response =>
          of({ type: ActionTypes.WORKSPACE_CREATE_SUCCESS, payload: response })
        ),
        catchError(error =>
          of({
            type: ActionTypes.WORKSPACE_CREATE_FAIL,
            payload: error && error.response ? error.response : error,
            error: true
          })
        )
      )
    )
  );

export default [register$, createWorkspace$];
