import React from 'react';
import { render } from 'react-dom';
// import { AppContainer } from 'react-hot-loader';
import Redbox from 'redbox-react';
import { AppContainer } from 'react-hot-loader';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable'
import { GatewayDest, GatewayProvider } from 'react-gateway';
import { fromJS } from 'immutable';
import parseDomain from 'parse-domain';

import configureStore, { history } from './store/configureStore';
import App from './components/App';
import RootApp from './components/RootApp';

import './index.scss';

const payload = Object.assign({}, window.__INITIAL_STATE__);

const store = configureStore(fromJS(payload));
const MOUNT_NODE = document.getElementById('root');

const { subdomain: workspace } = parseDomain(window.location.hostname, {
  customTlds: ['localhost']
});

const CurrentApp = workspace ? App : RootApp;
// render(
//   <Provider store={store}>
//     <ConnectedRouter history={history}>
//       <GatewayProvider>
//         <App />
//       </GatewayProvider>
//     </ConnectedRouter>
//   </Provider>,
//   MOUNT_NODE
// );

// const root = document.getElementById('root');
render(
  <AppContainer errorReporter={Redbox}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <GatewayProvider>
          <CurrentApp />
        </GatewayProvider>
      </ConnectedRouter>
    </Provider>
  </AppContainer>,
  MOUNT_NODE
);

if (module.hot) {
  module.hot.accept('./components/App', () => {
    render(
      <AppContainer errorReporter={Redbox}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <GatewayProvider>
              <CurrentApp />
            </GatewayProvider>
          </ConnectedRouter>
        </Provider>
      </AppContainer>,
      MOUNT_NODE
    );
  });
}

// if (module.hot) {
//   module.hot.accept('./components/App', () => {
//     render(
//       <AppContainer errorReporter={Redbox}>
//         <App store={store} browserHistory={history} />
//       </AppContainer>,
//       root
//     );
//   });
// }

// // Development Tools
// // ------------------------------------
// if (process.env.NODE_ENV === 'development') {
//   if (module.hot) {
//     const renderApp = render;
//     const renderError = error => {
//       const RedBox = require('redbox-react').default;

//       ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
//     };

//     render = () => {
//       try {
//         renderApp();
//       } catch (e) {
//         console.error(e);
//         renderError(e);
//       }
//     };

//     // Setup hot module replacement
//     module.hot.accept(
//       [
//         './components/App'
//         // './routes/index',
//       ],
//       () =>
//         setImmediate(() => {
//           ReactDOM.unmountComponentAtNode(MOUNT_NODE);
//           render();
//         })
//     );
//   }
// }

// if (!process.env.NODE_ENV !== 'test') render();
