import { Map, List, fromJS } from 'immutable';
// import { createSelector } from "reselect";

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  error: false,
  // overview
  service: Map(),
  team: Map(),
  dependencies: List(),
  remoteDependencies: List(),
  dependents: List(),
  overviewAnnouncements: List(),
  overviewIssues: List(),
  notFound: Map({
    service: false,
  }),

  incidents: List(),
  incidentModalVisible: false,
  incident: null,

  notices: List(),
  noticesParams: Map({
    type: 'incident,maintenance',
    params: 'open',
  }),
  noticeModalVisible: false,
  noticeViewModalVisible: false,
  notice: null,

  healthchecks: List(),
  healthcheckModalVisible: false,
  healthcheck: null,

  publishedPages: List(),
  publishedPage: Map(),
  draftPages: List(),
  draftPage: Map(),

  // Published Annoucements
  publishedAnnouncements: List(),
  publishedAnnouncement: Map(),
  // Draft Annoucements
  draftAnnouncements: List(),
  draftAnnouncement: Map(),

  // Published Annoucements
  publishedReleaseNotes: List(),
  publishedReleaseNote: Map(),
  // Draft Annoucements
  draftReleaseNotes: List(),
  draftReleaseNote: Map(),

  // Published Annoucements
  publishedApiDocs: List(),
  publishedApiDoc: Map(),
  // Draft Annoucements
  draftApiDocs: List(),
  draftApiDoc: Map(),

  // Published Annoucements
  publishedOpenApiSpecs: List(),
  publishedOpenApiSpec: Map(),
  // Draft Annoucements
  draftOpenApiSpecs: List(),
  draftOpenApiSpec: Map(),

  graph: [],

  runners: [],

  pending: Map({
    team: true,
    service: true,
    channels: true,
    clients: true,
    healthchecks: true,
    healthCheckReports: Map(),
    dependencies: true,
    remoteDependencies: true,
    remoteServices: false,
    dependents: true,
    overviewAnnouncements: true,
    overviewIssues: true,
    notices: true,

    //
    publishedPages: true,
    publishedPage: true,
    draftPages: true,
    draftPage: false,

    publishedAnnouncements: false,
    publishedAnnouncement: false,
    draftAnnouncements: false,
    draftAnnouncement: false,

    publishedReleaseNotes: false,
    publishedReleaseNote: false,
    draftReleaseNotes: false,
    draftReleaseNote: false,

    publishedApiDocs: false,
    publishedApiDoc: false,
    draftApiDocs: false,
    draftApiDoc: false,

    publishedOpenApiSpecs: false,
    publishedOpenApiSpec: false,
    draftOpenApiSpecs: false,
    draftOpenApiSpec: false,

    graph: true,
    runners: true,
  }),

  channels: List(),
  channel: Map(),

  clients: List(),
  client: Map(),

  dailyServiceHealth: Map(),
  healthCheckReports: Map(),
  filteredDependencies: List(),
  remoteServices: List(),
  dependencyModalVisible: false,
  remoteDependencyModalVisible: false,
  teams: List(),

  crumbs: [],
});

export default function ServiceReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SERVICE_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'service'], true)
        .set('service', Map());
    case ActionTypes.SERVICE_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'service'], false)
        .set('service', fromJS(action.payload));
    case ActionTypes.SERVICE_GET_FAIL:
      return state
        .set('error', true)
        .setIn(['pending', 'service'], false)
        .setIn(['pending', 'team'], false)
        .setIn(['notFound', 'service'], true)
        .set('service', Map());

    case ActionTypes.SERVICE_UPDATE_SUCCESS:
    case ActionTypes.SERVICE_UPLOAD_SUCCESS:
    case ActionTypes.SERVICE_ICON_DELETE_SUCCESS:
    case ActionTypes.SERVICE_HEALTH_POST_SUCCESS:
    case ActionTypes.SERVICE_STAGE_POST_SUCCESS:
    case ActionTypes.SERVICE_VISIBILITY_POST_SUCCESS:
      return state.set('service', fromJS(action.payload));

    case ActionTypes.SERVICE_UPDATE_FAIL:
      return state;

    case ActionTypes.SERVICE_TEAM_GET_SUCCESS:
      return state
        .setIn(['pending', 'team'], false)
        .set('team', fromJS(action.payload));

    // Health
    case ActionTypes.SERVICE_HEALTH_CHECKS_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'healthchecks'], true)
        .set('healthchecks', List());
    case ActionTypes.SERVICE_HEALTH_CHECKS_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'healthchecks'], false)
        .set('healthchecks', fromJS(action.payload));
    case ActionTypes.SERVICE_HEALTH_CHECKS_GET_FAIL:
      return state
        .set('error', true)
        .setIn(['pending', 'healthchecks'], false)
        .set('healthchecks', List());

    case ActionTypes.HEALTHCHECK_CREATE_SUCCESS:
      return state
        .set('error', false)
        .update('healthchecks', (healthchecks) =>
          healthchecks.push(fromJS(action.payload))
        );
    // Dependencies
    case ActionTypes.SERVICE_DEPENDENCIES_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'dependencies'], true)
        .set('dependencies', List());
    case ActionTypes.SERVICE_DEPENDENCIES_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'dependencies'], false)
        .set(
          'dependencies',
          fromJS(action.payload.map(({ dependency }) => dependency))
        );
    case ActionTypes.SERVICE_DEPENDENCIES_GET_FAIL:
      return state
        .set('error', true)
        .setIn(['pending', 'dependencies'], false)
        .set('dependencies', List());

    case ActionTypes.SERVICES_AUTOCOMPLETE:
      return state;
    case ActionTypes.SERVICES_AUTOCOMPLETE_SUCCESS:
      return state.set('filteredDependencies', List(action.payload));
    case ActionTypes.DEPENDENCIES_MODAL_VISIBLE:
      return state.set('dependencyModalVisible', action.payload.show);
    case ActionTypes.SERVICE_DEPENDENCIES_ADD_SUCCESS:
      return state
        .update('dependencies', (dependencies) =>
          dependencies
            .push(fromJS(action.payload.dependency))
            .sortBy((d) => d.get('name'))
        )
        .set('dependencyModalVisible', false);

    case ActionTypes.SERVICE_DEPENDENCIES_DELETE_SUCCESS:
      return state
        .update('dependencies', (dependencies) =>
          dependencies.filter((d) => {
            return d.get('handle') !== action.payload.dependencyHandle;
          })
        )
        .set('dependencyModalVisible', false);

    //remoteDependencies
    case ActionTypes.SERVICES_REMOTE_SERVICES_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'remoteServices'], true)
        .set('remoteServices', List());

    case ActionTypes.SERVICES_REMOTE_SERVICES_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'remoteServices'], false)
        .set('remoteServices', fromJS(action.payload));

    case ActionTypes.SERVICES_REMOTE_DEPENDENCIES_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'remoteDependencies'], true)
        .set('remoteDependencies', List());

    case ActionTypes.SERVICES_REMOTE_DEPENDENCIES_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'remoteDependencies'], false)
        .set(
          'remoteDependencies',
          fromJS(action.payload.map(({ dependency }) => dependency))
        );

    case ActionTypes.SERVICES_REMOTE_DEPENDENCY_CREATE_SUCCESS:
      return state
        .update('remoteDependencies', (dependencies) =>
          dependencies
            .push(fromJS(action.payload.dependency))
            .sortBy((d) => d.get('name'))
        )
        .set('remoteDependencyModalVisible', false);

    case ActionTypes.SERVICES_REMOTE_DEPENDENCY_DELETE_SUCCESS:
      return state
        .update('remoteDependencies', (dependencies) =>
          dependencies.filter((d) => {
            return d.get('handle') !== action.payload.remote;
          })
        )
        .set('remoteDependencyModalVisible', false);

    case ActionTypes.REMOTE_DEPENDENCIES_MODAL_VISIBLE:
      return state.set(
        'remoteDependencyModalVisible',
        action.payload.visibility
      );

    //dependents
    case ActionTypes.SERVICE_DEPENDENTS:
      return state
        .setIn(['pending', 'dependents'], true)
        .set('dependents', List());
    case ActionTypes.SERVICE_DEPENDENTS_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'dependents'], false)
        .set(
          'dependents',
          fromJS(action.payload.map(({ dependent }) => dependent))
        );

    //OVERVIEW_ANNOUNCEMENTS_GET_SUCCESS
    case ActionTypes.OVERVIEW_ANNOUNCEMENTS_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'overviewAnnouncements'], true)
        .set('overviewAnnouncements', List());
    case ActionTypes.OVERVIEW_ANNOUNCEMENTS_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'overviewAnnouncements'], false)
        .set('overviewAnnouncements', fromJS(action.payload));

    // OVERVIEW_ISSUES_GET_SUCCESS:
    case ActionTypes.OVERVIEW_ISSUES_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'overviewIssues'], true)
        .set('overviewIssues', List());
    case ActionTypes.OVERVIEW_ISSUES_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'overviewIssues'], false)
        .set('overviewIssues', fromJS(action.payload));

    // NOTICE
    case ActionTypes.NOTICES_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'notices'], true)
        .setIn(['noticesParams', 'type'], action.payload.type)
        .setIn(['noticesParams', 'status'], action.payload.status)
        .set('notices', List());
    case ActionTypes.NOTICES_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'notices'], false)
        .set('notices', fromJS(action.payload));
    case ActionTypes.NOTICES_GET_FAIL:
      return state
        .set('error', true)
        .setIn(['pending', 'notices'], false)
        .set('notices', List());

    case ActionTypes.NOTICE_MODAL_VISIBLE:
      return state
        .set('noticeModalVisible', action.payload.show)
        .set('notice', action.payload.notice || null);

    case ActionTypes.NOTICE_VIEW_MODAL_VISIBLE:
      return state
        .set('noticeViewModalVisible', action.payload.show)
        .set('notice', action.payload.notice || null);

    case ActionTypes.NOTICE_CREATE_SUCCESS:
      return state.set('noticeModalVisible', false);
    // .update('notices', maintenance =>
    //   maintenance.unshift(fromJS(action.payload))
    // );
    case ActionTypes.NOTICE_UPDATE_SUCCESS:
      return state.set('noticeModalVisible', false);
    // .update('maintenances', maintenances =>
    //   maintenances.update(
    //     maintenances.findIndex(
    //       maintenance => maintenance.get('id') === action.payload.id
    //     ),
    //     () => fromJS(action.payload)
    //   )
    // );

    //PUBLISHED PAGES
    case ActionTypes.PUBLISHED_PAGES_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedPages'], true)
        .set('publishedPages', List());
    case ActionTypes.PUBLISHED_PAGES_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedPages'], false)
        .set('publishedPages', fromJS(action.payload));

    case ActionTypes.PUBLISHED_PAGE_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedPage'], true)
        .set('publishedPage', Map());
    case ActionTypes.PUBLISHED_PAGE_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedPage'], false)
        .set('publishedPage', fromJS(action.payload));

    //DRAFT PAGES
    case ActionTypes.DRAFT_PAGES_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'draftPages'], true)
        .set('draftPages', List());
    case ActionTypes.DRAFT_PAGES_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftPages'], false)
        .set('draftPages', fromJS(action.payload));

    case ActionTypes.DRAFT_PAGE_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'draftPage'], true)
        .set('draftPage', Map());
    case ActionTypes.DRAFT_PAGE_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftPage'], false)
        .set('draftPage', fromJS(action.payload));

    case ActionTypes.DRAFT_PAGE_CREATE_SUCCESS:
    case ActionTypes.DRAFT_PAGE_UPDATE_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftPage'], false)
        .set('draftPage', fromJS(action.payload));
    case ActionTypes.DRAFT_PAGE_PUBLISH_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftPage'], false)
        .setIn(['draftPage', 'is_published'], action.payload.is_published)
        .setIn(['draftPage', 'published_at'], action.payload.published_at);
    case ActionTypes.DRAFT_PAGE_UNPUBLISH_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftPage'], false)
        .setIn(['draftPage', 'published_at'], null);

    //PUBLISHED ANNOUNCEMENTS
    case ActionTypes.PUBLISHED_ANNOUNCEMENTS_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedAnnouncements'], true)
        .set('publishedAnnouncements', List());
    case ActionTypes.PUBLISHED_ANNOUNCEMENTS_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedAnnouncements'], false)
        .set('publishedAnnouncements', fromJS(action.payload));

    case ActionTypes.PUBLISHED_ANNOUNCEMENT_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedAnnouncement'], true)
        .set('publishedAnnouncement', Map());
    case ActionTypes.PUBLISHED_ANNOUNCEMENT_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedAnnouncement'], false)
        .set('publishedAnnouncement', fromJS(action.payload));

    //DRAFT ANNOUNCEMENTS
    case ActionTypes.DRAFT_ANNOUNCEMENTS_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'draftAnnouncements'], true)
        .set('draftAnnouncements', List());
    case ActionTypes.DRAFT_ANNOUNCEMENTS_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftAnnouncements'], false)
        .set('draftAnnouncements', fromJS(action.payload));

    case ActionTypes.DRAFT_ANNOUNCEMENT_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'draftAnnouncement'], true)
        .set('draftAnnouncement', Map());
    case ActionTypes.DRAFT_ANNOUNCEMENT_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftAnnouncement'], false)
        .set('draftAnnouncement', fromJS(action.payload));

    case ActionTypes.DRAFT_ANNOUNCEMENT_CREATE_SUCCESS:
    case ActionTypes.DRAFT_ANNOUNCEMENT_UPDATE_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftAnnouncement'], false)
        .set('draftAnnouncement', fromJS(action.payload));
    case ActionTypes.DRAFT_ANNOUNCEMENT_PUBLISH_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftAnnouncement'], false)
        .setIn(
          ['draftAnnouncement', 'is_published'],
          action.payload.is_published
        )
        .setIn(
          ['draftAnnouncement', 'published_at'],
          action.payload.published_at
        );
    case ActionTypes.DRAFT_ANNOUNCEMENT_UNPUBLISH_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftAnnouncement'], false)
        .setIn(['draftAnnouncement', 'published_at'], null);
    case ActionTypes.DRAFT_ANNOUNCEMENT_RESET:
      return state.set('error', false).set('draftAnnouncement', Map());

    //PUBLISHED RELEASE_NOTES
    case ActionTypes.PUBLISHED_RELEASE_NOTES_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedReleaseNotes'], true)
        .set('publishedReleaseNotes', List());
    case ActionTypes.PUBLISHED_RELEASE_NOTES_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedReleaseNotes'], false)
        .set('publishedReleaseNotes', fromJS(action.payload));

    case ActionTypes.PUBLISHED_RELEASE_NOTE_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedReleaseNote'], true)
        .set('publishedReleaseNote', Map());
    case ActionTypes.PUBLISHED_RELEASE_NOTE_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedReleaseNote'], false)
        .set('publishedReleaseNote', fromJS(action.payload));

    //DRAFT RELEASE_NOTES
    case ActionTypes.DRAFT_RELEASE_NOTES_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'draftReleaseNotes'], true)
        .set('draftReleaseNotes', List());
    case ActionTypes.DRAFT_RELEASE_NOTES_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftReleaseNotes'], false)
        .set('draftReleaseNotes', fromJS(action.payload));

    case ActionTypes.DRAFT_RELEASE_NOTE_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'draftReleaseNote'], true)
        .set('draftReleaseNote', Map());
    case ActionTypes.DRAFT_RELEASE_NOTE_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftReleaseNote'], false)
        .set('draftReleaseNote', fromJS(action.payload));

    case ActionTypes.DRAFT_RELEASE_NOTE_CREATE_SUCCESS:
    case ActionTypes.DRAFT_RELEASE_NOTE_UPDATE_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftReleaseNote'], false)
        .set('draftReleaseNote', fromJS(action.payload));
    case ActionTypes.DRAFT_RELEASE_NOTE_PUBLISH_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftReleaseNote'], false)
        .setIn(
          ['draftReleaseNote', 'is_published'],
          action.payload.is_published
        )
        .setIn(
          ['draftReleaseNote', 'published_at'],
          action.payload.published_at
        );
    case ActionTypes.DRAFT_RELEASE_NOTE_UNPUBLISH_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftReleaseNote'], false)
        .setIn(['draftReleaseNote', 'published_at'], null);
    case ActionTypes.DRAFT_RELEASE_NOTE_RESET:
      return state.set('error', false).set('draftReleaseNote', Map());

    //PUBLISHED API_DOCS
    case ActionTypes.PUBLISHED_API_DOCS_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedApiDocs'], true)
        .set('publishedApiDocs', List());
    case ActionTypes.PUBLISHED_API_DOCS_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedApiDocs'], false)
        .set('publishedApiDocs', fromJS(action.payload));

    case ActionTypes.PUBLISHED_API_DOC_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedApiDoc'], true)
        .set('publishedApiDoc', Map());
    case ActionTypes.PUBLISHED_API_DOC_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedApiDoc'], false)
        .set('publishedApiDoc', fromJS(action.payload));

    //DRAFT API_DOCS
    case ActionTypes.DRAFT_API_DOCS_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'draftApiDocs'], true)
        .set('draftApiDocs', List());
    case ActionTypes.DRAFT_API_DOCS_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftApiDocs'], false)
        .set('draftApiDocs', fromJS(action.payload));

    case ActionTypes.DRAFT_API_DOC_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'draftApiDoc'], true)
        .set('draftApiDoc', Map());
    case ActionTypes.DRAFT_API_DOC_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftApiDoc'], false)
        .set('draftApiDoc', fromJS(action.payload));

    case ActionTypes.DRAFT_API_DOC_CREATE_SUCCESS:
    case ActionTypes.DRAFT_API_DOC_UPDATE_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftApiDoc'], false)
        .set('draftApiDoc', fromJS(action.payload));
    case ActionTypes.DRAFT_API_DOC_PUBLISH_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftApiDoc'], false)
        .setIn(['draftApiDoc', 'is_published'], action.payload.is_published)
        .setIn(['draftApiDoc', 'published_at'], action.payload.published_at);
    case ActionTypes.DRAFT_API_DOC_UNPUBLISH_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftApiDoc'], false)
        .setIn(['draftApiDoc', 'published_at'], null);
    case ActionTypes.DRAFT_API_DOC_RESET:
      return state.set('error', false).set('draftApiDoc', Map());

    //PUBLISHED OPEN_API_SPECS
    case ActionTypes.PUBLISHED_OPEN_API_SPECS_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedOpenApiSpecs'], true)
        .set('publishedOpenApiSpecs', List());
    case ActionTypes.PUBLISHED_OPEN_API_SPECS_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedOpenApiSpecs'], false)
        .set('publishedOpenApiSpecs', fromJS(action.payload));

    case ActionTypes.PUBLISHED_OPEN_API_SPEC_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedOpenApiSpec'], true)
        .set('publishedOpenApiSpec', Map());
    case ActionTypes.PUBLISHED_OPEN_API_SPEC_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'publishedOpenApiSpec'], false)
        .set('publishedOpenApiSpec', fromJS(action.payload));

    //DRAFT OPEN_API_SPECS
    case ActionTypes.DRAFT_OPEN_API_SPECS_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'draftOpenApiSpecs'], true)
        .set('draftOpenApiSpecs', List());
    case ActionTypes.DRAFT_OPEN_API_SPECS_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftOpenApiSpecs'], false)
        .set('draftOpenApiSpecs', fromJS(action.payload));

    case ActionTypes.DRAFT_OPEN_API_SPEC_GET:
      return state
        .set('error', false)
        .setIn(['pending', 'draftOpenApiSpec'], true)
        .set('draftOpenApiSpec', Map());
    case ActionTypes.DRAFT_OPEN_API_SPEC_GET_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftOpenApiSpec'], false)
        .set('draftOpenApiSpec', fromJS(action.payload));

    case ActionTypes.DRAFT_OPEN_API_SPEC_CREATE_SUCCESS:
    case ActionTypes.DRAFT_OPEN_API_SPEC_UPDATE_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftOpenApiSpec'], false)
        .set('draftOpenApiSpec', fromJS(action.payload));
    case ActionTypes.DRAFT_OPEN_API_SPEC_PUBLISH_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftOpenApiSpec'], false)
        .setIn(
          ['draftOpenApiSpec', 'is_published'],
          action.payload.is_published
        )
        .setIn(
          ['draftOpenApiSpec', 'published_at'],
          action.payload.published_at
        );
    case ActionTypes.DRAFT_OPEN_API_SPEC_UNPUBLISH_SUCCESS:
      return state
        .set('error', false)
        .setIn(['pending', 'draftOpenApiSpec'], false)
        .setIn(['draftOpenApiSpec', 'published_at'], null);
    case ActionTypes.DRAFT_OPEN_API_SPEC_RESET:
      return state.set('error', false).set('draftOpenApiSpec', Map());

    // REPORTS
    case ActionTypes.SERVICE_HEALH_CHECK_DAILY_GET_SUCCESS:
      return state.set('dailyServiceHealth', fromJS(action.payload.slice(-90)));

    case ActionTypes.SERVICE_HEALTH_CHECK_RESULTS_GET:
      return state
        .setIn(['healthCheckReports', action.payload.id], fromJS([]))
        .setIn(['pending', 'healthCheckReports', action.payload.id], true);

    case ActionTypes.SERVICE_HEALTH_CHECK_RESULTS_GET_SUCCESS:
      return state
        .setIn(
          ['healthCheckReports', action.payload.id],
          fromJS(action.payload.data.results || [])
        )
        .setIn(['pending', 'healthCheckReports', action.payload.id], false);

    case ActionTypes.HEALTHCHECK_UPDATE_SUCCESS:
      return state.update('healthchecks', (healthchecks) =>
        healthchecks.update(
          healthchecks.findIndex(
            (healthcheck) => healthcheck.get('id') === action.payload.id
          ),
          () => fromJS(action.payload)
        )
      );

    // HEALTHCHECK FORM
    case ActionTypes.HEALTHCHECK_MODAL_VISIBLE:
      return state
        .set('healthcheckModalVisible', action.payload.visible)
        .set('healthcheck', action.payload.record || null);

    // CHANNELS
    case ActionTypes.CHANNELS_GET:
      return state.setIn(['pending', 'channels'], true).set('channels', List());
    case ActionTypes.CHANNELS_GET_SUCCESS:
      return state
        .setIn(['pending', 'channels'], false)
        .set('channels', fromJS(action.payload));

    case ActionTypes.CHANNEL_CREATE:
      return state;
    case ActionTypes.CHANNEL_CREATE_SUCCESS:
      return state.update('channels', (channels) =>
        channels.push(fromJS(action.payload))
      );

    case ActionTypes.CHANNEL_UPDATE:
      return state;
    case ActionTypes.CHANNEL_UPDATE_SUCCESS:
      return state.update('channels', (channels) =>
        channels.update(
          channels.findIndex(
            (channel) => channel.get('id') === action.payload.id
          ),
          () => fromJS(action.payload)
        )
      );

    case ActionTypes.CHANNEL_DELETE:
      return state;
    case ActionTypes.CHANNEL_DELETE_SUCCESS:
      return state.update('channels', (channels) =>
        channels.filter((channel) => channel.get('id') !== action.payload.id)
      );

    case ActionTypes.TEAMS_GET_SUCCESS:
      return state.set('teams', fromJS(action.payload));

    case ActionTypes.GRAPH_GET:
      return state.setIn(['pending', 'graph'], true);
    case ActionTypes.GRAPH_GET_SUCCESS:
      return state
        .setIn(['pending', 'graph'], false)
        .set('graph', action.payload);

    case ActionTypes.RUNNERS_GET_SUCCESS:
      return state
        .setIn(['pending', 'runners'], false)
        .set('runners', action.payload);

    case ActionTypes.UPDATE_CRUMBS:
      return state.set('crumbs', action.payload.crumbs || []);

    case ActionTypes.RESET:
      return initialState;
    default:
      return state;
  }
}
