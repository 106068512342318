import React, { PureComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  CircularProgress,
  Avatar,
  Button,
  Toolbar,
  Icon,
  Card,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Select,
  MenuItem,
  InputBase,
  Portal,
  Link
} from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import { MemberMenu } from '../../components';
import { TablePagination } from '@/components';

const TableInput = withStyles(theme => ({
  input: {
    border: '1px solid transparent'
  }
}))(InputBase);

const MemberRow = params => {
  const { member, team, onMemberRoleChange, canEdit } = params;

  const id = member.getIn(['user', 'id']);
  const name = member.getIn(['user', 'name']);
  const role = member.get('role');
  const handle = member.getIn(['user', 'handle']);
  const initial = name.charAt(0).toUpperCase();
  const icon = member.getIn(['user', 'thumbnail_url']);
  const description = member.getIn(['user', 'description']);
  const types = ['admin', 'member'];

  const handleRoleChange = event => {
    const role = event.target.value;
    onMemberRoleChange(id, role);
  };

  return (
    <TableRow>
      <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Avatar src={icon}>{initial}</Avatar>
      </TableCell>
      <TableCell>
        <Link component={RouterLink} to={`/users/${handle}`}>
          {name}
        </Link>
      </TableCell>
      {canEdit ? (
        <TableCell>
          <Select
            defaultValue={role}
            onChange={handleRoleChange}
            input={<TableInput />}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="member">Member</MenuItem>
          </Select>
        </TableCell>
      ) : (
        <TableCell>{role}</TableCell>
      )}
      <TableCell>{description}</TableCell>
      <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
        <MemberMenu member={member} team={team} />
      </TableCell>
    </TableRow>
  );
};

const MembersContent = ({
  members,
  team,
  loading,
  canEdit,
  onMemberRoleChange
}) => {
  if (loading) {
    return (
      <TableRow>
        <TableCell
          align="center"
          colSpan={canEdit ? 5 : 4}
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <CircularProgress size={27} />
        </TableCell>
      </TableRow>
    );
  } else if (members.size === 0) {
    return (
      <TableRow>
        <TableCell align="center" colSpan={canEdit ? 5 : 4}>
          No Members
        </TableCell>
      </TableRow>
    );
  }

  // const handleRoleChange = (role, id) => {
  //   onMemberRoleChange(id, role);
  // };

  return members.map(member => (
    <MemberRow
      member={member}
      team={team}
      key={member.getIn(['user', 'id'])}
      onMemberRoleChange={onMemberRoleChange}
      canEdit={canEdit}
    />
  ));
};

class Members extends PureComponent {
  state = { limit: 20, offset: 0 };
  componentDidMount() {
    this.getTeamMembers();
    this.props.updateCrumbs([{ label: 'Members' }]);
  }

  getTeamMembers() {
    const { getTeamMembers, team } = this.props;
    const id = team.get('handle');
    const { offset, limit } = this.state;
    getTeamMembers(id, { cursor: offset, limit });
  }

  handleUpdateMemberRole = (userId, role) => {
    const teamId = this.props.team.get('id');
    this.props.updateMemberRole(teamId, userId, role);
  };

  updateOffset = offset => {
    this.setState({ offset }, () => {
      this.getTeams();
    });
  };

  render() {
    const { members, add, loading, team, total, canEdit } = this.props;
    const { offset, limit } = this.state;
    return (
      <div className="container">
        <Paper>
          {/* <Toolbar>
            <Typography variant="h6" style={{ flex: '1' }}>
              Members
            </Typography> */}
            {canEdit ? (
              <Portal container={document.getElementById('foo')}>
                <Button color="primary" onClick={add}>
                  Add Member
                </Button>
              </Portal>
            ) : null}
          {/* </Toolbar> */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '72px' }} />
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Description</TableCell>
                  {canEdit ? <TableCell style={{ width: '72px' }} /> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                <MembersContent
                  members={members}
                  loading={loading}
                  team={team}
                  canEdit={canEdit}
                  onMemberRoleChange={this.handleUpdateMemberRole}
                />
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            limit={limit}
            offset={offset}
            total={total}
            updateOffset={this.updateOffset}
          />
        </Paper>
      </div>
    );
  }
}

export default Members;
