import ActionTypes from './actionTypes';

export const getUserServices = payload => ({
  type: ActionTypes.USER_SERVICES_REQUEST,
  payload
});

export const setUserServices = payload => ({
  type: ActionTypes.USER_SERVICES_SUCCESS,
  payload
});

export const getUserTeams = payload => ({
  type: ActionTypes.USER_TEAMS_REQUEST,
  payload
});

export const setUserTeams = payload => ({
  type: ActionTypes.USER_TEAMS_SUCCESS,
  payload
});

export const Actions = {
  getUserServices,
  setUserServices,
  getUserTeams,
  setUserTeams
};
export default Actions;
