import {
  AssetFieldGroup,
  ConfirmationModal,
  InputFieldGroup,
} from '@/components';
import React, { PureComponent } from 'react';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';

import Clients from './components/Clients';
import Runners from './components/runners';

class Settings extends PureComponent {
  componentDidMount() {
    const id = this.props.team.get('id');
    this.props.getServiceCount(id);
    this.props.updateCrumbs([{ label: 'Setting' }]);
  }

  handleDeleteClick = () => {
    const { showDeleteWarning } = this.props;
    showDeleteWarning(true);
  };

  handleConfirmDelete = () => {
    const { showDeleteWarning, deleteTeam, team } = this.props;
    const id = team.get('id');
    deleteTeam(id);
    showDeleteWarning(false);
  };

  handleHideConfirmDelete = () => {
    const { showDeleteWarning } = this.props;
    showDeleteWarning(false);
  };
  render() {
    const {
      pending,
      team,
      update,
      upload,
      clear,
      showDeleteConfirm,
      serviceCount,
    } = this.props;

    if (pending) return 'loading';
    const entityId = team.get('handle');
    return (
      <div className="container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AssetFieldGroup
              label="Team Logo"
              entity={team}
              entityId={entityId}
              asset={team.getIn(['thumbnail_url'])}
              fallbackName={team.get('name')}
              upload={upload}
              clear={clear}
            />
          </Grid>
          <Grid item xs={12}>
            <InputFieldGroup
              label="Name"
              entity={team}
              entityId={entityId}
              field={'name'}
              save={update}
              sublabel="The name of your team."
            />
          </Grid>
          <Grid item xs={12}>
            <InputFieldGroup
              label="Description"
              entity={team}
              entityId={entityId}
              field={'description'}
              multiline={true}
              rows={4}
              save={update}
              sublabel="A quick description of your team."
            />
          </Grid>
          <Grid item xs={12}>
            <Clients />
          </Grid>
          <Grid item xs={12}>
            <Runners />
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Delete Team" />
              <CardContent>
                <Typography color="textSecondary" variant="body2">
                  Deleting a team is permanent and cannot be undone.
                </Typography>
                {serviceCount > 0 ? (
                  <Typography color="secondary" variant="body2">
                    You cannot delete a team with services or subteams.
                  </Typography>
                ) : (
                  ''
                )}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.handleDeleteClick}
                  disabled={serviceCount > 0}
                >
                  Delete Team
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <ConfirmationModal
          title={'Delete Team'}
          visible={showDeleteConfirm}
          text={'Are you sure you want to delete this team?'}
          confirm={this.handleConfirmDelete}
          hide={this.handleHideConfirmDelete}
        />
      </div>
    );
  }
}

export default Settings;
