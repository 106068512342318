import scenes from './scenes';

export const getRoutes = props => {
  const { team, canEdit } = props;
  return [
    {
      label: 'Overview',
      path: `/services/:id`,
      key: 'overview',
      exact: true,
      component: scenes.Overview
    },

    {
      label: 'Health Checks',
      path: `/services/:id/health-checks`,
      key: 'health-checks',
      exact: true,
      component: scenes.HealthChecks
    },
    {
      label: 'Notices',
      path: `/services/:id/notices`,
      key: 'notices',
      exact: true,
      component: scenes.Notices
    },
    {
      label: 'Announcements',
      path: `/services/:id/announcements`,
      childPath: [
        '/services/:id/draft-announcements',
        '/services/:id/announcements/'
      ],
      key: 'announcements',
      exact: true,
      component: scenes.PublishedAnnouncements,
      routeProps: {
        section: 'announcements',
        sectionTitle: 'Announcements',
        noResultsText: 'No published Announcements'
      }
    },
    {
      path: `/services/:id/announcements/:pageId`,
      key: 'announcement',
      exact: true,
      displayInNav: false,
      component: scenes.PublishedAnnouncement,
      routeProps: {
        section: 'announcements'
      }
    },

    {
      label: 'Release Notes',
      path: `/services/:id/release-notes`,
      childPath: [
        '/services/:id/draft-release-notes',
        '/services/:id/release-notes/:pageId'
      ],
      key: 'release-notes',
      exact: true,
      component: scenes.PublishedReleaseNotes,
      routeProps: {
        section: 'release-notes',
        sectionTitle: 'Release Notes',
        noResultsText: 'No published Release Notes'
      }
    },
    {
      label: 'Release Notes',
      path: `/services/:id/release-notes/:pageId`,
      key: 'release-note',
      exact: true,
      displayInNav: false,
      component: scenes.PublishedReleaseNote,
      routeProps: {
        section: 'release-notes'
      }
    },

    {
      label: 'Api Docs',
      path: `/services/:id/api-docs`,
      childPath: [
        '/services/:id/draft-api-docs',
        '/services/:id/api-docs/:pageId'
      ],
      key: 'api-docs',
      exact: true,
      component: scenes.PublishedApiDocs,
      routeProps: {
        section: 'api-docs',
        sectionTitle: 'Api Docs',
        noResultsText: 'No published Api Docs'
      }
    },
    {
      label: 'Api Docs',
      path: `/services/:id/api-docs/:pageId`,
      key: 'api-doc',
      exact: true,
      displayInNav: false,
      component: scenes.PublishedApiDoc,
      routeProps: {
        section: 'api-docs'
      }
    },

    {
      label: 'Open Api Specs',
      path: `/services/:id/open-api-specs`,
      childPath: [
        '/services/:id/draft-open-api-specs',
        '/services/:id/open-api-specs/:pageId'
      ],
      key: 'open-api-specs',
      exact: true,
      component: scenes.PublishedOpenApiSpecs,
      routeProps: {
        section: 'open-api-specs',
        sectionTitle: 'Open Api Specs',
        noResultsText: 'No published open api specs'
      }
    },
    {
      label: 'Open Api Spec',
      path: `/services/:id/open-api-specs/:pageId`,
      key: 'api-doc',
      exact: true,
      displayInNav: false,
      component: scenes.PublishedOpenApiSpec,
      routeProps: {
        section: 'open-api-specs'
      }
    },

    {
      label: 'Service Graph',
      path: `/services/:id/graph`,
      key: 'graph',
      exact: true,
      displayInNav: true,
      component: scenes.ServiceGraph
    },

    {
      label: 'Settings',
      path: `/services/:id/settings`,
      key: 'settings',
      exact: true,
      guard: true,
      component: scenes.Settings
    },

    {
      label: 'Draft Announcements',
      path: `/services/:id/draft-announcements`,
      key: 'announcement-drafts',
      exact: true,
      guard: true,
      component: scenes.DraftAnnouncements,
      routeProps: {
        title: 'Draft Announcements',
        section: 'announcements',
        noResultsText: 'No draft announcements'
      },
      displayInNav: false
    },
    {
      label: 'Draft Announcement',
      path: `/services/:id/draft-announcements/new`,
      key: 'announcement-draft-new',
      exact: true,
      guard: true,
      component: scenes.DraftAnnouncement,
      routeProps: {
        section: 'announcements',
        titlePlaceholder: 'Announcement Name'
      },
      displayInNav: false
    },
    {
      label: 'Draft Announcement',
      path: `/services/:id/draft-announcements/:draftId`,
      key: 'announcement-draft',
      exact: true,
      guard: true,
      component: scenes.DraftAnnouncement,
      routeProps: {
        section: 'announcements',
        titlePlaceholder: 'Announcement Name',
        handlePlaceholder: 'announcement-handle'
      },
      displayInNav: false
    },

    {
      label: 'Draft Release Notes',
      path: `/services/:id/draft-release-notes`,
      key: 'release-notes-drafts',
      exact: true,
      guard: true,
      component: scenes.DraftReleaseNotes,
      routeProps: {
        title: 'Draft Release Notes',
        section: 'release-notes',
        noResultsText: 'No draft release notes'
      },
      displayInNav: false
    },
    {
      label: 'Draft Release Note',
      path: `/services/:id/draft-release-notes/new`,
      key: 'release-notes-draft-new',
      exact: true,
      guard: true,
      component: scenes.DraftReleaseNote,
      routeProps: {
        section: 'release-notes',
        titlePlaceholder: 'Release Note Name'
      },
      displayInNav: false
    },
    {
      label: 'Draft Release Note',
      path: `/services/:id/draft-release-notes/:draftId`,
      key: 'release-notes-draft',
      exact: false,
      guard: true,
      component: scenes.DraftReleaseNote,
      routeProps: {
        section: 'release-notes',
        titlePlaceholder: 'Release Note Name',
        handlePlaceholder: 'release-note-handle'
      },
      displayInNav: false
    },
    {
      label: 'Draft Api Docs',
      path: `/services/:id/draft-api-docs`,
      key: 'api-doc-drafts',
      exact: true,
      guard: true,
      component: scenes.DraftApiDocs,
      routeProps: {
        title: 'Draft API Docs',
        section: 'api-docs',
        noResultsText: 'No draft API docs'
      },

      displayInNav: false
    },
    {
      label: 'Drafts Api Doc',
      path: `/services/:id/draft-api-docs/new`,
      key: 'api-doc-draft-new',
      exact: true,
      guard: true,
      component: scenes.DraftApiDoc,
      routeProps: {
        section: 'api-docs',
        titlePlaceholder: 'API Doc Name',
        handlePlaceholder: 'api-doc-handle'
      },
      displayInNav: false
    },
    {
      label: 'Drafts Api Doc',
      path: `/services/:id/draft-api-docs/:draftId`,
      key: 'api-doc-draft',
      exact: true,
      guard: true,
      component: scenes.DraftApiDoc,
      routeProps: {
        section: 'api-docs',
        titlePlaceholder: 'API Doc Name',
        handlePlaceholder: 'api-doc-handle'
      },
      displayInNav: false
    },

    {
      label: 'Draft Open Api Specs',
      path: `/services/:id/draft-open-api-specs`,
      key: 'api-doc-drafts',
      exact: true,
      guard: true,
      component: scenes.DraftOpenApiSpecs,
      routeProps: {
        title: 'Draft Open API Specs',
        section: 'open-api-specs',
        noResultsText: 'No draft open API specs'
      },

      displayInNav: false
    },
    {
      label: 'Draft Open Api Spec',
      path: `/services/:id/draft-open-api-specs/new`,
      key: 'open-api-spec-draft-new',
      exact: true,
      guard: true,
      component: scenes.DraftOpenApiSpec,
      routeProps: {
        section: 'open-api-specs',
        titlePlaceholder: 'Open API Spec Name',
        handlePlaceholder: 'open-api-spec-handle'
      },
      displayInNav: false
    },
    {
      label: 'Draft Open Api Spec',
      path: `/services/:id/draft-open-api-specs/:draftId`,
      key: 'open-api-spec-draft',
      exact: true,
      guard: true,
      component: scenes.DraftOpenApiSpec,
      routeProps: {
        section: 'open-api-specs',
        titlePlaceholder: 'Open API Spec Name',
        handlePlaceholder: 'open-api-spec-handle'
      },
      displayInNav: false
    }
  ].filter(route => {
    if (!route.guard || canEdit) {
      return true;
    }
    return false;
  });
};
