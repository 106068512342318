import React, { forwardRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router';

import {
  IconButton,
  Badge,
  Icon,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';

export const EventMenuItem = ({ event, handleClose }) => {
  const history = useHistory();

  const handleClick = useCallback(() => {
    const type = event.getIn(['subject', 'type']);
    const handle = event.getIn(['subject', 'handle']);
    const pathname = `/${type}s/${handle}`;
    history.replace({ pathname });
    handleClose();
  }, [event, handleClose]);

  const primaryText = event.getIn(['subject', 'name']);
  const secondaryText = event.getIn(['message']);

  return (
    <MenuItem onClick={handleClick} style={{ display: 'block' }}>
      <Typography variant="h6" gutterBottom>
        {primaryText}
      </Typography>
      <Typography variant="body1" noWrap>
        {secondaryText}
      </Typography>
    </MenuItem>
  );
};

const ForwardEventMenuItem = forwardRef((props, ref) => (
  <EventMenuItem {...props} innerRef={ref} />
));

const ActionMenu = props => {
  const { className, events } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => setAnchorEl(null));

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Badge badgeContent={events.size} color="secondary">
          <Icon style={{ color: '#fff' }} color="inherit">
            notifications
          </Icon>
        </Badge>
      </IconButton>
      <Menu
        className={className}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {events.map((event, i) => (
          <ForwardEventMenuItem
            key={event.get('id')}
            event={event}
            handleClose={handleClose}
          />
        ))}
      </Menu>
    </div>
  );
};

export default ActionMenu;
