import { of, concat } from 'rxjs';
import { switchMap, mergeMap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';

import ActionTypes from './actionTypes';

import { NS as AUTH_NS } from '@/reducers/auth';

export const getServices$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.WATCHED_SERVICES_GET),
    switchMap(() =>
      ajax(
        {
          url: `/users/${state$.value.getIn([AUTH_NS, 'username'])}/services`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        mergeMap(payload =>
          of({
            type: ActionTypes.WATCHED_SERVICES_GET_SUCCESS,
            payload
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.WATCHED_SERVICES_GET_FAIL
          })
        )
      )
    )
  );

export default [getServices$];
