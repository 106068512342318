import React, { PureComponent } from 'react';

import {
  Button,
  TextField,
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormControl
} from '@material-ui/core';
import { Container as Wapper } from '@/components';
import { useForm, Controller } from 'react-hook-form';

import { Main } from '@/components';

export default props => {
  const { pending, inviteUser } = props;

  const { control, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: ''
    }
  });

  const onSubmit = values => {
    inviteUser(values.email);
  };

  const { isDirty, isValid } = formState;

  return (
    <Wapper>
      <Main>
        <Container maxWidth="md">
          <Card>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardHeader
                title="Invite User"
                subheader="Enter an email to invite a user to this workspace"
              />
              <CardContent>
                <FormControl fullWidth>
                  <Controller
                    name="email"
                    label="Email"
                    control={control}
                    as={TextField}
                    autoComplete="off"
                  />
                </FormControl>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={pending}
                >
                  Invite User
                </Button>
              </CardActions>
            </form>
          </Card>
        </Container>
      </Main>
    </Wapper>
  );
};
