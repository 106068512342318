import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  FormControl
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    alignSelf: 'center',
    justifySelf: 'center',
    margin: 'auto',
    width: '80%',
    maxWidth: '400px'
  },
  actions: {
    justifyContent: 'space-between'
  },
  alert: { margin: '0 16px' }
});

const ForgotPassword = props => {
  const { success, error, forgotPassword, reset } = props;

  const styles = useStyles();

  const [submitting, setSubmitting] = useState(false);

  const { control, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: { email: '' }
  });

  useEffect(() => {
    if (success || error) {
      setSubmitting(false);
    }
  }, [success, error]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const onSubmit = form => {
    const { email } = form;
    setSubmitting(true);
    forgotPassword(email);
  };
  const { isValid } = formState;

  return (
    <Card className={styles.root} variant="outlined">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="Forgot Password" />
        {error ? (
          <Alert severity="error" className={styles.alert}>
            There was an issue while trying to reset your password
          </Alert>
        ) : null}
        {success ? (
          <Alert severity="success" className={styles.alert}>
            We emailed you a link to reset your password!
          </Alert>
        ) : null}
        <CardContent>
          <FormControl fullWidth>
            <Controller
              label="Email"
              name="email"
              as={TextField}
              control={control}
              rules={{
                required: true,
                pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
              }}
              fullWidth={true}
            />
          </FormControl>
        </CardContent>
        <CardActions className={styles.actions}>
          <Button
            className="mr-auto"
            to="/login"
            color="primary"
            component={Link}
          >
            Return to Login
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || submitting}
          >
            Submit
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default ForgotPassword;
