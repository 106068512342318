import { connect } from 'react-redux';
import { register, reset } from './../../ducks/actions';
import { NS } from './../../ducks/reducer';
import Register from './Register';

const mapStateToProps = state => ({
  submitting: state.getIn([NS, 'submitting', 'register']),
  error: state.getIn([NS, 'error', 'register'])
});

const mapDispatchToProps = dispatch => ({
  register: (betaKey, email) => dispatch(register(betaKey, email)),
//   reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
