export const NS = '@@grodoks/HOME';

export const ActionTypes = {
  USER_SERVICES_REQUEST: `${NS}/USER_SERVICES_REQUEST`,
  USER_SERVICES_SUCCESS: `${NS}/USER_SERVICES_SUCCESS`,
  USER_SERVICES_REJECTED: `${NS}/USER_SERVICES_REJECTED`,
  USER_TEAMS_REQUEST: `${NS}/USER_TEAM_REQUEST`,
  USER_TEAMS_SUCCESS: `${NS}/USER_TEAMS_SUCCESS`,
  USER_TEAMS_REJECTED: `${NS}/USER_TEAMS_REJECTED`
};

export default ActionTypes;
