import { connect } from 'react-redux';
import { NS } from './ducks/reducer';
import { NS as NS_USER } from '../../reducers/user';
import { getUsers, getInvites, updateUserRole } from './ducks/actions';
import Users from './Users';

const mapStateToProps = (state) => ({
  users: state.getIn([NS, 'users']),
  invites: state.getIn([NS, 'invites']),
  total: state.getIn([NS, 'pagination', 'users', 'total']),
  loading: state.getIn([NS, 'loading']),
  role: state.getIn([NS_USER, 'user', 'role'], false),
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (params) => dispatch(getUsers(params)),
  getInvites: (params) => dispatch(getInvites(params)),
  updateUserRole: (user, role) => dispatch(updateUserRole(user, role)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
