import { connect } from 'react-redux';

import { NS } from '@/screens/Service/ducks/reducer';
import { getOverviewIssues } from '@/screens/Service/ducks/actions';

import OpenIssues from './OpenIssues';
import './OpenIssues.scss';

const mapStateToProps = state => ({
  issues: state.getIn([NS, 'overviewIssues']),
  service: state.getIn([NS, 'service']),
  pending: state.getIn([NS, 'pending', 'overviewIssues'])
});

const mapDispatchToProps = dispatch => ({
  getIssues: (id, type, open) => dispatch(getOverviewIssues(id, type, open))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenIssues);
