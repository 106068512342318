import { connect } from 'react-redux';

import { NS } from '@/screens/Team/ducks/actionTypes';
import {
  getTeamMembersById,
  addTeamMember,
  toggleAddMemberModal,
  updateMemberRole,
  updateCrumbs
} from '@/screens/Team/ducks/actions';

import Members from './Members';

const mapStateToProps = state => ({
  team: state.getIn([NS, 'team']),
  members: state.getIn([NS, 'members']),
  total: state.getIn([NS, 'pagination', 'members', 'total']),
  loading: state.getIn([NS, 'pending', 'members']),
  canEdit: state.getIn([NS, 'team', 'is_admin'], false),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs))
});

const mapDispatchToProps = dispatch => ({
  getTeamMembers: (id, params) => dispatch(getTeamMembersById(id, params)),
  add: () => dispatch(toggleAddMemberModal(true)),
  updateMemberRole: (teamId, userId, role) =>
    dispatch(updateMemberRole(teamId, userId, role)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Members);
