import Health from './Health';

import { connect } from 'react-redux';
import { NS } from '../../ducks/actionTypes';
import { getHealth, updateCrumbs } from '../../ducks/actions';
import { getFormattedServiceHealth } from '../../ducks/selectors';
const mapStateToProps = state => ({
  team: state.getIn([NS, 'team']),
  health: getFormattedServiceHealth(state),
  pending: state.getIn([NS, 'pending', 'health']),
  canEdit: state.getIn([NS, 'team', 'is_admin'], false)
});

const mapDispatchToProps = dispatch => ({
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs)),
  getHealth: handle => dispatch(getHealth(handle))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Health);
