import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NS } from '@/screens/Service/ducks/reducer';

import {
  getDraftApiDoc,
  createDraftApiDoc,
  updateDraftApiDoc,
  publishDraftApiDoc,
  unpublishDraftApiDoc,
  saveAndPublishDraftApiDoc,
  resetDraftApiDoc,
  updateCrumbs
} from '@/screens/Service/ducks/actions';
import { DraftPage } from '../../components/DraftPage';

export const DraftApiDoc = props => {
  const { updateCrumbs } = props;
  useEffect(() => {
    updateCrumbs([
      {
        label: 'Draft Api Doc'
      }
    ]);
  }, []);
  return <DraftPage {...props} />;
};

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service']),
  draft: state.getIn([NS, 'draftApiDoc']),
  pending: state.getIn([NS, 'pending', 'draftApiDoc'])
});
const mapDispatchToProps = dispatch => ({
  get: draftId => dispatch(getDraftApiDoc(draftId)),
  create: (serviceId, draft) =>
    dispatch(createDraftApiDoc(serviceId, draft)),
  update: (draftId, draft) => dispatch(updateDraftApiDoc(draftId, draft)),
  publish: draftId => dispatch(publishDraftApiDoc(draftId)),
  unpublish: draftId => dispatch(unpublishDraftApiDoc(draftId)),
  saveAndPublish: (draftId, page) =>
    dispatch(saveAndPublishDraftApiDoc(draftId, page)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs)),

  reset: () => dispatch(resetDraftApiDoc())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraftApiDoc);
