import { Map, List, fromJS } from 'immutable';

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  success: false,
  error: false,
  slackWorkspaces: List(),
  ssoProviders: List(),
  ssoProvider: Map(),
  remoteWorkspaces: List(),
  pending: Map({
    slackWorkspaces: true,
    ssoProviders: true,
    ssoProviders: true,
    remoteWorkspaces: true
  }),
  visibility: Map({
    remoteWorkspace: false
  }),
  crumbs: []
});

export default function AdminReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SLACK_WORKSPACES_GET:
      return state
        .setIn(['pending', 'slackWorkspaces'], true)
        .set('error', false);

    case ActionTypes.SLACK_WORKSPACES_GET_SUCCESS:
      return state
        .setIn(['pending', 'slackWorkspaces'], false)
        .set('error', false)
        .set('slackWorkspaces', fromJS(action.payload));

    case ActionTypes.SLACK_WORKSPACES_POST_SUCCESS:
      return state

        .set('error', false)
        .set('slackWorkspaces', fromJS([action.payload]));

    case ActionTypes.SLACK_WORKSPACES_PATCH_SUCCESS:
      return state

        .set('error', false)
        .set('slackWorkspaces', fromJS([action.payload]));

    case ActionTypes.SSO_PROVIDERS_GET:
      return state.setIn(['pending', 'ssoProviders'], true).set('error', false);

    case ActionTypes.SSO_PROVIDERS_GET_SUCCESS:
      return state
        .setIn(['pending', 'ssoProviders'], false)
        .set('error', false)
        .set('ssoProviders', fromJS(action.payload));

    case ActionTypes.SSO_PROVIDER_GET:
      return state
        .set('ssoProvider', Map())
        .setIn(['pending', 'ssoProvider'], true);

    case ActionTypes.SSO_PROVIDER_GET_SUCCESS:
      return state
        .set('ssoProvider', fromJS(action.payload))
        .setIn(['pending', 'ssoProvider'], false);

    case ActionTypes.SSO_PROVIDER_POST_SUCCESS:
      return state
        .set('error', false)
        .set('ssoProvider', fromJS(action.payload));

    case ActionTypes.SSO_PROVIDER_PATCH_SUCCESS:
      return state
        .set('error', false)
        .set('ssoProvider', fromJS(action.payload));

    case ActionTypes.SSO_PROVIDER_RESET:
      return state
        .set('ssoProvider', Map())
        .setIn(['pending', 'ssoProvider'], true);

    case ActionTypes.REMOTE_WORKSPACES_GET:
      return state
        .setIn(['pending', 'remoteWorkspaces'], true)
        .set('error', false);

    case ActionTypes.REMOTE_WORKSPACES_GET_SUCCESS:
      return state
        .setIn(['pending', 'remoteWorkspaces'], false)
        .set('error', false)
        .set('remoteWorkspaces', fromJS(action.payload));

    case ActionTypes.REMOTE_WORKSPACE_MODAL_VISIBILITY_SET:
      return state.setIn(
        ['visibility', 'remoteWorkspace'],
        action.payload.visibility || false
      );

    case ActionTypes.UPDATE_CRUMBS:
      return state.set('crumbs', action.payload.crumbs || []);

    case ActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
}
