import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import { NS } from './actionTypes';

import moment from 'moment';

const getServicesHealth = state => state.getIn([NS, 'health']);

const GOOD = 'GOOD';
const MAINTENANCE = 'MAINTENANCE';
const WARNING = 'WARNING';
const ERROR = 'ERROR';
const CRITICAL = 'CRITICAL';

const getServiceHealthStatus = status => {
  const maintenance = status.get('maintenance');
  const warning = status.get('warning');
  const error = status.get('error');
  const critical = status.get('critical');

  if (critical) {
    return CRITICAL;
  }
  if (error) {
    return ERROR;
  }
  if (warning) {
    return WARNING;
  }
  if (maintenance) {
    return MAINTENANCE;
  }
  return GOOD;
};

const getServiceHealthMessages = status => {
  // const incidents = status.get('incidents');
  const maintenance = status.get('maintenance');
  const warning = status.get('warning');
  const error = status.get('error');
  const critical = status.get('critical');
  const messages = [];
  if (critical) {
    const time = moment().to(moment().add(critical, 'seconds'), true);
    messages.push(`${time} of critical`);
  }
  if (error) {
    const time = moment().to(moment().add(error, 'seconds'), true);
    messages.push(`${time} of error`);
  }
  if (warning) {
    const time = moment().to(moment().add(warning, 'seconds'), true);
    messages.push(`${time} of warning`);
  }
  if (maintenance) {
    const time = moment().to(moment().add(maintenance, 'seconds'), true);
    messages.push(`${time} of maintenance`);
  }
  return messages;
};

export const getFormattedServiceHealth = createSelector(
  [getServicesHealth],
  services => {
    return services.map(service => {
      const health = service.get('health');

      const data = health.map(result => {
        const time = result.get('date');
        const status = getServiceHealthStatus(result);
        const messages = getServiceHealthMessages(result);
        return Map({ time, status, messages });
      });

      const total = health.size * 864000;
      const downtime = health
        .map(
          item =>
            item.get('critical', 0) +
            item.get('error', 0) +
            item.get('maintenance', 0) +
            item.get('warning', 0)
        )
        .reduce((sum, incidents) => sum + incidents, 0);

      const uptime = parseFloat(100 - (downtime / total) * 100).toFixed(2);

      return Map({
        id: service.get('id'),
        name: service.get('name'),
        handle: service.get('handle'),
        uptime,
        data
      });
    });
  }
);
