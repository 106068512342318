import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

export const TeamMenu = props => {
  const location = useLocation();
  const { tabs } = props;
  return (
    <List className="TopHeader__buttons">
      {tabs.map(tab => (
        <ListItem
          key={tab.key}
          component={Link}
          to={tab.to}
          button
          className={location.pathname === tab.to ? 'active' : ''}
          selected={location.pathname === tab.to}
        >
          <ListItemText primary={tab.label} />
        </ListItem>
      ))}
    </List>
  );
};

export default TeamMenu;
