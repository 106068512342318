import { of, forkJoin } from 'rxjs';
import {
  switchMap,
  map,
  flatMap,
  pluck,
  tap,
  catchError
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';
import queryString from 'query-string';

import ActionTypes from '../actionTypes';

export const getServiceHealth$ = (action$, state$, id, params = {}) =>
  ajax(
    {
      url: `/services/${id}/reports/service-health-daily?${queryString.stringify(
        params || {}
      )}`,
      method: 'GET'
    },
    { action$, state$ }
  ).pipe(pluck('response'));

export const getHealth$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.HEALTH_GET),
    switchMap(action =>
      ajax(
        {
          url: `/teams/${action.payload.handle}/services`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        switchMap(res =>
          forkJoin(
            res.reduce(
              (prevState, s) => ({
                ...prevState,
                [s.id]: getServiceHealth$(action$, state$, s.id)
              }),
              {}
            )
          ).pipe(
            map(healths =>
              res.map(s => ({
                ...s,
                health: healths[s.id]
              }))
            )
          )
        ),

        flatMap(res =>
          of({
            type: ActionTypes.HEALTH_GET_SUCCESS,
            payload: res,
            error: true
          })
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.HEALTH_GET_FAIL
          })
        )
      )
    )
  );

export default [getHealth$];
