import { ajax, catchApiError } from '@/utils/ajax';
import { ofType } from 'redux-observable';
import { concat, of, defer, iif } from 'rxjs';
import {
  debounceTime,
  flatMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { addNotification } from '@/reducers/global';
import { NS as AUTH_NS } from '@/reducers/auth';
import ActionTypes from './actionTypes';

export const connectToSlack$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.CONNECT_TO_SLACK),
    // debounceTime(500),
    switchMap(({ payload: { token } }) =>
      ajax(
        {
          url: `/users/${state$.value.getIn([
            AUTH_NS,
            'username'
          ])}/actions/connect-to-slack`,
          method: 'POST',
          body: { token }
        },
        { action$, state$ }
      ).pipe(
        mergeMap(res =>
          concat(
            of({
              type: ActionTypes.CONNECT_TO_SLACK_SUCCESS,
              payload: res
            }),
            of(
              addNotification({
                message: 'Connected to Slack successfully',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, res =>
          concat(
            of({
              type: ActionTypes.CONNECT_TO_SLACK_FAIL,
              payload: res
            }),
            of(
              addNotification({
                message: 'There was an error while connecting to Slack',
                options: { variant: 'error' }
              })
            )
          )
        )
      )
    )
  );

export default [connectToSlack$];
