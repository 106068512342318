import { connect } from 'react-redux';

import { NS } from '../../ducks/actionTypes';

import {
  getSlackWorkspaces,
  createSlackWorkspace,
  updateSlackWorkspace,
  exportPages,
  updateCrumbs,
  changeRemoteWorkspaceModalVisibility
} from '../../ducks/actions';

import Overview from './Overview';

const mapStateToProps = state => ({
  pending: state.getIn([NS, 'pending', 'slackWorkspaces']),
  slackWorkspaces: state.getIn([NS, 'slackWorkspaces'])
});

const mapDispatchToProps = dispatch => ({
  getSlackWorkspaces: () => dispatch(getSlackWorkspaces()),
  createSlackWorkspace: token => dispatch(createSlackWorkspace(token)),
  updateSlackWorkspace: (id, token) =>
    dispatch(updateSlackWorkspace(id, token)),
  changeRemoteWorkspaceModalVisibility: visibility =>
    dispatch(changeRemoteWorkspaceModalVisibility(visibility)),
  exportPages: () => dispatch(exportPages()),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview);
