import { Map, List, fromJS } from 'immutable';

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  teams: List(),
  loading: true,
  error: false,
  pagination: Map({
    teams: Map({ total: 0 })
  })
});

export default function TeamsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.TEAMS_GET:
      return state.set('loading', true).set('error', false);

    case ActionTypes.TEAMS_GET_SUCCESS:
      return state
        .set('teams', fromJS(action.payload.teams))
        .setIn(['pagination', 'teams', 'total'], action.payload.total)
        .set('loading', false)
        .set('error', false);
    default:
      return state;
  }
}
