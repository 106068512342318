import React, { PureComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import './Breadcrumb.scss';
import { useState } from 'react';
import { useEffect } from 'react';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    padding: '0 2rem',
    margin: 0,
    listStyle: 'none',
    borderBottom: 'solid 1px #f0f0f0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '1.1rem'
  },
  crumbs: {
    display: 'flex',
    padding: '1rem 0',
    alignItems: 'center',
    listStyle: 'none',
    margin: 0
  },
  crumb: {
    display: 'flex',
    alignItems: 'center',

    '&:not(:last-child)': {
      '&::after': {
        content: "'/'",
        margin: '0 1rem',
        display: 'block',
        color: '#999'
      }
    }
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      'text-decoration': 'none'
    }
  },
  avatar: {
    height: '32px',
    width: '32px',
    marginRight: '1rem'
  }
});

const Crumb = props => {
  const classes = useStyles();
  if (props.data.path) {
    return (
      <li className={classes.crumb}>
        <Link
          component={RouterLink}
          to={props.data.path}
          className={classes.link}
        >
          {props.data.label}
        </Link>
      </li>
    );
  }
  return (
    <li className={classes.crumb}>
      <span>{props.data.label}</span>
    </li>
  );
};

const RootCrumb = props => {
  const { name, icon } = props;
  const classes = useStyles();
  const [initial, setInitial] = useState((name || '').charAt(0));
  if (props.path) {
    return (
      <li className={classes.crumb}>
        <Link component={RouterLink} to={props.path} className={classes.link}>
          {icon ? (
            <Avatar className={classes.avatar} alt={name} src={icon} mr={2}>
              {initial}
            </Avatar>
          ) : null}
          {name}
        </Link>
      </li>
    );
  }
  return (
    <li className={classes.crumb}>
      {icon ? (
        <Avatar className={classes.avatar} alt={name} src={icon} mr={2}>
          {initial}
        </Avatar>
      ) : null}
      {name}
    </li>
  );
};

const Breadcrumb = props => {
  const { icon, name, crumbs } = props;

  const [showCrumb, setShowCrumb] = useState(false);

  useEffect(() => {
    if (crumbs && crumbs.length) {
      setShowCrumb(true);
    } else {
      setShowCrumb(false);
    }
  }, [crumbs]);

  const classes = useStyles();

  const portal = React.useRef(null);

  return (
    <nav className={classes.root}>
      <ul className={classes.crumbs}>
        <RootCrumb
          icon={icon}
          name={name}
          path={showCrumb ? props.path : null}
        />

        {showCrumb && crumbs
          ? crumbs.map((crumb, idx) => <Crumb data={crumb} key={idx} />)
          : null}
      </ul>
      <span id="foo"></span>
    </nav>
  );
};

export default Breadcrumb;
