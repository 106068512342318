import React, { PureComponent } from 'react';

import { Switch, Route } from 'react-router-dom';
import { CircularProgress, Box } from '@material-ui/core';
import { Container, Main, Sidenav, NoMatch, Breadcrumb } from '@/components';
import {
  ServiceMenu,
  HealthcheckForm,
  NoticeForm,
  NoticeViewModal,
  DependencyForm,
  RemoteDependencyForm
} from './components';

import { getRoutes } from './routes';

import './Service.scss';

class Service extends PureComponent {
  state = { routes: [] };
  componentDidMount() {
    const {
      getService,
      match: {
        params: { id }
      }
    } = this.props;

    getService(id);
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  componentDidUpdate(previousProps) {
    if (this.props.match.params.id !== previousProps.match.params.id) {
      this.props.reset();
      this.props.getService(this.props.match.params.id);
    }
    if (previousProps.team !== this.props.team) {
      const routes = this.getRoutes();
      this.setState({ routes });
    }
  }

  getRoutes() {
    return getRoutes(this.props);
  }

  render() {
    const {
      pending,
      teamPending,
      service,
      location,
      notFound,
      crumbs
    } = this.props;
    const { routes } = this.state;

    if (pending || teamPending) {
      return (
        <Container>
        <Box p={20} mx="auto" display="flex" justifyContent="center">
            <CircularProgress size={48} />
          </Box>
        </Container>
      );
    }

    if (notFound) {
      return <NoMatch text="The service was not found" />;
    }

    return (
      <Container>
        <Main
          breadcrumb={
            <Breadcrumb
              icon={service.get('thumbnail_url')}
              name={service.get('name')}
              path={`/services/${service.get('handle')}`}
              pending={pending}
              crumbs={crumbs}
            />
          }
        >
          {!pending && (
            <Switch>
              {routes.map(
                ({
                  key,
                  exact,
                  path,
                  component: Component,
                  routeProps = {}
                }) => (
                  <Route
                    key={key}
                    exact={exact}
                    path={path}
                    render={() => <Component {...routeProps} />}
                  />
                )
              )}
              <Route component={NoMatch} />;
            </Switch>
          )}
        </Main>

        <Sidenav>
          <ServiceMenu
            tabs={routes}
            serviceId={service.get('handle')}
            location={location}
            serviceLinks={service.get('links', [])}
          />
        </Sidenav>
        <NoticeForm />
        <HealthcheckForm />
        <DependencyForm />
        <NoticeViewModal />
        <RemoteDependencyForm />
      </Container>
    );
  }
}

export default Service;
