import { of, concat } from 'rxjs';
import { switchMap, flatMap, map, tap, pluck } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';

import ActionTypes from '../actionTypes';

export const getGraph$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.GRAPH_GET),
    switchMap(({ payload: { serviceHandle, depth = 2 } }) =>
      ajax(
        {
          url: `/services/${serviceHandle}/graph?depth=${depth}`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        pluck('response'),
        flatMap(res =>
          concat(
            of({
              type: ActionTypes.GRAPH_GET_SUCCESS,
              payload: res
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.GRAPH_GET_FAIL
          })
        )
      )
    )
  );

export default [getGraph$];
