import React, { PureComponent, useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  CircularProgress,
  Avatar,
  Button,
  Toolbar,
  Icon,
  Card,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Link
} from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import { Main, Container, SearchInput, TablePagination } from '@/components';

const TeamContent = ({ teams, loading }) => {
  if (loading) {
    return (
      <TableRow>
        <TableCell
          colSpan={4}
          align="center"
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <CircularProgress size={27} />
        </TableCell>
      </TableRow>
    );
  }

  if (!teams.size) {
    return (
      <TableRow>
        <TableCell colSpan={4} align="center">
          No Teams.
        </TableCell>
      </TableRow>
    );
  }

  return teams.map(team => {
    const name = team.get('name');
    const handle = team.get('handle');
    const initial = name.charAt(0).toUpperCase();
    const icon = team.get('thumbnail_url', null);
    return (
      <TableRow key={handle}>
        <TableCell style={{ paddingTop: '0', paddingBottom: '0' }}>
          <Avatar alt={initial} src={icon}>
            {initial}
          </Avatar>
        </TableCell>
        <TableCell>
          <Link component={RouterLink} to={`/teams/${handle}`}>
            {name}
          </Link>
        </TableCell>
        {/* <TableCell>{team.get('handle')}</TableCell> */}
        <TableCell>{team.get('description') || '—'}</TableCell>
      </TableRow>
    );
  });
};

export default props => {
  const { user, teams, pending, getTeams, updateCrumbs } = props;

  //   const [limit, setLimit] = useState(20);
  //   const [offset, setOffset] = useState(0);
  //   const [query, setQuery] = useState('');

  useEffect(() => {
    const handle = user.get('handle');
    getTeams(handle);
    updateCrumbs([{ label: 'Teams' }]);
  }, []);

  //   const updateOffset = offset => {
  //     setOffset(offset);
  //   };

  return (
    <div>
      <Paper className="container Teams">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '72px' }} />
                <TableCell style={{ width: '30%' }}>Name</TableCell>

                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TeamContent teams={teams} loading={pending} />
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
              limit={limit}
              offset={offset}
              total={total}
              updateOffset={updateOffset}
            /> */}
      </Paper>
    </div>
  );
  // }
};
