import { createTypes, asyncType, createNS } from '@/utils/actionCreator';

export const NS = createNS('CONNECT_TO_SLACK');

export const ActionTypes = createTypes(
  [...asyncType('CONNECT_TO_SLACK'), 'RESET'],
  NS
);

export default ActionTypes;
