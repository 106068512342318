import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Actions as authActions } from '@/reducers/auth';

import { IconButton, Box, Avatar, Menu, MenuItem } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  userButton: {
    padding: '8px',
  },

  userMenu: {
    minWidth: '120px',
  },
}));

export const UserMenu = (props) => {
  const { user, logout } = props;

  const styles = useStyles();

  const [isAdmin, setIsAdmin] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (
      user.size &&
      (user.get('role') === 'owner' || user.get('role') === 'admin')
    ) {
      setIsAdmin(true);
    }
  }, [user]);

  if (!user.size) return null;

  return (
    <div>
      <IconButton className={styles.userButton} onClick={handleClick}>
        <Avatar
          style={{ width: '32px', height: '32px' }}
          alt={user.get('name')}
          src={user.get('thumbnail_url')}
        >
          {user.get('name').charAt(0)}
        </Avatar>
      </IconButton>
      <Menu
        classes={{ paper: styles.userMenu }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to={`/users/${user.get('handle')}`}
          onClick={handleClose}
        >
          Profile
        </MenuItem>

        {isAdmin
          ? [
              <MenuItem
                key="admin"
                component={Link}
                to={'/admin'}
                onClick={handleClose}
              >
                Admin
              </MenuItem>,
              <MenuItem
                key="billing"
                component={Link}
                to={'/billing'}
                onClick={handleClose}
              >
                Billing
              </MenuItem>,
            ]
          : null}

        <MenuItem onClick={logout}>Log Out</MenuItem>
      </Menu>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  logout: (payload) => dispatch(authActions.logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserMenu));
