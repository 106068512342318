import { Map, fromJS } from 'immutable';

import {
  createTypes,
  asyncType,
  createAction,
  createNS
} from '@/utils/actionCreator';

export const NS = createNS('AUTH');

export const ActionTypes = createTypes(
  [
    'INIT_AUTH',
    ...asyncType('WORKSPACE_REQUEST'),
    ...asyncType('AUTH_REQUEST'),
    ...asyncType('AUTH_REFRESH'),
    ...asyncType('LOGOUT')
  ],
  NS
);

export const initAuth = () => ({
  type: ActionTypes.INIT_AUTH
});

export const getWorkspace = () => ({
  type: ActionTypes.WORKSPACE_REQUEST
});

export const getToken = payload => ({
  type: ActionTypes.AUTH_REQUEST,
  payload: payload
});

export const setToken = payload => ({
  type: ActionTypes.AUTH_REQUEST_SUCCESS,
  payload: payload
});

export const logout = () => ({
  type: ActionTypes.LOGOUT
});

export const Actions = {
  getToken,
  setToken,
  logout
};

const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const initialState = new Map({
  workspace: null,
  token: null,
  error: null,
  username: null,
  workspaceNotFound: false,
  pending: new Map({ token: true, refresh: true, workspace: true })
  // pending: true
});

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // Workspace
    case ActionTypes.WORKSPACE_REQUEST: {
      return state
        .setIn(['workspace'], null)
        .setIn(['pending', 'workspace'], true);
    }
    case ActionTypes.WORKSPACE_REQUEST_SUCCESS: {
      return state
        .setIn(['workspace'], fromJS(payload))
        .setIn(['pending', 'workspace'], false);
    }
    case ActionTypes.WORKSPACE_REQUEST_FAIL: {
      return state
        .setIn(['workspaceNotFound'], true)
        .setIn(['pending', 'token'], false)
        .setIn(['pending', 'workspace'], false);
    }


    
    // Get Token
    case ActionTypes.AUTH_REQUEST: {
      return state.setIn(['pending', 'token'], true);
    }
    case ActionTypes.AUTH_REQUEST_SUCCESS: {
      const jwt = parseJwt(payload.access_token);
      const [, , username] = jwt.sub.split(':');

      return state
        .set('token', payload.access_token)
        .set('username', username)
        .setIn(['pending', 'token'], false)
        .set('error', false);
    }
    case ActionTypes.AUTH_REQUEST_FAIL: {
      return state
        .set('token', null)
        .setIn(['pending', 'token'], false)
        .set('error', true);
    }
    // Refresh Token
    case ActionTypes.AUTH_REFRESH: {
      return state.setIn(['pending', 'refresh'], true);
    }
    case ActionTypes.AUTH_REFRESH_SUCCESS: {
      return state
        .set('token', payload.access_token)
        .setIn(['pending', 'refresh'], false)
        .set('error', false);
    }
    case ActionTypes.AUTH_REFRESH_FAIL: {
      return state
        .set('token', null)
        .setIn(['pending', 'token'], false)
        .set('error', true);
    }

    // Logout
    case ActionTypes.LOGOUT_SUCCESS: {
      return state.set('token', null);
    }

    default:
      return state;
  }
};

export default authReducer;
