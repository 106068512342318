import { connect } from 'react-redux';

import { NS } from '@/screens/Team/ducks/actionTypes';
import {
  updateTeamById,
  updateTeamAvatarById,
  clearTeamIconById,
  getTeamPoliciesById,
  updateTeamPoliciesById,
  getServiceCount,
  deleteTeam,
  toggleDeleteModal,
  updateCrumbs
} from '@/screens/Team/ducks/actions';

import Settings from './Settings';

const mapStateToProps = state => ({
  team: state.getIn([NS, 'team']),
  policies: state.getIn([NS, 'policies']),
  pending: state.getIn([NS, 'pending', 'policies']),
  showDeleteConfirm: state.getIn([NS, 'deleteModalVisible']),
  serviceCount: state.getIn([NS, 'serviceCount']),
});

const mapDispatchToProps = dispatch => ({
  update: ({ id, body }) => dispatch(updateTeamById(id, body)),
  getPolicies: id => dispatch(getTeamPoliciesById(id)),
  updatePolicies: ({ id, body }) => dispatch(updateTeamPoliciesById(id, body)),
  upload: ({ id, body }) => dispatch(updateTeamAvatarById(id, body)),
  clear: ({ id }) => dispatch(clearTeamIconById(id)),
  getServiceCount: id => dispatch(getServiceCount(id)),
  deleteTeam: id => dispatch(deleteTeam(id)),
  showDeleteWarning: show => dispatch(toggleDeleteModal(show)),
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
