import React, { PureComponent } from 'react';

import {
  CircularProgress,
  Button,
  Toolbar,
  // Icon,
  Card,
  CardHeader,
  // Divider,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Portal,
  Link
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';

import Moment from 'react-moment';

const TITLE_DATE_FORMAT = 'MMM D, YYYY';

const PublishedPagesRows = ({
  rows,
  nameField = 'title',
  handle: serviceHandle,
  section,
  noResultsText,
  loading
}) => {
  if (loading) {
    return (
      <TableRow>
        <TableCell
          colSpan={2}
          align="center"
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <CircularProgress size={24} />
        </TableCell>
      </TableRow>
    );
  }

  if (!rows.size) {
    return (
      <TableRow>
        <TableCell colSpan={2}>{noResultsText || 'No pages Found'}</TableCell>
      </TableRow>
    );
  }
  return rows.map(row => {
    const id = row.get('id');
    const handle = row.get('id');
    const title = row.get(nameField);
    const updated_at = row.get('updated_at');

    return (
      <TableRow key={id}>
        <TableCell>
          <Link
            component={RouterLink}
            to={`/services/${serviceHandle}/${section}/${id}`}
          >
            {title}
          </Link>
        </TableCell>
        <TableCell>
          <Moment format={TITLE_DATE_FORMAT}>{updated_at}</Moment>
        </TableCell>
      </TableRow>
    );
  });
};

export class PublishedPages extends PureComponent {
  componentDidMount() {
    const { getPublishedPages, service } = this.props;
    const handle = service.get('handle');
    getPublishedPages(handle);
  }

  render() {
    const {
      publishedPages,
      pending,
      section,
      service,
      canEdit,
      sectionTitle,
      noResultsText,
      nameField
    } = this.props;
    const handle = service.get('handle');

    return (
      <Card>
        {canEdit ? (
          <Portal container={document.getElementById('foo')}>
            <Button
              className="ml-auto"
              color="primary"
              component={RouterLink}
              to={`/services/${service.get('handle')}/draft-${section}`}
            >
              Manage
            </Button>
          </Portal>
        ) : null}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <PublishedPagesRows
                nameField={nameField}
                rows={publishedPages}
                section={section}
                loading={pending}
                handle={handle}
                noResultsText={noResultsText}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    );
  }
}
