import { fromJS, Map } from 'immutable';
export const NS = '@@visr/404';

export const NOT_FOUND = `${NS}/TEAM_REQUEST`;
export const RESET = `${NS}/RESET`;

export const ActionTypes = {
  NOT_FOUND,
  RESET
};

export const setNotFound = payload => ({
  type: NOT_FOUND,
  payload: true
});

export const reset = payload => ({
  type: RESET
});

export const Actions = {
  setNotFound
};

const initialState = new Map({
  notFound: false
});

const notFoundReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case NOT_FOUND: {
      return state.set('notFound', true);
    }
    case RESET: {
      return state.set('notFound', false);

    }

    default:
      return state;
  }
};

export default notFoundReducer;
