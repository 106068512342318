import { createAction } from '@/utils/actionCreator';
import ActionTypes from './actionTypes';

export const resetPassword = createAction(
  ActionTypes.RESET_PASSWORD,
  'key',
  'password'
);

export const reset = createAction(ActionTypes.RESET);
