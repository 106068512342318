import { connect } from 'react-redux';

import { NS } from '@/screens/Service/ducks/reducer';
import {
  getNotices,
  toggleNoticeModal,
  toggleViewNoticeModal,
  updateCrumbs
} from '@/screens/Service/ducks/actions';

import Notices from './Notices';

import './Notice.scss';

const mapStateToProps = state => ({
  service: state.getIn([NS, 'service']),
  notices: state.getIn([NS, 'notices']),
  pending: state.getIn([NS, 'pending', 'notices']),
  canEdit: state.getIn([NS, 'team', 'is_member'], false)
});

const mapDispatchToProps = dispatch => ({
  createNotice: () => dispatch(toggleNoticeModal(true)),
  getNotices: (id, type, status) => dispatch(getNotices(id, type, status)),
  edit: record => {
    dispatch(toggleNoticeModal(true, record));
  },
  view: record => {
    dispatch(toggleViewNoticeModal(true, record));
  },
  updateCrumbs: crumbs => dispatch(updateCrumbs(crumbs))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notices);
