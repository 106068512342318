import { of, concat } from 'rxjs';
import { switchMap, mergeMap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';

import ActionTypes from '../actionTypes';

export const getRunners$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.RUNNERS_GET),
    switchMap(({ payload: { teamId } }) =>
      ajax(
        {
          url: `/teams/${teamId}/runners`,
          method: 'GET',
        },
        { action$, state$ }
      ).pipe(
        map((res) => res.response),
        mergeMap((payload) =>
          concat(
            of({
              type: ActionTypes.RUNNERS_GET_SUCCESS,
              payload,
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.RUNNERS_GET_FAIL,
          })
        )
      )
    )
  );

export default [getRunners$];
