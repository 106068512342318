import { createTypes, asyncType, createNS } from '@/utils/actionCreator';

export const NS = createNS('RESET_PASSWORD');

export default createTypes(
  [
    ...asyncType('RESET_PASSWORD'),
    'RESET'
  ],
  `${NS}`
);
