import { Map, List, fromJS } from 'immutable';
// import { createSelector } from "reselect";

import ActionTypes, { NS } from './actionTypes';

export { NS };

const initialState = Map({
  services: List(),
  teams: List(),
  pending: Map({
    teams: true,
    services: true
  })
});

export default function LoginReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.USER_SERVICES_REQUEST:
      return state.setIn(['pending', 'services'], true);
    case ActionTypes.USER_SERVICES_SUCCESS:
      return state
        .setIn(['pending', 'services'], false)
        .set('services', fromJS(action.payload));
    case ActionTypes.USER_SERVICES_REJECTED:
      return state
        .setIn(['pending', 'services'], false)
        .set('services', List());

    case ActionTypes.USER_TEAMS_REQUEST:
      return state.setIn(['pending', 'teams'], true);
    case ActionTypes.USER_TEAMS_SUCCESS:
      return state
        .setIn(['pending', 'teams'], false)
        .set('teams', fromJS(action.payload));
    case ActionTypes.USER_TEAMS_REJECTED:
      return state.setIn(['pending', 'teams'], false).set('teams', List());
    default:
      return state;
  }
}
