import {
  createTypes,
  asyncType,
  createNS
} from '@/utils/actionCreator';

export const NS = createNS('INVITE');

export default createTypes(
  [
    ...asyncType('CREATE_USER'),
  ],
  `${NS}`
);