import { of, concat } from 'rxjs';
import { switchMap, flatMap, merge, map, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax, catchApiError } from '@/utils/ajax';

import { addNotification } from '@/reducers/global';
import ActionTypes from '../actionTypes';

export const getClients$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.CLIENTS_GET),
    switchMap(({ payload: { teamId } }) =>
      ajax(
        {
          url: `/teams/${teamId}/clients`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.CLIENTS_GET_SUCCESS,
              payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.CLIENTS_GET_FAIL
          })
        )
      )
    )
  );

export const getClient$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.CLIENT_GET),
    switchMap(({ payload: { teamId, clientId } }) =>
      ajax(
        {
          url: `/teams/${teamId}/clients/${clientId}`,
          method: 'GET'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.CLIENT_GET_SUCCESS,
              payload
            })
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.CLIENT_GET_FAIL
          })
        )
      )
    )
  );

export const createClient$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.CLIENT_CREATE),
    switchMap(({ payload: { teamId, client } }) =>
      ajax(
        {
          url: `/teams/${teamId}/clients`,
          method: 'POST',
          body: client
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.CLIENT_CREATE_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Team Client Created',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.CLIENT_CREATE_FAIL
          })
        )
      )
    )
  );

export const updateClient$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.CLIENT_UPDATE),
    switchMap(({ payload: { teamId, clientId, client } }) =>
      ajax(
        {
          url: `/teams/${teamId}/clients/${clientId}`,
          method: 'PATCH',
          body: client
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.CLIENT_UPDATE_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Team Client Updated',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.CLIENT_UPDATE_FAIL
          })
        )
      )
    )
  );

export const updateClientSecret$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.CLIENT_SECRET_UPDATE),
    switchMap(({ payload: { teamId, clientId } }) =>
      ajax(
        {
          url: `/teams/${teamId}/clients/${clientId}/actions/update-secret`,
          method: 'POST'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(payload =>
          concat(
            of({
              type: ActionTypes.CLIENT_SECRET_UPDATE_SUCCESS,
              payload
            }),
            of(
              addNotification({
                message: 'Team Client Secret Updated',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.CLIENT_SECRET_UPDATE_FAIL
          })
        )
      )
    )
  );

export const deleteClient$ = (action$, state$) =>
  action$.pipe(
    ofType(ActionTypes.CLIENT_DELETE),
    switchMap(({ payload: { teamId, clientId } }) =>
      ajax(
        {
          url: `/teams/${teamId}/clients/${clientId}`,
          method: 'DELETE'
        },
        { action$, state$ }
      ).pipe(
        map(res => res.response),
        flatMap(() =>
          concat(
            of({
              type: ActionTypes.CLIENT_DELETE_SUCCESS,
              payload: { clientId }
            }),
            of(
              addNotification({
                message: 'Team Client Removed',
                options: { variant: 'success' }
              })
            )
          )
        ),
        catchApiError(action$, () =>
          of({
            type: ActionTypes.CLIENT_DELETE_FAIL
          })
        )
      )
    )
  );

export default [
  getClients$,
  getClient$,
  createClient$,
  updateClient$,
  updateClientSecret$,
  deleteClient$
];
