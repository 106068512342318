import React, { PureComponent } from 'react';
import { Card, Button, CardHeader, Divider, Portal } from '@material-ui/core';

import { StatusList } from '../../components';

import NoticeFilters from './NoticeFilters';

class Notices extends PureComponent {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     open: true,
  //     type: 'incident,maintenance'
  //   };
  // }
  state = {
    open: true,
    type: 'incident,maintenance'
  };

  componentDidMount() {
    const { getNotices, service } = this.props;
    const { type, open } = this.state;
    const id = service.get('id');

    getNotices(id, type, open ? 'open' : 'closed');

    this.props.updateCrumbs([{ label: 'Notices' }]);
  }

  handleFilterChange = ({ type, open }) => {
    const { getNotices, service } = this.props;
    const id = service.get('id');
    getNotices(id, type, open ? 'open' : 'closed');
    this.setState({ type, open });
  };

  render() {
    const {
      notices = [],
      pending,
      view,
      edit,
      createNotice,
      canEdit,
      team
    } = this.props;
    const { type, open } = this.state;

    return (
      <Card className="ServiceAnnouncements">
        {/* <CardHeader
          title="Notices"
          action={
            canEdit ? (
              <Button
                color="primary"
                className="ml-auto"
                onClick={createNotice}
              >
                Add
              </Button>
            ) : null
          }
        /> */}
        {/* <Divider /> */}
        {canEdit ? (
          <Portal container={document.getElementById('foo')}>
            <Button color="primary" className="ml-auto" onClick={createNotice}>
              Add Notice
            </Button>{' '}
          </Portal>
        ) : null}

        <NoticeFilters
          open={open}
          type={type}
          onChange={this.handleFilterChange}
        />
        <StatusList
          items={notices}
          view={view}
          edit={edit}
          canEdit={canEdit}
          pending={pending}
        />
      </Card>
    );
  }
}

export default Notices;
