import { connect } from 'react-redux';

import { NS } from '../../ducks/actionTypes';

import {
  listRemoteWorkspaces,
  linkRemoteWorkspace,
  unlinkRemoteWorkspace
} from '../../ducks/actions';

import RemoteWorkspaces from './RemoteWorkspaces';

const mapStateToProps = state => ({
  pending: state.getIn([NS, 'pending', 'remoteWorkspaces']),
  remoteWorkspaces: state.getIn([NS, 'remoteWorkspaces'])
  //   workspace: state.getIn([GLOBAL_NS, 'workspace']),
  //   user: state.getIn([USER_NS, 'user'])
});

const mapDispatchToProps = dispatch => ({
  listRemoteWorkspaces: () => dispatch(listRemoteWorkspaces()),
  linkRemoteWorkspace: remote => dispatch(linkRemoteWorkspace(remote)),
  unlinkRemoteWorkspace: remote => dispatch(unlinkRemoteWorkspace(remote))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoteWorkspaces);
